import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Spinner } from "reactstrap";

import { useToken } from "../Hooks/token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";

import "./images.scss";

const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp"];

const ImageUploader = (props) => {
  const { data, type, element, imageFor, pageRef, setRefreshFlag, match } = props;
  const [isLoading, setIsLoading] = useState();
  const { readBody = (body) => body.json() } = {};
  const env = useContext(EnvironmentContext);
  const { token } = useToken();

  function doDrop(files) {
    if (allowedFileTypes.includes(files[0].type)) {
      (async () => {
        setIsLoading(true);
        if (data[element]) {
          let endpoint =
            "v1/" +
            match.params.sport +
            "/o/" +
            data.organizationId +
            "/images/for/" +
            imageFor +
            "/" +
            data[element] +
            "/" +
            type;
          let url = environments["location"][env] + "/" + endpoint;

          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                Authorization: "Bearer " + token.token
              },
              body: files[0]
            });
            if (response.ok) {
              // const body = await readBody(response);
              // console.log("ok", body);
              pageRef.current.addFlash("Image uploaded successfully", "success", 10);
              setIsLoading(false);
              setRefreshFlag(true);
            } else {
              const body = await readBody(response);
              console.log("error", body);
              pageRef.current.addFlash("An error occurred", "danger", 10);
              setIsLoading(false);
            }
          } catch (e) {
            console.log("E", e);
            pageRef.current.addFlash("An error occurred", "danger", 10);
            setIsLoading(false);
          }
        }
      })();
    }
  }
  return (
    <>
      {!isLoading && (
        <Dropzone onDrop={(acceptedFiles) => doDrop(acceptedFiles)} accept={["image/jpeg", "image/png"]}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section className={"dropZone dragActive-" + isDragActive}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drop an image or click</p>
              </div>
            </section>
          )}
        </Dropzone>
      )}
      {isLoading && <Spinner size="sm" color="danger" />}
    </>
  );
};

export default withRouter(ImageUploader);

import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Collapse } from "reactstrap";

export default function FormCollapse(props) {
  const {
    closedId,
    closedDefaultMessage,
    openedId = "form.hide",
    openedDefaultMessage = "Hide Form",
    children
  } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <>
      <hr />
      <h4 onClick={() => setIsFormOpen(!isFormOpen)}>
        {!isFormOpen && (
          <span>
            <FormattedMessage id={closedId} defaultMessage={closedDefaultMessage} />{" "}
            <i className="fas fa-caret-down"></i>
          </span>
        )}
        {isFormOpen && (
          <span>
            <FormattedMessage id={openedId} defaultMessage={openedDefaultMessage} /> <i className="fas fa-caret-up"></i>
          </span>
        )}
      </h4>
      <Collapse isOpen={isFormOpen}>{children}</Collapse>
    </>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
// import { useUpdateToken } from "../Base/Hooks/updateToken";

import OrganizationRow from "./OrganizationRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";
import countryCodes from "../../config/countryCodes";

const Organizations = (props) => {
  const { title, updateState, history, intl } = props;
  const { formatMessage } = intl;
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch("/v1/info/access/available", "", refreshFlag, "token");

  const columns = [
    "OrganizationId",
    formatMessage({
      id: "name.local",
      defaultMessage: "Local Name"
    }),
    formatMessage({
      id: "name.latin",
      defaultMessage: "Name"
    }),
    formatMessage({
      id: "sport",
      defaultMessage: "Sport"
    }),
    ""
  ];
  const LookupData = [];
  LookupData["regionTypes"] = ["INTERNATIONAL", "NATIONAL", "INTERSTATE", "STATE", "LOCAL"];
  LookupData["countryCodes"] = countryCodes;

  useEffect(() => {
    if (data) {
      let filteredData = data !== null ? data.data.organizations : [];
      filteredData.sort(function (a, b) {
        var aMixed = a.nameLocal;
        var bMixed = b.nameLocal;
        return aMixed < bMixed ? -1 : 1;
      });
      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return (
            (result.organizationId !== null &&
              result.organizationId.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.nameLocal !== null && result.nameLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.nameLatin !== null && result.nameLatin.toUpperCase().indexOf(searchText.toUpperCase()) > -1)
          );
        });
      }
      let tableList = filteredData.map((result, index) => (
        <OrganizationRow
          key={result.organizationId}
          itemIndex={index}
          result={result}
          updateState={updateState}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  // function reloadData() {
  //   setRefreshFlag(true);
  // }

  return (
    <React.Fragment>
      <PageDisplay
        title="Organizations"
        error={error}
        pageTitle={"Organizations - " + title}
        ref={pageRef}
        history={history}
        // layout={layout}
        // setLayout={setLayout}
      >
        <SearchFilter doSearch={setSearchText} initial={searchText} />
        <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Organizations);

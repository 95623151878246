import React from "react";
import { FormattedMessage } from "react-intl";

function LiveStandings(props) {
  const handleCheckboxChange = (e) => {
    const qp = new URLSearchParams(props.queryParameters);
    if (e.target.checked) {
      qp.set("live", true);
    } else {
      qp.delete("live");
    }
    props.setQueryParameters(qp);
  };
  return (
    <div className="checkbox liveStandings">
      <label>
        <input onChange={handleCheckboxChange} type="checkbox" />
        <FormattedMessage id="live.standings" defaultMessage="Live Standings" />
        <span className="cr">
          <i className="cr-icon fa fa-check" />
        </span>
      </label>
    </div>
  );
}

export default LiveStandings;

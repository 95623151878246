import { useContext, useState, useEffect } from "react";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
import defaultSetupConfiguration from "../../../config/defaultSetupConfiguration";
import { useAuth0 } from "../../../Auth/react-auth0-wrapper";

export const useUpdateToken = (
  organizationId,
  productCode = defaultSetupConfiguration.options.productCode,
  subProduct = defaultSetupConfiguration.options.subProduct
) => {
  const [tokenUpdated, setTokenUpdated] = useState(false);
  const [customer, setCustomer] = useState(null);
  const { loading, isAuthenticated } = useAuth0();

  const [authToken, setAuthToken] = useState(null);
  const [rawProductList, setRawProductList] = useState(null);
  const { readBody = (body) => body.json() } = {};
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvironmentContext);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const tokenName = subProduct
    ? subProduct.replace(/\s/g, "_").toLowerCase() + "_" + productCode + "_tokenDetails"
    : productCode + "_tokenDetails";

  useEffect(() => {
    let tokenDetails = localStorage.getItem(tokenName) ? JSON.parse(localStorage.getItem(tokenName)) : null;
    if (tokenDetails) {
      if (tokenDetails.organizationId === organizationId) {
      } else {
        setCustomer(tokenDetails.customerId);
      }
    }
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (!loading && isAuthenticated) {
        try {
          const authToken = await getTokenSilently();
          setAuthToken(authToken);
        } catch (e) {
          console.log("EE (getToken):", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    (async () => {
      if (customer !== null && rawProductList && authToken) {
        let url = environments.token[env] + "/v1/oauth2/rest/token";
        let storedToken = JSON.parse(localStorage.getItem(tokenName));
        let payload = JSON.parse(atob(storedToken.token.split(".")[1]));
        let values = {
          userIdentityToken: authToken,
          applicationId: payload.appId,
          sport: "basketball",
          organization: {
            id: [organizationId]
          }
        };
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json"
            },
            signal: signal,
            body: JSON.stringify(values)
          });
          if (response.ok) {
            const body = await readBody(response);
            let expiryDelta = body.data.expiresIn * 1000;
            let expiry = expiryDelta + Date.now();
            let token = {
              token: body.data.token,
              tokenType: body.data.tokenType,
              expiry: expiry,
              customerId: customer,
              organizationId: organizationId
            };

            localStorage.setItem(tokenName, JSON.stringify(token));
            setTokenUpdated(true);
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [customer, authToken, rawProductList]);

  useEffect(() => {
    (async () => {
      if (authToken && customer > 0) {
        let url = environments.token[env] + "/v1/list/products";
        try {
          let values = {
            userIdentityToken: authToken,
            customerId: String(customer)
          };
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json"
            },
            signal: signal,
            body: JSON.stringify(values)
          });
          if (response.ok) {
            const body = await readBody(response);
            setRawProductList(body.data.products);
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();

    // eslint-disable-next-line
  }, [customer, authToken]);

  return { tokenUpdated };
};

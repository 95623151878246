import React from "react";
import { injectIntl } from "react-intl";

const TeamCoachStatsRow = (props) => {
  const { itemIndex, result, type } = props;

  function roundNumber(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  function getAverage(value) {
    if (result.statistics.games > 0) {
      return roundNumber(value / result.statistics.games);
    } else {
      return 0;
    }
  }

  return (
    <React.Fragment key={itemIndex}>
      {result && type === "averages" && (
        <>
          <tr className="coach-row">
            <td colSpan="3">Team / Coach</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{getAverage(result.statistics.reboundsTeamOffensive)}</td>
            <td>{getAverage(result.statistics.reboundsTeamDefensive)}</td>
            <td>{getAverage(result.statistics.reboundsTeamTotal)}</td>
            <td></td>
            <td>{getAverage(result.statistics.turnoversTeam)}</td>
            <td></td>
            <td></td>
            <td>
              {getAverage(
                result.statistics.foulsBenchTechnical +
                  result.statistics.foulsCoachDisqualifying +
                  result.statistics.foulsCoachTechnical
              )}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr className="totals-row">
            <td colSpan="3">Totals</td>
            <td>
              {getAverage(result.statistics.fieldGoalsMade)} / {getAverage(result.statistics.fieldGoalsAttempted)}
            </td>
            <td>{roundNumber(result.statistics.fieldGoalsPercentage)}</td>
            <td>
              {getAverage(result.statistics.pointsTwoMade)} / {getAverage(result.statistics.pointsTwoAttempted)}
            </td>
            <td>{roundNumber(result.statistics.pointsTwoPercentage)}</td>
            <td>
              {getAverage(result.statistics.pointsThreeMade)} / {getAverage(result.statistics.pointsThreeAttempted)}
            </td>
            <td>{roundNumber(result.statistics.pointsThreePercentage)}</td>
            <td>
              {getAverage(result.statistics.freeThrowsMade)} / {getAverage(result.statistics.freeThrowsAttempted)}
            </td>
            <td>{roundNumber(result.statistics.freeThrowsPercentage)}</td>
            <td>{roundNumber(result.statistics.reboundsOffensivePerGame)}</td>
            <td>{roundNumber(result.statistics.reboundsDefensivePerGame)}</td>
            <td>{roundNumber(result.statistics.reboundsPerGame)}</td>
            <td>{roundNumber(result.statistics.assistsPerGame)}</td>
            <td>{roundNumber(result.statistics.turnoversPerGame)}</td>
            <td>{roundNumber(result.statistics.stealsPerGame)}</td>
            <td>{roundNumber(result.statistics.blocksPerGame)}</td>
            <td>{roundNumber(result.statistics.foulsPersonalPerGame)}</td>
            <td>{getAverage(result.statistics.foulsDrawn)}</td>
            <td>{roundNumber(result.statistics.pointsPerGame)}</td>
          </tr>
        </>
      )}
      {result && type === "totals" && (
        <>
          <tr className="coach-row">
            <td colSpan="3">Team / Coach</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{result.statistics.reboundsTeamOffensive}</td>
            <td>{result.statistics.reboundsTeamDefensive}</td>
            <td>{result.statistics.reboundsTeamTotal}</td>
            <td></td>
            <td>{result.statistics.turnoversTeam}</td>
            <td></td>
            <td></td>
            <td>
              {result.statistics.foulsBenchTechnical +
                result.statistics.foulsCoachDisqualifying +
                result.statistics.foulsCoachTechnical}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr className="totals-row">
            <td colSpan="3">Totals</td>
            <td>
              {result.statistics.fieldGoalsMade} / {result.statistics.fieldGoalsAttempted}
            </td>
            <td>{roundNumber(result.statistics.fieldGoalsPercentage)}</td>
            <td>
              {result.statistics.pointsTwoMade} / {result.statistics.pointsTwoAttempted}
            </td>
            <td>{roundNumber(result.statistics.pointsTwoPercentage)}</td>
            <td>
              {result.statistics.pointsThreeMade} / {result.statistics.pointsThreeAttempted}
            </td>
            <td>{roundNumber(result.statistics.pointsThreePercentage)}</td>
            <td>
              {result.statistics.freeThrowsMade} / {result.statistics.freeThrowsAttempted}
            </td>
            <td>{roundNumber(result.statistics.freeThrowsPercentage)}</td>
            <td>{result.statistics.reboundsOffensive}</td>
            <td>{result.statistics.reboundsDefensive}</td>
            <td>{result.statistics.rebounds}</td>
            <td>{result.statistics.assists}</td>
            <td>{result.statistics.turnovers}</td>
            <td>{result.statistics.steals}</td>
            <td>{result.statistics.blocks}</td>
            <td>{result.statistics.foulsTotal}</td>
            <td>{result.statistics.foulsDrawn}</td>
            <td>{result.statistics.points}</td>
          </tr>
        </>
      )}
    </React.Fragment>
  );
};

export default injectIntl(TeamCoachStatsRow);

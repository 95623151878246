import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";
import ImageDisplay from "../Base/Images/ImageDisplay";
import ImageUploader from "../Base/Images/ImageUploader";

import countryCodes from "../../config/countryCodes";
import standards from "../../config/standardTypes";

const EditSeason = (props) => {
  const { title, history, match, intl, updateState } = props;
  const { formatMessage } = intl;

  const [refreshData, setRefreshData] = useState(true);
  const [current, setCurrent] = useState(null);
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "?include=organizations,competitions,seasons",
    "",
    refreshData
  );

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];
  LookupData["gender"] = ["", "MALE", "FEMALE", "MIXED"];
  LookupData["ageGroup"] = ["", "SENIOR", "JUNIOR", "MASTERS"];
  LookupData["eventType"] = ["", "FIXTURE", "PRACTICE"];
  LookupData["representation"] = ["", "CLUB", "COUNTRY", "PERSON", "REGION", "STATE"];
  LookupData["year"] = ["", "2020", "2021", "2022", "2023", "2024"];
  LookupData["seasonType"] = ["", "SEASON", "TOURNAMENT", "ONE_OFF"];
  LookupData["standards"] = standards;
  LookupData["nationalities"] = countryCodes;

  useEffect(() => {
    if (data) {
      setRefreshData(false);
      let organization = data.data.filter(function (organization) {
        return organization.organizationId === match.params.organizationId;
      });
      if (organization.length > 0) {
        setCurrent({ ...data[0], ...organization[0] });
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [match]);

  function reloadData() {
    setRefreshData(true);
  }

  return (
    <PageDisplay
      title={formatMessage({
        id: "season.update",
        defaultMessage: "Update Season"
      })}
      error={error}
      pageTitle={current && current.nameLocal + " - " + title}
      ref={pageRef}
      history={history}
    >
      {loading && <Spinner size="md" color="orange" />}
      {current && (
        <div>
          <h4>{current && current.nameLocal}</h4>
          {current && (
            <FormBuilder
              form="EditSeason"
              action={reloadData}
              formSetup={formSetup}
              formData={current}
              pageRef={pageRef}
              lookupData={LookupData}
            />
          )}
          {error} <hr />
          <h3>
            {formatMessage({
              id: "images.manage",
              defaultMessage: "Manage Images"
            })}
          </h3>
          {current && (
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "25%" }}>
                    <ImageDisplay
                      data={current}
                      images={current.images}
                      imageType="LOGO"
                      pageRef={pageRef}
                      setRefreshFlag={setRefreshData}
                      checkQuality={true}
                    />
                  </td>
                  <td style={{ width: "25%" }}>
                    <ImageUploader
                      data={current}
                      type={"LOGO"}
                      element={"seasonId"}
                      imageFor={"SEASON"}
                      pageRef={pageRef}
                      setRefreshFlag={setRefreshData}
                      checkQuality={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(EditSeason);

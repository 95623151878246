import { useCallback, useEffect, useRef, useState } from "react";
import { useFetch } from "./fetch";
import _debounce from "lodash/debounce";
import { shallowEqual } from "../Utils/shallowEqual";

export default function useResourceAPI(props) {
  const {
    match,
    limit = 200,
    resourceName,
    searchThresholdLength = 4,
    searchParameter = "nameFullLocalContains",
    initialQuery = {
      include: "organizations",
      limit: limit,
      offset: 0
    },
    controlledQuery = {}
  } = props;

  const { sport, organizationId } = match.params;

  const [query, setQuery] = useState(initialQuery);
  const [searchText, setSearchText] = useState("");
  const searchTextRef = useRef("");
  const controlledQueryRef = useRef({});

  const updateQuery = useCallback((updates) => setQuery({ ...query, ...updates }), [query]);
  const composeQuery = useCallback(
    (source = query) =>
      new URLSearchParams(Object.fromEntries(Object.entries(source).filter(([key, val]) => val !== ""))),
    [query]
  );

  const baseURL = `/v1/${sport}/o/${organizationId}/${resourceName}?`;
  const [currentURL, setCurrentURL] = useState(
    baseURL +
      composeQuery({
        ...query,
        ...controlledQuery,
        ...(searchText.length >= searchThresholdLength && { [searchParameter]: searchText })
      })
  );

  const { loading, data, refetch, error } = useFetch(currentURL, 0, true);

  useEffect(() => {
    let resetOffset;
    if (searchTextRef.current !== searchText) {
      searchTextRef.current = searchText;
      resetOffset = true;
    }
    if (!shallowEqual(controlledQueryRef.current, controlledQuery)) {
      controlledQueryRef.current = controlledQuery;
      resetOffset = true;
    }
    const url =
      baseURL +
      composeQuery({
        ...query,
        ...controlledQuery,
        ...(searchText.length >= searchThresholdLength && { [searchParameter]: searchText }),
        ...(resetOffset && { offset: 0 })
      });
    if (resetOffset) {
      updateQuery({ offset: 0 });
    }
    if (currentURL !== url) {
      setCurrentURL(url);
    }
  }, [
    baseURL,
    composeQuery,
    query,
    controlledQuery,
    searchText,
    searchParameter,
    searchThresholdLength,
    currentURL,
    setCurrentURL,
    updateQuery
  ]);

  function updatePagination(state) {
    updateQuery({ offset: new URLSearchParams(state).get("offset") });
  }

  // eslint-disable-next-line
  const updateSearch = useCallback(
    _debounce((searchText) => {
      setSearchText(searchText);
    }, 500),
    []
  );

  return {
    data,
    loading,
    error,
    baseURL,
    refetch,
    updatePagination,
    updateSearch
  };
}

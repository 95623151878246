import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { injectIntl, FormattedDate } from "react-intl";

import { useDelete } from "../../Base/Hooks/delete";
import Modal from "../../Base/Modal/Modal";

import InlineFixtureForm from "./InlineFixtureForm";
import Lock from "./Lock";
import { Link } from "react-router-dom";
import { getScore } from "./getScore";
import FixtureReset from "./Reset";

const FixtureRow = (props) => {
  const { itemIndex, result, match, reloadData, resources, teams, venues, pageRef, intl, toggleDebug } = props;
  const [editMode, setEditMode] = useState(false);
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [linkDeleteData, setLinkDeleteData] = useState(null);
  const [actionText, setActionText] = useState("");
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + "/fixtures/" + linkDeleteData,
    "",
    refreshDeleteData
  );
  const { formatMessage } = intl;

  useEffect(() => {
    if (deleteError) {
      window.scrollTo(0, 0);
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }) +
          ": " +
          deleteError.message,
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "fixture.delete.success",
            defaultMessage: "Fixture deleted successfully"
          }),
          "success",
          10
        );
        reloadData(true);
      }
    }
    setRefreshDeleteData(false);
    setLinkDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeleteLink(param, name) {
    if (Array.isArray(result.competitors) && result.competitors.length > 0) {
      setDeleteModalText(
        formatMessage(
          {
            id: "fixture.delete.teams.exist",
            defaultMessage:
              "You cannot delete a fixture that has teams assigned. Please remove the teams from the fixture and try again"
          },
          { item: name }
        )
      );
      setActionText(
        formatMessage({
          id: "ok",
          defaultMessage: "OK"
        })
      );
    } else {
      setDeleteModalText(
        formatMessage(
          {
            id: "fixture.delete.confirm",
            defaultMessage: "You are about to delete " + name + ". Is this OK?"
          },
          { item: name }
        )
      );
      setActionText(
        formatMessage({
          id: "delete",
          defaultMessage: "Delete"
        })
      );
    }
    setDeleteModalOpen(true);
    setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deleteLink() {
    setLinkDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  function showEdit() {
    if (!result.locked) {
      setEditMode(true);
    }
  }

  return (
    <React.Fragment key={itemIndex}>
      <tr className={"deleting-" + deleteLoading}>
        {!editMode && result && (
          <>
            <td onClick={() => showEdit(!editMode)}>{result.roundNumber}</td>
            <td onClick={() => showEdit(!editMode)}>{result.teamHome}</td>
            <td onClick={() => showEdit(!editMode)}>{getScore(result)}</td>
            <td onClick={() => showEdit(!editMode)}>{result.teamAway}</td>
            <td onClick={() => showEdit(!editMode)}>{result.venue}</td>
            <td onClick={() => showEdit(!editMode)}>
              {result.startTimeLocal && <FormattedDate value={result.startTimeLocal.split("T")[0]} />}
            </td>
            <td onClick={() => showEdit(!editMode)}>
              <span>
                {result.startTimeLocal && result.startTimeLocal.split("T")[1].split(":")[0]}:
                {result.startTimeLocal && result.startTimeLocal.split("T")[1].split(":")[1]}
              </span>
              <small className="block">{result.timezone}</small>
            </td>
            <td onClick={() => showEdit(!editMode)}>{result.fixtureType}</td>
            <td onClick={() => showEdit(!editMode)}>{result.status}</td>
            <td onClick={() => showEdit(!editMode)}>
              {result.liveVideoAvailable ? <i className="far fa-check-square"></i> : <i className="far fa-square"></i>}
            </td>
            <td className="text-right controls-3 fixture-inline-buttons">
              {match.params.seasonId && !result.locked && (
                <>
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => setEditMode(!editMode)}
                    title={formatMessage({
                      id: "edit.quick",
                      defaultMessage: "Quick Edit"
                    })}
                  >
                    <i className="fas fa-pen" />
                  </Button>{" "}
                  <Button
                    outline
                    color="danger"
                    size="sm"
                    onClick={() => showDeleteLink(result.fixtureId, result.teamHome + " v " + result.teamAway)}
                    title={formatMessage({
                      id: "match.delete",
                      defaultMessage: "Delete Match"
                    })}
                  >
                    <i className="fas fa-minus" />
                  </Button>{" "}
                  <Link
                    to={
                      "/admin/" +
                      match.params.sport +
                      "/" +
                      match.params.organizationId +
                      "/seasons/" +
                      match.params.seasonId +
                      "/fixtures/" +
                      result.fixtureId
                    }
                    className="btn btn-outline-secondary btn-sm"
                    title={formatMessage({
                      id: "edit.advanced",
                      defaultMessage: "Advanced Edit"
                    })}
                  >
                    <i className="fas fa-cog" />
                  </Link>{" "}
                  <FixtureReset {...{ ...result, ...props }} />
                </>
              )}
              <Lock action={reloadData} pageRef={pageRef} data={result} />

              <Modal
                isOpen={deleteModalOpen}
                heading={formatMessage({
                  id: "fixture.delete",
                  defaultMessage: "Delete Fixture?"
                })}
                text={deleteModalText}
                actionText={actionText}
                cancelText={formatMessage({
                  id: "cancel",
                  defaultMessage: "Cancel"
                })}
                action={Array.isArray(result.competitors) && result.competitors.length > 0 ? cancelModal : deleteLink}
                cancel={cancelModal}
              />
            </td>
          </>
        )}
        {editMode && (
          <>
            <InlineFixtureForm
              setEditMode={setEditMode}
              editMode={editMode}
              form="RosterUpdate"
              action={reloadData}
              pageRef={pageRef}
              formData={result}
              resources={resources}
              teams={teams}
              venues={venues}
            />
          </>
        )}
      </tr>
      {toggleDebug && (
        <tr>
          <td>
            <strong>
              <small>fixtureId:</small>
            </strong>
          </td>
          <td colSpan="2">{result.fixtureId}</td>
          <td>
            <strong>
              <small>externalId:</small>
            </strong>
          </td>
          <td>{result.externalId}</td>
          <td>
            <strong>
              <small>updated:</small>
            </strong>
          </td>
          <td colSpan="6">{result.updated}</td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default injectIntl(FixtureRow);

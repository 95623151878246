export function CheckBoxArrayHandler({
  formContext: { values, setFieldValue },
  field: { name, valueTransformer = (value) => value },
  props: { parent, isSubField },
  changeEvent: {
    target: { checked, value }
  }
}) {
  const fieldPath = isSubField ? `${parent.name}.${name}` : name;
  const fieldValue = isSubField ? values[parent.name][name] : values[name];

  const transformedValue = valueTransformer(value);

  if (checked) {
    if (!fieldValue) {
      setFieldValue(fieldPath, [transformedValue]);
      return;
    }
    setFieldValue(fieldPath, [...fieldValue, transformedValue]);
  } else {
    setFieldValue(
      fieldPath,
      fieldValue.filter((fv) => valueTransformer(fv) !== transformedValue)
    );
  }
}

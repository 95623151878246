import React, { useEffect, useState } from "react";

import "./Modal.scss";

const Modal = (props) => {
  const { isOpen, heading, text, actionText, cancelText, action, cancel } = props;
  const [openModal, setOpenModal] = useState(isOpen);
  const [winTop, setWinTop] = useState();

  document.addEventListener("keydown", function (e) {
    let keyCode = e.keyCode;
    if (keyCode === 27) {
      cancel();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      restoreWindowState();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let currentTop = window.scrollY;
    setWinTop(currentTop);
    if (openModal) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = "-" + currentTop + "px";
    } else {
      restoreWindowState();
    }
    // eslint-disable-next-line
  }, [openModal]);

  function restoreWindowState() {
    let currentTop = window.scrollY;
    document.body.style.position = "";
    document.body.style.top = "";
    if (winTop) {
      window.scrollTo(0, winTop);
    } else {
      window.scrollTo(0, currentTop);
    }
  }

  return (
    <React.Fragment>
      {openModal && (
        <div className="modal fade show" tabIndex="-1" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content-wrapper">
              <div className="modal-content">
                <div className="modal-header clearfix text-left">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <i className="pg-close fs-14"></i>
                  </button>
                  <h5>{heading}</h5>
                </div>
                <div className="modal-body">
                  <p className="no-margin">{text}</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary  pull-left inline"
                    data-dismiss="modal"
                    onClick={action}
                  >
                    {actionText}
                  </button>
                  <button
                    type="button"
                    onClick={cancel}
                    className="btn btn-default btn-cons no-margin pull-left inline"
                    data-dismiss="modal"
                  >
                    {cancelText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Modal;

import React, { useState, useEffect } from "react";
import TableDisplay from "../Base/Shared/TableDisplay";
import TablePagination from "../Base/Shared/TablePagination";
import SearchFilter from "../Base/Shared/SearchFilter";
import { injectIntl } from "react-intl";
import { Input, Label } from "reactstrap";
import "../Base/Shared/SearchFilter.scss";

const defaultColumns = [
  {
    id: "name.local.full",
    defaultMessage: "Local Full Name"
  },
  {
    id: "name.latin.full",
    defaultMessage: "Latin Full Name"
  },
  {
    id: "nationality",
    defaultMessage: "Nationality"
  },
  {
    id: "gender",
    defaultMessage: "Gender"
  },
  {
    id: "status",
    defaultMessage: "Status"
  }
];

function PersonList(props) {
  const {
    updateState,
    match,
    intl,
    pageRef,
    setPageTitle,
    PersonRowComponent,
    personsAPI,
    currentPerson,
    columns = defaultColumns,
    LookupData,
    statusFilter,
    setStatusFilter
  } = props;
  const { formatMessage } = intl;
  const [tableData, setTableData] = useState();

  const { data, refetch, updateSearch, updatePagination, loading } = personsAPI;

  const statusFilterHandler = (e) => {
    setStatusFilter(e.target.value);
  };

  useEffect(() => {
    if (data) {
      if (setPageTitle) {
        const organizations = data?.includes?.resources?.organizations;
        if (organizations) {
          setPageTitle(organizations[match.params.organizationId].nameLocal);
        }
      }

      let filteredData = data.data;

      let tableList = filteredData.map((result, index) => (
        <PersonRowComponent
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          pageRef={pageRef}
          refetch={refetch}
          {...props}
        />
      ));
      setTableData(tableList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentPerson, statusFilter]);

  return (
    <>
      <div className="table-search-filter">
        {LookupData ? (
          <div className="table-search-filter-status">
            <Label htmlFor="status-filter">
              {formatMessage({
                id: "status",
                defaultMessage: "Status"
              })}
              :
            </Label>
            <Input
              id="status-filter"
              className="table-search-filter-status-select"
              type="select"
              name="status-filter"
              value={statusFilter}
              onChange={statusFilterHandler}
            >
              {LookupData["status"].map((status) => (
                <option key={status} value={status}>
                  {status === "" ? "ALL" : status}
                </option>
              ))}
            </Input>
          </div>
        ) : null}
        <SearchFilter
          doSearch={updateSearch}
          placeholder={formatMessage({
            id: "person.search.placeholder",
            defaultMessage: "Search (4 letters min)"
          })}
        />
      </div>
      <TableDisplay
        containerClass="table-responsive"
        columns={columns.map(formatMessage)}
        rows={tableData}
        loading={loading}
        withButtonsCell
      />
      <TablePagination data={data} setPaginationString={updatePagination} />
    </>
  );
}

export default injectIntl(PersonList);

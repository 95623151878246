export default {
  forms: {
    CreateProfile: {
      endpoint: "v1/#sport/o/#0/fixtureProfiles",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New Match Profile added successfully.",
      submit: "Add",
      submittranslation: "add",
      fields: [
        {
          name: "name",
          size: "col-sm-4 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "defaultProfile",
          size: "col-sm-4 col",
          label: "Default Profile",
          labeltranslation: "default.profile",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          placeholder: "Default Profile",
          className: "form-control",
          initial: null
        },
        {
          name: "profile",
          size: "col-sm-12 col",
          type: "subFields",
          label: "Profile",
          placeholder: "",
          className: "form-control",
          default: {},
          initial: "",
          options: [
            {
              name: "numberOfPeriods",
              size: "col-sm-4 col",
              label: "Number Of Periods:",
              labeltranslation: "number.of.periods",
              type: "number",
              placeholder: "Number Of Periods",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "periodLength",
              size: "col-sm-4 col",
              label: "Period Length:",
              labeltranslation: "period.length",
              type: "number",
              placeholder: "Period Length",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "halfTimeDuration",
              size: "col-sm-4 col",
              label: "Half Time Duration",
              labeltranslation: "half.time.duration",
              type: "number",
              placeholder: "Half Time Duration",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "periodBreakDuration",
              size: "col-sm-4 col",
              label: "Period Break Duration",
              labeltranslation: "period.break.duration",
              type: "number",
              placeholder: "Period Break Duration",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "shotClock",
              size: "col-sm-4 col",
              label: "Shot Clock Length",
              labeltranslation: "shot.clock.length",
              type: "number",
              placeholder: "Shot Clock Length",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "maximumRosterSize",
              size: "col-sm-4 col",
              label: "Maximum Roster Size",
              labeltranslation: "maximum.roster.size",
              type: "number",
              placeholder: "Maximum Roster Size",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "maxFoulsPersonal",
              size: "col-sm-4 col",
              label: "Personal Fouls Allowed:",
              labeltranslation: "max.fouls.personal",
              type: "number",
              placeholder: "Personal Fouls Allowed",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "foulsAllowedTechnical",
              size: "col-sm-4 col",
              label: "Technical Fouls Allowed",
              labeltranslation: "fouls.allowed.technical",
              type: "number",
              placeholder: "Technical Fouls Allowed",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "foulsBeforeBonus",
              size: "col-sm-4 col",
              label: "Fouls Until Bonus:",
              labeltranslation: "fouls.until.bonus",
              type: "number",
              placeholder: "Fouls Until Bonus",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "overtimeAllowed",
              size: "col-sm-4 col",
              label: "Is Overtime Allowed",
              labeltranslation: "overtime.allowed",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              className: "form-control",
              initial: null
            },
            {
              name: "overtimeLength",
              size: "col-sm-4 col",
              label: "Overtime Length",
              labeltranslation: "overtime.length",
              type: "number",
              placeholder: "Overtime Length",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "maximumPlayersOnCourt",
              size: "col-sm-4 col",
              label: "Max Players on the court per team",
              labeltranslation: "max.players.on.court",
              type: "number",
              placeholder: "Max players on the court per team",
              className: "form-control",
              initial: null,
              min: 0
            },
            {
              name: "switchPlayingDirection",
              size: "col-sm-12 col",
              label: "Switch direction of play after period/s:",
              labeltranslation: "switch.play.direction",
              type: "checkboxarray",
              valueTransformer(value) {
                return parseInt(value);
              },
              placeholder: "Filters",
              className: "form-control checkboxes",
              initial: null,
              options: [
                { value: 1, label: "P1" },
                { value: 2, label: "P2" },
                { value: 3, label: "P3" },
                { value: 4, label: "P4" },
                { value: 11, label: "OT1" },
                { value: 12, label: "OT2" },
                { value: 13, label: "OT3" },
                { value: 14, label: "OT4" },
                { value: 15, label: "OT5" },
                { value: 16, label: "OT6" }
              ],
              optionsClass: "col col-lg-1"
            },
            {
              name: "resetFoulsAfterPeriods",
              size: "col-sm-12 col",
              label: "Reset fouls after period/s",
              labeltranslation: "reset.fouls.after.periods",
              type: "checkboxarray",
              valueTransformer(value) {
                return parseInt(value);
              },
              placeholder: "Filters",
              className: "form-control checkboxes",
              initial: null,
              options: [
                { value: 1, label: "P1" },
                { value: 2, label: "P2" },
                { value: 3, label: "P3" },
                { value: 4, label: "P4" },
                { value: 11, label: "OT1" },
                { value: 12, label: "OT2" },
                { value: 13, label: "OT3" },
                { value: 14, label: "OT4" },
                { value: 15, label: "OT5" },
                { value: 16, label: "OT6" }
              ],
              optionsClass: "col col-lg-1"
            },
            {
              name: "resetTimeoutsAfterPeriods",
              size: "col-sm-12 col",
              label: "Reset timeouts after period/s",
              labeltranslation: "reset.timeouts.after.periods",
              type: "checkboxarray",
              valueTransformer(value) {
                return parseInt(value);
              },
              placeholder: "Filters",
              className: "form-control checkboxes",
              initial: null,
              options: [
                { value: 1, label: "P1" },
                { value: 2, label: "P2" },
                { value: 3, label: "P3" },
                { value: 4, label: "P4" },
                { value: 11, label: "OT1" },
                { value: 12, label: "OT2" },
                { value: 13, label: "OT3" },
                { value: 14, label: "OT4" },
                { value: 15, label: "OT5" },
                { value: 16, label: "OT6" }
              ],
              optionsClass: "col col-lg-1"
            }
          ]
        }
      ]
    },
    EditProfile: {
      endpoint: "v1/#sport/o/#0/fixtureProfiles/#1",
      parameters: ["organizationId", "matchProfileId"],
      method: "PUT",
      successmessage: "Match Profile updated successfully.",
      submit: "Update",
      submittranslation: "update",
      fields: [
        {
          name: "name",
          size: "col-sm-4 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: "name",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "defaultProfile",
          size: "col-sm-4 col",
          label: "Default Profile",
          labeltranslation: "default.profile",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          placeholder: "Default Profile",
          className: "form-control",
          initial: "defaultProfile"
        },
        {
          name: "profile",
          size: "col-sm-12 col",
          type: "subFields",
          label: "Profile",
          placeholder: "",
          className: "form-control",
          default: {},
          initial: "profile",
          options: [
            {
              name: "numberOfPeriods",
              size: "col-sm-4 col",
              label: "Number Of Periods:",
              labeltranslation: "number.of.periods",
              type: "number",
              placeholder: "Number Of Periods",
              className: "form-control",
              initial: "profile#numberOfPeriods",
              min: 0
            },
            {
              name: "periodLength",
              size: "col-sm-4 col",
              label: "Period Length:",
              labeltranslation: "period.length",
              type: "number",
              placeholder: "Period Length",
              className: "form-control",
              initial: "profile#periodLength",
              min: 0
            },
            {
              name: "halfTimeDuration",
              size: "col-sm-4 col",
              label: "Half Time Duration",
              labeltranslation: "half.time.duration",
              type: "number",
              placeholder: "Half Time Duration",
              className: "form-control",
              initial: "profile#halfTimeDuration",
              min: 0
            },
            {
              name: "periodBreakDuration",
              size: "col-sm-4 col",
              label: "Period Break Duration",
              labeltranslation: "period.break.duration",
              type: "number",
              placeholder: "Period Break Duration",
              className: "form-control",
              initial: "profile#periodBreakDuration",
              min: 0
            },
            {
              name: "shotClock",
              size: "col-sm-4 col",
              label: "Shot Clock Length",
              labeltranslation: "shot.clock.length",
              type: "number",
              placeholder: "Shot Clock Length",
              className: "form-control",
              initial: "profile#shotClock",
              min: 0
            },
            {
              name: "maximumRosterSize",
              size: "col-sm-4 col",
              label: "Maximum Roster Size",
              labeltranslation: "maximum.roster.size",
              type: "number",
              placeholder: "Maximum Roster Size",
              className: "form-control",
              initial: "profile#maximumRosterSize",
              min: 0
            },
            {
              name: "maxFoulsPersonal",
              size: "col-sm-4 col",
              label: "Personal Fouls Allowed:",
              labeltranslation: "max.fouls.personal",
              type: "number",
              placeholder: "Personal Fouls Allowed",
              className: "form-control",
              initial: "profile#maxFoulsPersonal",
              min: 0
            },
            {
              name: "foulsAllowedTechnical",
              size: "col-sm-4 col",
              label: "Technical Fouls Allowed",
              labeltranslation: "fouls.allowed.technical",
              type: "number",
              placeholder: "Technical Fouls Allowed",
              className: "form-control",
              initial: "profile#foulsAllowedTechnical",
              min: 0
            },
            {
              name: "foulsBeforeBonus",
              size: "col-sm-4 col",
              label: "Fouls Until Bonus:",
              labeltranslation: "fouls.until.bonus",
              type: "number",
              placeholder: "Fouls Until Bonus",
              className: "form-control",
              initial: "profile#foulsBeforeBonus",
              min: 0
            },
            {
              name: "overtimeAllowed",
              size: "col-sm-4 col",
              label: "Is Overtime Allowed",
              labeltranslation: "overtime.allowed",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              className: "form-control",
              initial: "profile#overtimeAllowed"
            },
            {
              name: "overtimeLength",
              size: "col-sm-4 col",
              label: "Overtime Length",
              labeltranslation: "overtime.length",
              type: "number",
              placeholder: "Overtime Length",
              className: "form-control",
              initial: "profile#overtimeLength",
              min: 0
            },
            {
              name: "maximumPlayersOnCourt",
              size: "col-sm-4 col",
              label: "Max Players on the court per team",
              labeltranslation: "max.players.on.court",
              type: "number",
              placeholder: "Max players on the court per team",
              className: "form-control",
              initial: "profile#maximumPlayersOnCourt",
              min: 0
            },
            {
              name: "switchPlayingDirection",
              size: "col-sm-12 col",
              label: "Switch direction of play after period/s:",
              labeltranslation: "switch.play.direction",
              type: "checkboxarray",
              valueTransformer(value) {
                return parseInt(value);
              },
              placeholder: "Filters",
              className: "form-control checkboxes",
              initial: "profile#switchPlayingDirection",
              options: [
                { value: 1, label: "P1" },
                { value: 2, label: "P2" },
                { value: 3, label: "P3" },
                { value: 4, label: "P4" },
                { value: 11, label: "OT1" },
                { value: 12, label: "OT2" },
                { value: 13, label: "OT3" },
                { value: 14, label: "OT4" },
                { value: 15, label: "OT5" },
                { value: 16, label: "OT6" }
              ],
              optionsClass: "col col-lg-1"
            },
            {
              name: "resetFoulsAfterPeriods",
              size: "col-sm-12 col",
              label: "Reset fouls after period/s",
              labeltranslation: "reset.fouls.after.periods",
              type: "checkboxarray",
              valueTransformer(value) {
                return parseInt(value);
              },
              placeholder: "Filters",
              className: "form-control checkboxes",
              initial: "profile#resetFoulsAfterPeriods",
              options: [
                { value: 1, label: "P1" },
                { value: 2, label: "P2" },
                { value: 3, label: "P3" },
                { value: 4, label: "P4" },
                { value: 11, label: "OT1" },
                { value: 12, label: "OT2" },
                { value: 13, label: "OT3" },
                { value: 14, label: "OT4" },
                { value: 15, label: "OT5" },
                { value: 16, label: "OT6" }
              ],
              optionsClass: "col col-lg-1"
            },
            {
              name: "resetTimeoutsAfterPeriods",
              size: "col-sm-12 col",
              label: "Reset timeouts after period/s",
              labeltranslation: "reset.timeouts.after.periods",
              type: "checkboxarray",
              valueTransformer(value) {
                return parseInt(value);
              },
              placeholder: "Filters",
              className: "form-control checkboxes",
              initial: "profile#resetTimeoutsAfterPeriods",
              options: [
                { value: 1, label: "P1" },
                { value: 2, label: "P2" },
                { value: 3, label: "P3" },
                { value: 4, label: "P4" },
                { value: 11, label: "OT1" },
                { value: 12, label: "OT2" },
                { value: 13, label: "OT3" },
                { value: 14, label: "OT4" },
                { value: 15, label: "OT5" },
                { value: 16, label: "OT6" }
              ],
              optionsClass: "col col-lg-1"
            }
          ]
        }
      ]
    }
  }
};

import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import { useFetch } from "../../Base/Hooks/fetch";

import TeamRow from "./TeamRow";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import TableDisplay from "../../Base/Shared/TableDisplay";
import TablePagination from "../../Base/Shared/TablePagination";
import SearchFilter from "../../Base/Shared/SearchFilter";
import FormBuilder from "../../Base/Forms/FormBuilder";
import formSetup from "./formSetup";
import { useUpdateToken } from "../../Base/Hooks/updateToken";
import DivisionSelect from "./DivisionSelect";
import adjustFormSetup from "./adjustFormSetup";

const Teams = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationString, setPaginationString] = useState("limit=25&offset=0");
  const [formData, setFormData] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const [adjustedFormSetup, setAdjustedFormSetup] = useState();

  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/entities?include=organization,entities,seasons,conferences,divisions&" +
      paginationString,
    "",
    refreshFlag
  );

  const columns = [
    "",
    formatMessage({
      id: "name.local.full",
      defaultMessage: "Local Full Name"
    }),
    formatMessage({
      id: "name.local.code",
      defaultMessage: "Local Code"
    }),
    formatMessage({
      id: "name.latin.full",
      defaultMessage: "Latin Full Name"
    }),
    formatMessage({
      id: "name.latin.code",
      defaultMessage: "Latin Code"
    }),
    formatMessage({
      id: "status",
      defaultMessage: "Status"
    }),
    formatMessage({
      id: "conference",
      defaultMessage: "Conference"
    }),
    formatMessage({
      id: "division",
      defaultMessage: "Division"
    }),
    ""
  ];

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  const customFields = {};

  customFields.divisionId = (props) => (
    <DivisionSelect sport={match.params.sport} organizationId={match.params.organizationId} {...props} />
  );

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);

      setFormData({
        organizationId: match.params.organizationId,
        seasonId: match.params.seasonId
      });
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data) {
      if (data && data.includes && data.includes.resources && data.includes.resources.organization) {
        setPageTitle(data.includes.resources.seasons[match.params.seasonId].nameLocal);
      }

      let filteredData = data !== null ? data.data : [];

      const addConferenceAndDivisionsData = (result) => {
        const { divisions, conferences } = data.includes.resources;
        if (divisions && conferences) {
          return {
            conference: conferences[result.conferenceId],
            division: divisions[result.divisionId]
          };
        }
      };

      filteredData.forEach((result, index) => {
        filteredData[index] = {
          ...result,
          ...data.includes.resources.entities[result.entity.id],
          ...addConferenceAndDivisionsData(result)
        };
        filteredData[index].status = result.status;
      });

      /**
       * Do not show 'conference' and 'division' select boxes
       * to orgs that don't have any
       */
      adjustFormSetup("NewSeasonTeam", data, formSetup, setAdjustedFormSetup);

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.nameFullLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }
      let tableList = filteredData.map((result, index) => {
        return (
          <TeamRow
            key={index}
            itemIndex={index}
            result={result}
            updateState={updateState}
            match={match}
            pageRef={pageRef}
            setRefreshFlag={setRefreshFlag}
            reloadData={reloadData}
          />
        );
      });
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      <PageDisplay
        title={
          pageTitle +
          " " +
          formatMessage({
            id: "teams",
            defaultMessage: "Teams"
          })
        }
        error={error}
        pageTitle={
          pageTitle +
          " " +
          formatMessage({
            id: "teams",
            defaultMessage: "Teams"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
      >
        <Link
          to={
            "/admin/" +
            match.params.sport +
            "/" +
            match.params.organizationId +
            "/seasons/" +
            match.params.seasonId +
            "/teams/images"
          }
          className="btn btn-primary float-left"
        >
          <i className="fas fa-images" />
          {"  "}
          {formatMessage({
            id: "images.manage",
            defaultMessage: "Manage Images"
          })}
        </Link>
        <SearchFilter doSearch={setSearchText} />
        <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading && false} />
        <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
        <hr />
        <h4 onClick={toggleForm}>
          {!isFormOpen && (
            <span>
              <FormattedMessage id="team.add" defaultMessage="Add New Team" /> <i className="fas fa-caret-down"></i>
            </span>
          )}
          {isFormOpen && (
            <span>
              <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
            </span>
          )}
        </h4>
        <Collapse isOpen={isFormOpen}>
          {formData !== null && adjustedFormSetup && (
            <FormBuilder
              form="NewSeasonTeam"
              action={reloadData}
              formSetup={adjustedFormSetup}
              pageRef={pageRef}
              formData={formData}
              lookupData={LookupData}
              customFields={customFields}
            />
          )}
        </Collapse>
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Teams);

import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";

import countryCodes from "../../config/countryCodes";
import { PersonImages } from "./PersonImages";

const EditPerson = (props) => {
  const { title, history, match, intl, updateState } = props;
  const { formatMessage } = intl;
  const [refreshData, setRefreshData] = useState(true);
  const [current, setCurrent] = useState(null);
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/persons/" +
      match.params.personId +
      "?include=organizations",
    "",
    refreshData
  );

  let LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING", "DECEASED", "UNREGISTERED"];
  LookupData["gender"] = ["", "MALE", "FEMALE", "UNKNOWN"];
  LookupData["nationalities"] = countryCodes;

  useEffect(() => {
    if (data) {
      setRefreshData(false);
      setCurrent(data.data[0]);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [match]);

  function reloadData() {
    setRefreshData(true);
  }

  const renderPersonImages = () => {
    const headingText = formatMessage({
      id: "images.manage",
      defaultMessage: "Manage Images"
    });

    const imageTypesList = [
      {
        title: formatMessage({
          id: "images.headshot",
          defaultMessage: "Head Shot Photo"
        }),
        imageType: "PERSON_HEAD",
        checkQuality: true
      },
      {
        title: formatMessage({
          id: "images.waist",
          defaultMessage: "Waist Shot Photo"
        }),
        imageType: "PERSON_WAIST",
        checkQuality: true
      },
      {
        title: formatMessage({
          id: "images.pose",
          defaultMessage: "Posed / Action Shot Photo"
        }),
        imageType: "PERSON_POSE",
        checkQuality: true
      }
    ];

    return (
      <PersonImages
        headingText={headingText}
        current={current}
        images={current.images}
        pageRef={pageRef}
        setRefreshFlag={setRefreshData}
        imageTypesList={imageTypesList}
      />
    );
  };

  return (
    <PageDisplay
      title={formatMessage({
        id: "person.update",
        defaultMessage: "Update Person"
      })}
      error={error}
      pageTitle={current && current.nameFullLocal + " -" + title}
      ref={pageRef}
      history={history}
    >
      {loading && <Spinner size="md" color="orange" />}
      {current && (
        <div>
          <h4>{current && current.nameFullLocal}</h4>
          {current && (
            <FormBuilder
              form="EditPerson"
              action={reloadData}
              formSetup={formSetup}
              formData={current}
              pageRef={pageRef}
              lookupData={LookupData}
            />
          )}
          {error}
          <br />
          <hr />
          {renderPersonImages()}
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(EditPerson);

// Standings Adjustments
var formSetup = {
  forms: {
    CreateAdjustment: {
      title: "Create New Standings Adjustment",
      titletranslation: "standings.adjustment.create",
      endpoint: "v1/#sport/o/#0/seasons/#1/standings/adjustments",
      parameters: ["organizationId", "seasonId"],
      method: "POST",
      successmessage: "Standing Adjustment created successfully",
      submit: "Submit New Standing Adjustment",
      submittranslation: "standings.configurations.add",
      link: {
        show: true,
        message: "View Standings Adjustments",
        path: "/admin/teams/",
        parameters: []
      },
      fields: [
        {
          name: "entityId",
          size: "col-sm-6 col",
          label: "Team:",
          labeltranslation: "team",
          type: "lookup",
          placeholder: "Team",
          className: "form-control",
          required: true,
          initial: null,
          lookup: {
            endpoint: "v1/#sport/o/#0/entities?limit=200",
            text: "nameFullLocal",
            value: "entityId",
            parameters: ["organizationId"]
          },
          errors: {
            required: "Team Is Required"
          }
        },
        {
          name: "applyToAllStandings",
          size: "col-sm-6 col",
          label: "Apply to all generated standing types?",
          labeltranslation: "apply.to.all.standings",
          type: "select-boolean",
          options: [
            { value: null, text: "-" },
            {
              value: true,
              translation: "yes",
              text: "yes"
            },
            {
              value: false,
              translation: "no",
              text: "no"
            }
          ],
          className: "form-control",
          initial: "no"
        },
        {
          name: "adjustmentGroup",
          size: "col-sm-6 col",
          label: "Adjustment Group",
          labeltranslation: "adjustment.group",
          type: "select",
          default: "OVERALL",
          options: [
            {
              value: "OVERALL",
              translation: "overall",
              text: "Overall"
            },
            {
              value: "IN_CONFERENCE",
              translation: "in.conference",
              text: "In Conference"
            },
            {
              value: "OUT_CONFERENCE",
              translation: "out.conference",
              text: "Out Conference"
            },
            {
              value: "IN_DIVISION",
              translation: "in.division",
              text: "In Division"
            },
            {
              value: "OUT_DIVISION",
              translation: "out.division",
              text: "Out Divivsion"
            }
          ],
          className: "form-control",
          initial: "OVERALL"
        },
        {
          name: "adjustmentType",
          size: "col-sm-6 col",
          label: "Adjustment Type",
          labeltranslation: "adjustment.type",
          type: "select",
          options: [
            {
              value: null,
              text: "-"
            },
            {
              value: "SET",
              translation: "set.value",
              text: "Set Value"
            },
            {
              value: "ADD_MINUS",
              translation: "add.minus",
              text: "Add/Substract Value"
            }
          ],
          className: "form-control",
          initial: null,
          required: true,
          errors: {
            required: "Adjustment Type Is Required"
          }
        },
        {
          name: "adjustmentField",
          size: "col-sm-6 col",
          label: "Adjustment Field",
          labeltranslation: "adjustment.field",
          type: "select",
          default: "position",
          options: getAdjustmentFieldOptions(),
          className: "form-control",
          initial: null,
          required: true,
          errors: {
            required: "Adjustment Field Is Required"
          }
        },
        {
          name: "adjustmentValue",
          size: "col-sm-6 col",
          label: "Adjustment Value",
          labeltranslation: "adjustment.value",
          type: "number",
          className: "form-control",
          initial: null,
          required: true,
          errors: {
            required: "Value Is Required"
          }
        },
        {
          name: "reasonType",
          size: "col-sm-12 col",
          label: "Reason",
          labeltranslation: "standings.adjustment.reason",
          type: "textarea",
          className: "form-control",
          initial: null,
          required: true,
          errors: {
            required: "Reason Is Required"
          }
        },
        {
          name: "reasonDescription",
          size: "col-sm-12 col",
          label: "Reason Description",
          labeltranslation: "standings.adjustment.reason.description",
          type: "textarea",
          className: "form-control",
          initial: null
        }
      ]
    },
    ModifyAdjustment: {
      title: "Update Standings Adjustment",
      titletranslation: "standings.adjustment.edit",
      endpoint: "v1/#sport/o/#0/standings/adjustments/#1",
      parameters: ["organizationId", "standingAdjustmentId"],
      method: "PUT",
      successmessage: "Standing Adjustment updated successfully",
      submit: "Update Standings Adjustment",
      submittranslation: "standings.adjustment.edit",
      link: {
        show: false,
        message: "View Standings Adjustments",
        path: "/admin/standings/adjustments",
        parameters: []
      },
      fields: [
        {
          name: "entityId",
          size: "col-sm-6 col",
          label: "Team:",
          labeltranslation: "team",
          type: "lookup",
          placeholder: "Team",
          className: "form-control",
          required: true,
          initial: "entityId",
          lookup: {
            nonNullable: true,
            endpoint: "v1/#sport/o/#0/entities?limit=200",
            text: "nameFullLocal",
            value: "entityId",
            parameters: ["organizationId"]
          },
          errors: {
            required: "Team Is Required"
          }
        },
        {
          name: "applyToAllStandings",
          size: "col-sm-6 col",
          label: "Apply to all generated standing types?",
          labeltranslation: "apply.to.all.standings",
          type: "select-boolean",
          options: [
            {
              value: true,
              translation: "yes",
              text: "yes"
            },
            {
              value: false,
              translation: "no",
              text: "no"
            }
          ],
          className: "form-control",
          initial: "applyToAllStandings"
        },
        {
          name: "adjustmentGroup",
          size: "col-sm-6 col",
          label: "Adjustment Group",
          labeltranslation: "adjustment.group",
          type: "select",
          options: [
            {
              value: "OVERALL",
              translation: "overall",
              text: "Overall"
            },
            {
              value: "IN_CONFERENCE",
              translation: "in.conference",
              text: "In Conference"
            },
            {
              value: "OUT_CONFERENCE",
              translation: "out.conference",
              text: "Out Conference"
            },
            {
              value: "IN_DIVISION",
              translation: "in.division",
              text: "In Division"
            },
            {
              value: "OUT_DIVISION",
              translation: "out.division",
              text: "Out Division"
            }
          ],
          className: "form-control",
          initial: "adjustmentGroup",
          required: true
        },
        {
          name: "adjustmentType",
          size: "col-sm-6 col",
          label: "Adjustment Type",
          labeltranslation: "adjustment.type",
          type: "select",
          options: [
            {
              value: "SET",
              translation: "set.value",
              text: "Set Value"
            },
            {
              value: "ADD_MINUS",
              translation: "add.minus",
              text: "Add/Substract Value"
            }
          ],
          className: "form-control",
          initial: "adjustmentType",
          required: true,
          errors: {
            required: "Adjustment Type Is Required"
          }
        },
        {
          name: "adjustmentField",
          size: "col-sm-6 col",
          label: "Adjustment Field",
          labeltranslation: "adjustment.field",
          type: "select",
          options: getAdjustmentFieldOptions(),
          className: "form-control",
          initial: "adjustmentField",
          required: true,
          errors: {
            required: "Adjustment Field Is Required"
          }
        },
        {
          name: "adjustmentValue",
          size: "col-sm-6 col",
          label: "Adjustment Value",
          labeltranslation: "adjustment.value",
          type: "number",
          className: "form-control",
          initial: "adjustmentValue",
          required: true,
          errors: {
            required: "Value Is Required"
          }
        },
        {
          name: "reasonType",
          size: "col-sm-12 col",
          label: "Reason",
          labeltranslation: "standings.adjustment.reason",
          type: "textarea",
          className: "form-control",
          initial: "reasonType",
          required: true,
          errors: {
            required: "Reason Is Required"
          }
        },
        {
          name: "reasonDescription",
          size: "col-sm-12 col",
          label: "Reason Description",
          labeltranslation: "standings.adjustment.reason.description",
          type: "textarea",
          className: "form-control",
          initial: "reasonDescription"
        }
      ]
    }
  }
};
export default formSetup;
function getAdjustmentFieldOptions() {
  return [
    "position",
    "played",
    "playedHome",
    "playedAway",
    "wins",
    "winsHome",
    "winsAway",
    "losses",
    "lossesHome",
    "lossesAway",
    "draws",
    "drawsHome",
    "drawsAway",
    "washouts",
    "byes",
    "forfeitsGiven",
    "forfeitsWonBy",
    "winPercentage",
    "winPercentageHome",
    "winPercentageAway",
    "scoredFor",
    "scoredForHome",
    "scoredForAway",
    "scoredAgainst",
    "scoredAgainstHome",
    "scoredAgainstAway",
    "percentage",
    "percentageHome",
    "percentageAway",
    "pointDifference",
    "pointDifferenceHome",
    "pointDifferenceAway",
    "lowestScoreFor",
    "lowestScoreForHome",
    "lowestScoreForAway",
    "highestScoreFor",
    "highestScoreForHome",
    "highestScoreForAway",
    "lowestScoreAgainst",
    "lowestScoreAgainstHome",
    "lowestScoreAgainstAway",
    "highestScoreAgainst",
    "highestScoreAgainstHome",
    "highestScoreAgainstAway",
    "streak",
    "streakHome",
    "streakAway"
  ].map((v) => ({ value: v, translation: v }));
}

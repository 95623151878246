import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import InlineForm from "../../../Base/Forms/InlineForm";
import inlineFormSetup from "../inlineFormSetup";
import { injectIntl } from "react-intl";

const StaffMember = (props) => {
  const {
    handleDeleteIntent,
    roles,
    role,
    roleId,
    person,
    refetch,
    pageRef,
    match: {
      params: { sport, organizationId, seasonId, entityId }
    },
    intl
  } = props;
  const { formatMessage } = intl;
  const [isEditState, setEditState] = useState(false);

  const nameFullLocal = roles?.includes?.resources.persons[person.id].nameFullLocal;

  const personLink = `/admin/${sport}/${organizationId}/seasons/${seasonId}/teams/${entityId}/staff/${person.roleId}`;

  const Display = () => (
    <>
      <td>
        <Link to={personLink} className="link">
          {nameFullLocal}
        </Link>
      </td>
      <td>
        {formatMessage({
          id: `roles.${role}`,
          defaultMessage: role
        })}
      </td>
      <td align="right">
        <Button outline color="primary" size="sm" onClick={() => setEditState(true)}>
          <i className="fas fa-pen" />
        </Button>{" "}
        <Button outline size="sm" color="danger" onClick={() => handleDeleteIntent(roleId)}>
          <i className="fas fa-times" />
        </Button>
      </td>
    </>
  );

  const formData = {
    role,
    roleId,
    entityId,
    organizationId
  };

  const translateRole = (role) =>
    formatMessage({
      id: `roles.${role}`,
      defaultMessage: role
    });

  const Edit = () => (
    <InlineForm
      action={refetch}
      setEditMode={setEditState}
      pageRef={pageRef}
      form="StaffEdit"
      formData={formData}
      extraData={{ ...formData, nameFullLocal }}
      inlineFormSetup={inlineFormSetup}
      optionRenderer={translateRole}
    />
  );
  return <tr>{isEditState ? Edit() : Display()}</tr>;
};

export default injectIntl(StaffMember);

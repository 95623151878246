import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

import SeasonFinder from "./SeasonFinder/SeasonFinder";
import { useFetch } from "../Base/Hooks/fetch";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import PageDisplay from "../Base/PageDisplay/PageDisplay";

import "./manage.scss";

const ManageOrganization = (props) => {
  const { title, history, intl, match, updateState } = props;
  const { formatMessage } = intl;
  const [currentOrg, setCurrentOrg] = useState("");
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" + match.params.sport + "/organizations/" + match.params.organizationId,
    "",
    true
  );

  useEffect(() => {
    if (data) {
      setCurrentOrg(data.data[0].nameLocal);
      updateState("orgId", data.data[0].organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [data]);

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={
            formatMessage({
              id: "manage",
              defaultMessage: "Manage"
            }) +
            " - " +
            currentOrg
          }
          error={error}
          pageTitle={
            formatMessage({
              id: "manage",
              defaultMessage: "Manage"
            }) +
            " - " +
            currentOrg +
            title
          }
          ref={pageRef}
          history={history}
          // layout={layout}
          // setLayout={setLayout}
        >
          <div className="manage-organization">
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/leagues/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="leagues" defaultMessage="Leagues" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/competitions/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="competitions" defaultMessage="Competitions" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/conferences/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="conferences" defaultMessage="Conferences" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/clubs/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="clubs" defaultMessage="Clubs" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/teams/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="teams" defaultMessage="Teams" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/sites/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="sites" defaultMessage="Sites" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/venues/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="venues" defaultMessage="Venues" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/persons/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="persons" defaultMessage="Persons" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/standings-configurations/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="standings.configurations" defaultMessage="Standings Configurations" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/leaders-qualification/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="leaders.qualification" defaultMessage="Leaders Qualification" />
            </Link>
            <Link
              to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/match-profiles/"}
              className="btn btn-outline-secondary manage link"
            >
              <FormattedMessage id="match.profiles" defaultMessage="Match Profiles" />
            </Link>
            <hr />
            <SeasonFinder match={match} />
            <hr />
          </div>
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(ManageOrganization);

import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { Spinner, Button } from "reactstrap";

import { useToken } from "../Hooks/token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";

const ImageRemover = (props) => {
  const { data, imageId, pageRef, setRefreshFlag, setLoading, match } = props;
  const [isLoading, setIsLoading] = useState();
  // const { readBody = (body) => body.json() } = {};
  const env = useContext(EnvironmentContext);
  const { token } = useToken();

  function removeImage(id) {
    if (id) {
      (async () => {
        setIsLoading(true);
        setLoading(true);
        if (imageId) {
          let endpoint = "v1/" + match.params.sport + "/o/" + data.organizationId + "/images/" + imageId;
          let url = environments["location"][env] + "/" + endpoint;

          try {
            const response = await fetch(url, {
              method: "DELETE",
              headers: {
                // Accept: "application/json",
                // "Content-Type": "application/json",
                Authorization: "Bearer " + token.token
              },
              body: JSON.stringify({})
            });
            if (response.ok) {
              // const body = await readBody(response);
              // console.log("ok", body);
              pageRef.current.addFlash("Image removed successfully", "success", 10);
              setIsLoading(false);
              setRefreshFlag(true);
            } else {
              // const body = await readBody(response);
              // console.log("error", body);
              // pageRef.current.addFlash("An error occurred", "danger", 10);
              setIsLoading(false);
              setRefreshFlag(true);
            }
          } catch (e) {
            // console.log("E", e);
            // pageRef.current.addFlash("An error occurred", "danger", 10);
            setRefreshFlag(true);
            setIsLoading(false);
          }
        }
      })();
    }
  }
  return (
    <>
      {!isLoading && (
        <Button color="danger" size="sm" onClick={(e) => removeImage(imageId)}>
          <i className="fas fa-times"></i>
        </Button>
      )}
      {isLoading && <Spinner size="sm" color="danger" />}
    </>
  );
};

export default withRouter(ImageRemover);

import React from "react";
import { FormattedMessage } from "react-intl";
import { FormGroup, Label, Input } from "reactstrap";
export function SelectField(props) {
  const options = Array.from(new Set(props.data.list.map((o) => o[props.field])));

  const renderOptions = () =>
    options.map((option, index) => (
      <option key={`${option}-${index}`} value={option}>
        {option}
      </option>
    ));

  return (
    <FormGroup>
      <Label for={props.field}>
        <FormattedMessage id={props.field} />
      </Label>
      <Input
        bsSize="sm"
        onChange={(e) => props.onChange(props.field, e.target.value)}
        type="select"
        name="select"
        value={props.data.filters[props.field] || "none"}
        id={props.field}
      >
        <option value="none">None</option>
        {renderOptions()}
      </Input>
    </FormGroup>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";

const EditClub = (props) => {
  const { title, history, match, intl, updateState } = props;
  const { formatMessage } = intl;
  const [refreshData, setRefreshData] = useState(true);
  const [current, setCurrent] = useState(null);
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + "/entityGroups/" + match.params.clubId,
    "",
    refreshData
  );

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];

  useEffect(() => {
    if (data) {
      setRefreshData(false);
      setCurrent(data.data[0]);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [match]);

  function reloadData() {
    setRefreshData(true);
  }

  return (
    <PageDisplay
      title={formatMessage({
        id: "club.update",
        defaultMessage: "Update Club"
      })}
      error={error}
      pageTitle={current && current.nameFullLocal + " -" + title}
      ref={pageRef}
      history={history}
    >
      {loading && <Spinner size="md" color="orange" />}
      {data && (
        <div>
          <h4>{current && current.nameFullLocal}</h4>
          {current && (
            <FormBuilder
              form="EditClub"
              action={reloadData}
              formSetup={formSetup}
              formData={current}
              pageRef={pageRef}
              lookupData={LookupData}
            />
          )}
          {error}
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(EditClub);

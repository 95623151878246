export default {
  byes: "Byes",
  draws: "Games Drawn",
  drawsAway: "Games Drawn (Away)",
  drawsHome: "Games Drawn (Home)",
  forfeitsGiven: "Forfeits Given",
  forfeitsWonBy: "Forfeits Won",
  highestScoreAgainst: "Highest Score Against",
  highestScoreAgainstAway: "Highest Score Against (Away)",
  highestScoreAgainstHome: "Highest Score Against (Home)",
  highestScoreFor: "Highest Score For",
  highestScoreForAway: "Highest Score For (Away)",
  highestScoreForHome: "Highest Score For (Home)",
  losses: "Games Lost",
  lossesAway: "Games Lost (Away)",
  lossesHome: "Games Lost (Home)",
  lowestScoreAgainst: "Lowest Score Against",
  lowestScoreAgainstAway: "Lowest Score Against (Away)",
  lowestScoreAgainstHome: "Lowest Score Against (Home)",
  lowestScoreFor: "Lowest Score For",
  lowestScoreForAway: "Lowest Score For (Away)",
  lowestScoreForHome: "Lowest Score For (Home)",
  percentage: "For versus Against Percentage",
  percentageAway: "For versus Against Percentage (Away)",
  percentageHome: "For versus Against Percentage (Home)",
  played: "Games Played",
  playedAway: "Games Played (Away)",
  playedHome: "Games Played (Home)",
  pointDifference: "Point Difference",
  pointDifferenceAway: "Point Difference (Away)",
  pointDifferenceHome: "Point Difference (Home)",
  position: "Standings Position",
  scoredAgainst: "Score Against",
  scoredAgainstAway: "Score Against (Away)",
  scoredAgainstHome: "Score Against (Home)",
  scoredFor: "Score For",
  scoredForAway: "Score For (Away)",
  scoredForHome: "Score For (Home)",
  standingPoints: "Standing Points",
  streak: "Winning Streak for competitor",
  streakAway: "Winning Streak for competitor (Away)",
  streakHome: "Winning Streak for competitor (Home)",
  washouts: "Washouts",
  winPercentage: "Win Percentage",
  winPercentageAway: "Win Percentage (Away)",
  winPercentageHome: "Win Percentage (Home)",
  wins: "Games Won",
  winsAway: "Games Won (Away)",
  winsHome: "Games Won (Home)"
};

import React from "react";
import { injectIntl } from "react-intl";
import { useDeleteModal } from "../../../Base/MasterDisplay/useDeleteModal";
import TableDisplay from "../../../Base/Shared/TableDisplay";
import StaffMember from "./StaffMember";

function StaffMembers(props) {
  const {
    intl: { formatMessage },
    roles: { data: staffMembers },
    match: {
      params: { sport, organizationId }
    }
  } = props;

  const columns = [
    { id: "name.local.full", defaultMessage: "Local Full Name" },
    { id: "role", defaultMessage: "Role" }
  ].map(formatMessage);

  const deleteModal = useDeleteModal({
    headingText: formatMessage({ id: "delete" }),
    refreshTrigger: props.refetch,
    pageRef: props.pageRef
  });

  const handleDeleteIntent = (roleId) => {
    const URL = `/v1/${sport}/o/${organizationId}/roles/${roleId}`;
    deleteModal.setData.setUrl(URL);
    deleteModal.setData.setTextContent(formatMessage({ id: "delete.item.confirm" }));
    deleteModal.visibility.open();
  };

  const staffRows = staffMembers.map((staffMember) => (
    <StaffMember key={staffMember.roleId} {...{ ...staffMember, ...props, handleDeleteIntent }} />
  ));

  return (
    <>
      <TableDisplay columns={columns} rows={staffRows} containerClass="table-responsive" withButtonsCell />
      {deleteModal.element()}
    </>
  );
}

export default injectIntl(StaffMembers);

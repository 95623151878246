import React, { useEffect, useState, useRef } from "react";
import { Collapse } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { useFetch } from "../../Base/Hooks/fetch";

import RosterRow from "./RosterRow";
import Person from "./Players/PersonRow";

import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import TableDisplay from "../../Base/Shared/TableDisplay";
import TablePagination from "../../Base/Shared/TablePagination";
import SearchFilter from "../../Base/Shared/SearchFilter";
import { useUpdateToken } from "../../Base/Hooks/updateToken";
import PersonList from "../../Persons/PersonList";
import usePersonsAPI from "../../Persons/PersonsAPI";

const Roster = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationString, setPaginationString] = useState("limit=25&offset=0");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const personsAPI = usePersonsAPI({ match, limit: 50 });

  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/entities/" +
      match.params.entityId +
      "/roster?include=organizations,entities,seasons,persons&" +
      paginationString,
    "",
    refreshFlag
  );

  const { data: teamData } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/entities/" +
      match.params.entityId +
      "?fields=nameFullLocal",
    "",
    true
  );

  const teamName = teamData?.data?.[0].nameFullLocal || "";

  const columns = [
    "",
    formatMessage({
      id: "bib",
      defaultMessage: "Bib"
    }),
    formatMessage({
      id: "name.local.full",
      defaultMessage: "Local Full Name"
    }),
    formatMessage({
      id: "name.latin.full",
      defaultMessage: "Latin Full Name"
    }),
    formatMessage({
      id: "position",
      defaultMessage: "Position"
    }),
    formatMessage({
      id: "status.season",
      defaultMessage: "Status"
    }),
    ""
  ];

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data) {
      let filteredData = data !== null ? data.data : [];

      filteredData.forEach((result, index) => {
        filteredData[index] = {
          ...result,
          ...data.includes.resources.entities[result.entity.id]
        };
        filteredData[index].status = result.status;
      });

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return data.includes.resources.persons[result.person.id].nameFullLocal
            .toUpperCase()
            .includes(searchText.toUpperCase());
        });
      }
      let tableList = filteredData.map((result, index) => {
        return (
          <RosterRow
            key={index}
            itemIndex={index}
            result={result}
            person={data.includes.resources.persons[result.person.id]}
            updateState={updateState}
            match={match}
            pageRef={pageRef}
            reloadData={reloadData}
            setRefreshFlag={setRefreshFlag}
          />
        );
      });
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      <PageDisplay
        title={
          teamName +
          " " +
          formatMessage({
            id: "roster",
            defaultMessage: "Roster"
          })
        }
        error={error}
        pageTitle={
          teamName +
          formatMessage({
            id: "roster",
            defaultMessage: "Roster"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
      >
        <Link
          to={
            "/admin/" +
            match.params.sport +
            "/" +
            match.params.organizationId +
            "/seasons/" +
            match.params.seasonId +
            "/teams/" +
            match.params.entityId +
            "/roster/images"
          }
          className="btn btn-primary float-left"
        >
          <i className="fas fa-images" />{" "}
          {formatMessage({
            id: "images.manage",
            defaultMessage: "Manage Images"
          })}
        </Link>
        <SearchFilter doSearch={setSearchText} />
        <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading && false} />
        <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
        <hr />
        <h4 onClick={toggleForm}>
          {!isFormOpen && (
            <span>
              <FormattedMessage id="roster.add" defaultMessage="Add New Person" /> <i className="fas fa-caret-down"></i>
            </span>
          )}
          {isFormOpen && (
            <span>
              <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
            </span>
          )}
        </h4>
        <Collapse isOpen={isFormOpen}>
          <PersonList
            {...props}
            personsAPI={personsAPI}
            pageRef={pageRef}
            reloadData={reloadData}
            PersonRowComponent={Person}
            LookupData={LookupData}
          />
        </Collapse>
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Roster);

import React, { useEffect, useState, useRef, useCallback } from "react";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";

const ConferenceForm = (props) => {
  const { title, history, match, intl } = props;
  const { formatMessage } = intl;
  const [refreshData, setRefreshData] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [current, setCurrent] = useState(null);
  const pageRef = useRef();

  useEffect(() => {
    setCurrent({
      organizationId: match.params.organizationId
    });
    if (match.params.conferenceId) {
      setIsEditMode(true);
      setCurrent({
        ...current,
        conferenceId: match.params.conferenceId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { error, data } = useFetch(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + "/conferences/" + match.params.conferenceId,
    "",
    isEditMode
  );

  useEffect(() => {
    if (data) {
      setCurrent(data.data[0]);
    }
    // eslint-disable-next-line
  }, [data]);

  function reloadData(data) {
    setCurrent({ ...current, ...data });
    setRefreshData(false);
    setRefreshData(true);
    if (!isEditMode) {
      props.onCreate();
    }
  }

  const getPageTitle = useCallback(
    () =>
      isEditMode
        ? `Update Conference - ${title}`
        : formatMessage({
            id: isEditMode ? "conference.update" : "conference.add",
            defaultValue: isEditMode ? "Update Conference" : "Create Conference"
          }) +
          " - " +
          title,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditMode]
  );

  const Form = () => (
    <div>
      {current && refreshData && (
        <FormBuilder
          form={isEditMode ? "UpdateConference" : "CreateConference"}
          action={reloadData}
          formSetup={formSetup}
          formData={current}
          pageRef={isEditMode ? pageRef : props.pageRef}
        />
      )}
    </div>
  );

  const PageWrapper = (content) => (
    <PageDisplay
      title={
        formatMessage({
          id: isEditMode ? "conference.update" : "conference.add",
          defaultValue: isEditMode ? "Update Conference" : "Create Conference"
        }) + ` ${isEditMode ? current.nameLatin : ""}`
      }
      error={error}
      pageTitle={getPageTitle()}
      ref={isEditMode ? pageRef : props.pageRef}
      history={history}
    >
      {content()}
      {error}
    </PageDisplay>
  );

  return isEditMode ? PageWrapper(Form) : Form();
};

export default injectIntl(ConferenceForm);

export default function nameSort(a, b) {
  const [firstPersonFirstName, firstPersonLastName] = a.person.split(" ");
  const [secondPersonFirstName, secondPersonLastName] = b.person.split(" ");

  if (firstPersonLastName && secondPersonLastName) {
    return firstPersonLastName.localeCompare(secondPersonLastName);
  }

  return firstPersonFirstName.localeCompare(secondPersonFirstName);
}

import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { Spinner } from "reactstrap";

import ImageRemover from "./ImageRemover";
import "./images.scss";

const ImageDisplay = (props) => {
  const {
    data,
    images,
    pageRef,
    imageType,
    setRefreshFlag,
    checkQuality,
    intl: { formatMessage }
  } = props;
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (images && images.find((image) => image.imageType === imageType)) {
      setImage(images.find((image) => image.imageType === imageType));
    } else {
      setLoading(false);
      setImage(null);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(images)]);

  useEffect(() => {
    if (image) {
      setLoading(true);
      var img = new Image();
      img.src = image.url + "?t=" + new Date().getTime();
      img.onload = function () {
        setLoading(false);
      };
    }
    // eslint-disable-next-line
  }, [image]);

  function composeWarningTitle(image) {
    if (!image) {
      return [];
    }
    const warnings = [];
    if (image.rating === "LOW") {
      warnings.push(
        formatMessage({
          id: "image.quality.warning",
          defaultMessage: "Image may lack enough transparency"
        })
      );
    }
    if (image.maximumHeight * image.maximumWidth < 10e5) {
      warnings.push(
        formatMessage({
          id: "image.size.warning",
          defaultMessage: "Recommended image size is a minimum 1000x1000 pixels"
        })
      );
    }
    return warnings;
  }

  return (
    <div className="inline-image-container">
      {image && (
        <>
          {loading && <Spinner color="danger" size="sm" />}
          {!loading && (
            <>
              <img src={image.url} alt={imageType} className="inline-image" />
              {checkQuality && composeWarningTitle(image).length > 0 && (
                <span title={composeWarningTitle(image).join("\n")} className="quality-warning">
                  <i className="fas fa-exclamation-triangle">
                    <span>Low Quality</span>
                  </i>
                </span>
              )}
              <ImageRemover
                data={data}
                imageId={image.imageId}
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
                setLoading={setLoading}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default injectIntl(ImageDisplay);

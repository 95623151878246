var formSetup = {
  forms: {
    RosterUpdate: {
      endpoint: "v1/#sport/o/#0/seasons/#1/roster",
      parameters: ["organizationId", "seasonId"],
      method: "POST",
      successmessage: "Roster updated successfully.",
      dataFields: ["bib", "entityId", "personId", "position", "seasonId", "status"],
      fields: [
        {
          name: "",
          type: "blank",
          class: "sm"
        },
        {
          name: "bib",
          type: "number",
          class: "sm"
        },
        {
          name: "nameFullLocal",
          type: "display",
          source: "extra"
        },
        {
          name: "nameFullLatin",
          type: "display",
          source: "extra"
        },
        {
          name: "position",
          type: "select",
          data: ["", "GUARD", "POINT_GUARD", "SHOOTING_GUARD", "FORWARD", "SHOOTING_FORWARD", "POWER_FORWARD", "CENTER"]
        },
        {
          name: "status",
          type: "select",
          class: "med",
          data: ["ACTIVE", "INJURED", "OTHER_NOT_PARTICIPATING", "OUT", "SUSPENDED"]
        }
      ]
    }
  }
};
export default formSetup;

import React, { useRef } from "react";
import { injectIntl } from "react-intl";
import { Spinner } from "reactstrap";
import FormBuilder from "../../Base/Forms/FormBuilder";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import TableDisplay from "../../Base/Shared/TableDisplay";
import LeadersQualificationTemplate from "./LeadersQualificationTemplate";
import formSetup from "../formSetup";
import FormCollapse from "../../Base/Forms/FormCollapse";
import { useDeleteModal } from "../../Base/MasterDisplay/useDeleteModal";
import useResourceAPI from "../../Base/Hooks/resourceAPI";
import TablePagination from "../../Base/Shared/TablePagination";

function LeadersQualification(props) {
  const {
    history,
    match: {
      params: { sport, organizationId }
    },
    intl: { formatMessage },
    title
  } = props;

  const pageRef = useRef();

  const columns = ["name"].map((id) => formatMessage({ id }));

  const { data, loading, refetch, updatePagination } = useResourceAPI({
    ...props,
    resourceName: `statistics/leaders/criteria`,
    initialQuery: {
      limit: 20,
      offset: 0
    }
  });

  const formData = { sport, organizationId };

  const { element, setData, visibility } = useDeleteModal({
    headingText: formatMessage({ id: "delete" }),
    actionText: formatMessage({ id: "ok" }),
    cancelText: formatMessage({ id: "cancel" }),
    text: formatMessage({ id: "leaders.qualification.delete" }),
    refreshTrigger: refetch,
    pageRef
  });

  function handleDeleteIntent(leaderCriteriaId) {
    visibility.open();
    setData.setUrl(`/v1/${sport}/o/${organizationId}/statistics/leaders/criteria/${leaderCriteriaId}`);
  }

  return (
    <PageDisplay
      title={formatMessage({ id: "leaders.qualification" })}
      pageTitle={`${formatMessage({ id: "leaders.qualification" })} - ${title}`}
      ref={pageRef}
      history={history}
    >
      {element()}
      {loading && <Spinner size="md" color="orange" />}
      {data && !loading && (
        <>
          <TableDisplay
            withButtonsCell
            columns={columns}
            rows={data.data.map((item) => (
              <LeadersQualificationTemplate
                key={item.leaderCriteriaId}
                {...{
                  ...item,
                  ...props,
                  pageRef,
                  refetch,
                  handleDeleteIntent
                }}
              />
            ))}
          />
          <TablePagination data={data} setPaginationString={updatePagination} />
          <FormCollapse closedId="leaders.qualification.create">
            <FormBuilder
              pageRef={pageRef}
              formData={formData}
              formSetup={formSetup}
              form="CreateTemplate"
              action={refetch}
            />
          </FormCollapse>
        </>
      )}
    </PageDisplay>
  );
}

export default injectIntl(LeadersQualification);

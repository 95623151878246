import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { Link } from "react-router-dom";

import LookupField from "./LookupField";
import LookupDataField from "./LookupDataField";
import { useToken } from "../Hooks/token";

import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
import { CheckBoxArrayHandler } from "./CheckboxArray";

const baseSelectProps = { type: "select", component: "select" };

const FormBuilder = (props) => {
  const { form, formSetup, formData, dataId, lookupData, customFields, intl, match } = props;
  const { formatMessage } = intl;
  const { token } = useToken();
  const [responseData, setData] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [linkPath, setLinkPath] = useState("");
  const { readBody = (body) => body.json() } = {};

  const env = useContext(EnvironmentContext);

  const toggle = () => setModalOpen(!modalOpen);

  function isEmail(value) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) && value !== "") {
      return false;
    }
    return true;
  }

  function doSubmit(values = formValues) {
    setFormSubmitting(true);
    setModalOpen(false);
    let endpoint = formSetup.forms[form].endpoint;
    formSetup.forms[form].parameters.map((parameter, key) => {
      endpoint = endpoint.replace("#" + key, formData[parameter]);
      return null;
    });
    endpoint = endpoint.replace("#sport", match.params.sport);
    setShowSuccess(false);
    setTimeout(() => {
      formSetup.forms[form].fields.map((field, key) => {
        if (
          field.type === "lookup" &&
          values[field.name] &&
          values[field.name].length &&
          values[field.name].length < 4
        ) {
          values[field.name] = parseInt(values[field.name]) || values[field.name];
        }
        if ((field.type === "lookup" && values[field.name] === "null") || values[field.name] === "") {
          values[field.name] = null;
        }

        if (field.type === "number") {
          values[field.name] = parseInt(values[field.name]) || values[field.name];
        }
        if (field.type === "select-boolean" || field.type === "boolean") {
          if (typeof values[field.name] === "string") {
            switch (values[field.name].toLowerCase().trim()) {
              case "true":
                values[field.name] = true;
                break;
              case "false":
                values[field.name] = false;
                break;
              default:
                values[field.name] = false;
            }
          }
        }
        if (field.conditionValue && field.conditionValue !== setupValues[field.condition]) {
          delete values[field.name];
        }
        if (field.name.indexOf(".") > -1) {
          delete values[field.name];
        }

        if (field.type === "subFields") {
          for (const f of field.options) {
            if (f.type === "select-boolean") {
              if (!values[field.name]) {
                break;
              }
              if (values[field.name][f.name] === "true") {
                values[field.name][f.name] = true;
              } else if (values[field.name][f.name] === "false") {
                values[field.name][f.name] = false;
              }
            }
            if (values[field.name] && values[field.name][f.name] === null && f.default) {
              values[field.name][f.name] = f.default;
            }
            if (f.treatFalseAsNull && !Boolean(values[field.name]?.[f.name])) {
              values[field.name] = {
                ...values[field.name],
                [f.name]: null
              };
            }
          }
        }

        if (values[field.name] === null || (values[field.name] === undefined && field.hasOwnProperty("default"))) {
          values[field.name] = field.default;
        }

        if (field.readOnly || (values[field.name] === null && field.ignoreIfNull)) {
          delete values[field.name];
        }

        if (field.treatFalseAsNull && !values[field.name]) {
          values[field.name] = null;
        }

        return null;
      });
      if (formSetup.forms[form].method !== "CUSTOM") {
        (async () => {
          try {
            const response = await fetch(
              environments.location[env] + "/" + endpoint,

              {
                method: formSetup.forms[form].method,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: token.tokenType + " " + token.token
                },
                mode: "cors",
                body: JSON.stringify(values)
              }
            );
            if (response.ok) {
              const body = await readBody(response);
              let successmessage = null;
              if (formSetup.forms[form].successmessagetranslation) {
                successmessage = formatMessage({
                  id: formSetup.forms[form].successmessagetranslation,
                  defaultMessage: formSetup.forms[form].successmessage
                });
              } else {
                successmessage = formSetup.forms[form].successmessage;
              }
              if (props.pageRef) {
                props.pageRef.current.addFlash(successmessage + " - " + linkPath, "success", 5);
              } else {
                setShowSuccess(true);
              }
              if (formSetup.forms[form].link) {
                formSetup.forms[form].link.parameters.map((parameter, key) => {
                  setLinkPath(formSetup.forms[form].link.path.replace("#" + key, body.data[0][parameter]));
                  return null;
                });
              }
              setFormSubmitting(false);
              setData(body.data[0]);
              var getType = {};
              if (getType.toString.call(props.action) === "[object Function]") {
                props.action(values, body);
              }
            } else {
              const body = await readBody(response);
              if (props.pageRef) {
                props.pageRef.current.addFlash(body?.error?.errors[0].message, "danger", 15);
              }
              setFormSubmitting(false);
            }
          } catch (e) {
            console.log(e);
            if (props.pageRef) {
              console.log(props);
              props.pageRef.current.addFlash(String(e), "danger", 15);
            }
            setFormSubmitting(false);
          }
        })();
      }
      if (formSetup.forms[form].method === "CUSTOM") {
        props.action(values);
        setFormSubmitting(false);
      }
      // setSubmitting(false);
    }, 400);
  }

  let setupValues = {};
  formSetup.forms[form].fields.map((field, key) => {
    if (field.initial === "dataId") {
      setupValues[field["name"]] = parseInt(dataId);
    } else if (field.initial !== null && field.name.search("Date") >= 0) {
      var date = new Date(formData[field.initial]);
      var dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
      setupValues[field["name"]] = dateString;
    } else if (field.initial !== null && field.initial.search("##") >= 0) {
      setupValues[field["name"]] = field.initial.replace("##", "");
    } else {
      setupValues[field["name"]] = field.initial !== null ? formData[field.initial] : "";
    }
    if (setupValues[field["name"]] === null) {
      setupValues[field["name"]] = "";
    }
    if (field.isJSON) {
      setupValues[field.name] = JSON.stringify(formData[field.name]);
    }
    if (field.type === "lookup") {
      // setupValues[field["name"]] = field.initial; //setupValues[field["name"]];
      setupValues[field["name"]] = field.initial !== null ? formData[field.initial] : "";
    }
    if (field.type === "checkboxarray") {
      if (Array.isArray(setupValues[field["name"]]) === false) {
        setupValues[field["name"]] = [];
      }
    }
    if (field.type !== "subFields" && field.default) {
      setupValues[field["name"]] = field.default;
    }
    if (field.type === "subFields") {
      setupValues[field["name"]] = formData[field["name"]] || field.default;
    }
    if (field.initial && field.initial.indexOf(".") > 0) {
      let fieldParts = field.initial.split(".");
      if (typeof setupValues[fieldParts[0]] === "undefined") {
        setupValues[fieldParts[0]] = {};
      }
      if (formData[fieldParts[0]] !== null && formData[fieldParts[0]][fieldParts[1]] !== null) {
        setupValues[fieldParts[0]][fieldParts[1]] = formData[fieldParts[0]][fieldParts[1]];
      }
    }

    return null;
  });
  const dateTimeInput = ({ field, form: { touched, errors }, ...props }) => (
    <Input invalid={!!(touched[field.name] && errors[field.name])} {...field} {...props} />
  );

  const genericField = (field) => (props) => {
    const name = field.isSubField ? `${field.parent.name}.${field.name}` : field.name;

    return (
      <>
        <Label for={field.name}>
          {!field.labeltranslation && field.label}
          {field.labeltranslation && <FormattedMessage id={field.labeltranslation} defaultMessage={field.label} />}
          {field.required && <span style={{ color: "red" }}>*</span>}
          {(field.readOnly || field.disabled) && <i className="fas fa-xs fa-lock form-builder-locked-field" />}
        </Label>
        <Field
          type={field.type}
          name={name}
          id={field.name}
          placeholder={field.placeholder}
          className={field.className}
          title={field.placeholder}
          disabled={field.disabled || field.readOnly}
          defaultValue={field.default}
          min={field.min}
          max={field.max}
          pattern={field.pattern}
          {...props}
        />
        {field?.exploreResolver && (
          <Link
            className="form-explore"
            to={field?.exploreResolver?.call({}, { ...props, ...formData, ...match.params })}
          >
            {formatMessage({
              id: "view.configuration",
              defaultMessage: "View Configuration"
            })}
          </Link>
        )}
      </>
    );
  };

  const composeSelectOptions = (field) =>
    field.options.map((option) => (
      <option key={option.value} value={option.value}>
        {!option.translation && option.text}
        {option.translation &&
          formatMessage({
            id: option.translation,
            defaultMessage: option.text
          })}
      </option>
    ));

  const renderCheckboxArray = (field, props = { isSubField: false }, formContext) => {
    const isFieldChecked = (option) => {
      if (props.isSubField) {
        const value = formContext.values[props.parent.name];
        if (value && value[field.name]) {
          return value[field.name].includes(option.value);
        }
      } else {
        return formContext.values[field["name"]].includes(option.value);
      }
    };

    const name = props.isSubField ? `${props.parent.name}.${field.name}` : field.name;

    return (
      <React.Fragment>
        <Label for={field.name}>
          {!field.labeltranslation && field.label}
          {field.labeltranslation && <FormattedMessage id={field.labeltranslation} defaultMessage={field.label} />}
          {field.required && <span style={{ color: "red" }}>*</span>}
        </Label>
        <FieldArray
          name={name}
          id={field.name}
          className={field.className}
          render={(arrayHelpers) => (
            <div>
              {field.options.map((option) => (
                <div key={option.label} className={"checkbox " + field.optionsClass}>
                  <label>
                    <input
                      name={props.isSubField ? `${props.parent.name}[${field.name}]` : field.name}
                      type="checkbox"
                      value={option.value}
                      checked={isFieldChecked(option)}
                      onChange={(e) =>
                        CheckBoxArrayHandler({
                          changeEvent: e,
                          field,
                          props,
                          formContext
                        })
                      }
                    />
                    <span className="cr">
                      <i className="cr-icon fa fa-check" />
                    </span>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        />
      </React.Fragment>
    );
  };

  const renderField = (field, props, formContext) => {
    const f = genericField({ ...field, ...props });
    switch (field.type) {
      case "textarea":
        return f({ component: "textarea", rows: field.rows });
      case "checkboxarray":
        return renderCheckboxArray(field, props, formContext);
      case "date":
        return f({ component: dateTimeInput });
      case "datetime":
        return f({ type: "datetime-local" });
      case "select":
      case "select-boolean":
        return f({
          ...baseSelectProps,
          children: composeSelectOptions(field)
        });
      case "lookup":
        return f({
          ...baseSelectProps,
          currentFormValues: formContext.values,
          children: <LookupField lookup={field.lookup} formData={formData} defaultValue={setupValues[field["name"]]} />
        });
      case "lookupData":
        return f({
          ...baseSelectProps,
          children: <LookupDataField {...field} lookup={field.lookupData} lookupData={lookupData} />
        });
      case "custom":
        /** Wrap the component in a <Field/> to allow passing of form props to it */
        return f({
          name: field.name || field[props.parent.name],
          component: customFields[field["name"]] || customFields[props.parent.name][field.name]
        });
      case "subFields":
        return (
          <React.Fragment>
            <Label for={field.name}>
              {!field.labeltranslation && field.label}
              {field.labeltranslation && <FormattedMessage id={field.labeltranslation} defaultMessage={field.label} />}
              {field.required && <span style={{ color: "red" }}>*</span>}
            </Label>
            <FieldArray
              name={field.name}
              id={field.name}
              className={field.className}
              render={() => (
                <div>
                  {field.options.map((option, index) => (
                    <FormGroup key={index} className={option.size + " hidden-" + option.hidden}>
                      {renderField(option, { isSubField: true, parent: field }, formContext)}
                    </FormGroup>
                  ))}
                </div>
              )}
            />
          </React.Fragment>
        );
      default:
        return f();
    }
  };

  let fields = (currentFormValues) =>
    formSetup.forms[form].fields.map((field, index) => {
      return (
        <React.Fragment key={index}>
          {field.conditionValue === setupValues[field.condition] && (
            <FormGroup key={index} className={field.size + " hidden-" + field.hidden}>
              {renderField(field, {}, currentFormValues)}
              <div className="field-error-wrapper">
                <ErrorMessage name={field.name}>{(msg) => <div className="formError">{msg}</div>}</ErrorMessage>
              </div>
            </FormGroup>
          )}
        </React.Fragment>
      );
    });

  const validateForm = (values) => {
    let errors = {};
    formSetup.forms[form].fields.forEach((field) => {
      if (field.required) {
        if (!values[field["name"]]) {
          errors[field["name"]] = field.errors.required;
        }
        if (field.conditionValue && field.conditionValue !== setupValues[field.condition]) {
          delete errors[field["name"]];
        }
        if (Number.isInteger(field.min)) {
          if (values[field.name] < field.min) {
            errors[field.name] = field.errors.numberMin;
          }
        }
      }
      if (field.maxLength) {
        if (values[field["name"]].length > field.maxLength) {
          errors[field["name"]] = field.errors.maxLength;
        }
      }
      if (field.type === "email") {
        if (!isEmail(values[field["name"]])) {
          errors[field["name"]] = field.errors.email;
        }
      }
      if (field.type === "subFields") {
        let subFieldErrors = [];
        for (const option of field.options) {
          if ((option.required && !values[field.name]) || (option.required && !values[field.name][option.name])) {
            subFieldErrors.push(`${option.placeholder}`);
          }
        }
        if (subFieldErrors.length > 0) {
          errors[field.name] = `Missing properties: ${subFieldErrors.join(", ")}`;
        }
      }
    });
    return errors;
  };

  return (
    <Container className={formSetup.forms[form].formClass}>
      <Row className="form-header-row">
        <Col>
          <h3>
            {formSetup.forms[form].headingIcon && <i className={formSetup.forms[form].headingIcon}></i>}
            {!formSetup.forms[form].titletranslation && formSetup.forms[form].title}
            {formSetup.forms[form].titletranslation && (
              <FormattedMessage
                id={formSetup.forms[form].titletranslation}
                defaultMessage={formSetup.forms[form].title}
              />
            )}
          </h3>
          <Alert color="success" isOpen={showSuccess} fade={true}>
            {responseData && !formSetup.forms[form].successmessagetranslation && formSetup.forms[form].successmessage}
            {responseData && formSetup.forms[form].successmessagetranslation && (
              <FormattedMessage
                id={formSetup.forms[form].successmessagetranslation}
                defaultMessage={formSetup.forms[form].successmessage}
              />
            )}
            {responseData && formSetup.forms[form]?.link?.show && (
              <Link to={linkPath}>{formSetup.forms[form].link.message}</Link>
            )}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col className="formColumn">
          {formSubmitting && <Spinner size="md" color="orange" />}
          <Formik
            enableReinitialize={true}
            initialValues={setupValues}
            validate={validateForm}
            onSubmit={(values, { setSubmitting }) => {
              setFormValues(values);
              if (formSetup.forms[form].submitconfirm) {
                setModalOpen(true);
              } else {
                doSubmit(values);
              }
            }}
          >
            {(formContext) => (
              <Form className={"submitting-" + formSubmitting}>
                {fields(formContext)}
                <Col size="12">
                  <Button
                    type="submit"
                    color={formSetup.forms[form].submitcolor ? formSetup.forms[form].submitcolor : "secondary"}
                    disabled={formSubmitting}
                    className={formSetup.forms[form].submitClass}
                  >
                    {formSetup.forms[form].submitIcon && <i className={formSetup.forms[form].submitIcon}></i>}
                    {!formSetup.forms[form].submittranslation && formSetup.forms[form].submit}
                    {formSetup.forms[form].submittranslation && (
                      <FormattedMessage
                        id={formSetup.forms[form].submittranslation}
                        defaultMessage={formSetup.forms[form].submit}
                      />
                    )}
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <div>
        <Modal isOpen={modalOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <FormattedMessage id="are.you.sure" defaultMessage="Are you sure?" />
          </ModalHeader>
          <ModalBody>
            {formSetup.forms[form].submitconfirmtranslation && (
              <FormattedMessage
                id={formSetup.forms[form].submitconfirmtranslation}
                defaultMessage={formSetup.forms[form].submitconfirm}
              />
            )}
            {!formSetup.forms[form].submitconfirmtranslation && <span>{formSetup.forms[form].submitconfirm}</span>}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => doSubmit()}>
              <FormattedMessage id="ok" defaultMessage="OK" />
            </Button>
            <Button color="default" onClick={toggle}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default withRouter(injectIntl(FormBuilder));

import React from "react";
import { injectIntl } from "react-intl";
import { Col, Row } from "reactstrap";
import { ColorPicker } from "../Base/ColorPicker";

function TeamColors(props) {
  const {
    form,
    intl: { formatMessage }
  } = props;

  function handleColorChange(color, label) {
    form.setFieldValue("colors", {
      ...form.values.colors,
      [label]: color.replace("#", "")
    });
  }

  return (
    <Row>
      {["primary", "secondary", "tertiary"].map((colorType) => (
        <Col key={colorType}>
          <ColorPicker
            onColorChange={handleColorChange}
            label={formatMessage({ id: colorType })}
            type={colorType}
            color={"#" + form?.values?.colors?.[colorType]}
          />
        </Col>
      ))}
    </Row>
  );
}

export default injectIntl(TeamColors);

export const dataDisplayReducer = (state, action) => {
  switch (action.type) {
    case "fetchUpdate":
      return {
        ...state,
        list: action.payload.data.map((a) => ({ ...a, visible: true })),
        includes: action.payload.includes
      };
    case "filterFieldUpdate":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value
        }
      };
    case "textSearchUpdate":
      return {
        ...state,
        textSearch: action.payload
      };
    case "reset":
      return {
        ...state,
        textSearch: "",
        filters: {}
      };
    default:
      return state;
  }
};

export const initialDataDisplayState = {
  list: [],
  filters: {},
  textSearch: ""
};

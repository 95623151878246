import React, { useState, useEffect, Fragment } from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import TableDisplay from "../../Base/Shared/TableDisplay";
import { sortCollectionByKey } from "../../Base/Utils/Array/sortCollectionByKey";
import { allColumns, defaultColumns, pointsColumns } from "./standingsColumns";
import { Button } from "reactstrap";

const roundNumber = (number) => Math.round((number + Number.EPSILON) * 100) / 100;

function StandingsDisplay(props) {
  const {
    intl: { formatMessage },
    selectedConferenceId,
    selectedGrouping,
    conferencesData,
    organizationId,
    divisionsData,
    displayMode,
    standings,
    sport
  } = props;
  const [columns, setColumns] = useState(defaultColumns);

  function getTeamName(id) {
    return standings.includes.resources.entities[id].nameFullLocal;
  }

  function applyFilters(item) {
    if (selectedGrouping === "CONFERENCE") {
      return item.conferenceId === selectedConferenceId;
    }
    return true;
  }

  function getTeamLink(id) {
    return `/admin/${sport}/${organizationId}/teams/${id}`;
  }

  function composeConferencesRows() {
    const standingsByConference = conferencesData.data.map((conference) => (
      <Fragment key={conference.conferenceId}>
        <tr>
          <td colSpan="7">
            <h6>{conference.nameLocal}</h6>
          </td>
        </tr>
        {sortCollectionByKey(standings.data, "position")
          .filter((standingRow) => standingRow.conferenceId === conference.conferenceId)
          .map(renderStandingRow)}
      </Fragment>
    ));
    return standingsByConference;
  }

  function renderDivision(division) {
    const data = standings.data.filter((standingsRow) => standingsRow.divisionId === division.divisionId);
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={7}>No games played</td>
        </tr>
      );
    }
    return data.map(renderStandingRow);
  }

  function composeDivisionRows() {
    return conferencesData.data.map((conference) => (
      <Fragment key={conference.conferenceId}>
        <tr>
          <td colSpan="7">
            <h6>{conference.nameLocal}</h6>
          </td>
        </tr>
        {divisionsData.data.map((division) => (
          <Fragment key={division.divisionId}>
            <tr>
              <td colSpan={7}>
                <h6>{division.nameLocal}</h6>
              </td>
            </tr>
            {renderDivision(division)}
          </Fragment>
        ))}
      </Fragment>
    ));
  }

  function composeResultString(resultString) {
    return (
      <>
        {resultString
          .split("")
          .map((r, idx) => <span key={idx} className={`result-string-item r-${r.toUpperCase()}`}></span>)
          .slice(-5)}
      </>
    );
  }

  function renderStandingRow(row, index) {
    if (displayMode === "BASIC") {
      return renderSimpleRow(row, index);
    }
    const combinedData = { ...row.calculated.OVERALL, ...row.points.OVERALL };
    return (
      <tr className="standings-row" key={row.standingId + index}>
        <td>{index + 1}</td>
        <td>
          <Link className="link" to={getTeamLink(row.entityId)}>
            {getTeamName(row.entity.id)}
          </Link>
        </td>
        {Object.keys(combinedData).map((key) => (
          <td key={key}>{combinedData[key]}</td>
        ))}
      </tr>
    );
  }

  function renderSimpleRow(row) {
    const { standingId, position } = row;
    const {
      losses,
      lossesAway,
      lossesHome,
      percentage,
      played,
      resultString,
      scoredAgainst,
      scoredFor,
      streak,
      winPercentage,
      wins,
      winsAway,
      winsHome
    } = row.calculated.OVERALL;

    const { wins: divisionWins, losses: divisionLosses } = row.calculated.IN_DIVISION;
    const { wins: conferenceWins, losses: conferenceLosses } = row.calculated.IN_CONFERENCE;

    const { standingPoints, gamesBehind } = row.points.OVERALL;
    return (
      <tr className="standings-row" key={row.standingId}>
        <td>{position}</td>
        <td>
          <Link className="link" to={getTeamLink(row.entityId)}>
            {getTeamName(row.entity.id)}
          </Link>
        </td>
        <td>{played}</td>
        <td>{wins}</td>
        <td>{losses}</td>
        <td>{roundNumber(winPercentage * 100)}</td>
        <td>{roundNumber(percentage * 100)}</td>
        <td>{scoredFor}</td>
        <td>{scoredAgainst}</td>
        <td>
          {winsHome}-{lossesHome}
        </td>
        <td>
          {winsAway}-{lossesAway}
        </td>
        <td>{streak}</td>
        <td>
          <div className="result-string">{composeResultString(resultString)}</div>
        </td>
        <td>
          {divisionWins}-{divisionLosses}
        </td>
        <td>
          {conferenceWins}-{conferenceLosses}
        </td>
        <td>{standingPoints}</td>
        <td>{gamesBehind}</td>

        <td className="text-right">
          <Link
            to={`/admin/${sport}/${organizationId}/standings/${standingId}`}
            className="btn btn-outline-primary btn-sm"
          >
            <i className="fas fa-pen" />
          </Link>{" "}
          <Button onClick={() => props.onDeleteIntent(row)} outline color="danger" size="sm">
            <i className="fas fa-minus" />
          </Button>
        </td>
      </tr>
    );
  }

  function composeRows() {
    switch (selectedGrouping) {
      case "CONFERENCE":
        return conferencesData && composeConferencesRows();
      case "DIVISION":
        return composeDivisionRows();
      default:
        return composeOverallRows();
    }
  }

  function composeOverallRows() {
    return sortCollectionByKey(standings.data, "position").filter(applyFilters).map(renderStandingRow);
  }

  useEffect(() => {
    if (displayMode === "BASIC") {
      setColumns(defaultColumns);
    } else {
      setColumns(allColumns.concat(pointsColumns));
    }
  }, [displayMode]);

  return (
    <TableDisplay
      headerCellClassName={`standings-header-cell${displayMode === "ALL" ? "-full" : ""}`}
      containerClass="table-responsive"
      columns={columns.map((v) => formatMessage({ id: v, defaultMessage: v }))}
      rows={composeRows()}
    />
  );
}

export default injectIntl(StandingsDisplay);

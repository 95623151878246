var menuItems = [
  {
    items: [
      {
        product: "ca",
        link: "/",
        text: "Competition Admin",
        icon: "multi-color-icon-competition",
        children: [
          {
            link: "/organizations",
            text: "Organizations",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true }
            ]
          }
        ]
      },
      {
        product: "pm",
        link: "%PRODUCT_LINK%",
        text: "Live Production Monitor",
        icon: "multi-color-icon-production",
        conditions: [
          {
            variable: "products",
            key: "live_production_monitor",
            evaluator: true
          }
        ]
      },
      {
        product: "um",
        link: "%PRODUCT_LINK%",
        text: "User Manager",
        icon: "multi-color-icon-users",
        conditions: [{ variable: "products", key: "user_manager", evaluator: true }]
      },
      {
        product: "vm",
        link: "%PRODUCT_LINK%",
        text: "Video Stream Manager",
        icon: "multi-color-icon-video",
        conditions: [
          {
            variable: "products",
            key: "video_stream_manager",
            evaluator: true
          }
        ]
      },
      {
        product: "evo",
        link: "%PRODUCT_LINK%",
        text: "EVO Admin",
        icon: "multi-color-icon-evo",
        conditions: [
          {
            variable: "products",
            key: "evo_admin",
            evaluator: true
          }
        ]
      },
      {
        product: "admin.dc",
        link: "%PRODUCT_LINK%",
        text: "Datacore Admin",
        icon: "multi-color-icon-datacore",
        conditions: [
          {
            variable: "products",
            key: "Datacore_datacore_admin_tool",
            evaluator: true
          }
        ]
      },
      {
        link: "https://d45erdalr3dh.cloudfront.net/schedule-manager/",
        text: "Schedule Manager",
        icon: "multi-color-icon-schedule",
        conditions: [{ variable: "products", key: "s3_schedule_manager", evaluator: true }]
      },
      {
        link: "https://d45erdalr3dh.cloudfront.net/image-manager/",
        text: "s3 Image Manager",
        icon: "multi-color-icon-images",
        conditions: [{ variable: "products", key: "s3_image_manager", evaluator: true }]
      },
      {
        link: "https://d45erdalr3dh.cloudfront.net/dashboard/",
        text: "EVO Operations",
        icon: "multi-color-icon-evo",
        conditions: [
          {
            variable: "products",
            key: "evo_operations_panel",
            evaluator: true
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        link: "#",
        text: "Logout",
        translation: "logout",
        icon: "multi-color-icon-logout",
        function: "logout",
        conditions: [{ variable: "isAuthenticated", evaluator: true }]
      }
    ]
  }
];
export default menuItems;

import React, { useEffect, useState, useRef } from "react";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";

const DivisionsForm = (props) => {
  const { title, history, match, intl } = props;
  const { formatMessage } = intl;
  const [refreshData, setRefreshData] = useState(true);
  const [current, setCurrent] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const pageRef = useRef();

  useEffect(() => {
    setCurrent({
      organizationId: match.params.organizationId,
      conferenceId: match.params.conferenceId
    });
    if (match.params.divisionId) {
      setIsEditMode(true);
      setCurrent({
        ...current,
        divisionId: match.params.divisionId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { error, data } = useFetch(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + `/divisions/${match.params.divisionId}`,
    "",
    isEditMode
  );

  useEffect(() => {
    if (data) {
      setCurrent(data.data[0]);
    }
    // eslint-disable-next-line
  }, [data]);

  function reloadData(data) {
    setCurrent({ ...current, ...data });
    setRefreshData(false);
    setRefreshData(true);
    if (!isEditMode) {
      props.onCreate();
    }
  }

  const getPageTitle = () =>
    isEditMode
      ? `Update Division - ${title}`
      : formatMessage({
          id: isEditMode ? "division.update" : "division.add",
          defaultValue: isEditMode ? "Update Division" : "Create Division"
        }) +
        " - " +
        title;

  const Form = () =>
    current &&
    refreshData && (
      <FormBuilder
        form={isEditMode ? "UpdateDivision" : "CreateDivision"}
        action={reloadData}
        formSetup={formSetup}
        formData={current}
        pageRef={isEditMode ? pageRef : props.pageRef}
      />
    );

  const PageWrapper = (content) => (
    <PageDisplay
      title={
        formatMessage({
          id: isEditMode ? "division.update" : "division.add",
          defaultValue: isEditMode ? "Update Division" : "Create Division"
        }) + ` ${isEditMode ? current.nameLatin : ""}`
      }
      error={error}
      pageTitle={getPageTitle()}
      ref={isEditMode ? pageRef : props.pageRef}
      history={history}
    >
      {content()}
      {error}
    </PageDisplay>
  );

  return isEditMode ? PageWrapper(Form) : Form();
};

export default injectIntl(DivisionsForm);

import React, { useEffect, useState, useRef } from "react";
import { injectIntl } from "react-intl";
import { Spinner } from "reactstrap";
import { useFetch } from "../../Base/Hooks/fetch";

import TeamPlayerStatsTotal from "./TeamPlayerStatsTotal";
import TeamPlayerStatsAverage from "./TeamPlayerStatsAverage";
import TeamStatsSummary from "./TeamSummaryStats";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import { useUpdateToken } from "../../Base/Hooks/updateToken";

import "./TeamStats.scss";

const TeamStats = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [tableData, setTableData] = useState();
  const [refreshFlag, setRefreshFlag] = useState(true);
  const paginationString = "limit=200";
  const now = new Date();

  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/statistics/for/person/in/seasons/" +
      match.params.seasonId +
      "?include=entities,persons,organizations,seasons&" +
      paginationString,
    "",
    refreshFlag,
    "location",
    true
  );

  const teams = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/statistics/for/entity/in/seasons/" +
      match.params.seasonId +
      "?include=organizations,entities,seasons&" +
      paginationString,
    "",
    refreshFlag,
    "location",
    true
  );

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  useEffect(() => {
    props.updateState("fluid", true);
    return () => {
      props.updateState("fluid", false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data && teams && refreshFlag) {
      if (data && data.includes && data.includes.resources && data.includes.resources.seasons) {
        setPageTitle(data.includes.resources.seasons[match.params.seasonId].nameLocal);
      }
      let personData = data !== null ? data.data : [];
      personData.forEach((person, index) => {
        if (person.person.id) {
          // Load names from resources
          personData[index].person = data.includes.resources.persons[person.person.id].nameFullLocal;
          personData[index].team = data.includes.resources.entities[person.entity.id].nameFullLocal;
        }
      });
      let teamData = teams.data?.data || [];
      teamData.forEach((team, index) => {
        teamData[index].team = teams.data.includes.resources.entities[team.entity.id].nameFullLocal;
      });
      teamData = teamData.sort((a, b) => a.team.localeCompare(b.team));
      let tableList = teamData.map((team, index) => {
        return (
          <div key={index} className="team-stats">
            <TeamPlayerStatsTotal
              title={team.team}
              data={personData.filter((person) => person.entity.id === team.entity.id)}
              includes={data.includes.resources}
              team={team}
            />
            <TeamPlayerStatsAverage
              title={team.team}
              data={personData.filter((person) => person.entity.id === team.entity.id)}
              includes={data.includes.resources}
              team={team}
            />
            <TeamStatsSummary team={team} title={team.team} />
            <hr />
          </div>
        );
      });
      setTableData(tableList);
      setRefreshFlag(false);
    }
  }, [data, teams, match.params.seasonId, refreshFlag, now]);

  return (
    <React.Fragment>
      <PageDisplay
        title={
          pageTitle +
          " " +
          formatMessage({
            id: "team.stats",
            defaultMessage: "Team Stats"
          })
        }
        error={error}
        pageTitle={
          pageTitle +
          " " +
          formatMessage({
            id: "team.stats",
            defaultMessage: "Team Stats"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
      >
        {tableData}
        <div className="print-date print-only">Printed: {now.toString()}</div>
        {loading && <Spinner size="md" color="orange" />}
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(TeamStats);

import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import Modal from "../../Base/Modal/Modal";
import { useDelete } from "../../Base/Hooks/delete";

const TeamRow = (props) => {
  const { itemIndex, result, match, pageRef, setRefreshFlag, intl } = props;
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [linkDeleteData, setLinkDeleteData] = useState(null);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/entities/" +
      linkDeleteData,
    "",
    refreshDeleteData
  );
  const { formatMessage } = intl;

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }),
        +deleteError,
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "team.delete.success",
            defaultMessage: "Team deleted successfully"
          }),
          "success",
          10
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setLinkDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeleteLink(param, name) {
    setDeleteModalText(
      formatMessage(
        {
          id: "team.delete.confirm",
          defaultMessage: "You are about to delete " + name + ". Is this OK?"
        },
        { item: name }
      )
    );
    setDeleteModalOpen(true);
    setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deleteLink() {
    setLinkDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  return (
    <tr key={itemIndex} className={"deleting-" + deleteLoading}>
      {result && (
        <React.Fragment>
          <td className="sm">
            {result.images && result.images.find((image) => image.imageType === "LOGO") && (
              <div className="inline-image-container sm">
                <img
                  src={result.images.find((image) => image.imageType === "LOGO").url}
                  alt="LOGO"
                  className="inline-image sm"
                />
              </div>
            )}
          </td>
          <td>{result.nameFullLocal}</td>
          <td>{result.codeLocal}</td>
          <td>{result.nameFullLatin}</td>
          <td>{result.codeLatin}</td>
          <td>{result.status}</td>
          <td>{result.conference ? result.conference.nameLocal : "-"}</td>
          <td>{result.division ? result.division.nameLocal : "-"}</td>
          <td className="text-right controls-7">
            <Link
              to={
                "/admin/" +
                match.params.sport +
                "/" +
                result.organizationId +
                "/seasons/" +
                result.seasonId +
                "/teams/" +
                result.entityId
              }
              className="btn btn-outline-primary btn-sm"
            >
              <i className="fas fa-pen" />
            </Link>{" "}
            <Button
              outline
              color="danger"
              size="sm"
              onClick={(e) => showDeleteLink(result.entityId, result.nameFullLocal)}
            >
              <i className="fas fa-minus" />
            </Button>{" "}
            <Link
              to={
                "/admin/" +
                match.params.sport +
                "/" +
                result.organizationId +
                "/seasons/" +
                result.seasonId +
                "/teams/" +
                result.entityId +
                "/roster/"
              }
              className="btn btn-outline-secondary btn-sm btn-action"
            >
              {formatMessage({
                id: "roster",
                defaultMessage: "Roster"
              })}
              <i className="fas fa-chevron-right" />
            </Link>{" "}
            <Link
              to={
                "/admin/" +
                match.params.sport +
                "/" +
                result.organizationId +
                "/seasons/" +
                result.seasonId +
                "/teams/" +
                result.entityId +
                "/staff"
              }
              className="btn btn-outline-secondary btn-sm btn-action"
            >
              <FormattedMessage id="staff" />
              <i className="fas fa-chevron-right" />
            </Link>{" "}
            <Link
              to={"/admin/" + match.params.sport + "/" + result.organizationId + "/teams/" + result.entityId}
              className="btn btn-outline-secondary btn-sm btn-action"
            >
              {formatMessage({
                id: "details",
                defaultMessage: "Details"
              })}
              <i className="fas fa-chevron-right" />
            </Link>
            <Modal
              isOpen={deleteModalOpen}
              heading={formatMessage({
                id: "team.delete",
                defaultMessage: "Delete Team?"
              })}
              text={deleteModalText}
              actionText={formatMessage({
                id: "delete",
                defaultMessage: "Delete"
              })}
              cancelText={formatMessage({
                id: "cancel",
                defaultMessage: "Cancel"
              })}
              action={deleteLink}
              cancel={cancelModal}
            />
          </td>
        </React.Fragment>
      )}
    </tr>
  );
};

export default injectIntl(TeamRow);

import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";

import { useToken } from "../Hooks/token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";

const InlineForm = (props) => {
  const {
    editMode,
    setEditMode,
    form,
    action,
    inlineFormSetup,
    pageRef,
    formData,
    extraData,
    match,
    cancellable = true,
    submitIconClassName = "fas fa-check",
    preSendDataTransform,
    optionRenderer
  } = props;
  const [formDisplay, setFormDisplay] = useState();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [, updateAllState] = React.useState();
  const forceUpdate = React.useCallback(() => updateAllState({}), []);
  const { readBody = (body) => body.json() } = {};
  const env = useContext(EnvironmentContext);
  const { token } = useToken();

  useEffect(() => {
    setData(formData);
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    if (data) {
      let formFields = inlineFormSetup.forms[form].fields.map((field, index) => {
        return (
          <td key={index} className={"inline-field loading-" + isLoading + " " + field.class}>
            {generateField(field)}
          </td>
        );
      });
      setFormDisplay(formFields);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(data), isLoading, editMode]);

  function handleChange(e, field) {
    let tempData = data;
    tempData[field] = e.target.value;
    setData(tempData);
    forceUpdate();
  }

  function submit() {
    (async () => {
      setIsLoading(true);
      if (data) {
        let endpoint = inlineFormSetup.forms[form].endpoint;
        inlineFormSetup.forms[form].parameters.map((parameter, key) => {
          endpoint = endpoint.replace("#" + key, formData[parameter]);
          return null;
        });
        endpoint = endpoint.replace("#sport", match.params.sport);
        let url = environments["location"][env] + "/" + endpoint;

        let values = {};
        inlineFormSetup.forms[form].dataFields.forEach((field) => {
          values[field] = data[field];
        });

        try {
          const response = await fetch(url, {
            method: inlineFormSetup.forms[form].method,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token.token
            },
            body: preSendDataTransform ? JSON.stringify(preSendDataTransform(values)) : JSON.stringify(values)
          });
          if (response.ok) {
            // const body = await readBody(response);
            // console.log("ok", body);
            pageRef.current.addFlash(inlineFormSetup.forms[form].successmessage, "success", 10);
            // }
            action();
            setEditMode(false);
            setIsLoading(false);
          } else {
            const body = await readBody(response);
            console.log("error", body);
            pageRef.current.addFlash("An error occurred", "danger", 10);
            setIsLoading(false);
          }
        } catch (e) {
          console.log("E", e);
          pageRef.current.addFlash("An error occurred", "danger", 10);
          setIsLoading(false);
        }
      }
    })();
  }

  function generateField(field) {
    let fieldData = field.source && field.source === "extra" ? extraData[field.name] : data[field.name];
    if (field.type === "text" && fieldData === null) {
      fieldData = "";
    }
    switch (field.type) {
      case "number":
        return <input type="number" value={data[field.name]} onChange={(e) => handleChange(e, field.name)} />;
      case "text":
        return <input type="text" value={data[field.name]} onChange={(e) => handleChange(e, field.name)} />;
      case "blank":
        return <span></span>;
      case "select":
        return (
          <select type="text" value={data[field.name]} onChange={(e) => handleChange(e, field.name)}>
            {field.data.map(
              (option, index) =>
                field?.optionRender?.call({}, option) || (
                  <option value={option} key={option}>
                    {optionRenderer ? optionRenderer(option) : option}
                  </option>
                )
            )}
          </select>
        );
      case "display":
        return fieldData;
      default:
        break;
    }
  }

  return (
    <>
      {formDisplay}
      <td className={" text-right controls-3 inline-field loading-" + isLoading}>
        <Button outline color="success" size="sm" onClick={() => submit()}>
          <i className={submitIconClassName} />
        </Button>{" "}
        {cancellable && (
          <Button outline color="danger" size="sm" onClick={() => setEditMode(false)}>
            <i className="fas fa-times" />
          </Button>
        )}
      </td>
    </>
  );
};

export default withRouter(InlineForm);

import React from "react";
import { injectIntl } from "react-intl";
import ImageUploader from "../../Base/Images/ImageUploader";
import ImageDisplay from "../../Base/Images/ImageDisplay";

const RosterImageRow = (props) => {
  const { itemIndex, result, pageRef, setRefreshFlag } = props;

  return (
    <React.Fragment key={itemIndex}>
      {result && (
        <React.Fragment>
          <tr>
            <td>
              <h6>{result.nameFullLocal}</h6>
            </td>
          </tr>
          <tr>
            <td style={{ width: "16.665%" }}>
              <ImageDisplay
                data={result}
                images={result.images}
                imageType="PERSON_HEAD"
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
                checkQuality={true}
              />
            </td>
            <td style={{ width: "16.665%" }}>
              <ImageUploader
                data={result}
                type={"PERSON_HEAD"}
                element={"personId"}
                imageFor={"PERSON"}
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
              />
            </td>
            <td style={{ width: "16.665%" }}>
              <ImageDisplay
                data={result}
                images={result.images}
                imageType="PERSON_WAIST"
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
                checkQuality={true}
              />
            </td>
            <td style={{ width: "16.665%" }}>
              <ImageUploader
                data={result}
                type={"PERSON_WAIST"}
                element={"personId"}
                imageFor={"PERSON"}
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
              />
            </td>
            <td style={{ width: "16.665%" }}>
              <ImageDisplay
                data={result}
                images={result.images}
                imageType="PERSON_POSE"
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
                checkQuality={true}
              />
            </td>
            <td style={{ width: "16.665%" }}>
              <ImageUploader
                data={result}
                type={"PERSON_POSE"}
                element={"personId"}
                imageFor={"PERSON"}
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
              />
            </td>
          </tr>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default injectIntl(RosterImageRow);

import React, { useEffect, useState, useRef } from "react";
import { injectIntl } from "react-intl";

import { useFetch } from "../../Base/Hooks/fetch";

import AssignOfficialsRow from "./AssignOfficialsRow";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import TableDisplay from "../../Base/Shared/TableDisplay";
import TablePagination from "../../Base/Shared/TablePagination";
import FilterDisplay from "../../Base/Shared/FilterDisplay";
import { useFilterData } from "../../Base/Hooks/filterdata";
import { useUpdateToken } from "../../Base/Hooks/updateToken";
import officialRoles from "../Officials/officialRoles";

// created from ../Fixtures/Fixtures.js
const AssignOfficials = (props) => {
  const {
    title,
    updateState,
    history,
    match,
    match: {
      params: { sport, organizationId, seasonId }
    },
    intl
  } = props;
  const [pageTitle, setPageTitle] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [initialData, setInitialData] = useState(null);
  const [paginationString, setPaginationString] = useState("limit=20");
  const [tableData, setTableData] = useState();
  const [assigningRole, setAssigningRole] = useState("");
  const { formatMessage } = intl;
  const pageRef = useRef();

  const nowISO = new Date();
  const startOfTheDay = new Date(nowISO.setUTCHours(0, 0, 0, 0)).toISOString();

  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      `/fixtures?sortBy=startTimeUTC&include=seasons,entities,venues&fromTimeLocal=${startOfTheDay}&` +
      paginationString,
    "",
    refreshFlag,
    "location"
  );

  const staffQuery = new URLSearchParams({
    hideNull: true,
    include: ["persons", "seasons"],
    limit: 200,
    role: officialRoles,
    seasonId
  });
  const rosterURL = `/v1/${sport}/o/${organizationId}/roles?${staffQuery}`;
  const { data: seasonRoles } = useFetch(rosterURL, "", true, "location", true);

  const teams = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/entities?include=entities,venues&limit=200",
    "",
    true
  );

  const venues = useFetch(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + "/venues?limit=200",
    "",
    true,
    "location",
    true
  );

  const [filterFields, setFilterFields] = useState({
    date: "",
    venueName: "",
    teamHome: "",
    teamAway: ""
  });
  const { filteredData } = useFilterData(initialData, filterFields);

  const columns = ["Venue", "Date", "Home Team", "Away Team", "Officials"];

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  useEffect(() => {
    props.updateState("fluid", true);
    return () => {
      props.updateState("fluid", false);
    };
    // eslint-disable-next-line
  }, []);

  function handleFilter(field, value) {
    let newFilter = {
      ...filterFields,
      [field]: value
    };
    setFilterFields(newFilter);
    setRefreshFlag(true);
  }

  useEffect(() => {
    if (filteredData) {
      if (data?.includes?.resources?.seasons) {
        setPageTitle(data.includes.resources.seasons[match.params.seasonId].nameLocal);
      }

      let tableList = filteredData.map((result, index) => (
        <AssignOfficialsRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          resources={data && data.includes && data.includes.resources}
          reloadData={reloadData}
          teams={teams}
          venues={venues}
          pageRef={pageRef}
          seasonRoles={seasonRoles}
          assigningRole={assigningRole}
          setAssigningRole={setAssigningRole}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, seasonRoles, assigningRole, setAssigningRole]);

  useEffect(() => {
    if (data && venues && venues.data) {
      let initialData = data !== null ? data.data : [];
      initialData.forEach((fixture, index) => {
        if (fixture.startTimeLocal) {
          let matchDate = fixture.startTimeLocal.split("T");
          let matchTime = matchDate[1].split(":");
          initialData[index].date = intl.formatDate(matchDate[0]);
          initialData[index].ISOdate = matchDate[0];
          initialData[index].hour = matchTime[0];
          initialData[index].minute = matchTime[1];
        }
        if (fixture.competitors) {
          fixture.competitors.forEach((competitor) => {
            if (competitor.isHome) {
              initialData[index].teamHome = data.includes.resources.entities[competitor.entity.id]
                ? data.includes.resources.entities[competitor.entity.id].nameFullLocal
                : competitor.entityId;
              initialData[index].teamHomeId = competitor.entityId;
            } else {
              initialData[index].teamAway = data.includes.resources.entities[competitor.entity.id]
                ? data.includes.resources.entities[competitor.entity.id].nameFullLocal
                : competitor.entityId;
              initialData[index].teamAwayId = competitor.entityId;
            }
          });
        }
        if (fixture.venueId) {
          initialData[index].timezone =
            venues.data && venues.data.data.find((venue) => venue.venueId === fixture.venueId).timezone;
          initialData[index].venue =
            venues.data && venues.data.data.find((venue) => venue.venueId === fixture.venueId).nameLocal;
        }
      });

      setInitialData(initialData);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, venues]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      <PageDisplay
        title={
          pageTitle +
          ` ${formatMessage({
            id: "officials.assign",
            defaultMessage: "Assign Officials"
          })}`
        }
        error={error}
        pageTitle={
          pageTitle +
          ` ${formatMessage({
            id: "officials.assign",
            defaultMessage: "Assign Officials"
          })} - ` +
          title
        }
        ref={pageRef}
        history={history}
      >
        <FilterDisplay data={initialData} display="All Venues" value="venue" selectAction={handleFilter} />
        <FilterDisplay
          data={initialData}
          display="All Dates"
          value="startTimeLocal"
          selectAction={handleFilter}
          mapAfter={intl.formatDate}
          format="date"
        />
        <FilterDisplay data={initialData} display="All Home Teams" value="teamHome" selectAction={handleFilter} />
        <FilterDisplay data={initialData} display="All Away Teams" value="teamAway" selectAction={handleFilter} />
        <TableDisplay
          containerClass="table-responsive"
          columns={columns}
          rows={tableData}
          loading={loading || venues.loading}
        />
        <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(AssignOfficials);

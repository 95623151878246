import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { useFetch } from "../../Base/Hooks/fetch";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import BuildStandings from "./BuildStandings";
import StandingsDisplay from "./Display";
import DisplayModeSelector from "./DisplayModeSelector";
import LiveStandings from "./LiveStandings";
import GroupingSelector from "./GroupingSelector";
import { Row, Container, Collapse, Spinner } from "reactstrap";
import "./Standings.scss";
import EditStandings from "./EditStandings";
import { useDeleteModal } from "../../Base/MasterDisplay/useDeleteModal";

function Standings(props) {
  const {
    history,
    updateState,
    intl,
    match: {
      params: { sport, organizationId, seasonId }
    }
  } = props;

  const { formatMessage } = intl;

  const pageRef = useRef();
  const [standingsConfiguration, setStandingsConfiguration] = useState();
  const [initialBuildRule, setInitialBuildRule] = useState();

  const [queryParameters, setQueryParameters] = useState(new URLSearchParams());
  const [pageTitle, setPageTitle] = useState("Standings");
  const [displayMode, setDisplayMode] = useState("BASIC");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const [selectedConferenceId, setSelectedConferenceId] = useState();
  const [shouldFetchConferences, setConferenceFetchIntent] = useState(false);
  const [shouldFetchDivisions, setDivisionFetchIntent] = useState(false);

  const { data: conferencesData } = useFetch(
    `/v1/${sport}/o/${organizationId}/conferences`,
    false,
    shouldFetchConferences
  );

  const { data: divisionsData } = useFetch(
    `/v1/${sport}/o/${organizationId}/conferences/${selectedConferenceId}/divisions`,
    false,
    shouldFetchDivisions
  );

  useEffect(() => {
    if (conferencesData && conferencesData.data) {
      setSelectedConferenceId(conferencesData.data[0].conferenceId);
    }
  }, [conferencesData]);

  const apiRoot = `/v1/${sport}/o/${organizationId}/seasons/${seasonId}?include=standingConfigurations`;
  const { data } = useFetch(apiRoot, "", true);
  useEffect(() => {
    if (data && data.data) {
      const seasonName = data.data[0].nameLocal;
      setPageTitle(`${seasonName} Standings`);
      if (data.includes && data.includes.resources && data.includes.resources.standingConfigurations) {
        const configurationId = data.data[0].standingConfigurationId;
        const configuration = data.includes.resources.standingConfigurations[configurationId];

        setStandingsConfiguration(configuration);
      }
    }
  }, [data]);

  useEffect(() => {
    return handleUnmount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleUnmount() {
    updateState("fluid", false);
  }

  useEffect(() => {
    if (standingsConfiguration && selectedConferenceId && standingsConfiguration.buildRules.buildDivisions) {
      setDivisionFetchIntent(true);
    }
  }, [selectedConferenceId, standingsConfiguration]);

  useEffect(() => {
    if (standingsConfiguration) {
      const qp = new URLSearchParams();
      let rule;
      if (standingsConfiguration.buildRules.buildConferences) {
        rule = "CONFERENCE";
        setConferenceFetchIntent(true);
      } else {
        rule = "OVERALL";
      }
      qp.append("groupingConferenceDivision", rule);
      setInitialBuildRule(rule);
      setQueryParameters(qp);
    }
  }, [standingsConfiguration]);

  const {
    data: standings,
    refetch,
    loading
  } = useFetch(
    `/v1/${sport}/o/${organizationId}/seasons/${seasonId}/standings?limit=100&include=entities&${
      queryParameters ? `${queryParameters.toString()}` : ""
    }`,
    "",
    true
  );

  function renderStageSelector() {
    return (
      initialBuildRule && (
        <GroupingSelector
          initialBuildRule={initialBuildRule}
          standingsConfiguration={standingsConfiguration}
          queryParameters={queryParameters}
          setQueryParameters={setQueryParameters}
          refetch={refetch}
        />
      )
    );
  }

  function renderLiveStandings() {
    return (
      standingsConfiguration &&
      standingsConfiguration.buildRules.generateLiveStandings && (
        <LiveStandings queryParameters={queryParameters} setQueryParameters={setQueryParameters} />
      )
    );
  }

  function renderBuildStandings() {
    return (
      <BuildStandings
        {...props}
        pageRef={pageRef}
        refetch={refetch}
        disabled={!data?.includes?.resources?.standingConfigurations}
      />
    );
  }

  function renderStandingsDisplay() {
    return (
      standings && (
        <StandingsDisplay
          selectedGrouping={queryParameters.get("groupingConferenceDivision")}
          displayMode={displayMode}
          selectedConferenceId={selectedConferenceId}
          standings={standings}
          conferencesData={conferencesData}
          divisionsData={divisionsData}
          sport={sport}
          organizationId={organizationId}
          standingsConfiguration={standingsConfiguration}
          onDeleteIntent={handleDeleteIntent}
        />
      )
    );
  }

  function handleDisplayModeChange(mode) {
    setDisplayMode(mode);
  }

  useEffect(() => {
    updateState("fluid", true);
  }, [displayMode, updateState]);

  function renderDisplayModeSelector() {
    return <DisplayModeSelector displayMode={displayMode} onDisplayModeChange={handleDisplayModeChange} />;
  }

  function renderViewStandingsAdjustments() {
    return (
      <Link
        to={"/admin/" + sport + "/" + organizationId + "/seasons/" + seasonId + "/standings/adjustments"}
        className="btn btn-outline-secondary view-standings"
        title="View Standings Adjustments for this season"
      >
        <FormattedMessage id="view.standings.adjustments" />
      </Link>
    );
  }

  const deleteModal = useDeleteModal({
    headingText: formatMessage({ id: "delete" }),
    refreshTrigger: refetch,
    pageRef: pageRef
  });

  const handleDeleteIntent = (item) => {
    const URL = `/v1/${sport}/o/${organizationId}/standings/${item.standingId}`;
    deleteModal.setData.setUrl(URL);
    deleteModal.setData.setTextContent(formatMessage({ id: "delete.item.confirm" }));
    deleteModal.visibility.open();
  };

  return (
    <PageDisplay ref={pageRef} history={history} title={pageTitle} pageTitle={`${pageTitle} - ${props.title}`}>
      <Container fluid>
        {deleteModal.element()}
        <Row className="standings-feature">
          {renderBuildStandings()}
          {renderViewStandingsAdjustments()}
          {renderLiveStandings()}
        </Row>
        <Row className="standings-feature">
          {renderDisplayModeSelector()}
          {renderStageSelector()}
        </Row>
        <Row>{loading ? <Spinner /> : renderStandingsDisplay()}</Row>
        <hr />
        <h4 onClick={toggleForm}>
          {!isFormOpen && (
            <span>
              <FormattedMessage id="standings.row.add" defaultMessage="Add New Standings Row" />{" "}
              <i className="fas fa-caret-down"></i>
            </span>
          )}
          {isFormOpen && (
            <span>
              <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
            </span>
          )}
        </h4>
        <Collapse isOpen={isFormOpen}>
          <EditStandings {...props} pageRef={pageRef} onCreate={refetch} />
        </Collapse>
      </Container>
    </PageDisplay>
  );
}

export default injectIntl(Standings);

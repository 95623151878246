import React, { useEffect, useState } from "react";
import { useFetch } from "../../Base/Hooks/fetch";

const renderDivision = ({ divisionId, nameLocal }) => (
  <option key={divisionId} value={divisionId}>
    {nameLocal}
  </option>
);

/**
 * Select a division based on a currently selected
 * conference within the FormBuilder
 * @param {object} props
 */
function DivisionSelect(props) {
  const { conferenceId, divisionId } = props.form.values;
  const [divisions, setDivisions] = useState([]);
  const { data, refetch } = useFetch(
    `/v1/${props.sport}/o/${props.organizationId}/conferences/${conferenceId}/divisions`,
    "",
    conferenceId,
    "location",
    true,
    true
  );

  useEffect(() => {
    if (data && data.data) {
      setDivisions([{ divisionId: null, nameLocal: "-" }, ...data.data]);
    }
  }, [data]);

  useEffect(() => {
    if (!props.form.values.conferenceId) {
      props.form.setFieldValue("divisionId", null);
    } else {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.values.conferenceId]);

  /**
   * Always set divisionId to null on mount to
   * prevent API errors
   */
  useEffect(() => {
    if (!divisionId) {
      props.form.setFieldValue("divisionId", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionId]);

  const handleDivisionUpdate = (e) => {
    const { value: divisionId } = e.target;
    props.form.setFieldValue("divisionId", divisionId);
  };

  return (
    <div className="form-group">
      <select
        disabled={!conferenceId || divisions.length === 1}
        value={divisionId || ""}
        onChange={handleDivisionUpdate}
        className="form-control"
      >
        {divisions.map(renderDivision)}
      </select>
    </div>
  );
}

export default DivisionSelect;

/**
 * Empty placeholder to be used when there is no
 * conferenceId provided by the form
 * to prevent dangling fetches
 */
export const DivisionSelectPlaceholder = () => (
  <div className="form-group">
    <select disabled className="form-control"></select>
  </div>
);

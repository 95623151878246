import React, { useEffect, useState, useContext, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";

import { useToken } from "../../Base/Hooks/token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
import { getScore } from "./getScore";

const InlineFixtureForm = (props) => {
  const { editMode, setEditMode, action, pageRef, formData, resources, teams, venues, match } = props;
  const [formDisplay, setFormDisplay] = useState();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [, updateAllState] = React.useState();
  const forceUpdate = React.useCallback(() => updateAllState({}), []);
  const { readBody = (body) => body.json() } = {};
  const env = useContext(EnvironmentContext);
  const { token } = useToken();

  const fixtureTypes = ["REGULAR", "PLAYOFF", "FINAL", "PRESEASON", "FRIENDLY", "ALL_STAR", "DEMONSTRATION"];

  const statusArray = ["SCHEDULED", "PENDING", "CANCELLED", "CONFIRMED", "FINISHED", "BYE", "IF_NEEDED", "POSTPONED"];

  var dontShowCompetitors = ["FINISHED", "CONFIRMED", "IN_PROGRESS"];

  useEffect(() => {
    if (data === null) {
      let tempFormData = JSON.parse(JSON.stringify(formData));
      setData(tempFormData);
      forceUpdate();
    }
    // eslint-disable-next-line
  }, [formData]);

  const TeamOptions = useMemo(() => {
    if (teams && teams.data) {
      return (
        <>
          <option value="">--</option>
          {Object.entries(teams.data.includes.resources.entities)
            .sort(function (a, b) {
              var aMixed = a[1].nameFullLocal;
              var bMixed = b[1].nameFullLocal;
              return aMixed < bMixed ? -1 : 1;
            })
            .map((entity, index) => (
              <option value={entity[1].entityId} key={entity[1].entityId}>
                {entity[1].nameFullLocal}
              </option>
            ))}
        </>
      );
    }
  }, [teams]);

  const VenueOptions = useMemo(() => {
    if (!venues && !venues.data) {
      return undefined;
    }
    return (
      <>
        <option value="">--</option>
        {venues.data.data
          .sort(function (a, b) {
            var aMixed = a.nameLocal;
            var bMixed = b.nameLocal;
            return aMixed < bMixed ? -1 : 1;
          })
          .map((venue, index) => (
            <option value={venue.venueId} key={venue.venueId}>
              {venue.nameLocal}
            </option>
          ))}
      </>
    );
  }, [venues]);

  const Numbers = (num) => {
    return (
      <>
        {[...Array(num)].map((x, i) => (
          <option value={i.toString().length === 1 ? "0" + i : i} key={i}>
            {i.toString().length === 1 ? "0" + i : i}
          </option>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (data && resources && TeamOptions && VenueOptions) {
      let formFields = (
        <>
          <td className={"inline-field loading-" + isLoading}>
            {!dontShowCompetitors.includes(formData.status) && (
              <input
                name="roundNumber"
                style={{ minWidth: "70px" }}
                id="roundNumber"
                placeholder="Round Number"
                type="number"
                size="3"
                className="form-control form-control"
                value={data["roundNumber"] !== null ? data["roundNumber"] : ""}
                onChange={(e) => handleChange(e, "roundNumber")}
              ></input>
            )}
            {dontShowCompetitors.includes(formData.status) && data.roundNumber}
          </td>
          <td className={"inline-field loading-" + isLoading}>
            {!dontShowCompetitors.includes(formData.status) && (
              <select value={data["teamHomeId"]} onChange={(e) => handleChange(e, "teamHomeId")}>
                {TeamOptions}
              </select>
            )}
            {dontShowCompetitors.includes(formData.status) && data.teamHome}
          </td>
          <td className={"inline-field fixture-score-cell loading-" + isLoading}>{getScore(data)}</td>
          <td className={"inline-field loading-" + isLoading}>
            {!dontShowCompetitors.includes(formData.status) && (
              <select value={data["teamAwayId"]} onChange={(e) => handleChange(e, "teamAwayId")}>
                {TeamOptions}
              </select>
            )}
            {dontShowCompetitors.includes(formData.status) && data.teamAway}
          </td>
          <td className={"inline-field loading-" + isLoading}>
            {!dontShowCompetitors.includes(formData.status) && (
              <select value={data["venueId"]} onChange={(e) => handleChange(e, "venueId")}>
                {VenueOptions}
              </select>
            )}
            {dontShowCompetitors.includes(formData.status) && data.venue}
          </td>
          <td className={"inline-field loading-" + isLoading}>
            {!dontShowCompetitors.includes(formData.status) && (
              <>
                <input
                  name="startDate"
                  id="expiryDate"
                  placeholder="Expiry Date"
                  type="date"
                  className="form-control form-control"
                  value={data["ISOdate"]}
                  onChange={(e) => handleChange(e, "date")}
                ></input>
                {/* <p>
                  {data["ISOdate"]} - {data["date"]}
                </p> */}
              </>
            )}
            {dontShowCompetitors.includes(formData.status) && data.date}
          </td>
          <td className={"inline-field loading-" + isLoading} style={{ minWidth: "190px" }}>
            {!dontShowCompetitors.includes(formData.status) && (
              <span className="highlight" style={{ textAlign: "center" }}>
                <select value={data["hour"]} onChange={(e) => handleChange(e, "hour")}>
                  {Numbers(24)}
                </select>
                <strong>:</strong>
                <select value={data["minute"]} onChange={(e) => handleChange(e, "minute")}>
                  {Numbers(60)}
                </select>
                <small className="center">{data.timezone}</small>
              </span>
            )}
            {dontShowCompetitors.includes(formData.status) && (
              <>
                <span>
                  {data.hour}:{data.minute}
                </span>
                <small className="block">{data.timezone}</small>
              </>
            )}
          </td>
          <td className={"inline-field loading-" + isLoading}>
            <select value={data["fixtureType"]} onChange={(e) => handleChange(e, "fixtureType")}>
              {fixtureTypes.map((option, index) => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </select>
          </td>
          <td className={"inline-field loading-" + isLoading}>
            {data.status !== "IN_PROGRESS" && (
              <select value={data["status"]} onChange={(e) => handleChange(e, "status")}>
                {statusArray.map((option, index) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </select>
            )}
            {data.status === "IN_PROGRESS" && data["status"]}
          </td>
          <td className={"inline-field loading-" + isLoading}>
            {/* <input
              type="checkbox"
              checked={data["liveVideoAvailable"]}
              onChange={(e) => handleChange(e, "liveVideoAvailable")}
            ></input> */}
          </td>
        </>
      );

      setFormDisplay(formFields);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(data), isLoading, editMode, resources, TeamOptions]);

  function handleChange(e, field) {
    let tempData = data;
    tempData[field] = e.target.value;
    if (field === "hour" || field === "minute") {
      if (tempData[field] < 0) {
        tempData[field] = 0;
      }
    }
    if (field === "date") {
      tempData["date"] = new Date(e.target.value);
      tempData["ISOdate"] = e.target.value;
    }
    if (e.target.type === "checkbox") {
      tempData[field] = e.target.checked;
    }
    setData(tempData);
    forceUpdate();
  }

  function submit() {
    (async () => {
      setIsLoading(true);
      if (data) {
        let endpoint = "v1/" + match.params.sport + "/o/" + data.organizationId + "/fixtures/" + data.fixtureId;
        let url = environments["location"][env] + "/" + endpoint;

        let values = {};
        values.status = data.status;
        values.fixtureType = data.fixtureType;
        values.roundNumber = data.roundNumber;
        // values.liveVideoAvailable = data.liveVideoAvailable;
        if (!dontShowCompetitors.includes(formData.status)) {
          if (data.venueId) {
            values.venueId = data.venueId;
          }
          values.startTimeLocal = data.ISOdate + "T" + data.hour + ":" + data.minute + ":00";
          values.competitors = [];
          if (data.teamHomeId) {
            values.competitors.push({
              entityId: data.teamHomeId,
              isHome: true
            });
          }
          if (data.teamAwayId) {
            values.competitors.push({
              entityId: data.teamAwayId,
              isHome: false
            });
          }
        }

        try {
          const response = await fetch(url, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token.token
            },
            body: JSON.stringify(values)
          });
          if (response.ok) {
            // const body = await readBody(response);
            // console.log("ok", body);
            pageRef.current.addFlash("Fixture updated successfully", "success", 10);
            // }
            action();
            setEditMode(false);
            setIsLoading(false);
          } else {
            const body = await readBody(response);
            console.log("error", body);
            pageRef.current.addFlash("An error occurred", "danger", 10);
            setIsLoading(false);
          }
        } catch (e) {
          console.log("E", e);
          pageRef.current.addFlash("An error occurred", "danger", 10);
          setIsLoading(false);
        }
      }
    })();
  }

  return (
    <>
      {formDisplay}
      <td className={"text-right controls-3 inline-field loading-" + isLoading}>
        <Button outline color="success" size="sm" onClick={() => submit()}>
          <i className="fas fa-check" />
        </Button>{" "}
        <Button outline color="danger" size="sm" onClick={() => setEditMode(false)}>
          <i className="fas fa-times" />
        </Button>
      </td>
    </>
  );
};

export default withRouter(InlineFixtureForm);

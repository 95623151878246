// Standings Configurations
var formSetup = {
  forms: {
    NewStandingsConfiguration: {
      title: "Add New Standings Configuration",
      titletranslation: "standingsConfigurationsAdd",
      endpoint: "v1/#sport/o/#0/standings/configurations",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New Standings Configuration added successfully.",
      successmessagetranslation: "standings.configuration.add.success",
      submit: "Add New Standings Configuration",
      submittranslation: "add",
      link: {
        show: false,
        message: "View Standings Configurations",
        path: "/admin/standings/configurations",
        parameters: []
      },
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-6 col",
          label: "Local Full Name:",
          labeltranslation: "name.local.full",
          type: "text",
          placeholder: "Local Full Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Local Full Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-6 col",
          label: "Latin Name:",
          labeltranslation: "name.latin.full",
          type: "text",
          placeholder: "Latin Full Name",
          className: "form-control",
          initial: null,
          errors: {
            required: "Latin Full Name Is Required"
          }
        },
        {
          name: "buildRules",
          size: "col-sm-12 col",
          type: "subFields",
          label: "Build Rules",
          placeholder: "",
          className: "form-control",
          default: {
            grouping: "NONE",
            buildType: "OVERALL",
            decimalPlaces: 4
          },
          initial: "",
          options: [
            {
              name: "fixtureTypes",
              size: "col-sm-3 col",
              label: "Fixture Types:",
              labeltranslation: "fixture.types",
              type: "custom",
              customItem: "fixtureTypes",
              placeholder: "Fixture Types",
              className: "form-control",
              required: true,
              initial: null,
              errors: {
                required: "Fixture Types Is Required"
              }
            },
            {
              name: "buildType",
              size: "col-sm-3 col",
              label: "Build Type:",
              labeltranslation: "build.rules.build.type",
              type: "select",
              placeholder: "Build Type",
              className: "form-control",
              required: true,
              default: "OVERALL",
              initial: null,
              options: [
                { value: "ROUND", text: "ROUND" },
                { value: "OVERALL", text: "OVERALL" }
              ],
              errors: {
                required: "Build Type Is Required"
              }
            },
            {
              name: "grouping",
              size: "col-sm-3 col",
              label: "Grouping:",
              labeltranslation: "build.rules.grouping",
              type: "select",
              placeholder: "Grouping",
              className: "form-control",
              required: true,
              default: "NONE",
              initial: null,
              options: [
                { value: "NONE", text: "NONE" },
                { value: "STAGE", text: "STAGE" },
                { value: "STAGEPOOL", text: "STAGEPOOL" }
              ],
              errors: {
                required: "Grouping Is Required"
              }
            },
            {
              name: "buildOverall",
              size: "col-sm-3 col",
              label: "Build Overall:",
              labeltranslation: "build.rules.build.overall",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Build Overall",
              className: "form-control",
              initial: "buildRules#buildOverall"
            },
            {
              name: "buildConferences",
              size: "col-sm-3 col",
              label: "Build Conferences:",
              labeltranslation: "build.rules.build.conferences",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Build Conferences",
              className: "form-control",
              initial: "buildRules#buildConferences"
            },
            {
              name: "buildDivisions",
              size: "col-sm-3 col",
              label: "Build Divisions:",
              labeltranslation: "build.rules.build.divisions",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Build Divisions",
              className: "form-control",
              initial: "buildRules#buildDivisions"
            },
            {
              name: "blankStanding",
              size: "col-sm-3 col",
              label: "Blank Standing:",
              labeltranslation: "blankStanding",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Blank Standing",
              className: "form-control",
              initial: null
            },
            {
              name: "includeLiveFixtures",
              size: "col-sm-3 col",
              label: "Include Live Fixtures:",
              labeltranslation: "include.live.fixtures",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Include Live Fixtures",
              className: "form-control",
              initial: null
            },
            {
              name: "generateLiveStandings",
              size: "col-sm-3 col",
              label: "Generate Live Standings:",
              labeltranslation: "generate.live.standings",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Generate Live Standings",
              className: "form-control",
              initial: null
            },
            {
              name: "decimalPlaces",
              size: "col-sm-3 col",
              label: "Decimal Places",
              labeltranslation: "decimal.places",
              type: "number",
              default: 4,
              placeholder: "Number of decimal places for percentage calculations",
              className: "form-control",
              initial: "decimalPlaces"
            }
          ]
        },
        {
          name: "configuration",
          size: "col-sm-12 col",
          label: "Configuration",
          type: "subFields",
          default: {},
          placeholder: "",
          className: "form-control",
          initial: "",
          options: [
            {
              name: "winsHomePoints",
              size: "col-sm-6 col",
              label: "Won Home Points",
              labeltranslation: "wins.home.points",
              type: "number",
              placeholder: "Points for Home Win",
              className: "form-control",
              initial: "winsHomePoints"
            },
            {
              name: "lossesHomePoints",
              size: "col-sm-6 col",
              label: "Lost Home Points",
              labeltranslation: "losses.home.points",
              type: "number",
              placeholder: "Points for Home Loss",
              className: "form-control",
              initial: "lossesHomePoints"
            },
            {
              name: "drawsHomeScoredPoints",
              size: "col-sm-6 col",
              label: "Draw Home Scored Points",
              labeltranslation: "draws.home.scored.points",
              type: "number",
              placeholder: "Draw Home Scored Points",
              className: "form-control",
              initial: "drawsHomeScoredPoints"
            },
            {
              name: "drawsHomeZeroPoints",
              size: "col-sm-6 col",
              label: "Points for Home Draw with zero score",
              labeltranslation: "draws.home.zero.points",
              type: "number",
              placeholder: "Draw Home Zero Points",
              className: "form-control",
              initial: "drawsHomeZeroPoints"
            },
            {
              name: "forfeitWonByHomePoints",
              size: "col-sm-6 col",
              label: "Points for win by Forfeit at home",
              labeltranslation: "wins.home.forfeit",
              type: "number",
              placeholder: "Points for win by Forfeit at home",
              className: "form-control",
              initial: "forfeitWonByHomePoints "
            },
            {
              name: "forfeitGivenHomePoints",
              size: "col-sm-6 col",
              label: "Points for giving a forfeit at home",
              labeltranslation: "forfeit.given.home.points",
              type: "number",
              placeholder: "Points for giving a forfeit at home",
              className: "form-control",
              initial: "forfeitGivenHomePoints"
            },
            {
              name: "winsAwayPoints",
              size: "col-sm-6 col",
              label: "Points for Away Win",
              labeltranslation: "wins.away.points",
              type: "number",
              placeholder: "Points for Away Win",
              className: "form-control",
              initial: "winsAwayPoints"
            },
            {
              name: "lossesAwayPoints",
              size: "col-sm-6 col",
              label: "Points for Away Loss",
              labeltranslation: "losses.away.points",
              type: "number",
              placeholder: "Points for Away Loss",
              className: "form-control",
              initial: "lossesAwayPoints"
            },
            {
              name: "drawsAwayScoredPoints",
              size: "col-sm-6 col",
              label: "Points for Away Draw where entity scored",
              labeltranslation: "draws.away.scored.points",
              type: "number",
              placeholder: "Points for Away Draw where entity scored",
              className: "form-control",
              initial: "drawsAwayScoredPoints"
            },
            {
              name: "drawsAwayZeroPoints",
              size: "col-sm-6 col",
              label: "Points for Away Draw with zero score",
              labeltranslation: "draws.away.zero.points",
              type: "number",
              placeholder: "Points for Away Draw with zero score",
              className: "form-control",
              initial: "drawsAwayZeroPoints"
            },
            {
              name: "forfeitWonByAwayPoints",
              size: "col-sm-6 col",
              label: "Points for wins by Forfeit at away",
              labeltranslation: "forfeit.wins.by.away.points",
              type: "number",
              placeholder: "Points for wins by Forfeit at away",
              className: "form-control",
              initial: "forfeitWonByAwayPoints"
            },
            {
              name: "forfeitGivenAwayPoints",
              size: "col-sm-6 col",
              label: "Points for giving a forfeit at away",
              labeltranslation: "forfeit.given.away.points",
              type: "number",
              placeholder: "Points for giving a forfeit at away",
              className: "form-control",
              initial: "forfeitGivenAwayPoints"
            },
            {
              name: "byePoints",
              size: "col-sm-6 col",
              label: "Points for bye",
              labeltranslation: "bye.points",
              type: "number",
              placeholder: "Points for bye",
              className: "form-control",
              initial: "byePoints"
            },
            {
              name: "byeIsPlayed",
              size: "col-sm-6 col",
              label: "Is a Bye added to played ?",
              labeltranslation: "bye.is.played",
              type: "select-boolean",
              placeholder: "Is a Bye added to played ?",
              className: "form-control",
              initial: "byeIsPlayed",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "byeAddedWins",
              size: "col-sm-6 col",
              label: "Are Bye wins added to Total wins?",
              labeltranslation: "bye.added.wins",
              type: "select-boolean",
              className: "form-control",
              initial: "byeAddedWins",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "forfeitWonByAddedWins",
              size: "col-sm-6 col",
              label: "Is a forfeit wins by (received) included in wins count ?",
              labeltranslation: "forfeit.wins.by.added.wins",
              type: "select-boolean",
              placeholder: "Is a forfeit wins by (received) included in wins count ?",
              className: "form-control",
              initial: "forfeitWonByAddedWins",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "forfeitWonByAddedPlayed",
              size: "col-sm-6 col",
              label: "Is a forfeit wins by (received) included in played count ?",
              labeltranslation: "forfeit.wins.by.added.played",
              type: "select-boolean",
              placeholder: "Is a forfeit wins by (received) included in played count ?",
              className: "form-control",
              initial: "forfeitWonByAddedPlayed",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "forfeitGivenAddedLosses",
              size: "col-sm-6 col",
              label: "Is a forfeit given included in losses count ?",
              labeltranslation: "forfeit.given.added.losses",
              type: "select-boolean",
              placeholder: "Is a forfeit given included in losses count ?",
              className: "form-control",
              initial: "forfeitGivenAddedLosses",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "forfeitGivenAddedPlayed",
              size: "col-sm-6 col",
              label: "Is a forfeit given included in played count ?",
              labeltranslation: "forfeit.given.added.played",
              type: "select-boolean",
              placeholder: "Is a forfeit given included in played count ?",
              className: "form-control",
              initial: "forfeitGivenAddedPlayed",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "streak",
              size: "col-sm-6 col",
              label: "How is streak calculcated?",
              labeltranslation: "streak",
              type: "select",
              className: "form-control",
              initial: null,
              options: [
                {
                  value: "WON",
                  translation: "wins",
                  text: "wins"
                },
                {
                  value: "NONLOST",
                  translation: "nonlosses",
                  text: "nonlosses"
                }
              ]
            },
            {
              name: "headToHeadUseAdjustments",
              size: "col-sm-6 col",
              label: "Apply Standing Adjustments when performing Head to head resolutions?",
              labeltranslation: "head.to.head.use.adjustments",
              type: "select-boolean",
              className: "form-control",
              initial: "headToHeadUseAdjustments",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "headToHeadSingleRound",
              size: "col-sm-12 col",
              label: "Only use single round for Head to head resolutions?",
              labeltranslation: "head.to.head.single.round",
              type: "select-boolean",
              className: "form-control",
              initial: "headToHeadSingleRound",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "sorts",
              size: "col-sm-12 col",
              label: "Sorting",
              labeltranslation: "sorting",
              customItem: "sorts",
              default: [],
              type: "custom",
              className: "form-control",
              initial: null
            },
            {
              name: "headToHeadResolutions",
              size: "col-sm-12 col",
              label: "Head To Head resolutions",
              labeltranslation: "headToHeadResolutions",
              customItem: "headToHeadResolutions",
              type: "custom",
              default: [],
              className: "form-control",
              initial: null
            },
            {
              name: "headToHeadIdentification",
              size: "col-sm-12 col",
              label: "Head To Head identification",
              labeltranslation: "headToHeadIdentification",
              customItem: "headToHeadIdentification",
              default: [],
              type: "custom",
              className: "form-control",
              initial: null
            }
          ]
        }
      ]
    },
    EditStandingsConfiguration: {
      title: "Edit Standings Configuration",
      titletranslation: "standings.configurations.update",
      endpoint: "v1/#sport/o/#0/standings/configurations/#1",
      parameters: ["organizationId", "standingsConfigurationId"],
      method: "PUT",
      successmessage: "Standings Configuration updated successfully.",
      successmessagetranslation: "standings.configuration.update.success",
      submit: "Update Standings Configuration",
      submittranslation: "update",
      link: {
        show: false,
        message: "View Standings Configurations",
        path: "/admin/standings/configurations",
        parameters: []
      },
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-6 col",
          label: "Local Full Name:",
          labeltranslation: "name.local.full",
          type: "text",
          placeholder: "Local Full Name",
          className: "form-control",
          required: true,
          initial: "nameLocal",
          errors: {
            required: "Local Full Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-6 col",
          label: "Latin Name:",
          labeltranslation: "name.latin.full",
          type: "text",
          placeholder: "Latin Full Name",
          className: "form-control",
          initial: "nameLatin",
          errors: {
            required: "Latin Full Name Is Required"
          }
        },
        {
          name: "buildRules",
          size: "col-sm-12 col",
          type: "subFields",
          label: "Build Rules",
          placeholder: "",
          className: "form-control",
          initial: "",
          options: [
            {
              name: "fixtureTypes",
              size: "col-sm-3 col",
              label: "Fixture Types:",
              labeltranslation: "fixture.types",
              type: "custom",
              customItem: "fixtureTypes",
              placeholder: "Fixture Types",
              className: "form-control",
              required: true,
              initial: "fixtureTypes",
              errors: {
                required: "Fixture Types Is Required"
              }
            },
            {
              name: "buildType",
              size: "col-sm-3 col",
              label: "Build Type:",
              labeltranslation: "build.rules.build.type",
              type: "select",
              placeholder: "Build Type",
              className: "form-control",
              required: true,
              initial: "buildType",
              options: [
                { value: "ROUND", text: "ROUND" },
                { value: "OVERALL", text: "OVERALL" }
              ],
              errors: {
                required: "Build Type Is Required"
              }
            },
            {
              name: "grouping",
              size: "col-sm-3 col",
              label: "Grouping:",
              labeltranslation: "build.rules.grouping",
              type: "select",
              placeholder: "Grouping",
              className: "form-control",
              required: true,
              initial: "grouping",
              options: [
                { value: "NONE", text: "NONE" },
                { value: "STAGE", text: "STAGE" },
                { value: "STAGEPOOL", text: "STAGEPOOL" }
              ],
              errors: {
                required: "Grouping Is Required"
              }
            },
            {
              name: "buildOverall",
              size: "col-sm-3 col",
              label: "Build Overall:",
              labeltranslation: "build.rules.build.overall",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Build Overall",
              className: "form-control",
              initial: "buildRules#buildOverall"
            },
            {
              name: "buildConferences",
              size: "col-sm-3 col",
              label: "Build Conferences:",
              labeltranslation: "build.rules.build.conferences",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Build Conferences",
              className: "form-control",
              initial: "buildRules#buildConferences"
            },
            {
              name: "buildDivisions",
              size: "col-sm-3 col",
              label: "Build Divisions:",
              labeltranslation: "build.rules.build.divisions",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Build Divisions",
              className: "form-control",
              initial: "buildRules#buildDivisions"
            },
            {
              name: "blankStanding",
              size: "col-sm-3 col",
              label: "Blank Standing:",
              labeltranslation: "buildRules.blank.standing",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Blank Standing",
              className: "form-control",
              initial: "buildRules#blankStanding"
            },
            {
              name: "includeLiveFixtures",
              size: "col-sm-3 col",
              label: "Include Live Fixtures:",
              labeltranslation: "include.live.fixtures",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Include Live Fixtures",
              className: "form-control",
              initial: "buildRules#includeLiveFixtures"
            },
            {
              name: "generateLiveStandings",
              size: "col-sm-3 col",
              label: "Generate Live Standings:",
              labeltranslation: "generate.live.standings",
              type: "select-boolean",
              options: [
                {
                  value: false,
                  translation: "no",
                  text: "no"
                },
                {
                  value: true,
                  translation: "yes",
                  text: "yes"
                }
              ],
              placeholder: "Generate Live Standings",
              className: "form-control",
              initial: "buildRules#generateLiveStandings"
            },
            {
              name: "decimalPlaces",
              size: "col-sm-3 col",
              label: "Decimal Places",
              labeltranslation: "decimal.places",
              type: "number",
              placeholder: "Number of decimal places for percentage calculations",
              className: "form-control",
              initial: "buildRules#decimalPlaces"
            }
          ]
        },
        {
          name: "configuration",
          size: "col-sm-12 col",
          label: "Configuration",
          type: "subFields",
          placeholder: "",
          className: "form-control",
          initial: "",
          default: {},
          options: [
            {
              name: "winsHomePoints",
              size: "col-sm-6 col",
              label: "Won Home Points",
              labeltranslation: "wins.home.points",
              type: "number",
              placeholder: "Points for Home Win",
              className: "form-control",
              initial: "winsHomePoints"
            },
            {
              name: "lossesHomePoints",
              size: "col-sm-6 col",
              label: "Lost Home Points",
              labeltranslation: "losses.home.points",
              type: "number",
              placeholder: "Points for Home Loss",
              className: "form-control",
              initial: "lossesHomePoints"
            },
            {
              name: "drawsHomeScoredPoints",
              size: "col-sm-6 col",
              label: "Draw Home Scored Points",
              labeltranslation: "draws.home.scored.points",
              type: "number",
              placeholder: "Draw Home Scored Points",
              className: "form-control",
              initial: "drawsHomeScoredPoints"
            },
            {
              name: "drawsHomeZeroPoints",
              size: "col-sm-6 col",
              label: "Points for Home Draw with zero score",
              labeltranslation: "draws.home.zero.points",
              type: "number",
              placeholder: "Draw Home Zero Points",
              className: "form-control",
              initial: "drawsHomeZeroPoints"
            },
            {
              name: "forfeitWonByHomePoints",
              size: "col-sm-6 col",
              label: "Points for wins by Forfeit at home",
              labeltranslation: "wins.home.forfeit",
              type: "number",
              placeholder: "Points for wins by Forfeit at home",
              className: "form-control",
              initial: "forfeitWonByHomePoints "
            },
            {
              name: "forfeitGivenHomePoints",
              size: "col-sm-6 col",
              label: "Points for giving a forfeit at home",
              labeltranslation: "forfeit.given.home.points",
              type: "number",
              placeholder: "Points for giving a forfeit at home",
              className: "form-control",
              initial: "forfeitGivenHomePoints"
            },
            {
              name: "winsAwayPoints",
              size: "col-sm-6 col",
              label: "Points for Away Win",
              labeltranslation: "wins.away.points",
              type: "number",
              placeholder: "Points for Away Win",
              className: "form-control",
              initial: "winsAwayPoints"
            },
            {
              name: "lossesAwayPoints",
              size: "col-sm-6 col",
              label: "Points for Away Loss",
              labeltranslation: "losses.away.points",
              type: "number",
              placeholder: "Points for Away Loss",
              className: "form-control",
              initial: "lossesAwayPoints"
            },
            {
              name: "drawsAwayScoredPoints",
              size: "col-sm-6 col",
              label: "Points for Away Draw where entity scored",
              labeltranslation: "draws.away.scored.points",
              type: "number",
              placeholder: "Points for Away Draw where entity scored",
              className: "form-control",
              initial: "drawsAwayScoredPoints"
            },
            {
              name: "drawsAwayZeroPoints",
              size: "col-sm-6 col",
              label: "Points for Away Draw with zero score",
              labeltranslation: "draws.away.zero.points",
              type: "number",
              placeholder: "Points for Away Draw with zero score",
              className: "form-control",
              initial: "drawsAwayZeroPoints"
            },
            {
              name: "forfeitWonByAwayPoints",
              size: "col-sm-6 col",
              label: "Points for wins by Forfeit at away",
              labeltranslation: "forfeit.wins.by.away.points",
              type: "number",
              placeholder: "Points for wins by Forfeit at away",
              className: "form-control",
              initial: "forfeitWonByAwayPoints"
            },
            {
              name: "forfeitGivenAwayPoints",
              size: "col-sm-6 col",
              label: "Points for giving a forfeit at away",
              labeltranslation: "forfeit.given.away.points",
              type: "number",
              placeholder: "Points for giving a forfeit at away",
              className: "form-control",
              initial: "forfeitGivenAwayPoints"
            },
            {
              name: "byePoints",
              size: "col-sm-6 col",
              label: "Points for bye",
              labeltranslation: "bye.points",
              type: "number",
              placeholder: "Points for bye",
              className: "form-control",
              initial: "byePoints"
            },
            {
              name: "byeIsPlayed",
              size: "col-sm-6 col",
              label: "Is a Bye added to played ?",
              labeltranslation: "bye.is.played",
              type: "select-boolean",
              placeholder: "Is a Bye added to played ?",
              className: "form-control",
              initial: "byeIsPlayed",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "byeAddedWins",
              size: "col-sm-6 col",
              label: "Are Bye wins added to Total wins?",
              labeltranslation: "bye.added.wins",
              type: "select-boolean",
              className: "form-control",
              initial: "byeAddedWins",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "forfeitWonByAddedWins",
              size: "col-sm-6 col",
              label: "Is a forfeit wins by (received) included in wins count ?",
              labeltranslation: "forfeit.wins.by.added.wins",
              type: "select-boolean",
              placeholder: "Is a forfeit wins by (received) included in wins count ?",
              className: "form-control",
              initial: "forfeitWonByAddedWins",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "forfeitWonByAddedPlayed",
              size: "col-sm-6 col",
              label: "Is a forfeit wins by (received) included in played count ?",
              labeltranslation: "forfeit.wins.by.added.played",
              type: "select-boolean",
              placeholder: "Is a forfeit wins by (received) included in played count ?",
              className: "form-control",
              initial: "forfeitWonByAddedPlayed",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "forfeitGivenAddedLosses",
              size: "col-sm-6 col",
              label: "Is a forfeit given included in losses count ?",
              labeltranslation: "forfeit.given.added.losses",
              type: "select-boolean",
              placeholder: "Is a forfeit given included in losses count ?",
              className: "form-control",
              initial: "forfeitGivenAddedLosses",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "forfeitGivenAddedPlayed",
              size: "col-sm-6 col",
              label: "Is a forfeit given included in played count ?",
              labeltranslation: "forfeit.given.added.played",
              type: "select-boolean",
              placeholder: "Is a forfeit given included in played count ?",
              className: "form-control",
              initial: "forfeitGivenAddedPlayed",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "streak",
              size: "col-sm-6 col",
              label: "How is streak calculcated?",
              labeltranslation: "streak",
              type: "select",
              className: "form-control",
              initial: "streak",
              options: [
                {
                  value: "WON",
                  translation: "wins",
                  text: "WON"
                },
                {
                  value: "NONLOST",
                  translation: "nonlosses",
                  text: "nonlosses"
                }
              ]
            },
            {
              name: "headToHeadUseAdjustments",
              size: "col-sm-6 col",
              label: "Apply Standing Adjustments when performing Head to head resolutions?",
              labeltranslation: "head.to.head.use.adjustments",
              type: "select-boolean",
              className: "form-control",
              initial: "headToHeadUseAdjustments",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },
            {
              name: "headToHeadSingleRound",
              size: "col-sm-12 col",
              label: "Only use single round for Head to head resolutions?",
              labeltranslation: "head.to.head.single.round",
              type: "select-boolean",
              className: "form-control",
              initial: "headToHeadSingleRound",
              options: [
                { value: false, translation: "no", text: "no" },
                { value: true, translation: "yes", text: "yes" }
              ]
            },

            {
              name: "sorts",
              size: "col-sm-12 col",
              label: "Sorting",
              labeltranslation: "sorting",
              customItem: "sorts",
              type: "custom",
              className: "form-control",
              initial: "sorts"
            },
            {
              name: "headToHeadResolutions",
              size: "col-sm-12 col",
              label: "Head To Head resolutions",
              labeltranslation: "headToHeadResolutions",
              customItem: "headToHeadResolutions",
              type: "custom",
              className: "form-control",
              initial: "headToHeadResolutions"
            },
            {
              name: "headToHeadIdentification",
              size: "col-sm-12 col",
              label: "Head To Head identification",
              labeltranslation: "headToHeadIdentification",
              customItem: "headToHeadIdentification",
              type: "custom",
              className: "form-control",
              initial: "headToHeadIdentification"
            }
          ]
        }
      ]
    }
  }
};
export default formSetup;

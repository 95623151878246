import React, { useContext, useState } from "react";
import { Button } from "reactstrap";
import { injectIntl } from "react-intl";

import { useToken } from "../../../Base/Hooks/token";
import { EnvironmentContext, environments } from "../../../../config/EnvironmentContext";

const PersonRow = (props) => {
  const { itemIndex, result, pageRef, match, reloadData } = props;
  const [isLoading, setIsLoading] = useState();
  const { readBody = (body) => body.json() } = {};
  const env = useContext(EnvironmentContext);
  const { token } = useToken();

  function addPersonToRoster(personId, name) {
    (async () => {
      setIsLoading(true);
      if (match.params.seasonId) {
        let url =
          environments["location"][env] +
          "/v1/" +
          match.params.sport +
          "/o/" +
          match.params.organizationId +
          "/seasons/" +
          match.params.seasonId +
          "/roster";
        let values = {
          personId: personId,
          status: "ACTIVE",
          entityId: match.params.entityId
        };
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token.token
            },
            body: JSON.stringify(values)
          });
          if (response.ok) {
            // const body = await readBody(response);
            // console.log(body);
            // if (body.data[0].masterVenueId) {
            pageRef.current.addFlash("Player added to roster successfully", "success", 10);
            // }
            reloadData();
            setIsLoading(false);
          } else {
            const body = await readBody(response);
            console.log("error", body);
            pageRef.current.addFlash("An error occurred - Master Venue link already exists", "danger", 10);
            setIsLoading(false);
          }
        } catch (e) {
          console.log("E", e);
          pageRef.current.addFlash("An error occurred - Master Venue link already exists", "danger", 10);
          setIsLoading(false);
        }
      }
    })();
  }

  return (
    <tr key={itemIndex} className={"loading-" + isLoading}>
      <td>{result.nameFullLocal}</td>
      <td>{result.nameFullLatin}</td>
      <td>{result.nationality}</td>
      <td>{result.gender}</td>
      <td>{result.status}</td>
      <td>
        <Button
          outline
          color="success"
          size="sm"
          onClick={(e) => addPersonToRoster(result.personId, result.nameFullLocal)}
        >
          <i className="fas fa-plus" />
        </Button>
      </td>
    </tr>
  );
};

export default injectIntl(PersonRow);

import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { FormattedDate, injectIntl } from "react-intl";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import officialRoles from "../Officials/officialRoles";
import { useFetch } from "../../Base/Hooks/fetch";
import isEqual from "lodash/isEqual";
import { isArrayEqual } from "../../Base/Utils/Array/isArrayEqual";
import { Link } from "react-router-dom";

// created from ../Fixtures/FixtureRow.js
const AssignOfficialsRow = (props) => {
  const {
    itemIndex,
    result,
    match: {
      params: { organizationId, sport }
    },
    intl,
    seasonRoles,
    assigningRole,
    setAssigningRole
  } = props;
  const [selectedSeasonRole, setSelectedSeasonRole] = useState([]);
  const [fixtureRoles, setFixtureRoles] = useState(null);
  const [chosenRole, setChosenRole] = useState("");
  const [editingRole, setEditingRole] = useState(false);
  const [error, setError] = useState(null);
  const { formatMessage } = intl;

  const dataCreateRoleRef = useRef();
  const {
    data: dataCreateRole,
    refetch: refetchCreateRole,
    updateURL: updateURLCreateRole,
    updateValues: updateValuesCreateRole,
    error: errorCreateRole
  } = useFetch("", { method: "POST" }, true, "location", false, true);
  const createRole = (role) => {
    const updatedUrl = `/v1/${sport}/o/${organizationId}/roles`;
    updateURLCreateRole(updatedUrl);
    const roleWithNoExtraFields = { ...role };
    [
      "updated",
      "organization",
      "roleId",
      "organizationId",
      "added",
      "person",
      "season",
      "seasonId",
      "nameFullLocal"
    ].forEach((prop) => {
      delete roleWithNoExtraFields[prop];
    });
    updateValuesCreateRole(roleWithNoExtraFields);
    refetchCreateRole();
  };
  useEffect(() => {
    if (dataCreateRole?.data && !isEqual(dataCreateRole.data, dataCreateRoleRef.current)) {
      dataCreateRoleRef.current = dataCreateRole.data;
      setFixtureRoles([...fixtureRoles, ...dataCreateRole.data]);
    }
  }, [dataCreateRole, fixtureRoles]);
  useEffect(() => {
    setError(errorCreateRole);
  }, [errorCreateRole]);

  const dataUpdateRoleRef = useRef();
  const {
    data: dataUpdateRole,
    refetch: refetchUpdateRole,
    updateURL: updateURLUpdateRole,
    updateValues: updateValuesUpdateRole,
    error: errorUpdateRole
  } = useFetch("", { method: "PUT" }, true, "location", false, true);
  const updateRole = (wholeRole, newRole) => {
    updateURLUpdateRole(`/v1/${sport}/o/${organizationId}/roles/${wholeRole.roleId}`);
    updateValuesUpdateRole({ role: newRole });
    refetchUpdateRole();
    setEditingRole("");
  };
  useEffect(() => {
    if (dataUpdateRole?.data && !isArrayEqual(dataUpdateRole.data, dataUpdateRoleRef.current)) {
      dataUpdateRoleRef.current = dataUpdateRole.data;
      setFixtureRoles(
        fixtureRoles.map((r) => (dataUpdateRole.data[0].roleId === r.roleId ? dataUpdateRole.data[0] : r))
      );
    }
  }, [dataUpdateRole, fixtureRoles]);
  useEffect(() => {
    setError(errorUpdateRole);
  }, [errorUpdateRole]);

  const {
    refetch: refetchDeleteRole,
    updateURL: updateURLDeleteRole,
    error: errorDeleteRole
  } = useFetch("", { method: "DELETE" }, true, "location", false, true);
  const deleteRole = (roleId) => {
    updateURLDeleteRole(`/v1/${sport}/o/${organizationId}/roles/${roleId}`);
    refetchDeleteRole();
    setFixtureRoles(fixtureRoles.filter((r) => r.roleId !== roleId));
  };
  useEffect(() => {
    setError(errorDeleteRole);
  }, [errorDeleteRole]);

  const {
    data: dataFixtureRoles,
    refetch: refetchFixtureRoles,
    updateURL: updateURLFixtureRoles,
    loading: loadingFixtureRoles,
    error: errorFixtureRoles
  } = useFetch("", "", true, "location", true, true);
  const getRoles = (fixtureId) => {
    const query = new URLSearchParams({
      include: ["persons"],
      limit: 200,
      role: officialRoles
    });
    updateURLFixtureRoles(`/v1/${sport}/o/${organizationId}/fixtures/${fixtureId}/roles?${query}`);
    refetchFixtureRoles();
  };
  useEffect(() => {
    if (dataFixtureRoles?.data) {
      setFixtureRoles(dataFixtureRoles.data);
    }
  }, [dataFixtureRoles]);
  useEffect(() => {
    setError(errorFixtureRoles);
  }, [errorFixtureRoles]);

  useEffect(() => {
    if (selectedSeasonRole.length) {
      setChosenRole(selectedSeasonRole[0].role);
    }
  }, [selectedSeasonRole]);

  return (
    <React.Fragment key={itemIndex}>
      <tr className={""}>
        {result && (
          <>
            <td>{result.venue}</td>
            <td>{result.startTimeLocal && <FormattedDate value={result.startTimeLocal.split("T")[0]} />}</td>
            <td>{result.teamHome}</td>
            <td>{result.teamAway}</td>
            <td>
              {error ? (
                <div className="alert alert-danger" role="alert">
                  {error}
                  <br />
                  <Link
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    {formatMessage({
                      id: "reloadPageTryAgain",
                      defaultMessage: "Please reload the page and try again"
                    })}
                  </Link>
                </div>
              ) : null}
              <table>
                <tbody>
                  {assigningRole === result.fixtureId && fixtureRoles?.length
                    ? fixtureRoles.map((r) =>
                        editingRole.roleId === r.roleId ? (
                          <tr key={r.roleId}>
                            <td>
                              {
                                Object.values(seasonRoles?.includes?.resources.persons).find(
                                  (p) => p.personId === r.personId
                                )?.nameFullLocal
                              }
                            </td>
                            <td>
                              <select
                                value={r.role}
                                onChange={(e) => {
                                  updateRole(r, e.target.value);
                                }}
                              >
                                {officialRoles.map((r) => (
                                  <option key={r} value={r}>
                                    {formatMessage({
                                      id: `roles.${r}`,
                                      defaultMessage: r
                                    })}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <Button outline color="secondary" size="sm" onClick={() => setEditingRole("")}>
                                {formatMessage({
                                  id: "cancel",
                                  defaultMessage: "Cancel"
                                })}
                              </Button>
                            </td>
                          </tr>
                        ) : (
                          <tr key={r.roleId}>
                            <td>
                              {
                                Object.values(seasonRoles?.includes?.resources.persons).find(
                                  (p) => p.personId === r.personId
                                )?.nameFullLocal
                              }
                            </td>
                            <td>
                              {formatMessage({
                                id: `roles.${r.role}`,
                                defaultMessage: r.role
                              })}
                            </td>
                            <td>
                              <Button outline color="primary" size="sm" onClick={() => setEditingRole(r)}>
                                <i className="fas fa-pen" />
                              </Button>
                              {"  "}
                              <Button outline color="danger" size="sm" onClick={() => deleteRole(r.roleId)}>
                                <i className="fas fa-times" />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    : null}
                  {assigningRole === result.fixtureId && seasonRoles && fixtureRoles ? (
                    <>
                      <tr>
                        <td className="inline-field">
                          <span>
                            {formatMessage({
                              id: "officials.selectSeasonOfficial",
                              defaultMessage: "Select season official"
                            })}
                            :
                          </span>
                          <Typeahead
                            id="basic-typeahead-multiple"
                            align={"left"}
                            clearButton
                            selected={selectedSeasonRole}
                            onChange={setSelectedSeasonRole}
                            options={seasonRoles.data.map((r) => ({
                              ...r,
                              nameFullLocal: Object.values(seasonRoles?.includes?.resources.persons).find(
                                (p) => p.personId === r.personId
                              )?.nameFullLocal
                            }))}
                            emptyLabel={formatMessage({
                              id: "noResults",
                              defaultMessage: "No results"
                            })}
                            labelKey={(option) =>
                              `${option.nameFullLocal} (${formatMessage({
                                id: `roles.${option.role}`,
                                defaultMessage: option.role
                              })})`
                            }
                            renderMenuItemChildren={(option) => (
                              <div key={option.roleId} data-option={option.roleId}>
                                <span>
                                  {option.nameFullLocal} (
                                  {formatMessage({
                                    id: `roles.${option.role}`,
                                    defaultMessage: option.role
                                  })}
                                  )
                                </span>
                              </div>
                            )}
                            useCache={false}
                          />
                        </td>
                        <td className="inline-field">
                          <span>
                            {formatMessage({
                              id: "officials.assignInThisFixture",
                              defaultMessage: "Assign role in this fixture"
                            })}
                            :
                          </span>
                          <div>
                            <select
                              value={chosenRole}
                              onChange={(e) => {
                                setChosenRole(e.target.value);
                              }}
                            >
                              {officialRoles.map((r) => (
                                <option key={r} value={r}>
                                  {formatMessage({
                                    id: `roles.${r}`,
                                    defaultMessage: r
                                  })}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                        <td className="inline-field">
                          <Button
                            outline
                            disabled={!selectedSeasonRole.length}
                            color="success"
                            size="sm"
                            style={{ marginTop: "1.5rem" }}
                            onClick={() => {
                              createRole({
                                ...selectedSeasonRole[0],
                                role: chosenRole,
                                fixtureId: result.fixtureId,
                                status: "ACTIVE"
                              });
                            }}
                          >
                            <i className="fas fa-arrow-right" />
                          </Button>
                        </td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
              </table>
              {assigningRole === result.fixtureId && seasonRoles && fixtureRoles ? (
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    setAssigningRole("");
                  }}
                >
                  Close
                </Button>
              ) : loadingFixtureRoles ? (
                <Spinner size="sm" color="danger" />
              ) : (
                !error && (
                  <Button
                    outline
                    color="secondary"
                    size="sm"
                    onClick={() => {
                      getRoles(result.fixtureId);
                      setAssigningRole(result.fixtureId);
                    }}
                  >
                    {formatMessage({
                      id: "officials.assign",
                      defaultMessage: "Assign Officials"
                    })}
                  </Button>
                )
              )}
            </td>
          </>
        )}
      </tr>
    </React.Fragment>
  );
};

export default injectIntl(AssignOfficialsRow);

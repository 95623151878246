import React, { useState } from "react";
import { Button } from "reactstrap";
import InlineForm from "../../Base/Forms/InlineForm";
import { rules } from "./ComparisonRules";
import inlineFormSetup from "./inlineFormSetup";

export default function Qualifier(props) {
  const {
    statisticField,
    comparisonField,
    comparisonType,
    comparisonValue,
    handleDeleteIntent,
    qualifierId,
    refetch,
    pageRef,
    intl: { formatMessage, messages },
    match: {
      params: { sport, organizationId, leaderCriteriaId }
    }
  } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  const EditContent = () => (
    <tr>
      <InlineForm
        action={refetch}
        pageRef={pageRef}
        form="RuleEdit"
        setEditMode={setIsEditMode}
        inlineFormSetup={inlineFormSetup({ formatMessage, messages })}
        formData={{ leaderCriteriaId, sport, organizationId, ...props }}
      />
    </tr>
  );

  const DisplayContent = () => (
    <tr onClick={() => setIsEditMode(true)}>
      <td>{formatMessage({ id: `stats.full.${statisticField}` })}</td>
      <td>{formatMessage({ id: `stats.full.${comparisonField}` })}</td>
      <td>{rules[comparisonType]}</td>
      <td>{comparisonValue}</td>
      <td className="text-right controls-3">
        <Button color="primary" size="sm" outline onClick={() => setIsEditMode(true)}>
          <i className="fas fa-pen" />
        </Button>
        {"  "}
        <Button outline color="danger" size="sm" onClick={() => handleDeleteIntent(qualifierId)}>
          <i className="fas fa-minus" />
        </Button>
      </td>
    </tr>
  );

  return isEditMode ? <EditContent /> : <DisplayContent />;
}

import React from "react";
import MasterDisplay from "../Base/MasterDisplay";
import ConferencesForm from "./ConferencesForm";
import formSetup from "./formSetup";

const Conferences = (props) => {
  const entitySetup = {
    entityName: "conferences",
    singleEntityName: "conference",
    keyProp: "conferenceId",
    columns: ["nameLocal", "abbreviationLocal", "nameLatin", "abbreviationLatin"],
    subEntities: ["divisions"],
    searchField: "nameLocal",
    formSetup,
    creationFormLabel: "CreateConference",
    initialFormData: {
      organizationId: props.match.params.organizationId
    },
    formComponent: ConferencesForm,
    include: [
      {
        type: "organizations"
      }
    ]
  };

  return <MasterDisplay {...props} {...entitySetup} />;
};

export default Conferences;

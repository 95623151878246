import React from "react";
import MasterDisplay from "../Base/MasterDisplay";
import StandingsConfigurationsForm from "./StandingsConfigurationsForm";

function StandingsConfiguration(props) {
  const entitySetup = {
    entityName: "standingsConfigurations",
    entityURLBase: "standings/configurations",
    singleEntityName: "standings.configuration",
    singleEntityURLBase: "standings-configuration",
    columns: ["nameLocal", "nameLatin"],
    searchField: "nameLocal",
    keyProp: "standingConfigurationId",
    formComponent: StandingsConfigurationsForm,
    include: [
      {
        type: "organizations"
      }
    ]
  };
  return <MasterDisplay {...props} {...entitySetup} />;
}
export default StandingsConfiguration;

import React from "react";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

const OrganizationRow = (props) => {
  const { itemIndex, result } = props;

  return (
    <React.Fragment key={itemIndex}>
      <tr key={itemIndex}>
        <td>{result.organizationId}</td>
        <td>
          <Link to={"/admin/" + result.sport + "/" + result.organizationId + "/admin/"} className="link">
            {result.nameLocal}
          </Link>
        </td>
        <td>
          <Link to={"/admin/" + result.sport + "/" + result.organizationId + "/admin/"} className="link">
            {result.nameLatin}
          </Link>
        </td>
        <td>{result.sport}</td>
        <td className="text-right controls-5">
          {"  "}
          <Link
            to={"/admin/" + result.sport + "/" + result.organizationId + "/admin/"}
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            Manage
            <i className="fas fa-chevron-right" />
          </Link>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default injectIntl(OrganizationRow);

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { useFetch } from "../Hooks/fetch";

const LookupField = (props) => {
  const { lookup, formData, defaultValue, match } = props;
  const [lookupData, setLookupData] = useState([]);

  const { error, loading, data } = useFetch("/" + getURL(), "", true, "location", true);

  function getURL() {
    let endpoint = lookup.endpoint;
    if (lookup.parameters) {
      lookup.parameters.forEach((parameter, key) => {
        endpoint = endpoint.replace("#" + key, formData[parameter]);
      });
    }
    return endpoint.replace("#sport", match.params.sport);
  }

  useEffect(() => {
    if (data) {
      let array = data !== null ? data.data : [];
      setLookupData(
        array.sort(function (a, b) {
          var aMixed = a[lookup.text];
          var bMixed = b[lookup.text];
          return aMixed < bMixed ? -1 : 1;
        })
      );
    }
  }, [data, lookup.text]);

  /**
   * props.lookup.nonNullable is usable when we don't
   * want to append a "-" option, for example, when editing
   * an entity that should always have a non-omittable lookup value
   */
  return (
    <React.Fragment>
      {!props.lookup.nonNullable && <option value="">-</option>}
      {!loading &&
        lookupData &&
        lookupData.map((option, index) => (
          <option
            value={option[lookup.value]}
            selected={option[lookup.value] === defaultValue}
            key={option[lookup.value]}
          >
            {option[lookup.text]}
          </option>
        ))}
      {error}
    </React.Fragment>
  );
};

export default withRouter(LookupField);

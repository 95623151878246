import React from "react";
import { getTranslatedFields } from "../StatisticFields";
import { rules } from "./ComparisonRules";

export default ({ formatMessage, messages }) => {
  const translatedFields = getTranslatedFields({ formatMessage, messages });

  const optionRender = (option) => (
    <option value={option.value} key={option.value}>
      {option.text}
    </option>
  );

  return {
    forms: {
      RuleEdit: {
        endpoint: "v1/#sport/o/#0/statistics/leaders/criteria/#1/qualifiers/#2",
        parameters: ["organizationId", "leaderCriteriaId", "qualifierId"],
        method: "PUT",
        successmessage: "Leaders Rule updated successfully",
        dataFields: ["statisticField", "comparisonField", "comparisonType", "comparisonValue"],
        fields: [
          {
            name: "statisticField",
            type: "select",
            data: translatedFields,
            optionRender
          },
          {
            name: "comparisonField",
            type: "select",
            data: translatedFields,
            optionRender
          },
          {
            name: "comparisonType",
            type: "select",
            data: Object.keys(rules).map((rule) => ({
              value: rule,
              text: rules[rule]
            })),
            optionRender
          },
          {
            name: "comparisonValue",
            type: "number"
          }
        ]
      }
    }
  };
};

import React from "react";
import { Route } from "react-router";

import Seasons from "./Seasons";
import EditSeason from "./EditSeason";
import Teams from "./Teams/Teams";
import TeamImages from "../Images/Teams/TeamImages";
import RosterImages from "../Images/Roster/RosterImages";
import Roster from "./Roster/Roster";
import Fixtures from "./Fixtures/Fixtures";
import SeasonVenues from "../Venues/SeasonVenues";
import TeamStats from "./TeamStats/TeamStats";
import Standings from "./Standings/Standings";
import StandingsAdjustmentsForm from "./StandingsAdjustments/StandingsAdjustmentsForm";
import EditSeasonTeam from "./Teams/EditSeasonTeam";
import StandingsAdjustments from "./StandingsAdjustments/StandingsAdjustments";
import EditStandings from "./Standings/EditStandings";
import EditFixture from "./Fixtures/EditFixture";
import Staff from "./Staff/Staff";
import SeasonOfficials from "./Officials/SeasonOfficials";
import AssignOfficials from "./AssignOfficials/AssignOfficials";

const SeasonRoutes = (props) => {
  const { title, updateState } = props;
  return (
    <>
      <Route
        exact
        path={"/admin/:sport/:organizationId/competition/:competitionId/seasons/"}
        render={(props) => <Seasons {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId"}
        render={(props) => <EditSeason {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/teams"}
        render={(props) => <Teams {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/teams/:teamId"}
        render={(props) => <EditSeasonTeam {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/teams/images"}
        render={(props) => <TeamImages {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/teams/:entityId/roster"}
        render={(props) => <Roster {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/teams/:entityId/roster/images"}
        render={(props) => <RosterImages {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/teams/:entityId/staff"}
        render={(props) => <Staff {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/fixtures"}
        render={(props) => <Fixtures {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/fixtures/:fixtureId"}
        render={(props) => <EditFixture {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/venues"}
        render={(props) => <SeasonVenues {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/standings"}
        render={(props) => <Standings {...props} updateState={updateState} title={title} />}
      />

      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/teamstats"}
        render={(props) => <TeamStats {...props} updateState={updateState} title={title} />}
      />

      <Route
        exact
        path={"/admin/:sport/:organizationId/standings/:standingId"}
        render={(props) => <EditStandings {...props} updateState={updateState} title={title} />}
      />

      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/standings/adjustments"}
        render={(props) => <StandingsAdjustments {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/standings/adjustments/:standingsAdjustmentId"}
        render={(props) => <StandingsAdjustmentsForm {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/officials"}
        render={(props) => <SeasonOfficials {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/seasons/:seasonId/assign-officials"}
        render={(props) => <AssignOfficials {...props} updateState={updateState} title={title} />}
      />
    </>
  );
};

export default SeasonRoutes;

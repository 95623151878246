import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Navbar, NavbarToggler } from "reactstrap";

import MenuBuilder from "../MenuBuilder/MenuBuilder";
import menuItems from "../../Menus/HeaderMenuItems";

import "./Header.scss";
import logo from "./logo.png";
const Header = (props) => {
  const { title, token } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);
  window.addEventListener("scroll", setScollPos);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function setScollPos() {
    if (window.scrollY > 10) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  }

  return (
    <header className={"scrolled-" + scrollTop}>
      <Navbar color="light" light className="desk-header" expand="lg">
        <Container fluid>
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Logo" />
            {title}
            <span className="navbar-brand-version">{import.meta.env.PROJECT_VERSION}</span>
          </Link>
          <NavbarToggler onClick={toggle} className="fas" />
        </Container>
      </Navbar>
      <MenuBuilder
        color="light"
        expand="lg"
        light={true}
        token={token}
        className="main-nav"
        menuItems={menuItems}
        isOpen={isOpen}
        collapse={true}
        data={props}
      />
    </header>
  );
};

export default Header;

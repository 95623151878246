import React, { useRef } from "react";

import formSetup from "./formSetup";
import { Spinner } from "reactstrap";
import { useFetch } from "../Base/Hooks/fetch";
import MatchProfileRow from "./MatchProfileRow";
import FormBuilder from "../Base/Forms/FormBuilder";
import TableDisplay from "../Base/Shared/TableDisplay";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import { injectIntl } from "react-intl";
import FormCollapse from "../Base/Forms/FormCollapse";
import { useDeleteModal } from "../Base/MasterDisplay/useDeleteModal";
import useResourceAPI from "../Base/Hooks/resourceAPI";
import TablePagination from "../Base/Shared/TablePagination";

const columns = ["name", "defaultProfile"];

function SeasonProfiles(props) {
  const {
    history,
    intl: { formatMessage },
    match: {
      params: { sport, organizationId }
    }
  } = props;

  const pageRef = useRef();

  const i18nColumns = columns.map((column) => formatMessage({ id: column }));

  const { data, loading, refetch, updatePagination } = useResourceAPI({
    ...props,
    resourceName: `fixtureProfiles`,
    initialQuery: {
      include: "organizations",
      limit: 20,
      offset: 0
    }
  });

  const { data: organizationData } = useFetch(`/v1/${sport}/organizations/${organizationId}`, "", true);

  const {
    element: DeleteModal,
    visibility,
    setData
  } = useDeleteModal({
    headingText: formatMessage({ id: "delete" }),
    actionText: formatMessage({ id: "ok" }),
    cancelText: formatMessage({ id: "cancel" }),
    text: formatMessage({ id: "match.profile.delete" }),
    pageRef,
    refreshTrigger: refetch
  });

  const handleDeleteIntent = (fixtureProfileId) => {
    visibility.open();
    setData.setUrl(`/v1/${sport}/o/${organizationId}/fixtureProfiles/${fixtureProfileId}`);
  };

  const Form = () => (
    <FormBuilder
      pageRef={pageRef}
      form="CreateProfile"
      formData={{
        organizationId
      }}
      formSetup={formSetup}
      action={refetch}
    />
  );

  const List = () => {
    const rows = data.data.map((profile) => (
      <MatchProfileRow key={profile.profileId} {...{ ...props, ...profile, handleDeleteIntent }} />
    ));
    return <TableDisplay withButtonsCell columns={i18nColumns} rows={rows} />;
  };

  const title = organizationData?.data
    ? `${organizationData.data[0].nameLocal} ${formatMessage({
        id: "match.profiles",
        defaultMessage: "Match Profiles"
      })}`
    : formatMessage({
        id: "match.profiles",
        defaultMessage: "Match Profiles"
      });

  return (
    <PageDisplay title={title} pageTitle={title} history={history} ref={pageRef}>
      {loading && <Spinner />}
      {data && (
        <>
          <List />
          <TablePagination data={data} setPaginationString={updatePagination} />
        </>
      )}
      {<DeleteModal />}
      <FormCollapse closedId="fixture.profile.add" closedDefaultMessage="Add New Match Profile">
        <Form />
      </FormCollapse>
    </PageDisplay>
  );
}

export default injectIntl(SeasonProfiles);

import React, { useRef } from "react";
import { injectIntl } from "react-intl";
import { useFetch } from "../../Base/Hooks/fetch";
import FormBuilder from "../../Base/Forms/FormBuilder";
import FormCollapse from "../../Base/Forms/FormCollapse";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import { CreateQualifier } from "../formSetup";
import { Spinner } from "reactstrap";
import Rule from "./Rule";
import TableDisplay from "../../Base/Shared/TableDisplay";
import { useDeleteModal } from "../../Base/MasterDisplay/useDeleteModal";
import TablePagination from "../../Base/Shared/TablePagination";
import useResourceAPI from "../../Base/Hooks/resourceAPI";

const columns = ["statistic.field", "comparison.field", "comparison.type", "comparison.value"];

const getColumns = (i18nFunction) => columns.map((id) => i18nFunction({ id }));

function LeaderQualifiers(props) {
  const {
    history,
    match: {
      params: { sport, organizationId, leaderCriteriaId }
    },
    intl: { formatMessage, messages },
    title
  } = props;
  const pageRef = useRef();

  const {
    data: rules,
    loading,
    refetch,
    updatePagination
  } = useResourceAPI({
    ...props,
    searchParameter: "statisticField",
    resourceName: `statistics/leaders/criteria/${leaderCriteriaId}/qualifiers`,
    initialQuery: {
      include: "leadersCriteria",
      limit: 20,
      offset: 0
    }
  });

  const { data: criteria } = useFetch(
    `/v1/${sport}/o/${organizationId}/statistics/leaders/criteria/${leaderCriteriaId}`,
    "",
    true
  );

  const { element, setData, visibility, deleteLoading } = useDeleteModal({
    headingText: formatMessage({ id: "delete" }),
    actionText: formatMessage({ id: "ok" }),
    cancelText: formatMessage({ id: "cancel" }),
    text: formatMessage({ id: "leaders.rule.delete" }),
    refreshTrigger: refetch,
    pageRef
  });

  function handleDeleteIntent(qualifierId) {
    visibility.open();
    setData.setUrl(
      `/v1/${sport}/o/${organizationId}/statistics/leaders/criteria/${leaderCriteriaId}/qualifiers/${qualifierId}`
    );
  }

  const getPageTitle = () =>
    (criteria?.data?.[0].name || "") +
    " " +
    formatMessage({
      id: "leaders.rules",
      defaultMessage: "Leaders Rules"
    });

  const Rules = () => {
    const rows = rules.data
      .sort((a, b) => a.statisticField.localeCompare(b.statisticField))
      .map((rule) => <Rule key={rule.qualifierId} {...{ ...props, ...rule, handleDeleteIntent, pageRef, refetch }} />);
    return (
      <>
        <TableDisplay columns={getColumns(formatMessage)} rows={rows} withButtonsCell />
        <TablePagination data={rules} setPaginationString={updatePagination} />
      </>
    );
  };

  return (
    <PageDisplay history={history} ref={pageRef} title={getPageTitle()} pageTitle={`${getPageTitle()} - ${title}`}>
      {(loading || deleteLoading) && <Spinner size="md" color="orange" />}
      {rules?.data && <Rules />}
      {!loading && (
        <FormCollapse closedId="leaders.rule.create">
          <FormBuilder
            form="CreateQualifier"
            formData={{ leaderCriteriaId, organizationId, sport }}
            formSetup={CreateQualifier({ formatMessage, messages })}
            action={refetch}
            pageRef={pageRef}
          />
        </FormCollapse>
      )}
      {element()}
    </PageDisplay>
  );
}

export default injectIntl(LeaderQualifiers);

import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { injectIntl } from "react-intl";
import Modal from "../Base/Modal/Modal";
import { useDelete } from "../Base/Hooks/delete";
import ImageUploader from "../Base/Images/ImageUploader";
import ImageDisplay from "../Base/Images/ImageDisplay";
import { useClipboard } from "use-clipboard-copy";
import { StaffContext } from "../../config/StaffContext";

const SeasonRow = (props) => {
  const { itemIndex, result, match, pageRef, setRefreshFlag, intl } = props;
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [linkDeleteData, setLinkDeleteData] = useState(null);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + "/seasons/" + linkDeleteData,
    "",
    refreshDeleteData
  );
  const { formatMessage } = intl;

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }),
        +deleteError,
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "season.delete.success",
            defaultMessage: "Season deleted successfully"
          }),
          "success",
          10
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setLinkDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeleteLink(param, name) {
    setDeleteModalText(
      formatMessage(
        {
          id: "season.delete.confirm",
          defaultMessage: "You are about to delete " + name + ". Is this OK?"
        },
        { item: name }
      )
    );
    setDeleteModalOpen(true);
    setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deleteLink() {
    setLinkDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  const isStaffScope = useContext(StaffContext);

  const clipboard = useClipboard();
  const copyToClipboard = useCallback(() => {
    clipboard.copy(`Org ID: ${result.organizationId}\nComp ID: ${result.competitionId}\nSeason ID: ${result.seasonId}`);
  }, [clipboard, result]);

  return (
    <tr key={itemIndex} className={"deleting-" + deleteLoading}>
      <td>
        <Link
          to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/seasons/" + result.seasonId}
          className="link"
          title="Edit Season"
        >
          {result.nameLocal}
        </Link>
      </td>
      <td>
        <Link
          to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/seasons/" + result.seasonId}
          className="link"
          title="Edit Season"
        >
          {result.nameLatin}
        </Link>
      </td>
      <td>{result.year}</td>
      <td>
        <ImageDisplay
          data={result}
          images={result.images}
          imageType="LOGO"
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      </td>
      <td>
        <ImageUploader
          data={result}
          type={"LOGO"}
          element={"seasonId"}
          imageFor={"SEASON"}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      </td>
      <td className="text-right controls-5">
        <Link
          to={
            "/admin/" + match.params.sport + "/" + result.organizationId + "/seasons/" + result.seasonId + "/standings/"
          }
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          {formatMessage({
            id: "standings",
            defaultMessage: "Standings"
          })}
          <i className="fas fa-chevron-right" />
        </Link>
        {"  "}
        <Link
          to={
            "/admin/" + match.params.sport + "/" + result.organizationId + "/seasons/" + result.seasonId + "/fixtures/"
          }
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          {formatMessage({
            id: "fixtures",
            defaultMessage: "Fixtures"
          })}
          <i className="fas fa-chevron-right" />
        </Link>
        {"  "}
        <Link
          to={"/admin/" + match.params.sport + "/" + result.organizationId + "/seasons/" + result.seasonId + "/teams/"}
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          {formatMessage({
            id: "teams",
            defaultMessage: "Teams"
          })}
          <i className="fas fa-chevron-right" />
        </Link>{" "}
        {isStaffScope() ? (
          <>
            <Button
              className="btn btn-sm btn-action"
              color="secondary"
              onClick={copyToClipboard}
              outline
              title={formatMessage({
                id: "seasons.copyToClipboard",
                defaultMessage: "Copy Org, Competition & Season IDs to clipboard"
              })}
            >
              {formatMessage({
                id: "seasons.addToClipboard",
                defaultMessage: "Add to clipboard"
              })}{" "}
              <i className="fas fa-clipboard" />
            </Button>{" "}
          </>
        ) : null}
        <Link
          to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/seasons/" + result.seasonId}
          className="btn btn-outline-primary btn-sm"
        >
          <i className="fas fa-pen" />
        </Link>
        {"  "}
        <Button outline color="danger" size="sm" onClick={(e) => showDeleteLink(result.seasonId, result.nameLocal)}>
          <i className="fas fa-minus" />
        </Button>
        <Modal
          isOpen={deleteModalOpen}
          heading={formatMessage({
            id: "season.delete",
            defaultMessage: "Delete Season?"
          })}
          text={deleteModalText}
          actionText={formatMessage({
            id: "delete",
            defaultMessage: "Delete"
          })}
          cancelText={formatMessage({
            id: "cancel",
            defaultMessage: "Cancel"
          })}
          action={deleteLink}
          cancel={cancelModal}
        />
      </td>
    </tr>
  );
};

export default injectIntl(SeasonRow);

import React, { useState, useRef, useEffect } from "react";
import { injectIntl } from "react-intl";
import FormBuilder from "../Base/Forms/FormBuilder";
import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import formSetup from "./formSetup";
import ObjectList from "./ObjectList";
import FixtureTypes from "./FixtureTypes";
import sortFields from "./sortFields";

const sortDirection = [
  {
    value: "ASC",
    label: "ASC"
  },
  {
    value: "DESC",
    label: "DESC"
  }
];

function StandingsConfigurationsForm(props) {
  const { title, history, intl, match } = props;
  const { formatMessage } = intl;
  const [formData, setFormData] = useState({});
  const [refreshFlag, setRefreshFlag] = useState(true);

  const [isEditMode, setEditMode] = useState(false);

  const pageRef = useRef();
  const LookupData = [];
  LookupData["streak"] = ["WON", "NONLOST"];

  function reloadData() {
    setRefreshFlag(false);
    setRefreshFlag(true);
    if (!isEditMode) {
      props.onCreate();
    }
  }

  useEffect(() => {
    if (match.params.standingsConfigurationId) {
      setEditMode(true);
    }
    setFormData({
      organizationId: match.params.organizationId,
      seasonId: match.params.seasonId,
      standingsConfigurationId: match.params.standingsConfigurationId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      `/standings/configurations/${match.params.standingsConfigurationId}`,
    "",
    refreshFlag && isEditMode
  );

  useEffect(() => {
    if (data) {
      setFormData({ ...formData, ...data.data[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const customFields = {};
  const sortField = Object.keys(sortFields).map((sf) => ({
    value: sf,
    label: formatMessage({ id: sf, defaultMessage: sortFields[sf] })
  }));
  const baseComponent = (props) => (
    <ObjectList
      indexField="sortField"
      secondaryField="sortDirection"
      fieldSetup={{
        sortField,
        sortDirection
      }}
      {...props}
    />
  );
  customFields.sorts = (props) => baseComponent(props);
  customFields.headToHeadResolutions = (props) => baseComponent(props);
  customFields.fixtureTypes = (props) => FixtureTypes(props);

  customFields.headToHeadIdentification = (props) => (
    <ObjectList
      indexField="checkField"
      fieldSetup={{
        checkField: sortField
      }}
      {...props}
    />
  );
  const Form = () =>
    formData &&
    refreshFlag && (
      <FormBuilder
        form={isEditMode ? "EditStandingsConfiguration" : "NewStandingsConfiguration"}
        action={reloadData}
        formSetup={formSetup}
        pageRef={props.pageRef || pageRef}
        formData={formData}
        lookupData={LookupData}
        customFields={customFields}
      />
    );

  const titleBase = formatMessage({
    id: "standings.configurations",
    defaultMessage: "Standings Configuations"
  });
  const PageWrapper = (content) => (
    <PageDisplay
      title={`${titleBase}`}
      error={error}
      loading={loading}
      pageTitle={`${titleBase} - ${title}`}
      ref={props.pageRef || pageRef}
      history={history}
    >
      {content()}
    </PageDisplay>
  );

  return isEditMode ? PageWrapper(Form) : Form();
}

export default injectIntl(StandingsConfigurationsForm);

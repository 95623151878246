const defaultPointsSchema = {
  CONFERENCE: {
    gamesBehind: 0
  },
  DIVISION: {
    gamesBehind: 0
  },
  IN_CONFERENCE: {
    bonusStandingPoints: 0,
    byeStandingPoints: 0,
    gamesBehind: 0,
    penaltyStandingPoints: 0,
    standingPoints: 0,
    standingPointsAway: 0,
    standingPointsHome: 0,
    teamDifferential: 0,
    teamDifferentialAway: 0,
    teamDifferentialHome: 0
  },
  IN_DIVISION: {
    bonusStandingPoints: 0,
    byeStandingPoints: 0,
    gamesBehind: 0,
    penaltyStandingPoints: 0,
    standingPoints: 0,
    standingPointsAway: 0,
    standingPointsHome: 0,
    teamDifferential: 0,
    teamDifferentialAway: 0,
    teamDifferentialHome: 0
  },
  OUT_CONFERENCE: {
    bonusStandingPoints: 0,
    byeStandingPoints: 0,
    gamesBehind: 0,
    penaltyStandingPoints: 0,
    standingPoints: 0,
    standingPointsAway: 0,
    standingPointsHome: 0,
    teamDifferential: 0,
    teamDifferentialAway: 0,
    teamDifferentialHome: 0
  },
  OUT_DIVISION: {
    bonusStandingPoints: 0,
    byeStandingPoints: 0,
    gamesBehind: 0,
    penaltyStandingPoints: 0,
    standingPoints: 0,
    standingPointsAway: 0,
    standingPointsHome: 0,
    teamDifferential: 0,
    teamDifferentialAway: 0,
    teamDifferentialHome: 0
  },
  OVERALL: {
    bonusStandingPoints: 0,
    byeStandingPoints: 0,
    gamesBehind: 0,
    penaltyStandingPoints: 0,
    standingPoints: 0,
    standingPointsAway: 0,
    standingPointsHome: 0,
    teamDifferential: 0,
    teamDifferentialAway: 0,
    teamDifferentialHome: 0
  }
};

const defaultCalculatedSchema = {
  IN_CONFERENCE: {
    byes: 0,
    draws: 0,
    drawsAway: 0,
    drawsHome: 0,
    forfeitsGiven: 0,
    forfeitsWonBy: 0,
    highestScoreAgainst: null,
    highestScoreAgainstAway: null,
    highestScoreAgainstHome: null,
    highestScoreFor: null,
    highestScoreForAway: null,
    highestScoreForHome: null,
    losses: 0,
    lossesAway: 0,
    lossesHome: 0,
    lowestScoreAgainst: null,
    lowestScoreAgainstAway: null,
    lowestScoreAgainstHome: null,
    lowestScoreFor: null,
    lowestScoreForAway: null,
    lowestScoreForHome: null,
    percentage: 0,
    percentageAway: 0,
    percentageHome: 0,
    played: 0,
    playedAway: 0,
    playedHome: 0,
    pointDifference: 0,
    pointDifferenceAway: 0,
    pointDifferenceHome: 0,
    resultString: "",
    resultStringAway: "",
    resultStringHome: "",
    scoredAgainst: 0,
    scoredAgainstAway: 0,
    scoredAgainstHome: 0,
    scoredFor: 0,
    scoredForAway: 0,
    scoredForHome: 0,
    streak: 0,
    streakAway: 0,
    streakHome: 0,
    washouts: 0,
    winPercentage: 0,
    winPercentageAway: 0,
    winPercentageHome: 0,
    wins: 0,
    winsAway: 0,
    winsHome: 0
  },
  IN_DIVISION: {
    byes: 0,
    draws: 0,
    drawsAway: 0,
    drawsHome: 0,
    forfeitsGiven: 0,
    forfeitsWonBy: 0,
    highestScoreAgainst: null,
    highestScoreAgainstAway: null,
    highestScoreAgainstHome: null,
    highestScoreFor: null,
    highestScoreForAway: null,
    highestScoreForHome: null,
    losses: 0,
    lossesAway: 0,
    lossesHome: 0,
    lowestScoreAgainst: null,
    lowestScoreAgainstAway: null,
    lowestScoreAgainstHome: null,
    lowestScoreFor: null,
    lowestScoreForAway: null,
    lowestScoreForHome: null,
    percentage: 0,
    percentageAway: 0,
    percentageHome: 0,
    played: 0,
    playedAway: 0,
    playedHome: 0,
    pointDifference: 0,
    pointDifferenceAway: 0,
    pointDifferenceHome: 0,
    resultString: "",
    resultStringAway: "",
    resultStringHome: "",
    scoredAgainst: 0,
    scoredAgainstAway: 0,
    scoredAgainstHome: 0,
    scoredFor: 0,
    scoredForAway: 0,
    scoredForHome: 0,
    streak: 0,
    streakAway: 0,
    streakHome: 0,
    washouts: 0,
    winPercentage: 0,
    winPercentageAway: 0,
    winPercentageHome: 0,
    wins: 0,
    winsAway: 0,
    winsHome: 0
  },
  OUT_CONFERENCE: {
    byes: 0,
    draws: 0,
    drawsAway: 0,
    drawsHome: 0,
    forfeitsGiven: 0,
    forfeitsWonBy: 0,
    highestScoreAgainst: null,
    highestScoreAgainstAway: null,
    highestScoreAgainstHome: null,
    highestScoreFor: null,
    highestScoreForAway: null,
    highestScoreForHome: null,
    losses: 0,
    lossesAway: 0,
    lossesHome: 0,
    lowestScoreAgainst: null,
    lowestScoreAgainstAway: null,
    lowestScoreAgainstHome: null,
    lowestScoreFor: null,
    lowestScoreForAway: null,
    lowestScoreForHome: null,
    percentage: 0,
    percentageAway: 0,
    percentageHome: 0,
    played: 0,
    playedAway: 0,
    playedHome: 0,
    pointDifference: 0,
    pointDifferenceAway: 0,
    pointDifferenceHome: 0,
    resultString: "",
    resultStringAway: "",
    resultStringHome: "",
    scoredAgainst: 0,
    scoredAgainstAway: 0,
    scoredAgainstHome: 0,
    scoredFor: 0,
    scoredForAway: 0,
    scoredForHome: 0,
    streak: 0,
    streakAway: 0,
    streakHome: 0,
    washouts: 0,
    winPercentage: 0,
    winPercentageAway: 0,
    winPercentageHome: 0,
    wins: 0,
    winsAway: 0,
    winsHome: 0
  },
  OUT_DIVISION: {
    byes: 0,
    draws: 0,
    drawsAway: 0,
    drawsHome: 0,
    forfeitsGiven: 0,
    forfeitsWonBy: 0,
    highestScoreAgainst: null,
    highestScoreAgainstAway: null,
    highestScoreAgainstHome: null,
    highestScoreFor: null,
    highestScoreForAway: null,
    highestScoreForHome: null,
    losses: 0,
    lossesAway: 0,
    lossesHome: 0,
    lowestScoreAgainst: null,
    lowestScoreAgainstAway: null,
    lowestScoreAgainstHome: null,
    lowestScoreFor: null,
    lowestScoreForAway: null,
    lowestScoreForHome: null,
    percentage: 0,
    percentageAway: 0,
    percentageHome: 0,
    played: 0,
    playedAway: 0,
    playedHome: 0,
    pointDifference: 0,
    pointDifferenceAway: 0,
    pointDifferenceHome: 0,
    resultString: "",
    resultStringAway: "",
    resultStringHome: "",
    scoredAgainst: 0,
    scoredAgainstAway: 0,
    scoredAgainstHome: 0,
    scoredFor: 0,
    scoredForAway: 0,
    scoredForHome: 0,
    streak: 0,
    streakAway: 0,
    streakHome: 0,
    washouts: 0,
    winPercentage: 0,
    winPercentageAway: 0,
    winPercentageHome: 0,
    wins: 0,
    winsAway: 0,
    winsHome: 0
  },
  OVERALL: {
    byes: 0,
    draws: 0,
    drawsAway: 0,
    drawsHome: 0,
    forfeitsGiven: 0,
    forfeitsWonBy: 0,
    highestScoreAgainst: null,
    highestScoreAgainstAway: null,
    highestScoreAgainstHome: null,
    highestScoreFor: null,
    highestScoreForAway: null,
    highestScoreForHome: null,
    losses: 0,
    lossesAway: 0,
    lossesHome: 0,
    lowestScoreAgainst: null,
    lowestScoreAgainstAway: null,
    lowestScoreAgainstHome: null,
    lowestScoreFor: null,
    lowestScoreForAway: null,
    lowestScoreForHome: null,
    percentage: 0,
    percentageAway: 0,
    percentageHome: 0,
    played: 0,
    playedAway: 0,
    playedHome: 0,
    pointDifference: 0,
    pointDifferenceAway: 0,
    pointDifferenceHome: 0,
    resultString: "",
    resultStringAway: "",
    resultStringHome: "",
    scoredAgainst: 0,
    scoredAgainstAway: 0,
    scoredAgainstHome: 0,
    scoredFor: 0,
    scoredForAway: 0,
    scoredForHome: 0,
    streak: 0,
    streakAway: 0,
    streakHome: 0,
    washouts: 0,
    winPercentage: 0,
    winPercentageAway: 0,
    winPercentageHome: 0,
    wins: 0,
    winsAway: 0,
    winsHome: 0
  }
};

export default {
  forms: {
    NewStandingsRow: {
      title: "Add New Standings Row",
      titletranslation: "standings.row.add",
      endpoint: "v1/#sport/o/#0/seasons/#1/standings",
      parameters: ["organizationId", "seasonId"],
      method: "POST",
      successmessage: "New Standings Row added successfully",
      link: {
        show: false,
        message: "View Standings",
        path: "/admin/standings/",
        parameters: []
      },
      submit: "Add New Standings Row",
      submittranslation: "standings.row.add.submit",
      fields: [
        {
          name: "entityId",
          size: "col-sm-4 col",
          label: "Team:",
          labeltranslation: "team",
          type: "lookup",
          placeholder: "Team",
          className: "form-control",
          required: true,
          initial: "null",
          lookup: {
            endpoint: "v1/#sport/o/#0/entities?limit=200",
            text: "nameFullLocal",
            value: "entityId",
            parameters: ["organizationId"]
          },
          errors: {
            required: "Team Is Required"
          }
        },
        /**
         * TODO: [SCH-94] pass conferences from the controller to prevent double fetch
         */
        {
          name: "conferenceId",
          size: "col-sm-4 col",
          label: "Conference",
          labeltranslation: "conference",
          type: "lookup",
          className: "form-control",
          initial: null,
          lookup: {
            endpoint: "v1/#sport/o/#0/conferences",
            text: "nameLocal",
            value: "conferenceId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "divisionId",
          size: "col-sm-4 col",
          label: "Division",
          labeltranslation: "division",
          type: "custom",
          customItem: "DivisionSelector",
          className: "form-control",
          initial: null
        },
        {
          name: "fixtureType",
          size: "col-sm-4 col",
          label: "Fixture Type",
          labeltranslation: "fixture.type",
          type: "select",
          initial: "",
          default: "REGULAR",
          className: "form-control",
          required: true,
          options: [
            { value: "ALL_STAR", text: "ALL_STAR" },
            { value: "PRESEASON", text: "PRESEASON" },
            { value: "REGULAR", text: "REGULAR" },
            { value: "FINAL", text: "FINAL" },
            { value: "PLAYOFF", text: "PLAYOFF" },
            { value: "FRIENDLY", text: "FRIENDLY" },
            { value: "DEMONSTRATION", text: "DEMONSTRATION" }
          ],
          errors: {
            required: "Fixture Type Is Required"
          }
        },
        {
          name: "position",
          size: "col-sm-4 col",
          label: "Position",
          labeltranslation: "position",
          type: "number",
          initial: "position",
          default: 1,
          min: 1,
          className: "form-control",
          required: true,
          errors: {
            required: "Position Is Required"
          }
        },
        {
          name: "groupingBase",
          size: "col-sm-4 col",
          label: "Grouping Base",
          labeltranslation: "groupingBase",
          type: "select",
          placeholder: "Grouping Base",
          className: "form-control",
          required: true,
          default: "OVERALL",
          initial: null,
          options: [
            { value: "ROUND", text: "ROUND" },
            { value: "OVERALL", text: "OVERALL" }
          ],
          errors: {
            required: "Grouping Base Is Required"
          }
        },
        {
          name: "groupingConferenceDivision",
          size: "col-sm-4 col",
          label: "Grouping Conference/Division",
          labeltranslation: "groupingConferenceDivision",
          type: "select",
          placeholder: "Grouping Conference/Division",
          className: "form-control",
          required: true,
          default: "OVERALL",
          initial: null,
          options: [
            { value: "CONFERENCE", text: "CONFERENCE" },
            { value: "DIVISION", text: "DIVISION" },
            { value: "OVERALL", text: "OVERALL" }
          ],
          errors: {
            required: "Grouping Conference/Division Is Required"
          }
        },
        {
          name: "groupingStagePool",
          size: "col-sm-4 col",
          label: "Grouping Stage/Pool",
          labeltranslation: "groupingStagePool",
          type: "select",
          placeholder: "Grouping Stage/Pool",
          className: "form-control",
          required: true,
          default: "OVERALL",
          initial: "OVERALL",
          options: [
            { value: "STAGE", text: "STAGE" },
            { value: "STAGE_POOL", text: "STAGE_POOL" },
            { value: "OVERALL", text: "OVERALL" }
          ],
          errors: {
            required: "Grouping Stage/Pool Is Required"
          }
        },
        {
          name: "live",
          size: "col-sm-4 col",
          label: "Include Live fixtures",
          labeltranslation: "include.live.fixtures",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          default: false,
          className: "form-control",
          initial: ""
        },
        {
          name: "calculated",
          size: "col-sm-12 col",
          label: "Calculated",
          labeltranslation: "calculated",
          type: "textarea",
          initial: "",
          default: JSON.stringify(defaultCalculatedSchema),
          className: "form-control"
        },
        {
          name: "points",
          size: "col-sm-12 col",
          label: "Points",
          labeltranslation: "standingPoints",
          type: "textarea",
          default: JSON.stringify(defaultPointsSchema),
          initial: "",
          className: "form-control"
        }
      ]
    },
    EditStandingsRow: {
      title: "Edit Standings Row",
      titletranslation: "standings.row.update",
      endpoint: "v1/#sport/o/#0/standings/#1",
      parameters: ["organizationId", "standingId"],
      method: "PUT",
      successmessage: "Standings Row updated successfully",
      link: {
        show: false,
        message: "View Standings",
        path: "/admin/standings",
        parameters: []
      },
      submit: "Update Standings Row",
      submittranslation: "standings.row.edit.submit",
      fields: [
        {
          name: "entityId",
          size: "col-sm-6 col",
          label: "Team:",
          labeltranslation: "team",
          disabled: true,
          type: "lookup",
          placeholder: "Team",
          className: "form-control",
          required: false,
          initial: "entityId",
          lookup: {
            endpoint: "v1/#sport/o/#0/entities?limit=200",
            text: "nameFullLocal",
            value: "entityId",
            parameters: ["organizationId"]
          },
          errors: {
            required: "Team Is Required"
          }
        },
        {
          name: "conferenceId",
          size: "col-sm-6 col",
          label: "Conference",
          labeltranslation: "conference",
          type: "lookup",
          className: "form-control",
          initial: "conferenceId",
          lookup: {
            endpoint: "v1/#sport/o/#0/conferences",
            text: "nameLocal",
            value: "conferenceId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "divisionId",
          size: "col-sm-6 col",
          label: "Division",
          labeltranslation: "division",
          type: "custom",
          customItem: "DivisionSelector",
          className: "form-control",
          initial: null
        },
        {
          name: "position",
          size: "col-sm-6 col",
          label: "Position",
          labeltranslation: "position",
          type: "number",
          initial: "position",
          className: "form-control",
          min: 1,
          required: true,
          errors: {
            required: "Position Is Required"
          }
        },
        {
          name: "calculated",
          size: "col-sm-12 col",
          label: "Calculated",
          labeltranslation: "calculated",
          type: "textarea",
          isJSON: true,
          initial: "calculated",
          className: "form-control"
        },
        {
          name: "points",
          size: "col-sm-12 col",
          label: "Points",
          labeltranslation: "standingPoints",
          type: "textarea",
          isJSON: true,
          initial: "points",
          className: "form-control"
        }
      ]
    }
  }
};

import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Button, Row, Col, Collapse } from "reactstrap";
import { useFetch } from "../Hooks/fetch";
import { Link } from "react-router-dom";
import { useDeleteModal } from "./useDeleteModal";
import { ErrorBoundary } from "../Utils/ErrorBoundary";

import PageDisplay from "../PageDisplay/PageDisplay";
import SearchFilter from "../Shared/SearchFilter";
import TableDisplay from "../Shared/TableDisplay";
import { SelectField } from "./FilterSelect";
import { dataDisplayReducer, initialDataDisplayState } from "./reducer";
import "./MasterDisplay.scss";

/**
 *
 * @param {IMasterDisplayProps} props
 */
function MasterDisplay(props) {
  const { entityName, match, history, parent, intl, globalFeatures, defaultQueryParameters } = props;
  const {
    params: { sport, organizationId }
  } = match;
  const { formatMessage } = intl;

  const { entityURLBase = entityName, singleEntityURLBase = entityName } = props;

  /**
   * Prepend columns with a hashing symbol
   * to let the user know rows are being
   * enumerated
   */
  let columns = props.columns;
  if (props.enumerateRows) {
    columns = ["#"].concat(columns);
  }

  const pageRef = useRef();

  const [pageTitle, setPageTitle] = useState("");

  /**
   * Main reducer, handles the list of items,
   * searching & filtering
   */
  const [dataDisplay, dispatch] = useReducer(dataDisplayReducer, initialDataDisplayState);

  /**
   * To allow external modules control parts of the API path
   * string we provide this state setter
   */
  const [queryParameters, setQueryParameters] = useState(new URLSearchParams(defaultQueryParameters));

  /**
   * API-related helpers, use them to access the backend API
   * based on the current state of the MasterDisplay
   */
  const apiRoot = `/v1/${sport}/o/${organizationId}`;
  const getFetchAPI = () =>
    props.parent
      ? `${apiRoot}/${parent.source}/${parent.id}/${entityURLBase}?${includes}&${queryParameters}`
      : `${apiRoot}/${entityURLBase}?limit=200&offset=0&${includes}&${queryParameters}`;

  /**
   * viewRoot and getViewURL serve as a base for all
   * UI-related navigation within the app that relies
   * on current state of the MasterDisplay
   */
  const viewRoot = `/admin/${sport}/${organizationId}`;
  const getViewURL = () =>
    props.parent
      ? `${viewRoot}/${parent.source}/${parent.id}/${props.entityName}`
      : `${viewRoot}/${singleEntityURLBase}`;

  /**
   * Compose item edit URL route
   * to be used by "Edit" buttons
   * @param {object} item
   */
  const getItemEditRoute = (item) => `${getViewURL()}/${item[props.keyProp]}`;

  /**
   * Helper to unwrap the parameters that should be consumed
   * by the api in the form of 'includes' query parameters
   */
  const includes = props.include ? `include=${props.include.map((e) => e.type).join(",")}` : "";

  const urls = {
    fetchAPI: getFetchAPI(),
    viewURL: getViewURL()
  };

  /**
   * Fetch data hook, use `refetch()` to update the list
   * on demand. Currently used by deleteModal to update the
   * list after item deletion
   */
  const { data, error, refetch } = useFetch(getFetchAPI(), "", true);

  /**
   * Modal instance for deleting list items
   * Heading text serves as a modal window header
   * refreshTrigger is used to update
   * the list after successful deleteion
   */
  const deleteModal = useDeleteModal({
    headingText: `${formatMessage({ id: "delete" })} ${props.singleEntityName}`,
    refreshTrigger: refetch,
    pageRef
  });

  /**
   * Handles newly fetched data.
   * Sets the list by dispatching the "fetchUpdate" action
   * Sets the page title based on the 'includes' key of the response
   */
  useEffect(() => {
    if (data && data.data) {
      dispatch({ type: "fetchUpdate", payload: data });
      if (data.includes) {
        /**
         * To support entities that are children by default,
         * e.g standings/configurations and not create a separate
         * field to include in the title of the page,
         * we can supply:
         * - entity/subentity as `entityName` -> will get mapped to JSON dict value
         * if possible
         * - entityName/entityNameLabel -> will be used as is
         */
        let entityTitle;
        if (props.entityNameLabel) {
          entityTitle = props.entityNameLabel;
        } else if (entityName.includes("/")) {
          entityTitle = entityName.replace("/", ".");
        } else {
          entityTitle = entityName;
        }
        if (parent) {
          setPageTitle(
            `${data.includes.resources[parent.source][parent.id].nameLocal} - ${formatMessage({ id: entityTitle })}`
          );
        } else {
          setPageTitle(
            `${data.includes.resources.organizations[organizationId].nameLocal} - ${formatMessage({ id: entityTitle })}`
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderCell = (item, colIdx, value, rowIdx) => {
    if (colIdx === 0) {
      if (props.enumerateRows) {
        return rowIdx + 1;
      }
      return (
        <Link to={getItemEditRoute(item)} className="link">
          {item[columns[0]]}
        </Link>
      );
    }

    return value;
  };

  /**
   * Whenever a user wants to delete an item
   * set the DELETE url and the confirmation message,
   * open the window afterwards
   * @param {object} item
   */
  const handleDeleteIntent = (item) => {
    const getDeleteURL = (id) => `${apiRoot}/${entityURLBase}/${id}`;
    deleteModal.setData.setTextContent(formatMessage({ id: "delete.item.confirm" }));
    deleteModal.setData.setUrl(getDeleteURL(item[props.keyProp]));
    deleteModal.visibility.open();
  };

  /**
   * If the entity has sub-entities,
   * "e.g the conference has a number of divisions"
   * render each of those as a button link
   * @param {object} item
   */
  const renderSubEntities = (item) => {
    if (props.subEntities) {
      return props.subEntities.map((subEntity) => (
        <Link
          key={subEntity}
          to={`${urls.viewURL}/${item[props.keyProp]}/${subEntity}`}
          className="btn btn-outline-secondary btn-sm btn-action subEntity"
        >
          <span className="capitalize">{subEntity}</span>
          <i className="fas fa-chevron-right" />
        </Link>
      ));
    }
  };

  const renderEditButton = (item) => (
    <Link to={getItemEditRoute(item)} className="btn btn-outline-primary btn-sm">
      <i className="fas fa-pen" />
    </Link>
  );

  const renderDeleteButton = (item) => (
    <Button onClick={() => handleDeleteIntent(item)} outline color="danger" size="sm">
      <i className="fas fa-minus" />
    </Button>
  );

  const renderControlButtons = (item) => (
    <td className="text-right controls-3">
      {renderSubEntities(item)}
      {!props.disableEdit && renderEditButton(item)}
      {"  "}
      {!props.disableDelete && renderDeleteButton(item)}
    </td>
  );

  const shouldRenderRowButtons = () => {
    if (props.subEntities || !props.disableDelete || !props.disableEdit) {
      return true;
    }
  };

  const renderRow = (item, rowIdx) => {
    if (shouldDisplayItem(item, dataDisplay, props.searchField)) {
      return (
        <tr key={item[props.keyProp] || rowIdx}>
          {columns.map((col, colIdx) => (
            <td key={col || colIdx}> {renderCell(item, colIdx, item[col], rowIdx)} </td>
          ))}
          {shouldRenderRowButtons() && renderControlButtons(item)}
        </tr>
      );
    }
  };

  const renderList = useCallback(() => {
    let list = [...dataDisplay.list];

    if (props.include.some((i) => i.mapTo || i.mapWith)) {
      list = dataDisplay.list.map((item) => {
        const externalProps = props.include.reduce((a, c) => {
          if (!c.mapFrom) {
            return { ...a };
          }

          return {
            ...a,
            [c.mapTo]:
              dataDisplay.includes.resources[c.type][searchInChain({ searchPath: c.mapFrom, searchableObject: item })][
                c.mapWith
              ]
          };
        }, {});
        return {
          ...item,
          ...externalProps
        };
      });
    }

    const composeTableRows = () => list.map(renderRow);
    let mappedColumns = columns.map((col) => formatMessage({ id: col }));
    shouldRenderRowButtons() && mappedColumns.push("");
    return <TableDisplay containerClass="table-responsive" columns={mappedColumns} rows={composeTableRows()} />;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDisplay]);

  const renderSearchField = () =>
    props.searchField && <SearchFilter initial={dataDisplay.textSearch} doSearch={handleSearchUpdate} />;

  const handleFilterUpdate = (field, value) => {
    dispatch({ type: "filterFieldUpdate", payload: { field, value } });
  };

  const handleSearchUpdate = (value) => {
    dispatch({ type: "textSearchUpdate", payload: value });
  };

  const renderFilters = useCallback(() => {
    if (props.filters && data) {
      return <Row>{props.filters.map(renderFilter)}</Row>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDisplay]);

  const renderFilter = (criteria, index) => (
    <Col lg="3" key={`${criteria}-${index}`}>
      <SelectField {...props} field={criteria} data={dataDisplay} onChange={handleFilterUpdate} />
    </Col>
  );

  const [isFormOpen, setIsFormOpen] = useState(false);
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const renderCreationForm = () =>
    props.formComponent && (
      <>
        <hr />
        <h4 onClick={toggleForm}>
          {!isFormOpen && (
            <span>
              <FormattedMessage
                id={`${props.singleEntityName}.add`}
                defaultMessage={`Add New ${props.singleEntityName}`}
              />{" "}
              <i className="fas fa-caret-down"></i>
            </span>
          )}
          {isFormOpen && (
            <span>
              <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
            </span>
          )}
        </h4>
        <Collapse isOpen={isFormOpen}>{props.formComponent({ ...props, pageRef, onCreate: refetch })}</Collapse>
      </>
    );

  const renderGlobalFeatures = () => {
    if (globalFeatures) {
      const features = Object.values(globalFeatures).map((Feature, index) => (
        <Col className="global-feature-item">
          <Feature
            {...props}
            apiRoot={apiRoot}
            data={data}
            pageRef={pageRef}
            key={index}
            refetch={refetch}
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
          />
        </Col>
      ));
      return <Row>{features}</Row>;
    }
  };

  return (
    <PageDisplay
      pageTitle={`${pageTitle} - ${props.title}`}
      title={pageTitle}
      error={error}
      ref={pageRef}
      history={history}
    >
      {deleteModal.element()}
      <Col>{renderGlobalFeatures()}</Col>
      <Col>{renderSearchField()}</Col>
      {renderFilters()}
      <ErrorBoundary>
        {renderList()}
        {renderCreationForm()}
      </ErrorBoundary>
    </PageDisplay>
  );
}

/**
 * Utility funciton that decideds whether
 * to display a list item or not,
 * based on current state of search & filters
 * @param {object} item
 * @param {initialDataDisplayState} state
 * @param {string} searchField
 */
const shouldDisplayItem = (item, state, searchField) => {
  const hasTextSearchMatch = item[searchField].toLowerCase().includes(state.textSearch.toLowerCase());

  const hasfilterMatch = Object.keys(state.filters).every((f) => {
    if (state.filters[f] === "none") {
      return true;
    }
    return item[f] === state.filters[f];
  });

  return hasTextSearchMatch && hasfilterMatch;
};

export default injectIntl(MasterDisplay);

export function searchInChain(props) {
  if (!props.searchPath) {
    return;
  }
  for (const key of props.searchPath.split(".")) {
    if (!props.searchableObject.hasOwnProperty(key)) {
      return;
    }
    props.searchableObject = props.searchableObject[key];
  }
  return props.searchableObject;
}

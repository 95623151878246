const formSetup = {
  forms: {
    CreateDivision: {
      title: "Add New Division",
      titletranslation: "division.add",
      endpoint: "v1/#sport/o/#0/conferences/#1/divisions",
      parameters: ["organizationId", "conferenceId"],
      method: "POST",
      successmessage: "New Division added successfully.",
      link: {
        show: false,
        message: "View Venue",
        path: "/admin/venues/",
        parameters: []
      },
      submit: "Add New Division",
      submittranslation: "divisions.add",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Name Is Required"
          }
        }
      ]
    },
    UpdateDivision: {
      title: "Update Division",
      titletranslation: "division.update",
      endpoint: "v1/#sport/o/#0/divisions/#1",
      parameters: ["organizationId", "divisionId"],
      method: "PUT",
      successmessage: "Division Updated Successfully.",
      link: {
        show: false,
        message: "View Conference",
        path: "/admin/conferences/",
        parameters: []
      },
      submit: "Update",
      submittranslation: "division.update",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: "nameLocal",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: "nameLatin",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Name Is Required"
          }
        }
      ]
    }
  }
};

export default formSetup;

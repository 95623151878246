var menuItems = [
  {
    items: [
      {
        link: "/select/organization",
        text: "Organizations",
        conditions: [
          { variable: "isAuthenticated", evaluator: true },
          { variable: "hasAccess", evaluator: true }
        ]
      },
      {
        link: "/home",
        text: "Home",
        conditions: [
          { variable: "isAuthenticated", evaluator: true },
          { variable: "hasAccess", evaluator: true },
          { variable: "orgId", evaluator: true }
        ]
      },
      {
        link: "/admin",
        text: "Manage",
        translation: "manage",
        conditions: [
          { variable: "isAuthenticated", evaluator: true },
          { variable: "hasAccess", evaluator: true },
          { variable: "orgId", evaluator: true }
        ],
        children: [
          {
            link: "/admin/leagues",
            text: "Leagues",
            translation: "leagues",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/competitions",
            text: "Competitions",
            translation: "competitions",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/conferences",
            text: "Conferences",
            translation: "conferences",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/clubs",
            text: "Clubs",
            translation: "clubs",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/teams",
            text: "Teams",
            translation: "teams",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/sites",
            text: "Sites",
            translation: "sites",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/venues",
            text: "Venues",
            translation: "venues",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/persons",
            text: "Persons",
            translation: "persons",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/standings-configurations",
            text: "Standing Configurations",
            translation: "standings.configurations",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/leaders-qualification",
            text: "Leaders Qualification",
            translation: "leaders.qualification",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          },
          {
            link: "/admin/match-profiles",
            text: "Match Profiles",
            translation: "match.profiles",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "orgId", evaluator: true }
            ]
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        link: "#",
        text: "Login",
        icon: "fas fa-sign-in-alt",
        function: "login",
        conditions: [{ variable: "isAuthenticated", evaluator: false }]
      },
      {
        link: "#",
        text: "UserProfile",
        component: "userprofile",
        conditions: [{ variable: "isAuthenticated", evaluator: true }]
      },
      {
        link: "#",
        text: "LanguageSelect",
        component: "languageselect"
      },
      {
        link: "#",
        text: "",
        icon: "fas fa-sign-out-alt",
        function: "logout",
        conditions: [{ variable: "isAuthenticated", evaluator: true }]
      }
    ]
  }
];
export default menuItems;

/**
 * Returns a sorted version of a collection
 * TODO: support ordering
 * @param {Array<T>} collection
 * @param {string} key
 * @returns {Array<T>}
 */
export function sortCollectionByKey(collection, key) {
  return [...collection].sort((a, b) => a[key] - b[key]);
}

import React, { useState, useMemo } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import FinderSelect from "./FinderSelect";
import Group from "../../Base/Group/Group";

const seasonStatusList = ["ALL", "ACTIVE", "INACTIVE", "PENDING"];

const SeasonFinder = (props) => {
  const {
    match,
    intl: { formatMessage }
  } = props;
  const [finderState, setFinderState] = useState({});
  const [, updateAllState] = React.useState();

  const [seasonStatusFilter, setSeasonStatusFilter] = useState("&status=ACTIVE");

  const forceUpdate = React.useCallback(() => updateAllState({}), []);

  function updateFinderState(key, data) {
    let thisState = finderState;
    if (finderState[key] !== data) {
      thisState[key] = data;
      setFinderState(thisState);
      forceUpdate();
    }
  }

  function handleSeasonStatusFilterChange({ target: { value } }) {
    if (value === "ALL") {
      setSeasonStatusFilter("");
      return;
    }
    setFinderState({}); // always reset season
    setSeasonStatusFilter(`&status=${value}`);
  }

  const SeasonStatusFilter = () => (
    <div className="finder-filter">
      <h5>
        <FormattedMessage id="season.status.filter" />
      </h5>
      <select value={seasonStatusFilter.replace("&status=", "")} onChange={handleSeasonStatusFilterChange}>
        {seasonStatusList.map((status) => (
          <option key={status} value={status}>
            {formatMessage({ id: status.toLowerCase() })}
          </option>
        ))}
      </select>
    </div>
  );

  const SeasonButtons = useMemo(() => {
    if (finderState.seasonId) {
      return (
        <Group groupSize={3} wrap>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/fixtures/"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="fixtures" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/assign-officials"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="statistics.assignOfficials" />
          </Link>
          <Link
            to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/seasons/" + finderState.seasonId}
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="details" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/teams"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="teams" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/teams/images"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="team.images" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/teamstats"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="statistics.team" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/venues"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="venues" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/standings"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="standings" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/officials"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="statistics.seasonOfficials" />
          </Link>
        </Group>
      );
    }
    // eslint-disable-next-line
  }, [finderState, finderState.seasonId]);

  const TeamButtons = useMemo(() => {
    if (finderState.entityId) {
      return (
        <>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/teams/" +
              finderState.entityId +
              "/roster"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="roster" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/teams/" +
              finderState.entityId +
              "/roster/images"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="roster.images" />
          </Link>
          <Link
            to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/teams/" + finderState.entityId}
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="details" />
          </Link>
          <Link
            to={
              "/admin/" +
              match.params.sport +
              "/" +
              match.params.organizationId +
              "/seasons/" +
              finderState.seasonId +
              "/teams/" +
              finderState.entityId +
              "/staff"
            }
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="staff" />
          </Link>
        </>
      );
    }
    // eslint-disable-next-line
  }, [finderState, finderState.entityId]);

  const PersonButtons = useMemo(() => {
    if (finderState.personId) {
      return (
        <>
          <Link
            to={"/admin/" + match.params.sport + "/" + match.params.organizationId + "/persons/" + finderState.personId}
            className="btn btn-outline-secondary btn-sm btn-action"
          >
            <FormattedMessage id="details" />
          </Link>
        </>
      );
    }
    // eslint-disable-next-line
  }, [finderState, finderState.personId]);

  return (
    <div className="season-finder">
      <h3>
        <FormattedMessage id="seasons.manage" defaultMessage="Manage Seasons" />
      </h3>
      <hr />
      <FinderSelect
        updateFinderState={updateFinderState}
        url={`/seasons?limit=200&include=competitions${seasonStatusFilter}`}
        organizationId={match.params.organizationId}
        value="seasonId"
        translation="season.select"
        template="#0 - (#include)"
        variables={["nameLocal"]}
        include="competitions"
        includeId="competition"
        includeVariable="nameLocal"
        buttons={SeasonButtons}
        Filters={SeasonStatusFilter}
      />
      {finderState.seasonId && (
        <FinderSelect
          updateFinderState={updateFinderState}
          url={"/seasons/" + finderState.seasonId + "/entities?limit=200&include=entities"}
          organizationId={match.params.organizationId}
          value="entityId"
          translation="team.select"
          template="#include"
          variables={[]}
          include="entities"
          includeId="entity"
          includeVariable="nameFullLocal"
          buttons={TeamButtons}
        />
      )}
      {finderState.entityId && (
        <FinderSelect
          updateFinderState={updateFinderState}
          url={
            "/seasons/" +
            finderState.seasonId +
            "/entities/" +
            finderState.entityId +
            "/roster?limit=200&include=persons"
          }
          organizationId={match.params.organizationId}
          value="personId"
          translation="person.select"
          template="#include"
          variables={[]}
          include="persons"
          includeId="person"
          includeVariable="nameFullLocal"
          buttons={PersonButtons}
        />
      )}
    </div>
  );
};

export default injectIntl(SeasonFinder);

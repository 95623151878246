import React from "react";
import InlineForm from "../../../Base/Forms/InlineForm";
import inlineFormSetup from "../inlineFormSetup";
import officialRoles from "../officialRoles";

export default (props) => {
  const { result: person, match, pageRef, refetch } = props;
  return (
    <tr>
      <InlineForm
        editMode
        form="StaffAdd"
        pageRef={pageRef}
        extraData={person}
        cancellable={false}
        setEditMode={() => {}}
        action={refetch}
        inlineFormSetup={inlineFormSetup}
        submitIconClassName="fas fa-arrow-right"
        formData={{ ...match.params, ...person, role: officialRoles[0] }}
      />
    </tr>
  );
};

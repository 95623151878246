import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import formSetup from "./formSetup";
import FormBuilder from "../../Base/Forms/FormBuilder";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import { useFetch } from "../../Base/Hooks/fetch";
import DivisionSelect, { DivisionSelectPlaceholder } from "../Teams/DivisionSelect";
import { Spinner } from "reactstrap";

function EditStandings(props) {
  const {
    match: {
      params: { seasonId, sport, standingId, organizationId }
    },
    history,
    title,
    intl: { formatMessage }
  } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [refreshData, setRefreshData] = useState(true);
  const [entityName, setEntityName] = useState(" ");
  const pageRef = useRef();

  useEffect(() => {
    setCurrentData({
      organizationId,
      seasonId
    });

    if (standingId) {
      setCurrentData((c) => ({
        ...c,
        standingId
      }));
      setIsEditMode(true);
    }
  }, [organizationId, seasonId, standingId]);

  const { error, data } = useFetch(
    `/v1/${sport}/o/${organizationId}/standings/${standingId}?include=entities`,
    "",
    isEditMode
  );

  function reloadData(data) {
    setCurrentData({ ...currentData, ...data });
    setRefreshData(false);
    setRefreshData(true);
    if (!isEditMode) {
      props.onCreate();
    }
  }

  const customFields = {};

  customFields.divisionId = (props) =>
    props.form.values.conferenceId ? (
      <DivisionSelect sport={sport} organizationId={organizationId} {...props} />
    ) : (
      <DivisionSelectPlaceholder />
    );

  useEffect(() => {
    if (data && data.data && Array.isArray(data.data) && data.data[0]) {
      setCurrentData((c) => ({
        ...c,
        ...data.data[0]
      }));

      setEntityName(data.includes.resources.entities[data.data[0].entity.id].nameFullLocal);
    }
  }, [data]);

  const getContextualFormSetup = (setup) => {
    if (currentData.standingId && currentData.groupingConferenceDivision === "OVERALL") {
      return {
        ...setup,
        forms: {
          ...setup.forms,
          EditStandingsRow: {
            ...setup.forms.EditStandingsRow,
            fields: setup.forms.EditStandingsRow.fields.filter(
              (field) => !field.name.includes("conferenceId") && !field.name.includes("divisionId")
            )
          }
        }
      };
    }
    return setup;
  };

  function shouldDisplayForm() {
    const isDataReady = isEditMode ? data : true;
    return currentData && refreshData && isDataReady;
  }

  const renderForm = () => (
    <div>
      {shouldDisplayForm() ? (
        <FormBuilder
          action={reloadData}
          formSetup={getContextualFormSetup(formSetup)}
          form={isEditMode ? "EditStandingsRow" : "NewStandingsRow"}
          customFields={customFields}
          formData={currentData}
          pageRef={isEditMode ? pageRef : props.pageRef}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );

  const titleBase = shouldDisplayForm()
    ? `${entityName} - ` +
      formatMessage({
        id: isEditMode ? "standings.row.edit" : "standings.row.add",
        defaultValue: isEditMode ? "Update Standings Row" : "Create Standings Row"
      })
    : "";

  const renderPageWrapper = (content) => (
    <PageDisplay
      title={titleBase}
      pageTitle={`${titleBase} - ${title}`}
      error={error}
      ref={isEditMode ? pageRef : props.pageRef}
      history={history}
    >
      {content()}
    </PageDisplay>
  );
  return isEditMode ? renderPageWrapper(renderForm) : renderForm();
}

export default injectIntl(EditStandings);

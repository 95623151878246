const sportsPositions = (formatMessage) => ({
  basketball: {
    PG: formatMessage({
      id: "basketball.pointGuard",
      defaultMessage: "POINT GUARD"
    }),
    G: formatMessage({
      id: "basketball.guard",
      defaultMessage: "GUARD"
    }),
    SG: formatMessage({
      id: "basketball.shootingGuard",
      defaultMessage: "SHOOTING GUARD"
    }),
    PF: formatMessage({
      id: "basketball.powerForward",
      defaultMessage: "POWER FORWARD"
    }),
    SF: formatMessage({
      id: "basketball.smallForward",
      defaultMessage: "SMALL FORWARD"
    }),
    F: formatMessage({
      id: "basketball.forward",
      defaultMessage: "FORWARD"
    }),
    C: formatMessage({
      id: "basketball.center",
      defaultMessage: "CENTER"
    }),
    FC: formatMessage({
      id: "basketball.forwardCenter",
      defaultMessage: "FORWARD CENTER"
    }),
    GF: formatMessage({
      id: "basketball.guardForward",
      defaultMessage: "GUARD FORWARD"
    })
  }
});

export default sportsPositions;

var formSetup = {
  forms: {
    NewLeague: {
      title: "Add New League",
      titletranslation: "league.add",
      endpoint: "v1/#sport/o/#0/leagues",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New League added successfully.",
      link: {
        show: false,
        message: "View Org",
        path: "/admin/leagues/",
        parameters: []
      },
      submit: "Add New League",
      submittranslation: "league.add",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          labeltranslation: "name.local.abbreviation",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Name Is Required"
          }
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          labeltranslation: "name.latin.abbreviation",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "countryCode",
          size: "col-sm-6 col",
          label: "Country",
          labeltranslation: "country",
          type: "lookupData",
          placeholder: "Select a country",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "countryCodes",
          errors: {
            required: "Country Is Required"
          }
        },
        {
          name: "regionType",
          size: "col-sm-6 col",
          label: "Region Type:",
          labeltranslation: "region.type",
          type: "lookupData",
          placeholder: "Region Type",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "regionTypes",
          errors: {
            required: "Region Type Is Required"
          }
        }
      ]
    },
    EditLeague: {
      title: "Edit League",
      titletranslation: "league.update",
      endpoint: "v1/#sport/o/#0/leagues/#1",
      parameters: ["organizationId", "leagueId"],
      method: "PUT",
      successmessage: "League updated successfully.",
      link: {
        show: false,
        message: "View League",
        path: "/configuration/bundles/",
        parameters: []
      },
      submit: "Update League",
      submittranslation: "league.update",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: "nameLocal",
          errors: {
            required: "Local Name Is Required"
          }
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          type: "text",
          placeholder: "Local Abbr",
          labeltranslation: "name.local.abbreviation",
          className: "form-control",
          required: false,
          initial: "abbreviationLocal",
          errors: {
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          type: "text",
          placeholder: "Latin Name",
          labeltranslation: "name.latin",
          className: "form-control",
          required: false,
          initial: "nameLatin",
          errors: {
            required: "Latin Name Is Required"
          }
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          labeltranslation: "name.latin.abbreviation",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: "abbreviationLatin",
          errors: {
            required: "Local Name Is Required"
          }
        },
        {
          name: "countryCode",
          size: "col-sm-6 col",
          label: "Country",
          labeltranslation: "country",
          type: "lookupData",
          placeholder: "Select a country",
          className: "form-control",
          required: false,
          initial: "countryCode",
          lookupData: "countryCodes",
          errors: {
            required: "Country Is Required"
          }
        },
        {
          name: "regionType",
          size: "col-sm-6 col",
          label: "Region Type:",
          labeltranslation: "region.type",
          type: "lookupData",
          placeholder: "Region Type",
          className: "form-control",
          required: true,
          initial: "regionType",
          lookupData: "regionTypes",
          errors: {
            required: "Region Type Is Required"
          }
        }
      ]
    }
  }
};
export default formSetup;

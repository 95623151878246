import React from "react";
import { chunks } from "../Utils/Array/chunks";
import "./Group.scss";

const Group = (props) => {
  const {
    groups,
    groupSize = 1,
    classNameLastInGroup = "group-items-last-in-group",
    classNameLastInLastGroup = "group-items-last-in-group_last",
    wrap,
    Wrapper,
    classNameWrapper = "group-items-wrapper",
    classNameLastWrapper = "group-items-wrapper_last",
    Separator
  } = props;

  const chunked = groups ? groups : [...chunks(React.Children.toArray(props.children), groupSize)];

  return chunked.map((group, index, groups) => {
    const styledGroup =
      classNameLastInGroup || classNameLastInLastGroup
        ? group.map((g, i) => ({
            ...g,
            props: {
              ...g.props,
              className: `${g.props.className}${i + 1 === group.length ? " " + classNameLastInGroup : ""}${
                i + 1 === group.length && index + 1 === chunked.length ? " " + classNameLastInLastGroup : ""
              }`
            }
          }))
        : group;
    if (Wrapper || wrap) {
      const wrapperClassName =
        index + 1 === chunked.length ? classNameWrapper + " " + classNameLastWrapper : classNameWrapper;

      return (
        <React.Fragment key={group.map((g) => g.key).join("") || index}>
          {Wrapper ? (
            <Wrapper className={wrapperClassName}>{styledGroup}</Wrapper>
          ) : (
            <span className={wrapperClassName}>{styledGroup}</span>
          )}
          {Separator && index + 1 < groups.length ? <Separator /> : null}
        </React.Fragment>
      );
    }
    return styledGroup;
  });
};

export default Group;

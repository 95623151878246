import React from "react";
import { injectIntl } from "react-intl";

const TeamPlayerStatsRow = (props) => {
  const { itemIndex, result, type } = props;

  function convertClock(clock) {
    let clockDisplay = clock.split(".");
    clockDisplay = clockDisplay[0].replace("PT", "").replace("S", "");
    let clockParts = clockDisplay.split("M");
    clockDisplay =
      (clockParts[0].length < 2 ? "0" + clockParts[0] : clockParts[0]) + ":" + convertClockPart(clockParts[1]);
    // (clockParts[1].length < 2 ? "0" + clockParts[1] : clockParts[1]);
    return clockDisplay;
  }

  function convertClockPart(value) {
    switch (value.length) {
      case 1:
        return "0" + value;
      case 0:
        return "00";
      default:
        return value;
    }
  }

  function roundNumber(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  function getAverage(value) {
    if (result.statistics.games > 0) {
      return roundNumber(value / result.statistics.games);
    } else {
      return 0;
    }
  }

  return (
    <tr key={itemIndex}>
      {result && type === "totals" && (
        <React.Fragment>
          <td>{result.person}</td>
          <td>{result.statistics.games}</td>
          <td>{convertClock(result.statistics.minutes)}</td>
          <td>
            {result.statistics.fieldGoalsMade} / {result.statistics.fieldGoalsAttempted}
          </td>
          <td>{roundNumber(result.statistics.fieldGoalsPercentage)}</td>
          <td>
            {result.statistics.pointsTwoMade} / {result.statistics.pointsTwoAttempted}
          </td>
          <td>{roundNumber(result.statistics.pointsTwoPercentage)}</td>
          <td>
            {result.statistics.pointsThreeMade} / {result.statistics.pointsThreeAttempted}
          </td>
          <td>{roundNumber(result.statistics.pointsThreePercentage)}</td>
          <td>
            {result.statistics.freeThrowsMade} / {result.statistics.freeThrowsAttempted}
          </td>
          <td>{roundNumber(result.statistics.freeThrowsPercentage)}</td>
          <td>{result.statistics.reboundsOffensive}</td>
          <td>{result.statistics.reboundsDefensive}</td>
          <td>{result.statistics.rebounds}</td>
          <td>{result.statistics.assists}</td>
          <td>{result.statistics.turnovers}</td>
          <td>{result.statistics.steals}</td>
          <td>{result.statistics.blocks}</td>
          <td>{result.statistics.foulsTotal}</td>
          <td>{result.statistics.foulsDrawn}</td>
          <td>{result.statistics.points}</td>
        </React.Fragment>
      )}
      {result && type === "averages" && (
        <React.Fragment>
          <td>{result.person}</td>
          <td>{result.statistics.games}</td>
          <td>{convertClock(result.statistics.minutesPerGame)}</td>
          <td>
            {getAverage(result.statistics.fieldGoalsMade)} / {getAverage(result.statistics.fieldGoalsAttempted)}
          </td>
          <td>{roundNumber(result.statistics.fieldGoalsPercentage)}</td>
          <td>
            {getAverage(result.statistics.pointsTwoMade)} / {getAverage(result.statistics.pointsTwoAttempted)}
          </td>
          <td>{roundNumber(result.statistics.pointsTwoPercentage)}</td>
          <td>
            {getAverage(result.statistics.pointsThreeMade)} / {getAverage(result.statistics.pointsThreeAttempted)}
          </td>
          <td>{roundNumber(result.statistics.pointsThreePercentage)}</td>
          <td>
            {getAverage(result.statistics.freeThrowsMade)} / {getAverage(result.statistics.freeThrowsAttempted)}
          </td>
          <td>{roundNumber(result.statistics.freeThrowsPercentage)}</td>
          <td>{roundNumber(result.statistics.reboundsOffensivePerGame)}</td>
          <td>{roundNumber(result.statistics.reboundsDefensivePerGame)}</td>
          <td>{roundNumber(result.statistics.reboundsPerGame)}</td>
          <td>{roundNumber(result.statistics.assistsPerGame)}</td>
          <td>{roundNumber(result.statistics.turnoversPerGame)}</td>
          <td>{roundNumber(result.statistics.stealsPerGame)}</td>
          <td>{roundNumber(result.statistics.blocksPerGame)}</td>
          <td>{roundNumber(result.statistics.foulsPersonalPerGame)}</td>
          <td>{getAverage(result.statistics.foulsDrawn)}</td>
          <td>{roundNumber(result.statistics.pointsPerGame)}</td>
        </React.Fragment>
      )}
    </tr>
  );
};

export default injectIntl(TeamPlayerStatsRow);

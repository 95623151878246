import React, { useCallback, useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { useDelete } from "../Hooks/delete";

export function useDeleteModal(props) {
  const { headingText, actionText, cancelText, text, refreshTrigger, pageRef } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [textContent, setTextContent] = useState(text);
  const [url, setUrl] = useState("");
  const [revision, setRevision] = useState(0);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const handleSubmit = () => {
    setRevision(revision + 1);
    close();
  };

  const { deleteError, deleteData, deleteLoading } = useDelete(url, "", revision);

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash("Delete failed", "danger", 15);
    } else {
      if (deleteData) {
        refreshTrigger();
        pageRef.current.addFlash("Item deleted successfully", "success", 5);
      }
    }
    // eslint-disable-next-line
  }, [deleteData, deleteError]);

  const element = useCallback(
    () =>
      isOpen && (
        <Modal
          isOpen
          heading={headingText}
          actionText={actionText || "Ok"}
          cancelText={cancelText || "Cancel"}
          text={textContent}
          action={handleSubmit}
          cancel={close}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );
  return {
    element,
    deleteLoading,
    visibility: { open, close },
    setData: { setTextContent, setUrl }
  };
}

import React, { useState } from "react";
import { Button } from "reactstrap";
import InlineForm from "../../../Base/Forms/InlineForm";
import inlineFormSetup from "../inlineFormSetup";

const StaffMember = (props) => {
  const {
    handleDeleteIntent,
    roles,
    role,
    roleId,
    person,
    refetch,
    pageRef,
    match: {
      params: { organizationId }
    }
  } = props;

  const [isEditState, setEditState] = useState(false);

  const nameFullLocal = roles?.includes?.resources.persons[person.id].nameFullLocal;

  const Display = () => (
    <>
      <td>{nameFullLocal}</td>
      <td>{role}</td>
      <td align="right">
        <Button outline color="primary" size="sm" onClick={() => setEditState(true)}>
          <i className="fas fa-pen" />
        </Button>{" "}
        <Button outline size="sm" color="danger" onClick={() => handleDeleteIntent(roleId)}>
          <i className="fas fa-times" />
        </Button>
      </td>
    </>
  );

  const formData = {
    role,
    roleId,
    organizationId
  };

  const Edit = () => (
    <InlineForm
      action={refetch}
      setEditMode={setEditState}
      pageRef={pageRef}
      form="StaffEdit"
      formData={formData}
      extraData={{ ...formData, nameFullLocal }}
      inlineFormSetup={inlineFormSetup}
    />
  );
  return <tr>{isEditState ? Edit() : Display()}</tr>;
};

export default StaffMember;

import React, { useEffect, useState, useRef } from "react";
import { Collapse, Input, Label, Spinner } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import ClubRow from "./ClubRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import TablePagination from "../Base/Shared/TablePagination";
import SearchFilter from "../Base/Shared/SearchFilter";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";
import "../Base/Shared/SearchFilter.scss";

const Clubs = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationString, setPaginationString] = useState("limit=25&offset=0");
  const [formData, setFormData] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("ACTIVE");
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/entityGroups?include=organizations&" +
      paginationString,
    "",
    refreshFlag
  );

  const columns = [
    formatMessage({
      id: "name.local.full",
      defaultMessage: "Local Full Name"
    }),
    formatMessage({
      id: "name.latin.full",
      defaultMessage: "Latin Full Name"
    }),
    formatMessage({
      id: "status",
      defaultMessage: "Status"
    }),
    ""
  ];

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  const statusFilterHandler = (e) => {
    setStatusFilter(e.target.value);
  };

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);

      setFormData({
        organizationId: match.params.organizationId
      });
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data) {
      if (data && data.includes && data.includes.resources && data.includes.resources.organizations) {
        setPageTitle(data.includes.resources.organizations[match.params.organizationId].nameLocal);
      }

      let filteredData = data !== null ? data.data : [];

      if (statusFilter) {
        filteredData = filteredData.filter((d) => d.status === statusFilter);
      }

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.nameFullLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }
      let tableList = filteredData.map((result, index) => (
        <ClubRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText, statusFilter]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={
            pageTitle +
            " " +
            formatMessage({
              id: "clubs",
              defaultMessage: "Clubs"
            })
          }
          error={error}
          pageTitle={
            pageTitle +
            formatMessage({
              id: "clubs",
              defaultMessage: "Clubs"
            }) +
            " - " +
            title
          }
          ref={pageRef}
          history={history}
        >
          <div className="table-search-filter">
            <div className="table-search-filter-status">
              <Label htmlFor="status-filter">
                {formatMessage({
                  id: "status",
                  defaultMessage: "Status"
                })}
                :
              </Label>
              <Input
                id="status-filter"
                className="table-search-filter-status-select"
                type="select"
                name="status-filter"
                value={statusFilter}
                onChange={statusFilterHandler}
              >
                {LookupData["status"].map((status) => (
                  <option value={status}>{status === "" ? "ALL" : status}</option>
                ))}
              </Input>
            </div>
            <SearchFilter doSearch={setSearchText} />
          </div>
          <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
          <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
          <hr />
          <h4 onClick={toggleForm}>
            {!isFormOpen && (
              <span>
                <FormattedMessage id="club.add" defaultMessage="Add New Club" /> <i className="fas fa-caret-down"></i>
              </span>
            )}
            {isFormOpen && (
              <span>
                <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
              </span>
            )}
          </h4>
          <Collapse isOpen={isFormOpen}>
            {formData !== null && (
              <FormBuilder
                form="NewClub"
                action={reloadData}
                formSetup={formSetup}
                pageRef={pageRef}
                formData={formData}
                lookupData={LookupData}
              />
            )}
          </Collapse>
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(Clubs);

import React, { useCallback, useRef } from "react";
import { useFetch } from "../../Base/Hooks/fetch";
import StaffCandidates from "./Candidates/StaffCandidates";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import StaffMembers from "./Members/StaffMembers";
import { Spinner } from "reactstrap";
import FormCollapse from "../../Base/Forms/FormCollapse";
import { injectIntl } from "react-intl";
import officialRoles from "./officialRoles";

function SeasonOfficials(props) {
  const {
    history,
    match: {
      params: { sport, organizationId, seasonId }
    },
    intl: { formatMessage }
  } = props;
  const pageRef = useRef();

  const staffQuery = new URLSearchParams({
    hideNull: true,
    include: ["persons", "seasons"],
    limit: 200,
    role: officialRoles,
    seasonId
  });

  const rosterURL = `/v1/${sport}/o/${organizationId}/roles?${staffQuery}`;
  const { data: roles, loading, refetch } = useFetch(rosterURL, "", true);

  const Form = useCallback(<StaffCandidates {...props} refetch={refetch} pageRef={pageRef} />, [roles]);

  const seasonName = roles?.includes?.resources?.seasons
    ? roles?.includes?.resources?.seasons[seasonId]?.nameLocal
    : null;

  const getPageTitle = () =>
    `${seasonName ? seasonName + " " : ""}${formatMessage({
      id: "officials",
      defaultMessage: "Officials"
    })}`;

  return (
    <PageDisplay ref={pageRef} history={history} title={getPageTitle()} pageTitle={getPageTitle()}>
      {loading && <Spinner size="md" color="orange" />}
      {roles && !loading && (
        <>
          <StaffMembers {...props} pageRef={pageRef} refetch={refetch} roles={roles} />
        </>
      )}
      {roles && (
        <FormCollapse closedId="officials.add" closedDefaultMessage="Add New Official">
          {Form}
        </FormCollapse>
      )}
    </PageDisplay>
  );
}

export default injectIntl(SeasonOfficials);

import React, { useState, useContext } from "react";
import { useToken } from "../../Base/Hooks/token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
import { injectIntl } from "react-intl";
import { Button } from "reactstrap";
import Modal from "../../Base/Modal/Modal";

function FixtureReset(props) {
  const {
    intl: { formatMessage },
    match,
    fixtureId,
    pageRef
  } = props;
  const [fixtureClearCandidate, setFixtureClearCandidate] = useState();
  const { token } = useToken();
  const env = useContext(EnvironmentContext);

  function resetGame() {
    const url = `/v1/${match.params.sport}/o/${match.params.organizationId}/fixtures/${fixtureClearCandidate}/reset?forceDelete=true`;
    let tokenType = token.tokenType.charAt(0).toUpperCase() + token.tokenType.substr(1).toLowerCase();
    const envURL = environments["location"][env] + url;
    fetch(envURL, {
      method: "POST",
      headers: {
        Authorization: tokenType + " " + token.token
      }
    })
      .then((r) => r.json())
      .then(handleSuccess)
      .catch(handleError)
      .finally(() => setFixtureClearCandidate(null));
  }

  function handleSuccess(response) {
    if (response?.data) {
      const [result] = response.data;
      if (result.success && pageRef) {
        props.pageRef.current.addFlash(
          formatMessage({
            id: "fixture.clear.success"
          }),
          "success",
          5
        );
      }
    }
  }
  function handleError(error) {
    props.pageRef.current.addFlash(String(error), "danger", 5);
  }

  return (
    <>
      <Button
        outline
        onClick={() => setFixtureClearCandidate(fixtureId)}
        size="sm"
        title={formatMessage({
          id: "fixture.reset.title",
          defaultMessage: "Reset Fixture"
        })}
      >
        <i className="fas fa-redo" />
      </Button>{" "}
      <Modal
        isOpen={fixtureClearCandidate}
        heading={formatMessage({
          id: "fixture.reset.title",
          defaultMessage: "Reset fixture"
        })}
        text={formatMessage({
          id: "fixture.reset.warning"
        })}
        actionText={formatMessage({
          id: "ok",
          defaultMessage: "Ok"
        })}
        cancelText={formatMessage({
          id: "cancel",
          defaultMessage: "Cancel"
        })}
        action={resetGame}
        cancel={() => setFixtureClearCandidate(null)}
      />
    </>
  );
}

export default injectIntl(FixtureReset);

import React from "react";
import { Route } from "react-router";

import Organizations from "./Organizations";
import ManageOrganization from "../ManageOrganization/ManageOrganization";

const OrganizationRoutes = (props) => {
  const { setup, title, updateState } = props;
  return (
    <>
      <Route
        exact
        path={"/select/organization"}
        render={(props) => <Organizations {...props} updateState={updateState} title={title} setup={setup} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/admin"}
        render={(props) => <ManageOrganization {...props} updateState={updateState} title={title} setup={setup} />}
      />
    </>
  );
};

export default OrganizationRoutes;

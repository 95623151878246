import React, { useState, useRef, useEffect } from "react";
import { injectIntl } from "react-intl";
import { Spinner } from "reactstrap";
import FormBuilder from "../../Base/Forms/FormBuilder";
import { useFetch } from "../../Base/Hooks/fetch";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import formSetup from "./formSetup";

function StandingsAdjustmentsForm(props) {
  const { history, intl, match } = props;
  const { formatMessage } = intl;
  const [formData, setFormData] = useState(null);
  const pageRef = useRef();
  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    if (match.params.standingsAdjustmentId) {
      setEditMode(true);
    }
    setFormData({
      organizationId: match.params.organizationId,
      seasonId: match.params.seasonId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      `/standings/adjustments/${match.params.standingsAdjustmentId}`,
    "",
    isEditMode
  );

  useEffect(() => {
    if (data) {
      setFormData(data.data[0]);
    }
  }, [data]);

  const [refreshFlag, setRefreshFlag] = useState(true);
  const reloadData = (values) => {
    if (!isEditMode) {
      setRefreshFlag(false);
      setRefreshFlag(true);
      props.onCreate();
    } else {
      // If form was successfully submitted - apply those values
      // back to the original formData
      setFormData({ ...formData, values });
    }
  };

  const Form = () =>
    formData &&
    refreshFlag && (
      <FormBuilder
        form={isEditMode ? "ModifyAdjustment" : "CreateAdjustment"}
        action={reloadData}
        formSetup={formSetup}
        pageRef={props.pageRef || pageRef}
        formData={formData}
      />
    );

  const PageWrapper = (content) => (
    <PageDisplay
      title={formatMessage({
        id: "standings.adjustments",
        defaultMessage: "Standings"
      })}
      error={error}
      pageTitle={formatMessage({
        id: "standings",
        defaultMessage: "standings"
      })}
      ref={props.pageRef || pageRef}
      history={history}
    >
      {content()}
      {loading && <Spinner size="sm" color="danger" />}
    </PageDisplay>
  );

  return isEditMode ? PageWrapper(Form) : Form();
}

export default injectIntl(StandingsAdjustmentsForm);

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { injectIntl } from "react-intl";
import Modal from "../Base/Modal/Modal";
import { useDelete } from "../Base/Hooks/delete";
import ImageUploader from "../Base/Images/ImageUploader";
import ImageDisplay from "../Base/Images/ImageDisplay";

const CompetitionRow = (props) => {
  const { itemIndex, result, match, pageRef, setRefreshFlag, intl } = props;
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [linkDeleteData, setLinkDeleteData] = useState(null);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + "/competitions/" + linkDeleteData,
    "",
    refreshDeleteData
  );
  const { formatMessage } = intl;

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }),
        +deleteError,
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "competition.delete.success",
            defaultMessage: "competition deleted successfully"
          }),
          "success",
          10
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setLinkDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeleteLink(param, name) {
    setDeleteModalText(
      formatMessage(
        {
          id: "competition.delete.confirm",
          defaultMessage: "You are about to delete " + name + ". Is this OK?"
        },
        { item: name }
      )
    );
    setDeleteModalOpen(true);
    setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deleteLink() {
    setLinkDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  return (
    <tr key={itemIndex} className={"deleting-" + deleteLoading}>
      <td>
        <Link
          to={
            "/admin/" + match.params.sport + "/" + match.params.organizationId + "/competitions/" + result.competitionId
          }
          className="link"
          title="Edit Competition"
        >
          {result.nameLocal}
        </Link>
      </td>
      <td>
        <Link
          to={
            "/admin/" + match.params.sport + "/" + match.params.organizationId + "/competitions/" + result.competitionId
          }
          className="link"
          title="Edit Competition"
        >
          {result.nameLatin}
        </Link>
      </td>
      <td>
        <ImageDisplay
          data={result}
          images={result.images}
          imageType="LOGO"
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      </td>
      <td>
        <ImageUploader
          data={result}
          type={"LOGO"}
          element={"competitionId"}
          imageFor={"COMPETITION"}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      </td>
      <td className="text-right controls-5">
        <Link
          to={
            "/admin/" +
            match.params.sport +
            "/" +
            result.organizationId +
            "/competition/" +
            result.competitionId +
            "/seasons/"
          }
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          {formatMessage({
            id: "seasons",
            defaultMessage: "Seasons"
          })}
          <i className="fas fa-chevron-right" />
        </Link>
        {"  "}
        <Link
          to={
            "/admin/" + match.params.sport + "/" + match.params.organizationId + "/competitions/" + result.competitionId
          }
          className="btn btn-outline-primary btn-sm"
        >
          <i className="fas fa-pen" />
        </Link>
        {"  "}
        <Button
          outline
          color="danger"
          size="sm"
          onClick={(e) => showDeleteLink(result.competitionId, result.nameLocal)}
        >
          <i className="fas fa-minus" />
        </Button>
        <Modal
          isOpen={deleteModalOpen}
          heading={formatMessage({
            id: "competition.delete",
            defaultMessage: "Delete Competition?"
          })}
          text={deleteModalText}
          actionText={formatMessage({
            id: "delete",
            defaultMessage: "Delete"
          })}
          cancelText={formatMessage({
            id: "cancel",
            defaultMessage: "Cancel"
          })}
          action={deleteLink}
          cancel={cancelModal}
        />
      </td>
    </tr>
  );
};

export default injectIntl(CompetitionRow);

import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";

export function ColorPicker(props) {
  const { color, label, type, onColorChange } = props;
  const [pickerVisible, setPickerVisibility] = useState(false);
  const ref = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  function handleClickOutside(event) {
    if (!ref?.current?.contains(event.target)) {
      setPickerVisibility(false);
    }
  }

  function handleColorChange(color) {
    onColorChange(color.hex, type);
  }

  return (
    <div className="color-picker" ref={ref}>
      <label>{label}</label>
      <div className="color-preview">
        <button type="button" onClick={() => setPickerVisibility(!pickerVisible)} style={{ backgroundColor: color }} />
      </div>
      {pickerVisible && (
        <SketchPicker presetColors={[]} width="278px" onChangeComplete={handleColorChange} disableAlpha color={color} />
      )}
    </div>
  );
}

import React, { useEffect, useState, useRef, useCallback } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { useFetch } from "../../Base/Hooks/fetch";

import FixtureRow from "./FixtureRow";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import TableDisplay from "../../Base/Shared/TableDisplay";
import TablePagination from "../../Base/Shared/TablePagination";
import FilterDisplay from "../../Base/Shared/FilterDisplay";
import FormBuilder from "../../Base/Forms/FormBuilder";
import formSetup from "./formSetup";
import { useFilterData } from "../../Base/Hooks/filterdata";
import { useUpdateToken } from "../../Base/Hooks/updateToken";

const Fixtures = (props) => {
  const { title, updateState, history, match, intl } = props;
  const [pageTitle, setPageTitle] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [toggleDebug, setToggleDebug] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [paginationString, setPaginationString] = useState("limit=200");
  const [tableData, setTableData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/fixtures?include=seasons,entities,venues&" +
      paginationString,
    "",
    refreshFlag,
    "location",
    true
  );

  const teams = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/entities?include=entities,venues&limit=200",
    "",
    true
  );

  const venues = useFetch(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + "/venues?limit=200",
    "",
    true,
    "location",
    true
  );

  const [filterFields, setFilterFields] = useState({
    date: "",
    venueName: "",
    teamHome: "",
    teamAway: "",
    fixtureType: "",
    status: "",
    round: ""
  });
  const { filteredData } = useFilterData(initialData, filterFields);

  const columns = [
    "Round",
    "Home Team",
    "Result",
    "Away Team",
    "Venue",
    "Date",
    "Start Time",
    "Type",
    "Status",
    "Live Video",
    ""
  ];

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  useEffect(() => {
    props.updateState("fluid", true);
    return () => {
      props.updateState("fluid", false);
    };
    // eslint-disable-next-line
  }, []);

  function handleFilter(field, value) {
    let newFilter = {
      ...filterFields,
      [field]: value
    };
    setFilterFields(newFilter);
    setRefreshFlag(true);
  }

  const toggleSelect = useCallback((e) => {
    let value = e.target.checked;
    setToggleDebug(value);
  }, []);

  useEffect(() => {
    if (filteredData) {
      if (data && data.includes && data.includes.resources && data.includes.resources.seasons) {
        setPageTitle(data.includes.resources.seasons[match.params.seasonId].nameLocal);
      }

      let tableList = filteredData
        .sort(function (a, b) {
          var aMixed = a.startTimeLocal;
          var bMixed = b.startTimeLocal;
          return aMixed < bMixed ? -1 : 1;
        })
        .map((result, index) => (
          <FixtureRow
            key={index}
            itemIndex={index}
            result={result}
            updateState={updateState}
            match={match}
            resources={data && data.includes && data.includes.resources}
            reloadData={reloadData}
            teams={teams}
            venues={venues}
            pageRef={pageRef}
            toggleDebug={toggleDebug}
          />
        ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, toggleDebug]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);

      setFormData({
        organizationId: match.params.organizationId,
        seasonId: match.params.seasonId,
        entityType: "ENTITY",
        startTimeLocal: "2020-01-01T20:30:00",
        status: "IF_NEEDED",
        fixtureType: "REGULAR"
      });
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data && venues && venues.data) {
      let initialData = data !== null ? data.data : [];
      initialData.forEach((fixture, index) => {
        if (fixture.startTimeLocal) {
          let matchDate = fixture.startTimeLocal.split("T");
          let matchTime = matchDate[1].split(":");
          initialData[index].date = intl.formatDate(matchDate[0]);
          initialData[index].ISOdate = matchDate[0];
          initialData[index].hour = matchTime[0];
          initialData[index].minute = matchTime[1];
        }
        if (fixture.competitors) {
          fixture.competitors.forEach((competitor) => {
            if (competitor.isHome) {
              initialData[index].teamHome = data.includes.resources.entities[competitor.entity.id]
                ? data.includes.resources.entities[competitor.entity.id].nameFullLocal
                : competitor.entityId;
              initialData[index].teamHomeId = competitor.entityId;
            } else {
              initialData[index].teamAway = data.includes.resources.entities[competitor.entity.id]
                ? data.includes.resources.entities[competitor.entity.id].nameFullLocal
                : competitor.entityId;
              initialData[index].teamAwayId = competitor.entityId;
            }
          });
        }
        if (fixture.venueId) {
          initialData[index].timezone =
            venues.data && venues.data.data.find((venue) => venue.venueId === fixture.venueId).timezone;
          initialData[index].venue =
            venues.data && venues.data.data.find((venue) => venue.venueId === fixture.venueId).nameLocal;
        }
      });

      setInitialData(initialData);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, venues]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      <PageDisplay
        title={pageTitle + " Fixtures"}
        error={error}
        pageTitle={pageTitle + " Fixtures - " + title}
        ref={pageRef}
        history={history}
      >
        <>
          {formData !== null && (
            <FormBuilder
              form="NewFixture"
              action={reloadData}
              formSetup={formSetup}
              pageRef={pageRef}
              formData={formData}
            />
          )}
        </>
        <FilterDisplay
          data={initialData}
          display="All Rounds"
          value="roundNumber"
          selectAction={handleFilter}
          format="number"
        />
        <FilterDisplay data={initialData} display="All Home Teams" value="teamHome" selectAction={handleFilter} />
        <FilterDisplay data={initialData} display="All Away Teams" value="teamAway" selectAction={handleFilter} />
        <FilterDisplay
          data={initialData?.filter(
            (v) =>
              data?.includes?.resources?.venues &&
              Object.values(data?.includes?.resources?.venues)
                .filter((v) => v?.status === "ACTIVE")
                .map((v) => v.venueId)
                .includes(v.venueId)
          )}
          display="All Venues"
          value="venue"
          selectAction={handleFilter}
        />
        <FilterDisplay
          data={initialData}
          display="All Dates"
          value="startTimeLocal"
          selectAction={handleFilter}
          mapAfter={intl.formatDate}
          format="date"
        />
        <FilterDisplay data={initialData} display="All Types" value="fixtureType" selectAction={handleFilter} />
        <FilterDisplay data={initialData} display="All Status" value="status" selectAction={handleFilter} />
        <div className="checkbox inline running">
          <label>
            <input type="checkbox" onChange={toggleSelect} />
            <span className="cr">
              <i className="cr-icon fa fa-check" />
            </span>
            <FormattedMessage id="toggle.debug" defaultMessage="Show Advanced Info" description="Show Advanced Info" />
          </label>
        </div>
        <TableDisplay
          containerClass="table-responsive"
          columns={columns}
          rows={tableData}
          loading={loading || venues.loading}
        />
        <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Fixtures);

//https://stackoverflow.com/a/55435856
export function* chunks(arr, n) {
  if (n) {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  } else {
    yield [];
  }
}

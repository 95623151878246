import React, { useRef } from "react";
import { injectIntl } from "react-intl";
import { Spinner } from "reactstrap";
import FormBuilder from "../Base/Forms/FormBuilder";
import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import formSetup from "./formSetup";

function EditMatchProfile(props) {
  const {
    history,
    intl: { formatMessage },
    match: {
      params: { organizationId, sport, matchProfileId }
    }
  } = props;
  const ref = useRef();

  const { data, loading, refetch } = useFetch(
    `/v1/${sport}/o/${organizationId}/fixtureProfiles/${matchProfileId}`,
    "",
    true
  );

  const title = formatMessage({ id: "edit.match.profile" });

  return (
    <PageDisplay title={title} pageTitle={title} history={history} ref={ref}>
      {loading && <Spinner size="md" color="orange" />}
      {data && (
        <FormBuilder
          pageRef={ref}
          form="EditProfile"
          formData={{
            organizationId,
            sport,
            matchProfileId,
            ...data.data[0]
          }}
          formSetup={formSetup}
          action={refetch}
        />
      )}
    </PageDisplay>
  );
}

export default injectIntl(EditMatchProfile);

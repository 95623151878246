const formSetup = {
  forms: {
    CreateConference: {
      title: "Add New Conference",
      titletranslation: "conference.add",
      endpoint: "v1/#sport/o/#0/conferences",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New Conference added successfully.",
      link: {
        show: false,
        message: "View Venue",
        path: "/admin/venues/",
        parameters: []
      },
      submit: "Add New Conference",
      submittranslation: "conference.add",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          labeltranslation: "name.local.abbreviation",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Name Is Required"
          }
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          labeltranslation: "name.latin.abbreviation",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters",
            required: "Local Name Is Required"
          }
        }
      ]
    },
    UpdateConference: {
      title: "Update Conference",
      titletranslation: "conference.update",
      endpoint: "v1/#sport/o/#0/conferences/#1",
      parameters: ["organizationId", "conferenceId"],
      method: "PUT",
      successmessage: "Conference Updated Successfully.",
      link: {
        show: false,
        message: "View Conference",
        path: "/admin/conferences/",
        parameters: []
      },
      submit: "Update",
      submittranslation: "conference.update",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: "nameLocal",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          labeltranslation: "name.local.abbreviation",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          required: false,
          initial: "abbreviationLocal",
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: "nameLatin",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Name Is Required"
          }
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          labeltranslation: "name.latin.abbreviation",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: "abbreviationLatin",
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters",
            required: "Local Name Is Required"
          }
        }
      ]
    }
  }
};

export default formSetup;

import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import FormBuilder from "../Base/Forms/FormBuilder";
import ImageDisplay from "../Base/Images/ImageDisplay";
import ImageUploader from "../Base/Images/ImageUploader";
import formSetup from "./formSetup";
import Colors from "./Colors";

const EditTeam = (props) => {
  const { title, history, match, intl, updateState } = props;
  const { formatMessage } = intl;
  const [refreshData, setRefreshData] = useState(true);
  const [current, setCurrent] = useState(null);
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/entities/" +
      match.params.teamId +
      "?include=organizations, entityGroups",
    "",
    refreshData
  );

  let LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];

  useEffect(() => {
    if (data) {
      setRefreshData(false);
      setCurrent(data.data[0]);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [match]);

  function reloadData() {
    setRefreshData(true);
  }

  return (
    <PageDisplay
      title={formatMessage({
        id: "team.update",
        defaultMessage: "Update Team"
      })}
      error={error}
      pageTitle={current && current.nameFullLocal + " -" + title}
      ref={pageRef}
      history={history}
    >
      {loading && <Spinner size="md" color="orange" />}
      {current && (
        <div>
          <h4>{current && current.nameFullLocal}</h4>
          {current && (
            <FormBuilder
              form="EditTeam"
              action={reloadData}
              formSetup={formSetup}
              formData={current}
              pageRef={pageRef}
              lookupData={LookupData}
              customFields={{ colors: Colors }}
            />
          )}
          {error}
          <br />
          <hr />
          <h3>
            {formatMessage({
              id: "images.manage",
              defaultMessage: "Manage Images"
            })}
          </h3>
          <div className="flex team-images">
            <div className="flex flex-column">
              <h6>
                {formatMessage({ id: "primary", defaultMessage: "Primary" }) +
                  " " +
                  formatMessage({
                    id: "images.logo",
                    defaultMessage: "Logo"
                  })}
              </h6>
              <div className="flex">
                <ImageDisplay
                  data={current}
                  images={current.images}
                  imageType="LOGO"
                  pageRef={pageRef}
                  setRefreshFlag={setRefreshData}
                  checkQuality={true}
                />
                <ImageUploader
                  data={current}
                  type={"LOGO"}
                  element={"entityId"}
                  imageFor={"ENTITY"}
                  pageRef={pageRef}
                  setRefreshFlag={setRefreshData}
                  checkQuality={true}
                />
              </div>
            </div>
            <div className="flex flex-column">
              <h6>
                {formatMessage({
                  id: "secondary",
                  defaultMessage: "Secondary"
                }) +
                  " " +
                  formatMessage({
                    id: "images.logo",
                    defaultMessage: "Logo"
                  })}
              </h6>
              <div className="flex">
                <ImageDisplay
                  data={current}
                  images={current.images}
                  imageType="LOGO_ALTERNATE"
                  pageRef={pageRef}
                  setRefreshFlag={setRefreshData}
                  checkQuality={true}
                />
                <ImageUploader
                  data={current}
                  type={"LOGO_ALTERNATE"}
                  element={"entityId"}
                  imageFor={"ENTITY"}
                  pageRef={pageRef}
                  setRefreshFlag={setRefreshData}
                  checkQuality={true}
                />
              </div>
            </div>
            <div className="flex flex-column">
              <h6>
                {formatMessage({
                  id: "images.background",
                  defaultMessage: "Background"
                }) +
                  " " +
                  formatMessage({
                    id: "image",
                    defaultMessage: "Image"
                  })}
              </h6>
              <div className="flex">
                <ImageDisplay
                  data={current}
                  images={current.images}
                  imageType="LOGO_BACKGROUND"
                  pageRef={pageRef}
                  setRefreshFlag={setRefreshData}
                  checkQuality={true}
                />
                <ImageUploader
                  data={current}
                  type={"LOGO_BACKGROUND"}
                  element={"entityId"}
                  imageFor={"ENTITY"}
                  pageRef={pageRef}
                  setRefreshFlag={setRefreshData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(EditTeam);

import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";

import { FormattedMessage, injectIntl } from "react-intl";
import { useFetch } from "../../Base/Hooks/fetch";
import { Spinner } from "reactstrap";

const FinderSelect = (props) => {
  const {
    updateFinderState,
    organizationId,
    url,
    value,
    translation,
    template,
    variables,
    include,
    includeId,
    includeVariable,
    buttons,
    match,
    Filters
  } = props;
  // const { formatMessage } = intl;
  const { error, loading, data } = useFetch("/v1/" + match.params.sport + "/o/" + organizationId + url, "", true);

  const FinderOptions = useMemo(() => {
    if (data && value && !error) {
      return (
        <>
          <option value="">--</option>
          {data.data
            .sort(function (a, b) {
              var aMixed = buildText(a);
              var bMixed = buildText(b);
              return aMixed < bMixed ? -1 : 1;
            })
            .map((item, index) => (
              <option value={item[value]} key={item[value]}>
                {buildText(item)}
              </option>
            ))}
        </>
      );
    }
    // eslint-disable-next-line
  }, [data, value, error]);

  function buildText(item) {
    let text = template;
    variables.forEach((variable, key) => {
      text = text.replace("#" + key, item[variable]);
    });
    if (include) {
      text = text.replace("#include", data.includes.resources[include][item[includeId]["id"]][includeVariable]);
    }
    return text;
  }

  function handleChange(e, field) {
    updateFinderState(field, e.target.value);
  }

  return (
    <div className="finder-select">
      {loading && <Spinner color="danger" size="sm" />}
      {!loading && FinderOptions && (
        <div className="flex">
          {Filters && <Filters />}
          <div>
            <h5>
              <FormattedMessage id={translation} />
            </h5>
            <select onChange={(e) => handleChange(e, value)}>{FinderOptions}</select>
          </div>
        </div>
      )}
      <br />
      {buttons}
    </div>
  );
};

export default withRouter(injectIntl(FinderSelect));

import React, { useCallback, useRef } from "react";
import { useFetch } from "../../Base/Hooks/fetch";
import StaffCandidates from "./Candidates/StaffCandidates";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import StaffMembers from "./Members/StaffMembers";
import { Spinner } from "reactstrap";
import FormCollapse from "../../Base/Forms/FormCollapse";
import { injectIntl } from "react-intl";

function Staff(props) {
  const {
    history,
    match: {
      params: { sport, organizationId, entityId }
    },
    intl: { formatMessage }
  } = props;
  const pageRef = useRef();

  const staffQuery = new URLSearchParams({
    entityId,
    hideNull: true,
    include: ["persons", "entities"],
    limit: 30
  });

  const rosterURL = `/v1/${sport}/o/${organizationId}/roles?${staffQuery}`;
  const { data: roles, loading, refetch } = useFetch(rosterURL, "", true);

  const Form = useCallback(<StaffCandidates {...props} refetch={refetch} pageRef={pageRef} />, [roles]);

  const getTeamName = () => roles?.includes?.resources.entities[`${organizationId}:${entityId}`].nameFullLocal || "";

  const getPageTitle = () =>
    `${getTeamName()} ${formatMessage({
      id: "staff",
      defaultMessage: "staff"
    })}`;

  return (
    <PageDisplay ref={pageRef} history={history} title={getPageTitle()} pageTitle={getPageTitle()}>
      {loading && <Spinner size="md" color="orange" />}
      {roles && !loading && (
        <>
          <StaffMembers {...props} pageRef={pageRef} refetch={refetch} roles={roles} />
        </>
      )}
      {roles && (
        <FormCollapse closedId="staff.add" closedDefaultMessage="Add New Staff Member">
          {Form}
        </FormCollapse>
      )}
    </PageDisplay>
  );
}

export default injectIntl(Staff);

import React, { useEffect, useState, useRef } from "react";
import { injectIntl } from "react-intl";

import { useFetch } from "../../Base/Hooks/fetch";

import TeamImageRow from "./TeamImageRow";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import TableDisplay from "../../Base/Shared/TableDisplay";
import TablePagination from "../../Base/Shared/TablePagination";
import SearchFilter from "../../Base/Shared/SearchFilter";
import { useUpdateToken } from "../../Base/Hooks/updateToken";

const TeamImages = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationString, setPaginationString] = useState("limit=25&offset=0");

  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/entities?include=organizations,entities,seasons&" +
      paginationString,
    "",
    refreshFlag
  );

  const columns = [
    formatMessage({
      id: "images.logo",
      defaultMessage: "Logo"
    }),
    "",
    formatMessage({
      id: "images.background",
      defaultMessage: "Background"
    }),
    "",
    ""
  ];

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data) {
      if (data && data.includes && data.includes.resources && data.includes.resources.organizations) {
        setPageTitle(data.includes.resources.seasons[match.params.seasonId].nameLocal);
      }

      let filteredData = data !== null ? data.data : [];

      filteredData.forEach((result, index) => {
        filteredData[index] = {
          ...result,
          ...data.includes.resources.entities[result.entity.id]
        };
        filteredData[index].status = result.status;
      });

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.nameFullLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }
      let tableList = filteredData.map((result, index) => {
        return (
          <TeamImageRow
            key={index}
            itemIndex={index}
            result={result}
            updateState={updateState}
            match={match}
            pageRef={pageRef}
            setRefreshFlag={reloadData}
            reloadData={reloadData}
          />
        );
      });
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), searchText]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      <PageDisplay
        title={
          pageTitle +
          " - " +
          formatMessage({
            id: "team.images",
            defaultMessage: "Team Images"
          })
        }
        error={error}
        pageTitle={
          pageTitle +
          " - " +
          formatMessage({
            id: "team.images",
            defaultMessage: "Team Images"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
      >
        <SearchFilter doSearch={setSearchText} />
        <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading && false} />
        <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(TeamImages);

import { rulesList } from "./Rules/ComparisonRules";
import { getTranslatedFields } from "./StatisticFields";

export const CreateQualifier = ({ formatMessage, messages } = {}) => {
  const fields = getTranslatedFields({ formatMessage, messages });
  return {
    forms: {
      CreateQualifier: {
        endpoint: "v1/#sport/o/#0/statistics/leaders/criteria/#1/qualifiers",
        parameters: ["organizationId", "leaderCriteriaId"],
        method: "POST",
        successmessage: "Leader Qualification Criteria created successfully",
        submit: "Add",
        submittranslation: "add",
        fields: [
          {
            name: "statisticField",
            size: "col-sm-4 col",
            label: "Statistic Field",
            labeltranslation: "statistic.field",
            type: "select",
            placeholder: "What Statistic Field to check?",
            className: "form-control capitalize",
            required: true,
            initial: null,
            default: fields[0].value,
            options: fields,
            errors: {
              required: "Statistic Field Is Required"
            }
          },
          {
            name: "comparisonField",
            size: "col-sm-4 col",
            label: "Comparison Field",
            labeltranslation: "comparison.field",
            type: "select",
            className: "form-control capitalize",
            placeholder: "What criteria field to check?",
            required: true,
            initial: null,
            default: fields[0].value,
            options: fields,
            errors: {
              required: "Comparison Field Is Required"
            }
          },
          {
            name: "comparisonType",
            size: "col-sm-4 col",
            label: "Comparison Type",
            labeltranslation: "comparison.type",
            type: "select",
            className: "form-control",
            required: true,
            default: "EQUAL",
            initial: null,
            options: rulesList,
            errors: {
              required: "Grouping Stage/Pool Is Required"
            }
          },
          {
            name: "comparisonValue",
            size: "col-sm-4 col",
            label: "Comparison Value",
            labeltranslation: "comparison.value",
            type: "number",
            placeholder: "Numeric value",
            className: "form-control",
            required: true,
            initial: null,
            min: 0,
            errors: {
              required: "Comparison Value Is Required"
            }
          }
        ]
      }
    }
  };
};

export default {
  forms: {
    CreateTemplate: {
      endpoint: "v1/#sport/o/#0/statistics/leaders/criteria",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "Leader Qualification Template created successfully",
      submit: "Add",
      submittranslation: "add",
      fields: [
        {
          name: "name",
          size: "col-sm-4 col",
          label: "Name",
          labeltranslation: "name",
          type: "text",
          placeholder: "Template name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Name Is Required"
          }
        }
      ]
    }
  }
};

import React from "react";
import PersonList from "../../../Persons/PersonList";
import usePersonsAPI from "../../../Persons/PersonsAPI";
import StaffCandidate from "./StaffCandidate";

export default function StaffCandidates(props) {
  const personsAPI = usePersonsAPI({ ...props, limit: 10 });

  return (
    <PersonList
      {...props}
      columns={[
        { id: "name.local.full", defaultMessage: "Local Full Name" },
        { id: "role", defaultMessage: "Role" }
      ]}
      personsAPI={personsAPI}
      PersonRowComponent={StaffCandidate}
    />
  );
}

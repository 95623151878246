import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";

const buildOptions = [
  { option: "OVERALL", evaluator: "buildOverall" },
  { option: "CONFERENCE", evaluator: "buildConferences" },
  { option: "DIVISION", evaluator: "buildDivisions" }
];

function StageSelector(props) {
  const { queryParameters, setQueryParameters, initialBuildRule, standingsConfiguration } = props;
  const [selectedBuildOption, setSelectedBuildOption] = useState(initialBuildRule);
  useEffect(() => {
    if (selectedBuildOption) {
      const searchParameters = new URLSearchParams(queryParameters);
      searchParameters.set("groupingConferenceDivision", selectedBuildOption);
      setQueryParameters(searchParameters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuildOption]);

  function handleBuildOptionChange(event) {
    setSelectedBuildOption(event.target.value);
  }

  function renderBuildOption({ option, evaluator }) {
    if (!standingsConfiguration.buildRules[evaluator]) {
      return;
    }
    return (
      <option key={option} value={option}>
        {option}
      </option>
    );
  }

  return (
    <FormGroup>
      <Label>
        <FormattedMessage id="grouping" defaultMessage="Grouping" />
      </Label>
      <Input type="select" value={selectedBuildOption} onChange={handleBuildOptionChange}>
        {buildOptions.map(renderBuildOption)}
      </Input>
    </FormGroup>
  );
}

export default injectIntl(StageSelector);

import React, { useRef, useEffect, useState } from "react";
import { useFetch } from "../../Base/Hooks/fetch";
import formSetup from "./formSetup";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import FormBuilder from "../../Base/Forms/FormBuilder";
import { Spinner, Col, Row } from "reactstrap";

function EditFixture(props) {
  const { title, updateState, history, match } = props;

  const [currentFixture, setCurrentFixture] = useState();
  const [refreshData, setRefreshData] = useState(true);
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/fixtures/" +
      match.params.fixtureId +
      "?include=entities",
    refreshData
  );

  function assignEntityData(fixture, entities) {
    return {
      ...fixture,
      competitors: fixture.competitors
        .map((c) => ({
          ...entities[c.entity.id],
          ...c
        }))
        // display home team first (to the left)
        .sort((a, b) => b.isHome - a.isHome)
    };
  }

  function composeFixtureTitle() {
    if (!currentFixture) {
      return;
    }
    return currentFixture.competitors.map((c) => c.nameFullLocal).join(" vs ");
  }

  useEffect(() => {
    if (data) {
      setCurrentFixture({
        seasonId: match.params.seasonId,
        ...assignEntityData(data.data[0], data?.includes?.resources.entities)
      });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);
    }
    // eslint-disable-next-line
  }, [match]);

  function reloadData() {
    setRefreshData(true);
  }

  function TeamForms() {
    return currentFixture.competitors.map((c) => (
      <Col key={c.entityId}>
        <h3>{c.nameFullLocal}</h3>
        <FormBuilder
          form="EditFixtureEntity"
          action={reloadData}
          formSetup={formSetup}
          formData={c}
          pageRef={pageRef}
        />
      </Col>
    ));
  }

  const pageRef = useRef();
  return (
    <PageDisplay
      title={composeFixtureTitle()}
      pageTitle={`${composeFixtureTitle()} - ${title}`}
      ref={pageRef}
      history={history}
    >
      {loading && <Spinner />}
      {currentFixture && (
        <>
          <FormBuilder
            form="EditFixture"
            action={reloadData}
            formSetup={formSetup}
            formData={currentFixture}
            pageRef={pageRef}
          />
          <Row>
            <TeamForms />
          </Row>
        </>
      )}
      {error}
    </PageDisplay>
  );
}

export default EditFixture;

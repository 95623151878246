var formSetup = {
  forms: {
    NewTeam: {
      title: "Add New Team",
      titletranslation: "team.add",
      endpoint: "v1/#sport/o/#0/entities",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New Team added successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: []
      },
      submit: "Add New Team",
      submittranslation: "team.add",
      fields: [
        {
          name: "nameFullLocal",
          size: "col-sm-4 col",
          label: "Local Full Name:",
          labeltranslation: "name.local.full",
          type: "text",
          placeholder: "Local Full Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Local Full Name Is Required"
          }
        },
        {
          name: "codeLocal",
          size: "col-sm-2 col",
          label: "Local Code:",
          labeltranslation: "name.local.code",
          type: "text",
          placeholder: "Local Code",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 5,
          errors: {
            maxLength: "Max 5 characters",
            required: "Local Code Is Required"
          }
        },
        {
          name: "nameFullLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin.full",
          type: "text",
          placeholder: "Latin Full Name",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Full Name Is Required"
          }
        },
        {
          name: "codeLatin",
          size: "col-sm-2 col",
          label: "Latin Code:",
          labeltranslation: "name.latin.code",
          type: "text",
          placeholder: "Latin Code",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 5,
          errors: {
            maxLength: "Max 5 characters",
            required: "Latin Code Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "entityGroupId",
          size: "col-sm-4 col",
          label: "Club:",
          labeltranslation: "club",
          type: "lookup",
          placeholder: "Club",
          className: "form-control",
          required: false,
          initial: "null",
          lookup: {
            endpoint: "v1/#sport/o/#0/entityGroups?limit=200",
            text: "nameFullLocal",
            value: "entityGroupId",
            parameters: ["organizationId"]
          },
          errors: {
            required: "Club Is Required"
          }
        },
        {
          name: "internationalReference",
          size: "col-sm-4 col",
          label: "International Reference",
          labeltranslation: "international.reference",
          type: "text",
          placeholder: "",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "International Reference Is Required"
          }
        },
        {
          name: "defaultVenueId",
          size: "col-sm-4 col",
          label: "Default Venue:",
          labeltranslation: "venue.default",
          type: "lookup",
          placeholder: "Default Venue",
          className: "form-control",
          required: false,
          initial: null,
          lookup: {
            endpoint: "v1/#sport/o/#0/venues?limit=200&offset=0",
            text: "nameLocal",
            value: "venueId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "colors",
          size: "col-sm-12 col",
          label: "Colors",
          labeltranslation: "colors",
          customItem: "colors",
          type: "custom",
          className: "form-control",
          required: false,
          initial: null
        },
        {
          name: "social",
          size: "col-sm-12 col",
          type: "subFields",
          label: "Social",
          placeholder: "",
          className: "form-control",
          initial: "",
          options: [
            {
              name: "website",
              size: "col-sm-4 col",
              label: "Website",
              labeltranslation: "website",
              type: "text",
              placeholder: "http(s)://yourwebsite.domain",
              className: "form-control",
              initial: null,
              pattern: "https?://(www)?([A-Z0-9a-z._-])+"
            },
            {
              name: "facebook",
              size: "col-sm-4 col",
              label: "Facebook",
              type: "text",
              placeholder: "https://www.facebook.com/username",
              className: "form-control",
              initial: null,
              pattern: "^(https://)?(www.)?facebook.com/[A-Za-z0-9.]+$"
            },
            {
              name: "twitter",
              size: "col-sm-4 col",
              label: "Twitter",
              type: "text",
              placeholder: "https://twitter.com/username",
              className: "form-control",
              initial: null,
              pattern: "^(https://)?(www.)?twitter.com/[A-Za-z0-9_]+$"
            },
            {
              name: "instagram",
              size: "col-sm-4 col",
              label: "Instagram",
              type: "text",
              placeholder: "https://www.instagram.com/username",
              className: "form-control",
              initial: null,
              pattern: "^(https://)?(www.)?instagram.com/[A-Za-z0-9_.]+$"
            }
          ]
        }
      ]
    },
    EditTeam: {
      title: "Edit Team",
      titletranslation: "team.update",
      endpoint: "v1/#sport/o/#0/entities/#1",
      parameters: ["organizationId", "entityId"],
      method: "PUT",
      successmessage: "Team updated successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/configuration/teams/",
        parameters: []
      },
      submit: "Update Team",
      submittranslation: "team.update",
      fields: [
        {
          name: "nameFullLocal",
          size: "col-sm-4 col",
          label: "Local Full Name:",
          labeltranslation: "name.local.full",
          type: "text",
          placeholder: "Local Full Name",
          className: "form-control",
          required: true,
          initial: "nameFullLocal",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Full Name Is Required"
          }
        },
        {
          name: "codeLocal",
          size: "col-sm-2 col",
          label: "Local Code:",
          labeltranslation: "name.local.code",
          type: "text",
          placeholder: "Local Code",
          className: "form-control",
          required: false,
          initial: "codeLocal",
          maxLength: 5,
          errors: {
            maxLength: "Max 5 characters",
            required: "Local Code Is Required"
          }
        },
        {
          name: "nameFullLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin.full",
          type: "text",
          placeholder: "Latin Full Name",
          className: "form-control",
          required: false,
          initial: "nameFullLatin",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Full Name Is Required"
          }
        },
        {
          name: "codeLatin",
          size: "col-sm-2 col",
          label: "Latin Code:",
          labeltranslation: "name.latin.code",
          type: "text",
          placeholder: "Latin Code",
          className: "form-control",
          required: false,
          initial: "codeLatin",
          maxLength: 5,
          errors: {
            maxLength: "Max 5 characters",
            required: "Latin Code Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: "status",
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "entityGroupId",
          size: "col-sm-4 col",
          label: "Club:",
          labeltranslation: "club",
          type: "lookup",
          placeholder: "Club",
          className: "form-control",
          required: false,
          initial: "entityGroupId",
          lookup: {
            endpoint: "v1/#sport/o/#0/entityGroups",
            text: "nameFullLocal",
            value: "entityGroupId",
            parameters: ["organizationId"]
          },
          errors: {
            required: "Club Is Required"
          }
        },
        {
          name: "internationalReference",
          size: "col-sm-4 col",
          label: "International Reference",
          labeltranslation: "international.reference",
          type: "text",
          placeholder: "",
          className: "form-control",
          required: false,
          initial: "internationalReference",
          errors: {
            required: "International Reference Is Required"
          }
        },
        {
          name: "defaultVenueId",
          size: "col-sm-4 col",
          label: "Default Venue:",
          labeltranslation: "venue.default",
          type: "lookup",
          placeholder: "Default Venue",
          className: "form-control",
          required: false,
          initial: "defaultVenueId",
          treatFalseAsNull: true,
          lookup: {
            endpoint: "v1/#sport/o/#0/venues?limit=200&offset=0",
            text: "nameLocal",
            value: "venueId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "colors",
          size: "col-sm-12 col",
          label: "Colors",
          labeltranslation: "colors",
          customItem: "colors",
          type: "custom",
          className: "form-control",
          required: false,
          initial: "colors"
        },
        {
          name: "social",
          size: "col-sm-12 col",
          type: "subFields",
          label: "Social",
          placeholder: "",
          className: "form-control",
          initial: "",
          options: [
            {
              name: "website",
              size: "col-sm-4 col",
              label: "Website",
              labeltranslation: "website",
              type: "text",
              placeholder: "http(s)://yourwebsite.domain",
              className: "form-control",
              initial: "website",
              pattern: "https?://(www)?([A-Z0-9a-z._-])+"
            },
            {
              name: "facebook",
              size: "col-sm-4 col",
              label: "Facebook",
              type: "text",
              placeholder: "https://www.facebook.com/username",
              className: "form-control",
              initial: "facebook",
              pattern: "^(https://)?(www.)?facebook.com/[A-Za-z0-9.]+$"
            },
            {
              name: "twitter",
              size: "col-sm-4 col",
              label: "Twitter",
              type: "text",
              placeholder: "https://twitter.com/username",
              className: "form-control",
              initial: "twitter",
              pattern: "^(https://)?(www.)?twitter.com/[A-Za-z0-9_]+$"
            },
            {
              name: "instagram",
              size: "col-sm-4 col",
              label: "Instagram",
              type: "text",
              placeholder: "https://www.instagram.com/username",
              className: "form-control",
              initial: "instagram",
              pattern: "^(https://)?(www.)?instagram.com/[A-Za-z0-9_.]+$"
            }
          ]
        }
      ]
    }
  }
};
export default formSetup;

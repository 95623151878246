import React from "react";

const LookupDataField = (props) => {
  const { lookup, lookupData, nullPlaceholder = "-" } = props;
  const selectData = lookupData[lookup];

  const maskOutput = ([value, placeholder]) => (value ? `${placeholder} - (${value})` : nullPlaceholder);

  return (
    <React.Fragment>
      {selectData &&
        selectData.map((option, index) => {
          return Array.isArray(option) ? (
            <option value={option[0]} key={index}>
              {maskOutput(option)}
            </option>
          ) : (
            <option value={option} key={index}>
              {option}
            </option>
          );
        })}
    </React.Fragment>
  );
};

export default LookupDataField;

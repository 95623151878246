var formSetup = {
  forms: {
    NewClub: {
      title: "Add New Club",
      titletranslation: "club.add",
      endpoint: "v1/#sport/o/#0/entityGroups",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New Club added successfully.",
      link: {
        show: false,
        message: "View Club",
        path: "/admin/clubs/",
        parameters: []
      },
      submit: "Add New Club",
      submittranslation: "club.add",
      fields: [
        {
          name: "nameFullLocal",
          size: "col-sm-4 col",
          label: "Local Full Name:",
          labeltranslation: "name.local.full",
          type: "text",
          placeholder: "Local Full Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Local Full Name Is Required"
          }
        },
        {
          name: "codeLocal",
          size: "col-sm-2 col",
          label: "Local Code:",
          labeltranslation: "name.local.code",
          type: "text",
          placeholder: "Local Code",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 5,
          errors: {
            maxLength: "Max 5 characters",
            required: "Local Code Is Required"
          }
        },
        {
          name: "nameFullLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin.full",
          type: "text",
          placeholder: "Latin Full Name",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Full Name Is Required"
          }
        },
        {
          name: "codeLatin",
          size: "col-sm-2 col",
          label: "Latin Code:",
          labeltranslation: "name.latin.code",
          type: "text",
          placeholder: "Latin Code",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 5,
          errors: {
            maxLength: "Max 5 characters",
            required: "Latin Code Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-6 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "internationalReference",
          size: "col-sm-6 col",
          label: "International Reference",
          labeltranslation: "international.reference",
          type: "text",
          placeholder: "",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "International Reference Is Required"
          }
        }
      ]
    },
    EditClub: {
      title: "Edit Club",
      titletranslation: "club.update",
      endpoint: "v1/#sport/o/#0/entityGroups/#1",
      parameters: ["organizationId", "entityGroupId"],
      method: "PUT",
      successmessage: "Club updated successfully.",
      link: {
        show: false,
        message: "View Club",
        path: "/configuration/clubs/",
        parameters: []
      },
      submit: "Update Club",
      submittranslation: "club.update",
      fields: [
        {
          name: "nameFullLocal",
          size: "col-sm-4 col",
          label: "Local Full Name:",
          labeltranslation: "name.local.full",
          type: "text",
          placeholder: "Local Full Name",
          className: "form-control",
          required: true,
          initial: "nameFullLocal",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Full Name Is Required"
          }
        },
        {
          name: "codeLocal",
          size: "col-sm-2 col",
          label: "Local Code:",
          labeltranslation: "name.local.code",
          type: "text",
          placeholder: "Local Code",
          className: "form-control",
          required: false,
          initial: "codeLocal",
          maxLength: 5,
          errors: {
            maxLength: "Max 5 characters",
            required: "Local Code Is Required"
          }
        },
        {
          name: "nameFullLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin.full",
          type: "text",
          placeholder: "Latin Full Name",
          className: "form-control",
          required: false,
          initial: "nameFullLatin",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Full Name Is Required"
          }
        },
        {
          name: "codeLatin",
          size: "col-sm-2 col",
          label: "Latin Code:",
          labeltranslation: "name.latin.code",
          type: "text",
          placeholder: "Latin Code",
          className: "form-control",
          required: false,
          initial: "codeLatin",
          maxLength: 5,
          errors: {
            maxLength: "Max 5 characters",
            required: "Latin Code Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-6 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: "status",
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "internationalReference",
          size: "col-sm-6 col",
          label: "International Reference",
          labeltranslation: "international.reference",
          type: "text",
          placeholder: "",
          className: "form-control",
          required: false,
          initial: "internationalReference",
          errors: {
            required: "International Reference Is Required"
          }
        }
      ]
    }
  }
};
export default formSetup;

import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";

import countryCodes from "../../config/countryCodes";
import timeZones from "../../config/timeZones";

const EditVenue = (props) => {
  const { title, history, match, intl } = props;
  const { formatMessage } = intl;
  const [refreshData, setRefreshData] = useState(true);
  const [current, setCurrent] = useState(null);
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" + match.params.sport + "/o/" + match.params.organizationId + "/venues/" + match.params.venueId,
    "",
    refreshData
  );
  const LookupData = [];
  LookupData["countryCodes"] = countryCodes;
  LookupData["timeZones"] = timeZones;
  LookupData["status"] = ["ACTIVE", "INACTIVE"];

  useEffect(() => {
    if (data) {
      setRefreshData(false);
      setCurrent(data.data[0]);
    }
    // eslint-disable-next-line
  }, [data]);

  function reloadData() {
    setRefreshData(true);
  }

  return (
    <PageDisplay
      title={formatMessage({
        id: "venue.update",
        defaultMessage: "Update Venue"
      })}
      error={error}
      pageTitle={current && current.nameLocal + " -" + title}
      ref={pageRef}
      history={history}
    >
      {loading && <Spinner size="md" color="orange" />}
      {data && (
        <div>
          <h4>{current && current.nameLocal}</h4>
          {current && (
            <FormBuilder
              form="EditVenue"
              action={reloadData}
              formSetup={formSetup}
              formData={current}
              pageRef={pageRef}
              lookupData={LookupData}
            />
          )}
          {error}
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(EditVenue);

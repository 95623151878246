import { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useToken } from "./token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
const defaultOpts = { method: "GET", values: null };

export const useFetch = (
  input,
  opts = defaultOpts,
  refreshFlag = true,
  location = "location",
  all = false,
  manual = false
) => {
  const { token } = useToken();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(!manual);
  const [data, setData] = useState(null);
  const [revision, setRevision] = useState(1);
  const revisionRef = useRef(1);
  const { readBody = (body) => body.json() } = opts;
  const env = useContext(EnvironmentContext);
  const abortController = new AbortController();
  const signal = abortController.signal;

  const [url, setURL] = useState(input);
  const [updatedValues, setUpdatedValues] = useState(null);
  const [isControlled, setIsControlled] = useState(false);

  useEffect(() => {
    if (!manual || (manual && revision !== revisionRef.current)) {
      (async () => {
        let outputURL = environments[location][env] + (isControlled ? url : input);
        const allData = await getAllData(outputURL);
        if (typeof allData !== "undefined") {
          setData(allData);
        }
      })();
      if (manual) {
        revisionRef.current = revision;
      }
    }
    // eslint-disable-next-line
  }, [url, input, opts, refreshFlag, token, revision]);

  const getData = useCallback(
    async function (url) {
      if (url && refreshFlag && token) {
        setLoading(true);
        let tokenType = token.tokenType.charAt(0).toUpperCase() + token.tokenType.substr(1).toLowerCase();
        try {
          const response = await fetch(url, {
            method: opts.method,
            headers: {
              Authorization: tokenType + " " + token.token,
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            signal: signal,
            body: JSON.stringify(updatedValues ? updatedValues : opts.values)
          });
          if (response.ok) {
            const body = await readBody(response);
            setLoading(false);
            return body;
          } else {
            const body = await readBody(response);
            console.log("error", body);
            setError(body.message || body.error?.message || body.Message);
            setLoading(false);
          }
        } catch (e) {
          console.error("E", e);
        }
      }
    },
    [updatedValues, opts.method, opts.values, readBody, refreshFlag, signal, token]
  );

  const getAllData = async function (url) {
    try {
      const results = await getData(url);
      if (results) {
        if (results.links && results.links.next && all) {
          return combineData(results, await getAllData(results.links.next));
        } else {
          return results;
        }
      }
    } catch (e) {
      console.error("E", e);
    }
  };

  function combineData(currentData, addedData) {
    if (currentData.includes && addedData.includes) {
      let includes = currentData.includes.resources;
      // Checl for resources in this part of the data
      if (currentData.includes.resources && addedData.includes.resources) {
        includes = mergeIncludes(currentData.includes.resources, addedData.includes.resources);
        currentData.includes.resources = includes;
      }
    }
    let data = currentData.data.concat(addedData.data);
    currentData.data = data;
    return currentData;
  }

  function mergeIncludes(target, source) {
    let returnObj = {};
    Object.keys(target).forEach((key) => {
      returnObj[key] = Object.assign(target[key], source[key]);
    });
    return returnObj;
  }

  useEffect(() => {
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  /**
   * Manually trigger the fetch to update the data
   */
  const refetch = () => setRevision(Date.now());

  const updateURL = (newURL) => {
    if (!isControlled) {
      setIsControlled(true);
    }
    setURL(newURL);
  };

  const updateValues = (newValues) => {
    setUpdatedValues(newValues);
  };

  return { error, loading, data, refetch, updateURL, updateValues };
};

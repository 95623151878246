import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";

import { Button } from "reactstrap";

import { useToken } from "../../Base/Hooks/token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
import { injectIntl } from "react-intl";

const Lock = (props) => {
  const {
    data,
    pageRef,
    action,
    match,
    intl: { formatMessage }
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { readBody = (body) => body.json() } = {};
  const env = useContext(EnvironmentContext);
  const { token } = useToken();

  function submit() {
    (async () => {
      setIsLoading(true);
      if (data) {
        let endpoint =
          "v1/" +
          match.params.sport +
          "/o/" +
          data.organizationId +
          "/fixtures/" +
          data.fixtureId +
          (data.locked ? "/unlock" : "/lock");
        let url = environments["location"][env] + "/" + endpoint;

        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token.token
            },
            body: JSON.stringify({})
          });
          if (response.ok) {
            // const body = await readBody(response);
            // console.log("ok", body);
            pageRef.current.addFlash("Fixture updated successfully", "success", 10);
            // }
            action();
            setIsLoading(false);
          } else {
            const body = await readBody(response);
            console.log("error", body);
            pageRef.current.addFlash("An error occurred", "danger", 10);
            setIsLoading(false);
          }
        } catch (e) {
          console.log("E", e);
          pageRef.current.addFlash("An error occurred", "danger", 10);
          setIsLoading(false);
        }
      }
    })();
  }

  const title = formatMessage({
    id: data.locked ? "match.unlock" : "match.lock",
    defaultMessage: data.locked ? "Lock Match" : "Unlock Match"
  });

  return (
    <Button
      className={"loading-" + isLoading}
      outline
      title={title}
      color="secondary"
      size="sm"
      onClick={() => submit()}
    >
      {data.locked ? <i className="fas fa-lock-open"></i> : <i className="fas fa-lock"></i>}
    </Button>
  );
};

export default injectIntl(withRouter(Lock));

var formSetup = {
  forms: {
    NewSeason: {
      title: "Add New Season",
      titletranslation: "season.add",
      endpoint: "v1/#sport/o/#0/competitions/#1/seasons",
      parameters: ["organizationId", "competitionId"],
      method: "POST",
      successmessage: "New Season added successfully.",
      link: {
        show: false,
        message: "View Season",
        path: "/admin/competitions/",
        parameters: []
      },
      submit: "Add New Season",
      submittranslation: "season.add",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Name Is Required"
          }
        },
        {
          name: "nameShortLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          labeltranslation: "abbreviationLocal",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          initial: null,
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters"
          }
        },
        {
          name: "nameShortLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          labeltranslation: "abbreviationLatin",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          initial: null,
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters"
          }
        },
        {
          name: "year",
          size: "col-sm-2 col",
          label: "Year:",
          labeltranslation: "year",
          type: "lookupData",
          lookupData: "year",
          placeholder: "Year",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Year Is Required"
          }
        },
        {
          name: "seasonType",
          size: "col-sm-3 col",
          label: "Season Type:",
          labeltranslation: "season.type",
          type: "lookupData",
          placeholder: "Season Type",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "seasonType",
          errors: {
            required: "Season Type Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-7 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "startDate",
          size: "col-sm-3 col",
          label: "Start Date:",
          labeltranslation: "date.start",
          type: "date",
          placeholder: "Start Date",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Start Date Is Required"
          }
        },
        {
          name: "endDate",
          size: "col-sm-3 col",
          label: "End Date:",
          labeltranslation: "date.end",
          type: "date",
          placeholder: "End Date",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "End Date Is Required"
          }
        },
        {
          name: "gender",
          size: "col-sm-3 col",
          label: "Gender:",
          labeltranslation: "gender",
          type: "lookupData",
          placeholder: "Gender",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "gender",
          errors: {
            required: "Gender Is Required"
          }
        },
        {
          name: "ageGroup",
          size: "col-sm-3 col",
          label: "Age Group:",
          labeltranslation: "ageGroup",
          type: "lookupData",
          placeholder: "Age Group",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "ageGroup",
          errors: {
            required: "Age Group Is Required"
          }
        },
        {
          name: "standard",
          size: "col-sm-3 col",
          label: "Standard:",
          labeltranslation: "standard",
          type: "lookupData",
          placeholder: "Standard",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "standards",
          errors: {
            required: "Standard Is Required"
          }
        },
        {
          name: "representation",
          size: "col-sm-3 col",
          label: "Representation:",
          labeltranslation: "representation",
          type: "lookupData",
          placeholder: "Representation",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "representation",
          errors: {
            required: "Representation Is Required"
          }
        },
        {
          name: "standingConfigurationId",
          size: "col-sm-3 col",
          label: "Standings Configuration:",
          labeltranslation: "standings.configuration",
          type: "lookup",
          placeholder: "Standings Configurations",
          className: "form-control",
          treatFalseAsNull: true,
          initial: "",
          exploreResolver: ({ currentFormValues, organizationId, sport }) =>
            currentFormValues.standingConfigurationId
              ? `/admin/${sport}/${organizationId}/standings-configuration/${currentFormValues.standingConfigurationId}`
              : `/admin/${sport}/${organizationId}/standings-configurations`,
          lookup: {
            endpoint: "v1/#sport/o/#0/standings/configurations",
            text: "nameLocal",
            value: "standingConfigurationId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "profileId",
          size: "col-sm-3 col",
          label: "Match Profile",
          labeltranslation: "match.profile",
          type: "lookup",
          className: "form-control",
          initial: null,
          treatFalseAsNull: true,
          exploreResolver: ({ currentFormValues: { profileId }, organizationId, sport }) =>
            profileId
              ? `/admin/${sport}/${organizationId}/match-profiles/${profileId}`
              : `/admin/${sport}/${organizationId}/match-profiles`,
          lookup: {
            endpoint: "v1/#sport/o/#0/fixtureProfiles",
            text: "name",
            value: "profileId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "leaderCriteriaId",
          size: "col-sm-3 col",
          label: "Leaders Qualification",
          labeltranslation: "leaders.qualification",
          type: "lookup",
          className: "form-control",
          initial: null,
          treatFalseAsNull: true,
          exploreResolver: ({ currentFormValues: { leaderCriteriaId }, organizationId, sport }) =>
            leaderCriteriaId
              ? `/admin/${sport}/${organizationId}/leaders-qualification/${leaderCriteriaId}/qualifiers`
              : `/admin/${sport}/${organizationId}/leaders-qualification`,
          lookup: {
            endpoint: "v1/#sport/o/#0/statistics/leaders/criteria",
            text: "name",
            value: "leaderCriteriaId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "externalId",
          size: "col-sm-3 col",
          label: "External Id",
          labeltranslation: "externalId",
          type: "text",
          placeholder: "Numbers, letters, '_' or '/'",
          className: "form-control",
          initial: null,
          maxLength: 150,
          pattern: "^[0-9A-Za-z/_]+$",
          errors: {
            maxLength: "Max 150 characters"
          }
        },
        {
          name: "includeInStatistics",
          size: "col-sm-3 col",
          label: "Include in statistics",
          labeltranslation: "includeInStatistics",
          type: "select-boolean",
          className: "form-control",
          required: false,
          initial: "includeInStatistics",
          default: true,
          options: [
            {
              value: false,
              translation: "NO",
              text: "NO"
            },
            {
              value: true,
              translation: "YES",
              text: "YES"
            }
          ]
        }
      ]
    },
    EditSeason: {
      title: "Edit Season",
      titletranslation: "season.update",
      endpoint: "v1/#sport/o/#0/seasons/#1",
      parameters: ["organizationId", "seasonId"],
      method: "PUT",
      successmessage: "Season updated successfully.",
      link: {
        show: false,
        message: "View Season",
        path: "/configuration/competitions/",
        parameters: []
      },
      submit: "Update Season",
      submittranslation: "season.update",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: "nameLocal",
          errors: {
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          type: "text",
          placeholder: "Latin Name",
          labeltranslation: "name.latin",
          className: "form-control",
          required: false,
          initial: "nameLatin",
          errors: {
            required: "Latin Name Is Required"
          }
        },
        {
          name: "nameShortLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          labeltranslation: "abbreviationLocal",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          initial: "nameShortLocal",
          maxLength: 50,
          treatFalseAsNull: true,
          errors: {
            maxLength: "Max 50 characters"
          }
        },
        {
          name: "nameShortLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          labeltranslation: "abbreviationLatin",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          initial: "nameShortLatin",
          maxLength: 50,
          treatFalseAsNull: true,
          errors: {
            maxLength: "Max 50 characters"
          }
        },
        {
          name: "year",
          size: "col-sm-2 col",
          label: "Year:",
          labeltranslation: "year",
          type: "lookupData",
          lookupData: "year",
          placeholder: "Year",
          className: "form-control",
          required: true,
          initial: "year",
          errors: {
            required: "Year Is Required"
          }
        },
        {
          name: "seasonType",
          size: "col-sm-3 col",
          label: "Season Type:",
          labeltranslation: "season.type",
          type: "lookupData",
          placeholder: "Season Type",
          className: "form-control",
          required: true,
          initial: "seasonType",
          lookupData: "seasonType",
          errors: {
            required: "Season Type Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-7 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: "status",
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "startDate",
          size: "col-sm-3 col",
          label: "Start Date:",
          labeltranslation: "date.start",
          type: "date",
          placeholder: "Start Date",
          className: "form-control",
          required: true,
          initial: "startDate",
          errors: {
            required: "Start Date Is Required"
          }
        },
        {
          name: "endDate",
          size: "col-sm-3 col",
          label: "End Date:",
          labeltranslation: "date.end",
          type: "date",
          placeholder: "End Date",
          className: "form-control",
          required: true,
          initial: "endDate",
          errors: {
            required: "End Date Is Required"
          }
        },
        {
          name: "gender",
          size: "col-sm-3 col",
          label: "Gender:",
          labeltranslation: "gender",
          type: "lookupData",
          placeholder: "Gender",
          className: "form-control",
          required: true,
          initial: "gender",
          lookupData: "gender",
          errors: {
            required: "Gender Is Required"
          }
        },
        {
          name: "ageGroup",
          size: "col-sm-3 col",
          label: "Age Group:",
          labeltranslation: "ageGroup",
          type: "lookupData",
          placeholder: "Age Group",
          className: "form-control",
          required: true,
          initial: "ageGroup",
          lookupData: "ageGroup",
          errors: {
            required: "Age Group Is Required"
          }
        },
        {
          name: "standard",
          size: "col-sm-3 col",
          label: "Standard:",
          labeltranslation: "standard",
          type: "lookupData",
          placeholder: "Standard",
          className: "form-control",
          required: true,
          initial: "standard",
          lookupData: "standards",
          errors: {
            required: "Standard Is Required"
          }
        },
        {
          name: "representation",
          size: "col-sm-3 col",
          label: "Representation:",
          labeltranslation: "representation",
          type: "lookupData",
          placeholder: "Representation",
          className: "form-control",
          required: true,
          initial: "representation",
          lookupData: "representation",
          errors: {
            required: "Representation Is Required"
          }
        },
        {
          name: "standingConfigurationId",
          size: "col-sm-3 col",
          label: "Standings Configuration:",
          labeltranslation: "standings.configuration",
          type: "lookup",
          placeholder: "Standings Configurations",
          className: "form-control",
          initial: "standingConfigurationId",
          treatFalseAsNull: true,
          exploreResolver: ({ currentFormValues, organizationId, sport }) =>
            currentFormValues.standingConfigurationId
              ? `/admin/${sport}/${organizationId}/standings-configuration/${currentFormValues.standingConfigurationId}`
              : `/admin/${sport}/${organizationId}/standings-configurations`,
          lookup: {
            endpoint: "v1/#sport/o/#0/standings/configurations",
            text: "nameLocal",
            value: "standingConfigurationId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "profileId",
          size: "col-sm-3 col",
          label: "Match Profile",
          labeltranslation: "match.profile",
          type: "lookup",
          className: "form-control",
          initial: "profileId",
          treatFalseAsNull: true,
          exploreResolver: ({ currentFormValues: { profileId }, organizationId, sport }) =>
            profileId
              ? `/admin/${sport}/${organizationId}/match-profiles/${profileId}`
              : `/admin/${sport}/${organizationId}/match-profiles`,
          lookup: {
            endpoint: "v1/#sport/o/#0/fixtureProfiles",
            text: "name",
            value: "profileId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "leaderCriteriaId",
          size: "col-sm-3 col",
          label: "Leaders Qualification",
          labeltranslation: "leaders.qualification",
          type: "lookup",
          className: "form-control",
          initial: "leaderCriteriaId",
          treatFalseAsNull: true,
          exploreResolver: ({ currentFormValues: { leaderCriteriaId }, organizationId, sport }) =>
            leaderCriteriaId
              ? `/admin/${sport}/${organizationId}/leaders-qualification/${leaderCriteriaId}/qualifiers`
              : `/admin/${sport}/${organizationId}/leaders-qualification`,
          lookup: {
            endpoint: "v1/#sport/o/#0/statistics/leaders/criteria",
            text: "name",
            value: "leaderCriteriaId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "externalId",
          size: "col-sm-3 col",
          label: "External Id",
          labeltranslation: "externalId",
          type: "text",
          placeholder: "Numbers, letters, '_' or '/'",
          className: "form-control",
          initial: "externalId",
          maxLength: 150,
          pattern: "^[0-9A-Za-z/_]+$",
          errors: {
            maxLength: "Max 150 characters"
          }
        },
        {
          name: "lockStandings",
          size: "col-sm-3 col",
          label: "Lock Standings",
          labeltranslation: "standings.lock",
          type: "select-boolean",
          className: "form-control",
          required: true,
          initial: "lockStandings",
          options: [
            {
              value: false,
              translation: "NO",
              text: "NO"
            },
            {
              value: true,
              translation: "YES",
              text: "YES"
            }
          ]
        },
        {
          name: "includeInStatistics",
          size: "col-sm-3 col",
          label: "Include in statistics",
          labeltranslation: "includeInStatistics",
          type: "select-boolean",
          className: "form-control",
          required: false,
          initial: "includeInStatistics",
          options: [
            {
              value: false,
              translation: "NO",
              text: "NO"
            },
            {
              value: true,
              translation: "YES",
              text: "YES"
            }
          ]
        },
        {
          name: "videoProduction",
          size: "col-sm-3 col",
          label: "videoProduction",
          labeltranslation: "videoProduction",
          type: "select",
          className: "form-control",
          required: false,
          initial: "videoProduction",
          options: [
            {
              value: "NONE",
              translation: "NONE",
              text: "NONE"
            },
            {
              value: "AUTOMATED",
              translation: "automated",
              text: "AUTOMATED"
            },
            {
              value: "MANUAL",
              translation: "manual",
              text: "MANUAL"
            }
          ]
        }
      ]
    },
    SeasonConfig: {
      title: "",
      titletranslation: "",
      endpoint: "v1/#sport/o/#0/seasons/#1",
      parameters: ["organizationId", "seasonId"],
      method: "PUT",
      successmessage: "Season updated successfully.",
      link: {
        show: false,
        message: "View Season",
        path: "/configuration/competitions/",
        parameters: []
      },
      submit: "Configure Season",
      submittranslation: "season.config",
      fields: [
        {
          name: "configuration",
          size: "col-sm-12 col",
          label: "",
          type: "subFields",
          placeholder: "",
          className: "form-control",
          required: false,
          initial: "",
          options: [
            {
              name: "foulsAllowedPersonal",
              size: "col-sm-4 col",
              label: "Personal Fouls Allowed",
              labeltranslation: "season.config.foulsallowed",
              type: "number",
              placeholder: "",
              className: "form-control",
              required: false,
              initial: "foulsAllowedPersonal"
            },
            {
              name: "foulsAllowedTechnical",
              size: "col-sm-4 col",
              label: "Technical Fouls Allowed",
              labeltranslation: "season.config.foulsAllowedTechnical",
              type: "number",
              placeholder: "",
              className: "form-control",
              required: false,
              initial: "foulsAllowedTechnical"
            },
            {
              name: "foulsUntilBonus",
              size: "col-sm-4 col",
              label: "Fouls Until Bonus",
              labeltranslation: "season.config.foulsUntilBonus",
              type: "number",
              placeholder: "",
              className: "form-control",
              required: false,
              initial: "foulsUntilBonus"
            },
            {
              name: "halfTimeDuration",
              size: "col-sm-4 col",
              label: "Half Time Duration (seconds)",
              labeltranslation: "season.config.halfTimeDuration",
              type: "number",
              placeholder: "",
              className: "form-control",
              required: false,
              initial: "halfTimeDuration"
            },
            {
              name: "periodBreakDuration",
              size: "col-sm-4 col",
              label: "Period Break Duration (seconds)",
              labeltranslation: "season.config.periodBreakDuration",
              type: "number",
              placeholder: "",
              className: "form-control",
              required: false,
              initial: "periodBreakDuration"
            },
            {
              name: "shotClockLength",
              size: "col-sm-4 col",
              label: "Shot Clock Length (seconds)",
              labeltranslation: "season.config.shotClockLength",
              type: "number",
              placeholder: "",
              className: "form-control",
              required: false,
              initial: "shotClockLength"
            }
          ],
          errors: {
            required: "Local Name Is Required"
          }
        }
      ]
    }
  }
};
export default formSetup;

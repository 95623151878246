import React from "react";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import ImageUploader from "../../Base/Images/ImageUploader";
import ImageDisplay from "../../Base/Images/ImageDisplay";

const TeamImageRow = (props) => {
  const {
    itemIndex,
    result,
    match,
    pageRef,
    setRefreshFlag,
    intl
    // reloadData,
  } = props;
  const { formatMessage } = intl;

  return (
    <React.Fragment key={itemIndex}>
      {result && (
        <React.Fragment>
          <tr>
            <td>
              <h6>{result.nameFullLocal}</h6>
            </td>
          </tr>
          <tr>
            <td style={{ width: "18%" }}>
              <ImageDisplay
                data={result}
                images={result.images}
                imageType="LOGO"
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
                checkQuality={true}
              />
            </td>
            <td style={{ width: "18%" }}>
              <ImageUploader
                data={result}
                type={"LOGO"}
                element={"entityId"}
                imageFor={"ENTITY"}
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
                checkQuality={true}
              />
            </td>
            <td style={{ width: "18%" }}>
              <ImageDisplay
                data={result}
                images={result.images}
                imageType="LOGO_BACKGROUND"
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
              />
            </td>
            <td style={{ width: "18%" }}>
              <ImageUploader
                data={result}
                type={"LOGO_BACKGROUND"}
                element={"entityId"}
                imageFor={"ENTITY"}
                pageRef={pageRef}
                setRefreshFlag={setRefreshFlag}
              />
            </td>
            <td className="text-right controls-6">
              <Link
                to={"/admin/" + match.params.sport + "/" + result.organizationId + "/teams/" + result.entityId}
                className="btn btn-outline-secondary btn-sm btn-action"
              >
                {formatMessage({
                  id: "details",
                  defaultMessage: "Details"
                })}
                <i className="fas fa-chevron-right" />
              </Link>
            </td>
          </tr>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default injectIntl(TeamImageRow);

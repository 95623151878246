/**
 * Strips away unneded conf/div fields from organizations
 * that don't have those entities
 */
export default (formName, data, formSetup, formSetupSetter) => {
  if (data.data.length === 0 || !data.includes.resources.conferences) {
    formSetupSetter({
      ...formSetup,
      forms: {
        ...formSetup.forms,
        [formName]: {
          ...formSetup.forms[formName],
          fields: formSetup.forms[formName].fields.filter((f) => f.name !== "conferenceId" && f.name !== "divisionId")
        }
      }
    });
  } else {
    formSetupSetter(formSetup);
  }
};

import React, { useEffect, useState } from "react";
import { FormBuilder } from "@atriumsports/coreui";
import { useFetch } from "../../Base/Hooks/fetch";
import PageDisplay from "../../Base/PageDisplay/PageDisplay";
import formSetup from "./formSetup";
import DivisionSelect from "./DivisionSelect";
import { useApi } from "../../Base/Hooks/api";

function EditSeasonTeam(props) {
  const { history, match } = props;
  const [formData, setFormData] = useState();
  const [currentSeason, setCurrentSeason] = useState();
  const api = useApi();
  const { data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/entities/" +
      match.params.teamId +
      "?include=seasons,entities,conferences",
    "",
    true
  );
  const customFields = {};

  customFields.divisionId = (props) =>
    formData && <DivisionSelect sport={match.params.sport} organizationId={match.params.organizationId} {...props} />;

  useEffect(() => {
    if (data && data.data) {
      const team = data.data[0];
      setFormData({ ...team, sport: match.params.sport });
      if (
        data.includes &&
        data.includes.resources &&
        data.includes.resources.seasons &&
        data.includes.resources.entities
      ) {
        setCurrentSeason(
          `${data.includes.resources.seasons[team.seasonId].nameLocal} - ${
            data.includes.resources.entities[team.entity.id].nameFullLocal
          }`
        );
      }
    }
  }, [data, match.params.sport]);

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];

  return (
    <PageDisplay history={history} title={currentSeason || "Loading"}>
      {formData && (
        <FormBuilder
          api={api}
          form="EditSeasonTeam"
          formData={formData}
          lookupData={LookupData}
          formSetup={formSetup}
          customFields={customFields}
        />
      )}
    </PageDisplay>
  );
}

export default EditSeasonTeam;

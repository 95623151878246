import React, { useEffect, useState } from "react";

import "./FilterDisplay.scss";

const FilterDisplay = (props) => {
  const { data, display, value, format = "text" } = props;
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (data) {
      let properties = value.split("||");
      let uniques = [];
      if (properties.count > 1) {
        uniques = [...new Set(data.map((item) => item[properties[0]][properties[1]]))];
      } else {
        if (data) {
          uniques = [...new Set(data.map((item) => item[properties[0]]))];
          if (format !== "date") {
            if (format === "number") {
              uniques.sort((a, b) => a - b);
            } else {
              uniques.sort();
            }
          } else {
            uniques.sort(function (a, b) {
              var aMixed = new Date(a);
              var bMixed = new Date(b);
              return aMixed < bMixed ? -1 : 1;
            });
          }
        }
      }
      setSelectOptions(uniques);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(data)]);

  function handleSelect(e) {
    props.selectAction(value, e.target.value);
  }

  return (
    <div className={value + "-filter filterDisplay"}>
      <select onChange={handleSelect}>
        <option value="">{display}</option>
        {selectOptions.map((option, index) => (
          <option key={index} value={option}>
            {props.mapAfter && props.mapAfter(option)}
            {!props.mapAfter && option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterDisplay;

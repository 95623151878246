import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import InlineForm from "../../Base/Forms/InlineForm";
import inlineFormSetup from "./inlineFormSetup";

import Modal from "../../Base/Modal/Modal";
import { useDelete } from "../../Base/Hooks/delete";
import sportsPositionsUntranslated from "./sportsPositions";

const RosterRow = (props) => {
  const { itemIndex, result, match, pageRef, setRefreshFlag, intl, person, reloadData } = props;
  const [editMode, setEditMode] = useState(false);
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [linkDeleteData, setLinkDeleteData] = useState(null);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/persons/" +
      linkDeleteData +
      "/roster",
    "",
    refreshDeleteData
  );
  const { formatMessage } = intl;
  const sportsPositions = sportsPositionsUntranslated(formatMessage);

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }),
        +deleteError,
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "team.delete.success",
            defaultMessage: "Team deleted successfully"
          }),
          "success",
          10
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setLinkDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeleteLink(param, name) {
    setDeleteModalText(
      formatMessage(
        {
          id: "team.delete.confirm",
          defaultMessage: "You are about to delete " + name + ". Is this OK?"
        },
        { item: name }
      )
    );
    setDeleteModalOpen(true);
    setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deleteLink() {
    setLinkDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  function applySportsDetails(formSetup, positions) {
    return {
      ...formSetup,
      forms: {
        ...formSetup.forms,
        RosterUpdate: {
          ...formSetup.forms.RosterUpdate,
          fields: formSetup.forms.RosterUpdate.fields.map((field) => {
            if (field.name === "position") {
              return { ...field, data: ["", ...Object.values(positions[match.params.sport])] };
            }
            return field;
          })
        }
      }
    };
  }

  function sportsPositionBackToCode(values) {
    return {
      ...values,
      position: Object.keys(sportsPositions[match.params.sport]).find(
        (code) => sportsPositions[match.params.sport][code] === values.position
      )
    };
  }

  return (
    <tr key={itemIndex} className={"deleting-" + deleteLoading}>
      {!editMode && result && (
        <>
          <td className="sm" onClick={() => setEditMode(!editMode)}>
            {person.images && person.images.find((image) => image.imageType === "PERSON_HEAD") && (
              <div className="inline-image-container sm">
                <img
                  src={person.images.find((image) => image.imageType === "PERSON_HEAD").url}
                  alt="PERSON_HEAD"
                  className="inline-image sm"
                />
              </div>
            )}
          </td>
          <td onClick={() => setEditMode(!editMode)}>{result.bib}</td>
          <td onClick={() => setEditMode(!editMode)}>{person.nameFullLocal}</td>
          <td onClick={() => setEditMode(!editMode)}>{person.nameFullLatin}</td>
          <td onClick={() => setEditMode(!editMode)}>{sportsPositions[match.params.sport][result.position]}</td>
          <td onClick={() => setEditMode(!editMode)}>{result.status}</td>
          <td className="text-right controls-3">
            <Button outline color="primary" size="sm" onClick={() => setEditMode(!editMode)}>
              <i className="fas fa-pen" />
            </Button>{" "}
            <Button
              outline
              color="danger"
              size="sm"
              onClick={(e) => showDeleteLink(result.personId, person.nameFullLocal)}
            >
              <i className="fas fa-minus" />
            </Button>{" "}
            <Link
              to={"/admin/" + match.params.sport + "/" + result.organizationId + "/persons/" + result.personId}
              className="btn btn-outline-secondary btn-sm btn-action"
            >
              {formatMessage({
                id: "details",
                defaultMessage: "Details"
              })}
              <i className="fas fa-chevron-right" />
            </Link>
            <Modal
              isOpen={deleteModalOpen}
              heading={formatMessage({
                id: "team.delete",
                defaultMessage: "Delete Team?"
              })}
              text={deleteModalText}
              actionText={formatMessage({
                id: "delete",
                defaultMessage: "Delete"
              })}
              cancelText={formatMessage({
                id: "cancel",
                defaultMessage: "Cancel"
              })}
              action={deleteLink}
              cancel={cancelModal}
            />
          </td>
        </>
      )}
      {editMode && (
        <InlineForm
          setEditMode={setEditMode}
          editMode={editMode}
          form="RosterUpdate"
          action={reloadData}
          inlineFormSetup={applySportsDetails(inlineFormSetup, sportsPositions)}
          pageRef={pageRef}
          formData={{
            ...result,
            position: sportsPositions[match.params.sport][result.position]
          }}
          extraData={person}
          preSendDataTransform={sportsPositionBackToCode}
        />
      )}
    </tr>
  );
};

export default injectIntl(RosterRow);

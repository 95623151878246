import React from "react";
import ImageDisplay from "../Base/Images/ImageDisplay";
import ImageUploader from "../Base/Images/ImageUploader";
import "./persons.scss";

export const PersonImages = (props) => {
  const { headingText, imageTypesList, current, pageRef, setRefreshFlag } = props;

  const renderImageBox = (image) => (
    <div key={image.imageType} className="person-images-box">
      <h5 className="person-images-box-heading">{image.title}</h5>
      <div className="person-images-box-content">
        <ImageDisplay
          data={current}
          images={current.images}
          imageType={image.imageType}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
          checkQuality={image.checkQuality}
        />
        <ImageUploader
          data={current}
          type={image.imageType}
          element={"personId"}
          imageFor={"PERSON"}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      </div>
    </div>
  );

  return (
    <div className="person-images">
      <h3>{headingText}</h3>
      <div className="person-images-list">{imageTypesList.map(renderImageBox)}</div>
    </div>
  );
};

var formSetup = {
  forms: {
    NewVenue: {
      title: "Add New Venue",
      titletranslation: "venue.add",
      endpoint: "v1/#sport/o/#0/venues",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New Venue added successfully.",
      link: {
        show: false,
        message: "View Venue",
        path: "/admin/venues/",
        parameters: []
      },
      submit: "Add New Venue",
      submittranslation: "venue.add",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          labeltranslation: "name.local.abbreviation",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Name Is Required"
          }
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          labeltranslation: "name.latin.abbreviation",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: null,
          maxLength: 50,
          errors: {
            maxLength: "Max 50 characters",
            required: "Local Name Is Required"
          }
        },
        {
          name: "timezone",
          size: "col-sm-6 col",
          label: "Time Zone",
          labeltranslation: "timezone",
          type: "lookupData",
          placeholder: "Select a time zone",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "timeZones",
          errors: {
            required: "Time Zone Is Required"
          }
        },
        {
          name: "countryCode",
          size: "col-sm-6 col",
          label: "Country",
          labeltranslation: "country",
          type: "lookupData",
          placeholder: "Select a country",
          className: "form-control",
          required: false,
          initial: null,
          lookupData: "countryCodes",
          errors: {
            required: "Country Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status:",
          labeltranslation: "status",
          type: "select",
          initial: "",
          default: "ACTIVE",
          className: "form-control",
          required: true,
          options: [
            { value: "ACTIVE", text: "ACTIVE" },
            { value: "INACTIVE", text: "INACTIVE" }
          ],
          errors: {
            required: "Status Is Required"
          }
        }
        // {
        //   name: "siteId",
        //   size: "col-sm-4 col",
        //   label: "Site:",
        //   labeltranslation: "site",
        //   type: "lookup",
        //   placeholder: "Site",
        //   className: "form-control",
        //   required: false,
        //   initial: null,
        //   lookup: {
        //     endpoint: "v1/#sport/o/#0/sites",
        //     text: "nameLocal",
        //     value: "siteId",
        //     parameters: ["organizationId"],
        //   },
        //   errors: {
        //     required: "Club Is Required",
        //   },
        // },
      ]
    },
    EditVenue: {
      title: "Edit Venue",
      titletranslation: "venue.update",
      endpoint: "v1/#sport/o/#0/venues/#1",
      parameters: ["organizationId", "venueId"],
      method: "PUT",
      successmessage: "Venue updated successfully.",
      link: {
        show: false,
        message: "View Venues",
        path: "/admin/venues/",
        parameters: []
      },
      submit: "Update Venue",
      submittranslation: "venue.update",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: "nameLocal",
          errors: {
            required: "Local Name Is Required"
          }
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          type: "text",
          placeholder: "Local Abbr",
          labeltranslation: "name.local.abbreviation",
          className: "form-control",
          required: false,
          initial: "abbreviationLocal",
          errors: {
            required: "Local Name Is Required"
          }
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          type: "text",
          placeholder: "Latin Name",
          labeltranslation: "name.latin",
          className: "form-control",
          required: false,
          initial: "nameLatin",
          errors: {
            required: "Latin Name Is Required"
          }
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          labeltranslation: "name.latin.abbreviation",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: "abbreviationLatin",
          errors: {
            required: "Local Name Is Required"
          }
        },
        {
          name: "timezone",
          size: "col-sm-4 col",
          label: "Time Zone",
          labeltranslation: "timezone",
          type: "lookupData",
          placeholder: "Select a time zone",
          className: "form-control",
          required: true,
          initial: "timezone",
          lookupData: "timeZones",
          errors: {
            required: "Time ZOne Is Required"
          }
        },

        {
          name: "countryCode",
          size: "col-sm-4 col",
          label: "Country",
          labeltranslation: "country",
          type: "lookupData",
          placeholder: "Select a country",
          className: "form-control",
          required: false,
          initial: "countryCode",
          lookupData: "countryCodes",
          errors: {
            required: "Country Is Required"
          }
        },
        {
          name: "siteId",
          size: "col-sm-4 col",
          label: "Site:",
          labeltranslation: "site",
          type: "lookup",
          placeholder: "Site",
          className: "form-control",
          required: false,
          initial: "siteId",
          lookup: {
            endpoint: "v1/#sport/o/#0/sites",
            text: "nameLocal",
            value: "siteId",
            parameters: ["organizationId"]
          },
          errors: {
            required: "Site Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: "status",
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        }
      ]
    }
  }
};
export default formSetup;

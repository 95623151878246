import React, { useEffect, useState, useRef } from "react";
import { Collapse, Input, Label, Spinner } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import TeamRow from "./TeamRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import TablePagination from "../Base/Shared/TablePagination";
import SearchFilter from "../Base/Shared/SearchFilter";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import Colors from "./Colors";
import useResourceAPI from "../Base/Hooks/resourceAPI";
import "../Base/Shared/SearchFilter.scss";

const Teams = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [tableData, setTableData] = useState();
  const [formData, setFormData] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("ACTIVE");
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const pageRef = useRef();
  const { data, loading, error, updateSearch, updatePagination, refetch } = useResourceAPI({
    ...props,
    limit: 25,
    resourceName: "entities",
    controlledQuery: { status: statusFilter }
  });

  const columns = [
    "",
    formatMessage({
      id: "name.local.full",
      defaultMessage: "Local Full Name"
    }),
    formatMessage({
      id: "name.latin.full",
      defaultMessage: "Latin Full Name"
    }),
    formatMessage({
      id: "status",
      defaultMessage: "Status"
    }),
    ""
  ];

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  const statusFilterHandler = (e) => {
    setStatusFilter(e.target.value);
  };

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);

      setFormData({
        organizationId: match.params.organizationId
      });
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data) {
      if (data && data.includes && data.includes.resources && data.includes.resources.organizations) {
        setPageTitle(data.includes.resources.organizations[match.params.organizationId].nameLocal);
      }

      let filteredData = data !== null ? data.data : [];
      if (statusFilter) {
        filteredData = filteredData.filter((d) => d.status === statusFilter);
      }
      let tableList = filteredData.map((result, index) => (
        <TeamRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          pageRef={pageRef}
        />
      ));
      setTableData(tableList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, statusFilter]);

  return (
    <React.Fragment>
      <PageDisplay
        title={
          pageTitle +
          " " +
          formatMessage({
            id: "teams",
            defaultMessage: "Teams"
          })
        }
        error={error}
        pageTitle={
          pageTitle +
          formatMessage({
            id: "teams",
            defaultMessage: "Teams"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
      >
        <div className="table-search-filter">
          <div className="table-search-filter-status">
            <Label htmlFor="status-filter">
              {formatMessage({
                id: "status",
                defaultMessage: "Status"
              })}
              :
            </Label>
            <Input
              id="status-filter"
              className="table-search-filter-status-select"
              type="select"
              name="status-filter"
              value={statusFilter}
              onChange={statusFilterHandler}
            >
              {LookupData["status"].map((status) => (
                <option value={status}>{status === "" ? "ALL" : status}</option>
              ))}
            </Input>
          </div>
          <SearchFilter
            placeholder={formatMessage({
              id: "search.placeholder",
              defaultMessage: "Search (4 letters min)"
            })}
            doSearch={updateSearch}
          />
        </div>
        {loading && <Spinner size="md" color="orange" />}
        {!loading && (
          <>
            <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
            <TablePagination data={data} setPaginationString={updatePagination} />
          </>
        )}
        <hr />
        <h4 onClick={toggleForm}>
          {!isFormOpen && (
            <span>
              <FormattedMessage id="team.add" defaultMessage="Add New Team" /> <i className="fas fa-caret-down"></i>
            </span>
          )}
          {isFormOpen && (
            <span>
              <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
            </span>
          )}
        </h4>
        <Collapse isOpen={isFormOpen}>
          {formData !== null && !loading && (
            <FormBuilder
              form="NewTeam"
              action={refetch}
              formSetup={formSetup}
              pageRef={pageRef}
              formData={formData}
              lookupData={LookupData}
              customFields={{ colors: Colors }}
            />
          )}
        </Collapse>
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Teams);

const defaultColumns = [
  "#",
  "teamname",
  "PLAYED",
  "W",
  "L",
  "W%",
  "%",
  "FOR",
  "AGAINST",
  "HOME",
  "AWAY",
  "STREAK",
  "RESULTS",
  "DIV",
  "CONF",
  "standingPoints",
  "GB",
  " "
];
const allColumns = [
  "#",
  "teamname",
  "byes",
  "draws",
  "drawsAway",
  "drawsHome",
  "forfeitsGiven",
  "forfeitsWonBy",
  "highestScoreAgainst",
  "highestScoreAgainstAway",
  "highestScoreAgainstHome",
  "highestScoreFor",
  "highestScoreForAway",
  "highestScoreForHome",
  "losses",
  "lossesAway",
  "lossesHome",
  "lowestScoreAgainst",
  "lowestScoreAgainstAway",
  "lowestScoreAgainstHome",
  "lowestScoreFor",
  "lowestScoreForAway",
  "lowestScoreForHome",
  "percentage",
  "percentageAway",
  "percentageHome",
  "played",
  "playedAway",
  "playedHome",
  "pointDifference",
  "pointDifferenceAway",
  "pointDifferenceHome",
  "resultString",
  "resultStringAway",
  "resultStringHome",
  "scoredAgainst",
  "scoredAgainstAway",
  "scoredAgainstHome",
  "scoredFor",
  "scoredForAway",
  "scoredForHome",
  "streak",
  "streakAway",
  "streakHome",
  "washouts",
  "winPercentage",
  "winPercentageAway",
  "winPercentageHome",
  "wins",
  "winsAway",
  "winsHome",
  " "
];

const pointsColumns = [
  "bonusStandingPoints",
  "byeStandingPoints",
  "gamesBehind",
  "penaltyStandingPoints",
  "standingPoints",
  "standingPointsAway",
  "standingPointsHome",
  "teamDifferential",
  "teamDifferentialAway",
  "teamDifferentialHome"
];

export { defaultColumns, allColumns, pointsColumns };

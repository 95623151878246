import React, { useEffect, useState, useRef } from "react";
import { Spinner, Collapse } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";

import VenueRow from "./VenueRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import TablePagination from "../Base/Shared/TablePagination";
import SearchFilter from "../Base/Shared/SearchFilter";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import countryCodes from "../../config/countryCodes";
import timeZones from "../../config/timeZones";

const Venues = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationString, setPaginationString] = useState("limit=200&offset=0");
  const [formData, setFormData] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/venues?include=organizations&" +
      paginationString,
    "",
    refreshFlag
  );

  const columns = [
    "VenueId",
    formatMessage({
      id: "name.local",
      defaultMessage: "Local Name"
    }),
    formatMessage({
      id: "name.latin",
      defaultMessage: "Latin Name"
    }),
    formatMessage({
      id: "timezone",
      defaultMessage: "Time Zone"
    }),
    formatMessage({
      id: "country",
      defaultMessage: "Country"
    }),
    ""
  ];

  const LookupData = [];
  LookupData["countryCodes"] = countryCodes;
  LookupData["timeZones"] = timeZones;

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);

      setFormData({
        organizationId: match.params.organizationId
      });
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data) {
      if (data && data.includes && data.includes.resources && data.includes.resources.organizations) {
        setPageTitle(data.includes.resources.organizations[match.params.organizationId].nameLocal);
      }

      let filteredData = data !== null ? data.data : [];

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.nameLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }
      let tableList = filteredData.map((result, index) => (
        <VenueRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={
            pageTitle +
            " " +
            formatMessage({
              id: "venues",
              defaultMessage: "Venues"
            })
          }
          error={error}
          pageTitle={
            pageTitle +
            formatMessage({
              id: "venues",
              defaultMessage: "Venues"
            }) +
            " - " +
            title
          }
          ref={pageRef}
          history={history}
        >
          <SearchFilter doSearch={setSearchText} />
          <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
          <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
          <hr />
          <h4 onClick={toggleForm}>
            {!isFormOpen && (
              <span>
                <FormattedMessage id="venue.add" defaultMessage="Add New Venue" /> <i className="fas fa-caret-down"></i>
              </span>
            )}
            {isFormOpen && (
              <span>
                <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
              </span>
            )}
          </h4>
          <Collapse isOpen={isFormOpen}>
            {formData !== null && (
              <FormBuilder
                form="NewVenue"
                action={reloadData}
                formSetup={formSetup}
                pageRef={pageRef}
                formData={formData}
                lookupData={LookupData}
              />
            )}
          </Collapse>
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(Venues);

export default {
  forms: {
    TemplateEdit: {
      endpoint: "v1/#sport/o/#0/statistics/leaders/criteria/#1",
      parameters: ["organizationId", "leaderCriteriaId"],
      method: "PUT",
      successmessage: "Qualification template updated successfully",
      dataFields: ["name"],
      fields: [
        {
          name: "name",
          type: "text"
        }
      ]
    }
  }
};

import React, { useState, useEffect } from "react";

const options = ["ALL_STAR", "PRESEASON", "REGULAR", "FINAL", "PLAYOFF", "FRIENDLY", "DEMONSTRATION"];

function FixtureTypes(props) {
  const [localOptions, setOptions] = useState({});

  useEffect(() => {
    const { buildRules } = props.form.values;
    if (buildRules && buildRules.hasOwnProperty("fixtureTypes")) {
      const initialOptions = buildRules.fixtureTypes.reduce((a, c) => ({ ...a, [c]: true }), {});
      setOptions(initialOptions);
    }
  }, [props.form.values]);

  function isChecked(option) {
    return localOptions[option];
  }

  function handleCheckboxChange(option) {
    const _options = { ...localOptions };
    if (localOptions[option]) {
      _options[option] = false;
    } else {
      _options[option] = true;
    }
    setOptions(_options);
    const list = Object.keys(_options).filter((key) => _options[key]);
    const finalValue = list.length > 0 ? list : undefined;
    props.form.setFieldValue("buildRules.fixtureTypes", finalValue);
  }

  function renderOption(option) {
    return (
      <div className="checkbox" key={option}>
        <label>
          <input
            value={localOptions[option]}
            onChange={() => handleCheckboxChange(option)}
            checked={isChecked(option)}
            type="checkbox"
          />
          <span className="cr">
            <i className="cr-icon fa fa-check" />
          </span>

          {option}
        </label>
      </div>
    );
  }

  function renderOptions() {
    return options.map(renderOption);
  }

  return <>{renderOptions()}</>;
}

export default FixtureTypes;

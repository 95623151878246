var standards = [
  "",
  "ELITE",
  "FRIENDLY",
  "GRASS_ROOT",
  "INTERNATIONAL",
  "NONCONTINENTAL_CHAMPIONSHIP",
  "OLYMPIC",
  "REGION",
  "TIER2",
  "TIER1",
  "WORLD_CHAMPIONSHIP",
  "ZONE_CHAMPIONSHIP"
];
export default standards;

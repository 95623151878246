import React, { useEffect, useState, useRef } from "react";
import { Collapse } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import PersonRow from "./PersonRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import countryCodes from "../../config/countryCodes";
import PersonList from "./PersonList";
import usePersonsAPI from "./PersonsAPI";

const Persons = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [formData, setFormData] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("ACTIVE");
  const toggleForm = () => setIsFormOpen(!isFormOpen);
  const personsAPI = usePersonsAPI({ ...props, limit: 20, controlledQuery: { status: statusFilter } });
  const pageRef = useRef();

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING", "DECEASED", "UNREGISTERED"];
  LookupData["gender"] = ["", "MALE", "FEMALE", "UNKNOWN"];
  LookupData["nationalities"] = countryCodes;

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);

      setFormData({
        organizationId: match.params.organizationId
      });
    }
    // eslint-disable-next-line
  }, [match]);

  return (
    <React.Fragment>
      <PageDisplay
        title={
          pageTitle +
          " " +
          formatMessage({
            id: "persons",
            defaultMessage: "People"
          })
        }
        pageTitle={
          pageTitle +
          formatMessage({
            id: "persons",
            defaultMessage: "People"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
      >
        <PersonList
          {...props}
          personsAPI={personsAPI}
          pageRef={pageRef}
          setPageTitle={setPageTitle}
          PersonRowComponent={PersonRow}
          LookupData={LookupData}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />
        <hr />
        <h4 onClick={toggleForm}>
          {!isFormOpen && (
            <span>
              <FormattedMessage id="person.add" defaultMessage="Add New Person" /> <i className="fas fa-caret-down"></i>
            </span>
          )}
          {isFormOpen && (
            <span>
              <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
            </span>
          )}
        </h4>
        <Collapse isOpen={isFormOpen}>
          {formData !== null &&
            !personsAPI.loading && ( // refresh the form on list update
              <FormBuilder
                form="NewPerson"
                action={personsAPI.refetch}
                formSetup={formSetup}
                pageRef={pageRef}
                formData={formData}
                lookupData={LookupData}
              />
            )}
        </Collapse>
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Persons);

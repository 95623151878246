var formSetup = {
  forms: {
    NewPerson: {
      title: "Add New Person",
      titletranslation: "person.add",
      endpoint: "v1/#sport/o/#0/persons",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New Person added successfully.",
      link: {
        show: false,
        message: "View Person",
        path: "/admin/persons/",
        parameters: []
      },
      submit: "Add New Person",
      submittranslation: "person.add",
      fields: [
        {
          name: "nameFullLocal",
          size: "col-sm-4 col",
          label: "Local Full Name:",
          labeltranslation: "name.local.full",
          type: "text",
          placeholder: "Local Full Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Local Full Name Is Required"
          }
        },
        {
          name: "nameFullLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin.full",
          type: "text",
          placeholder: "Latin Full Name",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Full Name Is Required"
          }
        },
        {
          name: "dob",
          size: "col-sm-4 col",
          label: "Date Of Birth:",
          type: "date",
          placeholder: "Date Of Birth",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Date Of Birth Is Required"
          }
        },
        {
          name: "nationality",
          size: "col-sm-4 col",
          label: "Nationality",
          labeltranslation: "nationality",
          type: "lookupData",
          placeholder: "Select a nationality",
          className: "form-control",
          required: false,
          initial: null,
          lookupData: "nationalities",
          errors: {
            required: "nationality Is Required"
          }
        },
        {
          name: "gender",
          size: "col-sm-4 col",
          label: "Gender:",
          labeltranslation: "gender",
          type: "lookupData",
          placeholder: "Gender",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "gender",
          errors: {
            required: "Gender Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "additionalDetails",
          size: "col-sm-8 col",
          type: "subFields",
          label: "Additional Details",
          placeholder: "",
          className: "form-control",
          initial: "",
          options: [
            {
              name: "height",
              size: "col-sm-4 col",
              label: "Height (cm):",
              labeltranslation: "height",
              type: "number",
              className: "form-control",
              placeholder: "Enter person's height in cm",
              treatFalseAsNull: true,
              initial: null,
              min: 100,
              max: 270
            },
            {
              name: "weight",
              size: "col-sm-4 col",
              label: "Weight (kg):",
              labeltranslation: "weight",
              type: "number",
              className: "form-control",
              placeholder: "Enter person's weight in kg",
              initial: null,
              treatFalseAsNull: true,
              min: 30,
              max: 180
            }
          ]
        },
        {
          name: "social",
          size: "col-sm-12 col",
          type: "subFields",
          label: "Social",
          placeholder: "",
          className: "form-control",
          initial: "",
          options: [
            {
              name: "website",
              size: "col-sm-4 col",
              label: "Website",
              labeltranslation: "website",
              type: "text",
              placeholder: "http(s)://yourwebsite.domain",
              className: "form-control",
              initial: null,
              pattern: "https?://(www.)?[a-zA-Z0-9_-./]+"
            },
            {
              name: "facebook",
              size: "col-sm-4 col",
              label: "Facebook",
              type: "text",
              placeholder: "https://www.facebook.com/username",
              className: "form-control",
              initial: null,
              pattern: "^(https://)?(www.)?facebook.com/[A-Za-z0-9.]+$"
            },
            {
              name: "twitter",
              size: "col-sm-4 col",
              label: "Twitter",
              type: "text",
              placeholder: "https://twitter.com/username",
              className: "form-control",
              initial: null,
              pattern: "^(https://)?(www.)?twitter.com/[A-Za-z0-9_]+$"
            },
            {
              name: "wikipedia",
              size: "col-sm-4 col",
              label: "Wikipedia",
              type: "text",
              placeholder: "https://en.wikipedia.org/wiki/person_name",
              className: "form-control",
              initial: null,
              pattern: "^https://en.wikipedia.org/wiki/[A-Za-z0-9_]+$"
            },
            {
              name: "instagram",
              size: "col-sm-4 col",
              label: "Instagram",
              type: "text",
              placeholder: "https://www.instagram.com/username",
              className: "form-control",
              initial: null,
              pattern: "^(https://)?(www.)?instagram.com/[A-Za-z0-9_.]+$"
            }
          ]
        }
      ]
    },
    EditPerson: {
      title: "Edit Person",
      titletranslation: "person.update",
      endpoint: "v1/#sport/o/#0/persons/#1",
      parameters: ["organizationId", "personId"],
      method: "PUT",
      successmessage: "Person updated successfully.",
      link: {
        show: false,
        message: "View Person",
        path: "/configuration/persons/",
        parameters: []
      },
      submit: "Update Person",
      submittranslation: "person.update",
      fields: [
        {
          name: "nameFullLocal",
          size: "col-sm-4 col",
          label: "Local Full Name:",
          labeltranslation: "name.local.full",
          type: "text",
          placeholder: "Local Full Name",
          className: "form-control",
          required: true,
          initial: "nameFullLocal",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Local Full Name Is Required"
          }
        },
        {
          name: "nameFullLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          labeltranslation: "name.latin.full",
          type: "text",
          placeholder: "Latin Full Name",
          className: "form-control",
          required: false,
          initial: "nameFullLatin",
          maxLength: 150,
          errors: {
            maxLength: "Max 150 characters",
            required: "Latin Full Name Is Required"
          }
        },
        {
          name: "dob",
          size: "col-sm-4 col",
          label: "Date Of Birth:",
          type: "date",
          placeholder: "Date Of Birth",
          className: "form-control",
          required: false,
          initial: "dob",
          errors: {
            required: "Date Of Birth Is Required"
          }
        },
        {
          name: "nationality",
          size: "col-sm-4 col",
          label: "Nationality",
          labeltranslation: "nationality",
          type: "lookupData",
          placeholder: "Select a nationality",
          className: "form-control",
          required: false,
          initial: "nationality",
          lookupData: "nationalities",
          treatFalseAsNull: true,
          errors: {
            required: "nationality Is Required"
          }
        },
        {
          name: "gender",
          size: "col-sm-4 col",
          label: "Gender:",
          labeltranslation: "gender",
          type: "lookupData",
          placeholder: "Gender",
          className: "form-control",
          required: true,
          initial: "gender",
          lookupData: "gender",
          errors: {
            required: "Gender Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: "status",
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "additionalDetails",
          size: "col-sm-8 col",
          type: "subFields",
          label: "Additional Details",
          placeholder: "",
          className: "form-control",
          initial: "",
          options: [
            {
              name: "height",
              size: "col-sm-4 col",
              label: "Height (cm):",
              labeltranslation: "height",
              type: "number",
              className: "form-control",
              placeholder: "Enter person's height in cm",
              treatFalseAsNull: true,
              initial: "height",
              min: 100,
              max: 270
            },
            {
              name: "weight",
              size: "col-sm-4 col",
              label: "Weight (kg):",
              labeltranslation: "weight",
              type: "number",
              className: "form-control",
              placeholder: "Enter person's weight in kg",
              treatFalseAsNull: true,
              initial: "weight",
              min: 30,
              max: 180
            }
          ]
        },

        {
          name: "social",
          size: "col-sm-12 col",
          type: "subFields",
          label: "Social",
          placeholder: "",
          className: "form-control",
          initial: "",
          options: [
            {
              name: "website",
              size: "col-sm-4 col",
              label: "Website",
              labeltranslation: "website",
              type: "text",
              placeholder: "http(s)://yourwebsite.domain",
              className: "form-control",
              initial: "website",
              pattern: "https?://(www.)?[a-zA-Z0-9_-./]+"
            },
            {
              name: "facebook",
              size: "col-sm-4 col",
              label: "Facebook",
              type: "text",
              placeholder: "https://www.facebook.com/username",
              className: "form-control",
              initial: "facebook",
              pattern: "^(https://)?(www.)?facebook.com/[A-Za-z0-9.]+$"
            },
            {
              name: "twitter",
              size: "col-sm-4 col",
              label: "Twitter",
              type: "text",
              placeholder: "https://twitter.com/username",
              className: "form-control",
              initial: "twitter",
              pattern: "^(https://)?(www.)?twitter.com/[A-Za-z0-9_]+$"
            },
            {
              name: "wikipedia",
              size: "col-sm-4 col",
              label: "Wikipedia",
              type: "text",
              placeholder: "https://en.wikipedia.org/wiki/person_name",
              className: "form-control",
              initial: "wikipedia",
              pattern: "^https://en.wikipedia.org/wiki/[A-Za-z0-9_]+$"
            },
            {
              name: "instagram",
              size: "col-sm-4 col",
              label: "Instagram",
              type: "text",
              placeholder: "https://www.instagram.com/username",
              className: "form-control",
              initial: "instagram",
              pattern: "^(https://)?(www.)?instagram.com/[A-Za-z0-9_.]+$"
            }
          ]
        }
      ]
    }
  }
};
export default formSetup;

import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

const TeamSummaryStats = (props) => {
  const { team, title } = props;
  const [result, setResult] = useState();

  useEffect(() => {
    setResult(team.statistics);
    // eslint-disable-next-line
  }, [team]);

  return (
    <React.Fragment>
      {result && (
        <>
          <h5>{title} - Team Summary </h5>
          <table className="summary-table table table-striped table-hover">
            <tbody>
              <tr className="summary-row">
                <td className="summary-row-title">Results:</td>
                <td className="summary-row-data">
                  <span>Played: {result.games}</span> <span>Wins: {result.wins}</span>{" "}
                  <span>Losses: {result.losses}</span>
                </td>
              </tr>
              <tr className="summary-row">
                <td className="summary-row-title">Fast break points:</td>
                <td className="summary-row-data">{result.pointsFastBreak}</td>
              </tr>
              <tr className="summary-row">
                <td className="summary-row-title">Points in the paint:</td>
                <td className="summary-row-data">{result.pointsInThePaint}</td>
              </tr>
              <tr className="summary-row">
                <td className="summary-row-title">Second chance points:</td>
                <td className="summary-row-data">{result.pointsSecondChance}</td>
              </tr>
              <tr className="summary-row">
                <td className="summary-row-title">Bench points:</td>
                <td className="summary-row-data">{result.pointsFromBench}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </React.Fragment>
  );
};

export default injectIntl(TeamSummaryStats);

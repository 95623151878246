import React, { useEffect, useState, useRef } from "react";
import { Collapse, Input, Label, Spinner } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import SeasonRow from "./SeasonRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import TablePagination from "../Base/Shared/TablePagination";
import SearchFilter from "../Base/Shared/SearchFilter";
import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import countryCodes from "../../config/countryCodes";
import standards from "../../config/standardTypes";
import "../Base/Shared/SearchFilter.scss";

const Seasons = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [paginationString, setPaginationString] = useState("limit=200&offset=0");
  const [tableData, setTableData] = useState();
  const [formData, setFormData] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("ACTIVE");
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      match.params.sport +
      "/o/" +
      match.params.organizationId +
      "/competitions/" +
      match.params.competitionId +
      "/seasons?include=organizations,competitions&" +
      paginationString,
    "",
    refreshFlag
  );

  const columns = [
    formatMessage({
      id: "name.local",
      defaultMessage: "Local Name"
    }),
    formatMessage({
      id: "name.latin",
      defaultMessage: "Latin Name"
    }),
    formatMessage({
      id: "year",
      defaultMessage: "Year"
    }),
    formatMessage({
      id: "images.logo",
      defaultMessage: "Logo"
    }),
    "",

    ""
  ];

  const LookupData = [];
  LookupData["status"] = ["", "ACTIVE", "INACTIVE", "PENDING"];
  LookupData["gender"] = ["", "MALE", "FEMALE", "MIXED"];
  LookupData["ageGroup"] = ["", "SENIOR", "JUNIOR", "MASTERS"];
  LookupData["eventType"] = ["", "FIXTURE", "PRACTICE"];
  LookupData["representation"] = ["", "CLUB", "COUNTRY", "PERSON", "REGION", "STATE"];
  LookupData["year"] = ["", "2020", "2021", "2022", "2023", "2024"];
  LookupData["seasonType"] = ["", "SEASON", "TOURNAMENT", "ONE_OFF"];
  LookupData["standards"] = standards;
  LookupData["nationalities"] = countryCodes;

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);
  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  const statusFilterHandler = (e) => {
    setStatusFilter(e.target.value);
  };

  useEffect(() => {
    if (match) {
      updateState("orgId", match.params.organizationId);
      updateState("sport", match.params.sport);

      setFormData({
        organizationId: match.params.organizationId,
        competitionId: match.params.competitionId
      });
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (data) {
      if (data && data.includes && data.includes.resources && data.includes.resources.organizations) {
        setPageTitle(
          data.includes.resources.organizations[match.params.organizationId].nameLocal +
            " - " +
            data.includes.resources.competitions[match.params.competitionId].nameLocal
        );
      }
      let filteredData = data !== null ? data.data : [];

      if (statusFilter) {
        filteredData = filteredData.filter((d) => d.status === statusFilter);
      }

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.nameLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }

      let tableList = filteredData
        .sort(function (a, b) {
          var aMixed = a.year;
          var bMixed = b.year;
          return aMixed > bMixed ? -1 : 1;
        })
        .map((result, index) => (
          <SeasonRow
            key={index}
            itemIndex={index}
            result={result}
            updateState={updateState}
            match={match}
            pageRef={pageRef}
            setRefreshFlag={setRefreshFlag}
          />
        ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText, statusFilter]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={
            pageTitle +
            " - " +
            formatMessage({
              id: "seasons",
              defaultMessage: "Seasons"
            })
          }
          error={error}
          pageTitle={
            pageTitle +
            " " +
            formatMessage({
              id: "seasons",
              defaultMessage: "Seasons"
            }) +
            " - " +
            title
          }
          ref={pageRef}
          history={history}
        >
          <div className="table-search-filter">
            <div className="table-search-filter-status">
              <Label htmlFor="status-filter">
                {formatMessage({
                  id: "status",
                  defaultMessage: "Status"
                })}
                :
              </Label>
              <Input
                id="status-filter"
                className="table-search-filter-status-select"
                type="select"
                name="status-filter"
                value={statusFilter}
                onChange={statusFilterHandler}
              >
                {LookupData["status"].map((status) => (
                  <option value={status}>{status === "" ? "ALL" : status}</option>
                ))}
              </Input>
            </div>
            <SearchFilter doSearch={setSearchText} />
          </div>
          <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
          <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
          <hr />
          <h4 onClick={toggleForm}>
            {!isFormOpen && (
              <span>
                <FormattedMessage id="season.add" defaultMessage="Add New Season" />{" "}
                <i className="fas fa-caret-down"></i>
              </span>
            )}
            {isFormOpen && (
              <span>
                <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
              </span>
            )}
          </h4>
          <Collapse isOpen={isFormOpen}>
            {formData !== null && (
              <FormBuilder
                form="NewSeason"
                action={reloadData}
                formSetup={formSetup}
                pageRef={pageRef}
                formData={formData}
                lookupData={LookupData}
              />
            )}
          </Collapse>
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(Seasons);

var defaultSetup = {
  options: {
    productCode: "competition_manager",
    subProduct: "datacore"
  },
  copyright: "Synergy Sports",
  languages: ["en-AU", "en-GB", "en-US"]
  // languages: ["en-AU", "en-GB", "es-ES", "fr-FR", "de-DE"]
};

export default defaultSetup;

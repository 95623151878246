import React from "react";
import MasterDisplay from "../../Base/MasterDisplay";
import StandingsAdjustmentsForm from "./StandingsAdjustmentsForm";

const StandingsAdjustments = (props) => {
  const { seasonId } = props.match.params;
  const setup = {
    entityName: "standings/adjustments",
    singleEntityName: "standings.adjustment",
    parent: {
      id: seasonId,
      source: "seasons"
    },
    columns: ["adjustmentField", "adjustmentValue", "adjustmentType", "team"],
    keyProp: "standingAdjustmentId",
    formComponent: StandingsAdjustmentsForm,
    creationFormLabel: "CreateAdjustment",
    searchField: "adjustmentField",
    include: [
      { type: "seasons" },
      {
        type: "entities",
        mapFrom: "entity.id",
        mapWith: "nameFullLocal",
        mapTo: "team"
      }
    ]
  };
  return <MasterDisplay {...props} {...setup} />;
};

export default StandingsAdjustments;

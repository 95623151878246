export const fields = [
  "assists",
  "assistsPerGame",
  "assistsDefensive",
  "assistsTurnoverRatio",
  "blocks",
  "blocksPerGame",
  "blocksReceived",
  "doubleDouble",
  "draws",
  "efficiency",
  "fieldGoalsAttempted",
  "fieldGoalsMade",
  "fieldGoalsEffectivePercentage",
  "fieldGoalsPercentage",
  "freeThrowsAttempted",
  "freeThrowsMade",
  "freeThrowsPercentage",
  "foulsCoachDisqualifying",
  "foulsCoachTechnical",
  "foulsDisqualifying",
  "foulsOffensive",
  "foulsDrawn",
  "foulsPersonal",
  "foulsPersonalPerGame",
  "foulsTechnical",
  "foulsTotal",
  "foulsUnsportsmanlike",
  "games",
  "gamesPercentage",
  "gamesStarted",
  "indexOfSuccess",
  "losses",
  "minus",
  "minutes",
  "minutesPerGame",
  "plus",
  "plusMinus",
  "points",
  "pointsFastBreak",
  "pointsFastBreakAttempted",
  "pointsFastBreakMade",
  "pointsInThePaint",
  "pointsInThePaintMade",
  "pointsInThePaintAttempted",
  "pointsPerGame",
  "pointsSecondChance",
  "pointsSecondChanceAttempted",
  "pointsSecondChanceMade",
  "pointsTwoAttempted",
  "pointsTwoMade",
  "pointsTwoPercentage",
  "pointsThreeAttempted",
  "pointsThreeMade",
  "pointsThreePercentage",
  "rebounds",
  "reboundsPerGame",
  "reboundsDefensive",
  "reboundsDefensivePercentage",
  "reboundsDefensivePerGame",
  "reboundsOffensive",
  "reboundsOffensivePercentage",
  "reboundsOffensivePerGame",
  "reboundsPercentage",
  "steals",
  "stealsPercentage",
  "stealsPerGame",
  "tripleDouble",
  "trueShootingAttempts",
  "trueShootingPercentage",
  "turnovers",
  "turnoversPercentage",
  "turnoversPerGame",
  "wins"
];

export const getTranslatedFields = ({ messages, formatMessage }) => {
  const getTranslationLabel = (field) => {
    if (messages[`stats.full.${field}`]) {
      return `stats.full.${field}`;
    }

    return field;
  };

  return fields
    .map((field) => ({
      text: formatMessage({ id: getTranslationLabel(field) }),
      value: field
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
};

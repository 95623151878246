import React from "react";
import MasterDisplay from "../Base/MasterDisplay";
import DivisionsForm from "./DivisionsForm";
import formSetup from "./formSetup";

function Divisions(props) {
  const { organizationId, conferenceId } = props.match.params;
  const entitySetup = {
    parent: {
      id: conferenceId,
      source: "conferences"
    },
    entityName: "divisions",
    singleEntityName: "division",
    keyProp: "divisionId",
    searchField: "nameLocal",
    columns: ["nameLocal", "nameLatin"],
    formSetup,
    creationFormLabel: "CreateDivision",
    initialFormData: {
      organizationId,
      conferenceId
    },
    formComponent: DivisionsForm,
    include: [
      {
        type: "conferences"
      }
    ]
  };
  return <MasterDisplay {...props} {...entitySetup} />;
}

export default Divisions;

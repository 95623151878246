import { useContext, useState, useEffect } from "react";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
import { useAuth0 } from "../../../Auth/react-auth0-wrapper";
import defaultSetupConfiguration from "../../../config/defaultSetupConfiguration";

export const useToken = () => {
  const [token, setToken] = useState(null);
  const [productsToken, setProductsToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const { readBody = (body) => body.json() } = {};
  const { getTokenSilently, loading } = useAuth0();
  const env = useContext(EnvironmentContext);
  const productCode = defaultSetupConfiguration.options.productCode;
  const abortController = new AbortController();
  const signal = abortController.signal;
  const tokenName = defaultSetupConfiguration.options.subProduct
    ? defaultSetupConfiguration.options.subProduct.replace(/\s/g, "_").toLowerCase() +
      "_" +
      productCode +
      "_tokenDetails"
    : productCode + "_tokenDetails";

  useEffect(() => {
    // localStorage.clear();
    let tokenDetails = localStorage.getItem(tokenName) !== null ? JSON.parse(localStorage.getItem(tokenName)) : null;
    if (localStorage.getItem("productsToken")) {
      setProductsToken(JSON.parse(localStorage.getItem("productsToken")));
    }
    if (tokenDetails && !checkExpiry(tokenDetails.expiry)) {
      //Get New Token
      setCustomerId(tokenDetails.customerId);
      setRefreshToken(true);
    } else {
      setToken(tokenDetails);
    }
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (!loading && refreshToken) {
        try {
          const authToken = await getTokenSilently();
          setAuthToken(authToken);
        } catch (e) {
          console.log("EE (getToken):", e);
        }
      }
    })(); // eslint-disable-next-line
  }, [refreshToken, loading]);

  useEffect(() => {
    (async () => {
      if (refreshToken && authToken) {
        let url = environments.token[env] + "/v1/products/token";
        try {
          let values = {
            userIdentityToken: authToken,
            product: productCode,
            customerId: String(customerId)
          };
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json"
            },
            signal: signal,
            body: JSON.stringify(values)
          });
          if (response.ok) {
            const body = await readBody(response);
            let expiryDelta = body.data.expiresIn * 1000;
            let expiry = expiryDelta + Date.now();
            let token = {
              token: body.data.token,
              tokenType: body.data.tokenType,
              expiry: expiry,
              customerId: customerId
            };
            setToken(token);
            localStorage.setItem(tokenName, JSON.stringify(token));
            localStorage.setItem("productsToken", JSON.stringify(token)); // for consistency with access.js
            setProductsToken(token);
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [refreshToken, authToken]);

  function checkExpiry(tokenDate) {
    if (parseInt(tokenDate) > Date.now()) {
      return true;
    } else {
      return false;
    }
  }

  return { token, productsToken };
};

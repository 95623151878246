export default {
  forms: {
    StaffAdd: {
      endpoint: "v1/#sport/o/#0/roles",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "Staff member added",
      dataFields: ["personId", "role", "entityId", "status", "seasonId"],
      fields: [
        {
          name: "nameFullLocal",
          type: "display",
          source: "extra"
        },
        {
          name: "role",
          type: "select",
          data: ["COACH", "COACH_ASSISTANT", "MANAGER", "PHYSIOTHERAPIST", "DOCTOR", "TRAINER"]
        }
      ]
    },
    StaffEdit: {
      endpoint: "v1/#sport/o/#0/roles/#1",
      parameters: ["organizationId", "roleId"],
      method: "PUT",
      successmessage: "Staff member edited",
      dataFields: ["personId", "role", "entityId"],
      fields: [
        {
          name: "nameFullLocal",
          type: "display",
          source: "extra"
        },
        {
          name: "role",
          type: "select",
          data: ["COACH", "COACH_ASSISTANT", "MANAGER", "PHYSIOTHERAPIST", "DOCTOR", "TRAINER"]
        }
      ]
    }
  }
};

import React from "react";
import InlineForm from "../../../Base/Forms/InlineForm";
import inlineFormSetup from "../inlineFormSetup";
import { injectIntl } from "react-intl";

const StaffCandidate = (props) => {
  const { result: person, match, pageRef, refetch, intl } = props;
  const { formatMessage } = intl;

  const translateRole = (role) =>
    formatMessage({
      id: `roles.${role}`,
      defaultMessage: role
    });

  return (
    <tr>
      <InlineForm
        editMode
        form="StaffAdd"
        pageRef={pageRef}
        extraData={person}
        cancellable={false}
        setEditMode={() => {}}
        action={refetch}
        inlineFormSetup={inlineFormSetup}
        submitIconClassName="fas fa-arrow-right"
        formData={{ ...match.params, ...person, role: "COACH" }}
        optionRenderer={translateRole}
      />
    </tr>
  );
};

export default injectIntl(StaffCandidate);

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import InlineForm from "../../Base/Forms/InlineForm";
import inlineFormSetup from "./inlineFormSetup";

export default function LeadersQualificationTemplate(props) {
  const {
    handleDeleteIntent,
    leaderCriteriaId,
    name,
    refetch,
    pageRef,
    match: {
      params: { organizationId, sport }
    },
    intl: { formatMessage }
  } = props;

  const [isEditState, setEditState] = useState(false);
  const Edit = () => (
    <InlineForm
      action={refetch}
      pageRef={pageRef}
      form="TemplateEdit"
      setEditMode={setEditState}
      inlineFormSetup={inlineFormSetup}
      formData={{ name, leaderCriteriaId, organizationId }}
    />
  );

  const Display = () => (
    <>
      <td>{name}</td>
      <td className="text-right controls-3">
        <Link
          to={"/admin/" + sport + "/" + organizationId + "/leaders-qualification/" + leaderCriteriaId + "/qualifiers"}
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          {formatMessage({
            id: "rules",
            defaultMessage: "Rules"
          })}
        </Link>{" "}
        <Button onClick={() => setEditState(true)} color="primary" size="sm" outline>
          <i className="fas fa-pen" />
        </Button>
        {"  "}
        <Button onClick={() => handleDeleteIntent(leaderCriteriaId)} outline color="danger" size="sm">
          <i className="fas fa-minus" />
        </Button>
      </td>
    </>
  );

  return <tr>{isEditState ? <Edit /> : <Display />}</tr>;
}

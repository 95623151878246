var formSetup = {
  forms: {
    NewFixture: {
      title: "Add New Fixture",
      titletranslation: "fixture.add",
      formClass: "mini-form",
      endpoint: "v1/#sport/o/#0/seasons/#1/fixtures",
      parameters: ["organizationId", "seasonId"],
      method: "POST",
      successmessage: "New fixture added successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: []
      },
      submit: "Add New Fixture",
      submittranslation: "fixture.add",
      submitcolor: "success",
      submitIcon: "fas fa-plus",
      fields: [
        {
          name: "competitorType",
          size: "col-sm-4 col",
          label: "",
          labeltranslation: "",
          type: "hidden",
          placeholder: "EntityType",
          className: "form-control",
          required: false,
          initial: "entityType",
          errors: {
            required: "EntityType Is Required"
          }
        },
        {
          name: "startTimeLocal",
          size: "col-sm-4 col",
          label: "",
          labeltranslation: "",
          type: "hidden",
          placeholder: "EntityType",
          className: "form-control",
          required: false,
          initial: "startTimeLocal",
          errors: {
            required: "startTimeLocal Is Required"
          }
        },
        {
          name: "fixtureType",
          size: "col-sm-4 col",
          label: "",
          labeltranslation: "",
          type: "hidden",
          placeholder: "EntityType",
          className: "form-control",
          required: false,
          initial: "fixtureType",
          errors: {
            required: "fixtureType Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "",
          labeltranslation: "",
          type: "hidden",
          placeholder: "EntityType",
          className: "form-control",
          required: false,
          initial: "status",
          errors: {
            required: "EntityType Is Required"
          }
        }
      ]
    },
    EditFixture: {
      title: "Update Fixture Details",
      titletranslation: "fixture.update",
      endpoint: "v1/#sport/o/#0/fixtures/#1",
      parameters: ["organizationId", "fixtureId"],
      method: "PUT",
      successmessage: "Fixture Details Updated Successfully",
      link: {
        show: false,
        message: "View Fixture",
        path: "/admin/fixtures/",
        parameters: []
      },
      submit: "Update",
      submittranslation: "update",
      fields: [
        {
          name: "added",
          disabled: true,
          readOnly: true,
          size: "col-sm-4 col",
          label: "Added",
          labeltranslation: "added",
          type: "text",
          className: "form-control",
          initial: "added"
        },
        {
          name: "attendance",
          size: "col-sm-4 col",
          label: "Attendance",
          labeltranslation: "attendance",
          type: "number",
          placeholder: "Attendance",
          className: "form-control",
          initial: "attendance",
          min: 0,
          errors: {
            numberMin: "Attendance can't be lower than 0"
          }
        },
        {
          name: "externalId",
          size: "col-sm-4 col",
          label: "External Id",
          labeltranslation: "externalId",
          type: "text",
          placeholder: "External Id",
          className: "form-control",
          initial: "externalId"
        },
        {
          name: "fixtureNumber",
          size: "col-sm-4 col",
          label: "Fixture number",
          labeltranslation: "fixtureNumber",
          type: "number",
          placeholder: "Fixture number",
          className: "form-control",
          initial: "fixtureNumber",
          min: 1,
          errors: {
            numberMin: "Fixture number can't be lower than 1"
          }
        },
        {
          name: "internationalReference",
          size: "col-sm-4 col",
          label: "International reference",
          labeltranslation: "internationalReference",
          type: "text",
          placeholder: "International reference",
          className: "form-control",
          initial: "internationalReference"
        },
        {
          name: "liveDataAvailable",
          size: "col-sm-4 col",
          label: "Live Data available",
          labeltranslation: "liveDataAvailable",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          className: "form-control",
          initial: "liveDataAvailable"
        },
        {
          name: "liveVideoAvailable",
          readOnly: true,
          size: "col-sm-4 col",
          label: "Live Video available",
          labeltranslation: "liveVideoAvailable",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          className: "form-control",
          initial: "liveVideoAvailable"
        },
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.local",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          initial: "nameLocal"
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Local Name:",
          labeltranslation: "name.latin",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          initial: "nameLatin"
        },
        {
          name: "placingIfLost",
          size: "col-sm-4 col",
          label: "Placing if lost",
          labeltranslation: "placingIfLost",
          type: "number",
          min: 1,
          errors: {
            numberMin: "Placing can't be lower than 1"
          },
          placeholder: "Placing if lost",
          className: "form-control",
          initial: "placingIfLost"
        },
        {
          name: "placingIfWon",
          size: "col-sm-4 col",
          label: "Placing if won",
          labeltranslation: "placingIfWon",
          type: "number",
          placeholder: "Placing if won",
          className: "form-control",
          initial: "placingIfWon",
          min: 1,
          errors: {
            numberMin: "Placing can't be lower than 1"
          }
        },
        {
          name: "poolCode",
          size: "col-sm-4 col",
          label: "Pool Code",
          labeltranslation: "poolCode",
          type: "lookup",
          placeholder: "Pool Code",
          className: "form-control",
          initial: "poolCode",
          ignoreIfNull: true,
          lookup: {
            endpoint: "v1/#sport/o/#0/seasons/#1/pools",
            text: "poolCode",
            value: "poolCode",
            parameters: ["organizationId", "seasonId"]
          }
        },
        {
          name: "roundCode",
          size: "col-sm-4 col",
          label: "Round Code",
          labeltranslation: "roundCode",
          type: "lookup",
          placeholder: "Round Code",
          className: "form-control",
          initial: "roundCode",
          ignoreIfNull: true,
          lookup: {
            endpoint: "v1/#sport/o/#0/seasons/#1/rounds",
            text: "roundCode",
            value: "roundCode",
            parameters: ["organizationId", "seasonId"]
          }
        },
        {
          name: "roundNumber",
          size: "col-sm-4 col",
          label: "Round number",
          labeltranslation: "roundNumber",
          type: "text",
          placeholder: "Round Number",
          className: "form-control",
          disabled: true,
          initial: "roundNumber"
        },
        {
          name: "sellout",
          size: "col-sm-4 col",
          label: "Sellout",
          labeltranslation: "sellout",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          className: "form-control",
          initial: "sellout"
        },
        {
          name: "startTimeActualUTC",
          disabled: true,
          readOnly: true,
          size: "col-sm-4 col",
          label: "Actual UTC start time",
          labeltranslation: "startTimeActualUTC",
          type: "text",
          className: "form-control",
          initial: "startTimeActualUTC"
        },
        {
          name: "startTimeUTC",
          readOnly: true,
          size: "col-sm-4 col",
          label: "UTC Start time",
          labeltranslation: "startTimeUTC",
          type: "datetime",
          className: "form-control",
          initial: "startTimeUTC"
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status",
          labeltranslation: "status",
          type: "select",
          className: "form-control",
          initial: "status",
          options: [
            {
              value: "ABANDONED",
              translation: "abandoned",
              text: "Abandoned"
            },
            {
              value: "BYE",
              translation: "bye",
              text: "Bye"
            },
            {
              value: "CANCELLED",
              translation: "cancelled",
              text: "Cancelled"
            },
            {
              value: "CONFIRMED",
              translation: "confirmed",
              text: "Confirmed"
            },
            {
              value: "FINISHED",
              translation: "finished",
              text: "Finished"
            },
            {
              value: "IF_NEEDED",
              translation: "ifNeeded",
              text: "If Needed"
            },
            {
              value: "IN_PROGRESS",
              translation: "inProgress",
              text: "In Progress"
            },
            {
              value: "POSTPONED",
              translation: "postponed",
              text: "Postponed"
            },
            {
              value: "SCHEDULED",
              translation: "scheduled",
              text: "Scheduled"
            }
          ]
        },
        {
          name: "ticketURL",
          size: "col-sm-4 col",
          label: "Ticket URL",
          labeltranslation: "ticketURL",
          type: "text",
          placeholder: "Ticket URL",
          className: "form-control",
          initial: "ticketURL"
        },
        {
          name: "timesUnconfirmed",
          size: "col-sm-4 col",
          label: "Time is unconfirmed",
          labeltranslation: "timesUnconfirmed",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          className: "form-control",
          initial: "timesUnconfirmed"
        },
        {
          name: "updated",
          disabled: true,
          readOnly: true,
          size: "col-sm-4 col",
          label: "Updated",
          labeltranslation: "updated",
          type: "text",
          className: "form-control",
          initial: "updated"
        }
      ]
    },
    EditFixtureEntity: {
      title: "Update Team Details",
      titletranslation: "teamDetailsUpdate",
      endpoint: "v1/#sport/o/#0/fixtures/#1/entities",
      parameters: ["organizationId", "fixtureId"],
      method: "POST",
      successmessage: "Fixture Details Updated Successfully",
      link: {
        show: false,
        message: "View Fixture",
        path: "/admin/fixtures/",
        parameters: []
      },
      submit: "Update",
      submittranslation: "update",
      fields: [
        {
          name: "draw",
          size: "col-sm-6 col",
          label: "Draw",
          labeltranslation: "fixtureDraw",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          className: "form-control",
          initial: "draw"
        },
        {
          name: "externalId",
          size: "col-sm-6 col",
          label: "External Id",
          labeltranslation: "externalId",
          type: "text",
          className: "form-control",
          initial: "externalId"
        },
        {
          name: "resultPlace",
          size: "col-sm-6 col",
          label: "Result placing",
          labeltranslation: "resultPlace",
          type: "select",
          className: "form-control",
          initial: "resultPlace",
          options: [
            {
              value: "",
              translation: "-",
              text: "-"
            },
            {
              value: 1,
              translation: "won",
              text: "Won"
            },
            {
              value: 2,
              translation: "lost",
              text: "Lost"
            }
          ]
        },
        {
          name: "resultStatus",
          size: "col-sm-6 col",
          label: "Result status",
          labeltranslation: "resultStatus",
          type: "select",
          required: true,
          className: "form-control",
          initial: "resultStatus",
          options: [
            {
              value: "CONFIRMED",
              translation: "confirmed",
              text: "CONFIRMED"
            },
            {
              value: "DID_NOT_FINISH",
              translation: "didNotFinish",
              text: "DID_NOT_FINISH"
            },
            {
              value: "DID_NOT_START",
              translation: "didNotStart",
              text: "DID_NOT_START"
            },
            {
              value: "DISQUALIFIED",
              translation: "disqualified",
              text: "DISQUALIFIED"
            },
            {
              value: "FORFEITED",
              translation: "forfeited",
              text: "FORFEITED"
            },
            {
              value: "IN_PROGRESS",
              translation: "inProgress",
              text: "IN_PROGRESS"
            },
            {
              value: "SCHEDULED",
              translation: "scheduled",
              text: "SCHEDULED"
            },
            {
              value: "WITHDRAWN",
              translation: "withdrawn",
              text: "WITHDRAWN"
            },
            {
              value: "WON_BY_FORFEIT",
              translation: "wonByForfeit",
              text: "WON_BY_FORFEIT"
            }
          ]
        },
        {
          name: "score",
          size: "col-sm-6 col",
          label: "Score",
          labeltranslation: "score",
          type: "text",
          className: "form-control",
          initial: "score"
        },
        {
          name: "fixtureId",
          hidden: true,
          type: "text",
          initial: "fixtureId"
        },
        {
          name: "entityId",
          hidden: true,
          type: "text",
          initial: "entityId"
        },
        {
          name: "secondaryScore",
          size: "col-sm-6 col",
          label: "Secondary Score",
          labeltranslation: "secondaryScore",
          type: "text",
          className: "form-control",
          initial: "secondaryScore"
        },
        {
          name: "includeInConferenceStatistics",
          size: "col-sm-12 col",
          label: "Include in Conference statistics",
          labeltranslation: "includeInConferenceStatistics",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          className: "form-control",
          initial: "includeInConferenceStatistics"
        },
        {
          name: "isHome",
          type: "text",
          className: "form-control",
          initial: "isHome",
          hidden: true
        },
        {
          name: "isNeutralVenue",
          size: "col-sm-12 col",
          label: "Competitor playing at a neutral venue?",
          labeltranslation: "isNeutralVenue",
          type: "select-boolean",
          options: [
            {
              value: false,
              translation: "no",
              text: "no"
            },
            {
              value: true,
              translation: "yes",
              text: "yes"
            }
          ],
          className: "form-control",
          initial: "isNeutralVenue"
        }
      ]
    }
  }
};
export default formSetup;

import React, { useState, useEffect } from "react";
import { Spinner, Table } from "reactstrap";

const TableDisplay = (props) => {
  const {
    containerClass,
    columns,
    columnTitles = [],
    rows,
    loading,
    fixed = false,
    headerCellClassName = "",
    withButtonsCell = false
  } = props;
  const [headerData, setHeaderData] = useState(null);

  useEffect(() => {
    if (columns) {
      if (withButtonsCell) {
        columns.push("");
      }
      const headerColumns = columns.map((column, index) => (
        <th className={headerCellClassName} key={index} title={columnTitles[index]}>
          {column}
        </th>
      ));
      setHeaderData(headerColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <React.Fragment>
      <div className={containerClass + " disabled-" + loading + " fixed-" + fixed}>
        <Table striped hover>
          <thead>
            <tr>{headerData}</tr>
          </thead>
          {fixed && (
            <colgroup>
              <col span="1" className="fixed-column" />
            </colgroup>
          )}
          {rows && rows.length > 0 && <tbody>{rows}</tbody>}
          {rows && rows.length === 0 && (
            <tbody>
              <tr className="no-records">
                <td>No records found</td>
              </tr>
            </tbody>
          )}
        </Table>
        {loading && <Spinner size="md" color="orange" />}
      </div>
    </React.Fragment>
  );
};

export default TableDisplay;

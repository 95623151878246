/**
 * Composes a string representation for the fixture result
 * e.g homeScore - awayScore
 * Fixture has to be status === "CONFIRMED"
 * If one or both scores are null - returns "N/A"
 * @param {object} result
 * @returns {string}
 */
export function getScore(result) {
  if (result.status === "CONFIRMED") {
    const homeTeam = result.competitors.find((c) => c.isHome);
    const awayTeam = result.competitors.find((c) => !c.isHome);
    if (
      homeTeam &&
      awayTeam &&
      homeTeam.hasOwnProperty("score") &&
      awayTeam.hasOwnProperty("score")
    ) {
      return `${homeTeam.score} - ${awayTeam.score}`;
    }
  }
  return "";
}

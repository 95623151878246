import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import TeamPlayerStatsRow from "./TeamPlayerStatsRow";
import TeamCoachStatsRow from "./TeamCoachStatsRow";
import TableDisplay from "../../Base/Shared/TableDisplay";
import nameSort from "./name-sort";

const statColumns = [
  "games",
  "minutes",
  "fieldGoals",
  "fieldGoalsPercentage",
  "pointsTwo",
  "pointsTwoPercentage",
  "pointsThree",
  "pointsThreePercentage",
  "freeThrows",
  "freeThrowsPercentage",
  "reboundsOffensive",
  "reboundsDefensive",
  "rebounds",
  "assists",
  "turnovers",
  "steals",
  "blocks",
  "fouls",
  "foulsDrawn",
  "points"
];

const TeamPlayerStatsTotal = (props) => {
  const { title, data, intl, match, team } = props;
  const { formatMessage } = intl;
  const [tableData, setTableData] = useState();

  const columns = [
    formatMessage({
      id: "name.local.full",
      defaultMessage: "Local Full Name"
    }),
    ...statColumns.map((stat) => formatMessage({ id: `stats.abbrev.${stat}` }))
  ];

  const columnTitles = ["", ...statColumns.map((stat) => formatMessage({ id: `stats.full.${stat}` }))];

  useEffect(() => {
    if (data) {
      let filteredData = data !== null ? data : [];
      let tableList = filteredData
        .sort(nameSort)
        .concat(team)
        .map((result, index) => {
          if (result.person) {
            return (
              <TeamPlayerStatsRow
                key={index}
                itemIndex={index}
                result={result}
                match={match}
                includes={data.includes.resources}
                type={"totals"}
              />
            );
          } else {
            return (
              <TeamCoachStatsRow
                key={index}
                itemIndex={index}
                result={result}
                match={match}
                includes={data.includes.resources}
                type={"totals"}
              />
            );
          }
        });
      setTableData(tableList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <React.Fragment>
      <h5>{title} - Player Totals </h5>
      <TableDisplay
        containerClass="table-responsive"
        columns={columns}
        columnTitles={columnTitles}
        rows={tableData}
        loading={false}
      />
    </React.Fragment>
  );
};

export default injectIntl(TeamPlayerStatsTotal);

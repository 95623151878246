import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

export default function SeasonProfileRow(props) {
  const {
    name,
    handleDeleteIntent,
    defaultProfile,
    match: {
      params: { sport, organizationId }
    },
    profileId
  } = props;

  return (
    <tr>
      <td>
        <Link to={"/admin/" + sport + "/" + organizationId + `/match-profiles/${profileId}`} className="link">
          {name}
        </Link>{" "}
      </td>
      <td>{defaultProfile ? <i className="fas fa-check" /> : ""}</td>
      <td className="text-right controls-6">
        <Link
          to={"/admin/" + sport + "/" + organizationId + `/match-profiles/${profileId}`}
          className="btn btn-outline-primary btn-sm"
        >
          <i className="fas fa-pen" />
        </Link>{" "}
        <Button onClick={() => handleDeleteIntent(profileId)} outline color="danger" size="sm">
          <i className="fas fa-minus" />
        </Button>{" "}
      </td>
    </tr>
  );
}

import React, { useReducer } from "react";
import { Container, FormGroup, Col, Input, Label, Button } from "reactstrap";
import { injectIntl } from "react-intl";
import "./StandingsConfigurations.scss";

function reducer(state, action) {
  switch (action.type) {
    case "submit":
      return {
        ...state,
        mainValue: action.payload.newMainDefault.value,
        secondaryValue: action.payload.newSecondaryDefault.value
      };
    case "change":
      const { value, valueTag } = action.payload;
      return {
        ...state,
        [valueTag]: value
      };

    default:
      return state;
  }
}

function ObjectList(props) {
  const {
    indexField,
    secondaryField,
    fieldSetup,
    field: { name },
    form: {
      values: { configuration }
    },
    intl: { formatMessage }
  } = props;

  const [state, dispatch] = useReducer(reducer, {
    ...fieldSetup,
    indexField,
    mainValue: "",
    secondaryValue: ""
  });

  const applyFormValues = (values) => props.form.setFieldValue(`configuration.${props.field.name}`, values);

  function getSecondaryFields() {
    const { [indexField]: main, ...secondaryFields } = fieldSetup;
    return secondaryFields;
  }

  function handleValueChange(event, field, valueTag) {
    dispatch({
      type: "change",
      payload: {
        valueTag,
        field,
        value: event.target.value
      }
    });
  }

  function submit() {
    let payload = {};
    const options = getAdjustedIndexOptions();
    const { mainValue, secondaryValue } = state;
    if (!mainValue) {
      payload[indexField] = options[0].value;
    } else {
      payload[indexField] = mainValue;
    }

    if (secondaryField) {
      if (!secondaryValue) {
        payload[secondaryField] = fieldSetup[secondaryField][0].value;
      } else {
        payload[secondaryField] = secondaryValue;
      }
    }

    const updatedValues = configuration[name] ? [...configuration[name], payload] : [payload];

    dispatch({
      type: "submit",
      payload: {
        newMainDefault: options.length > 1 ? options.slice(1)[0] : { value: "NONE", label: "NONE" },
        newSecondaryDefault: secondaryField ? fieldSetup[secondaryField][0] : ""
      }
    });
    applyFormValues(updatedValues);
  }

  function renderSecondaryInputs(isDisabled) {
    const fields = getSecondaryFields();
    const keyedFields = Object.keys(fields);
    if (keyedFields.length === 0) {
      return;
    }

    return keyedFields.map((field) => (
      <FormGroup key={field}>
        <Label>{formatMessage({ id: field })}</Label>
        <Input
          disabled={isDisabled}
          value={state.secondaryValue}
          type="select"
          onChange={(e) => handleValueChange(e, field, "secondaryValue")}
        >
          {fields[field].map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </Input>
      </FormGroup>
    ));
  }

  function getAdjustedIndexOptions() {
    const indexOptions = fieldSetup[indexField];
    return indexOptions.filter((option) => {
      if (!configuration || !configuration[name] || configuration[name].length === 0) {
        return true;
      }
      const predicate = configuration[name].map((a) => a[indexField]).some((o) => o === option.value);
      return !predicate;
    });
  }

  function renderFieldAddControls() {
    const isEditingForbidden = getAdjustedIndexOptions().length === 0;
    return (
      <Col>
        <FormGroup>
          <Label>{formatMessage({ id: indexField })}</Label>
          <Input
            disabled={isEditingForbidden}
            type="select"
            value={state.mainValue}
            onChange={(e) => handleValueChange(e, indexField, "mainValue")}
          >
            {isEditingForbidden && (
              <option value="NONE">{formatMessage({ id: "NONE", defaultMessage: "NONE" })}</option>
            )}
            {getAdjustedIndexOptions().map(({ value, label }) => (
              <option value={value} key={value}>
                {formatMessage({ id: value, defaultMessage: label })}
              </option>
            ))}
          </Input>
        </FormGroup>
        {renderSecondaryInputs(isEditingForbidden)}
        <Button disabled={isEditingForbidden} block color="success" onClick={submit} type="button">
          {formatMessage({ id: "add" })}
        </Button>
      </Col>
    );
  }

  function handleFieldDelete(field) {
    const updatedList = configuration[name].filter((v) => v[indexField] !== field[indexField]);
    applyFormValues(updatedList);
  }

  function handleOrderingClick(index, direction) {
    const list = configuration[name];
    let center = list[index];
    let swappedItem = list[index - 1];
    let less = list.filter((_, idx) => idx < index);
    let more = list.filter((_, idx) => idx > index);
    let newArray = [];
    if (direction === "up") {
      newArray = [...less.slice(0, index - 1), center, swappedItem, ...more];
    } else {
      swappedItem = list[index + 1];
      newArray = [...less, swappedItem, center, ...more.slice(1, list.length - 1)];
    }
    applyFormValues(newArray);
  }

  function renderSelectedFields() {
    const values = configuration[name];
    if (values && Array.isArray(values)) {
      return values.map((v, index) => (
        <div className="object-list-item">
          <div className="display-controls">
            <Button
              onClick={() => handleOrderingClick(index, "up")}
              disabled={index === 0}
              type="button"
              className="arrow-button"
              outline
              color="secondary"
            >
              <i className="fas fa-arrow-up" />
            </Button>
            <Button
              onClick={() => handleOrderingClick(index, "down")}
              disabled={index === values.length - 1}
              type="button"
              className="arrow-button"
              outline
              color="secondary"
            >
              <i className="fas fa-arrow-down" />
            </Button>
            {formatMessage({ id: v[indexField] })} {secondaryField && `(${v[secondaryField]})`}
          </div>
          <Button outline color="danger" type="button" onClick={() => handleFieldDelete(v)}>
            <i className="fas fa-minus" />
          </Button>
        </div>
      ));
    }
  }

  return (
    <Container>
      {renderFieldAddControls()}
      <Col>{renderSelectedFields()}</Col>
    </Container>
  );
}

export default injectIntl(ObjectList);

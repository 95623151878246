import React from "react";
import { Route, Redirect } from "react-router";

import Clubs from "../Clubs/Clubs";
import EditClub from "../Clubs/EditClub";
import Competitions from "../Competitions/Competitions";
import EditCompetitions from "../Competitions/EditCompetitions";
import Leagues from "../Leagues/Leagues";
import EditLeagues from "../Leagues/EditLeagues";
import Persons from "../Persons/Persons";
import EditPerson from "../Persons/EditPerson";
import Sites from "../Sites/Sites";
import EditSite from "../Sites/EditSite";
import Teams from "../Teams/Teams";
import EditTeam from "../Teams/EditTeam";
import Venues from "../Venues/Venues";
import EditVenue from "../Venues/EditVenue";
import Conferences from "../Conferences/Conferences";
import ConferencesForm from "../Conferences/ConferencesForm";
import Divisions from "../Divisions/Divisions";
import DivisionsForm from "../Divisions/DivisionsForm";

import StandingsConfiguration from "../StandingsConfigurations/StandingsConfigurations";
import StandingsConfigurationsForm from "../StandingsConfigurations/StandingsConfigurationsForm";
import LeadersQualification from "../LeadersQualification/Criterias/LeadersQualification";
import LeaderQualifiers from "../LeadersQualification/Rules/Rules";

import MatchProfiles from "../MatchProfiles/MatchProfiles";
import EditMatchProfile from "../MatchProfiles/EditMatchProfile";

const AdminRoutes = (props) => {
  const { title, updateState, mainState } = props;
  const { orgId } = mainState;

  return (
    <>
      <Route
        exact
        path={"/home"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + props.mainState.orgId + "/admin"} />}
      />
      <Route
        exact
        path={"/admin/leagues"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/leagues"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/leagues"}
        render={(props) => <Leagues {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/leagues/:leagueId"}
        render={(props) => <EditLeagues {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/competitions"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/competitions"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/competitions"}
        render={(props) => <Competitions {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/competitions/:competitionId"}
        render={(props) => <EditCompetitions {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/clubs"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/clubs"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/clubs"}
        render={(props) => <Clubs {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/clubs/:clubId"}
        render={(props) => <EditClub {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/teams"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/teams"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/teams"}
        render={(props) => <Teams {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/teams/:teamId"}
        render={(props) => <EditTeam {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/sites"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/sites"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/sites"}
        render={(props) => <Sites {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/sites/:siteId"}
        render={(props) => <EditSite {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/venues"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/venues"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/venues"}
        render={(props) => <Venues {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/venues/:venueId"}
        render={(props) => <EditVenue {...props} updateState={updateState} title={title} />}
      />{" "}
      <Route
        exact
        path={"/admin/persons"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/persons"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/persons"}
        render={(props) => <Persons {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/persons/:personId"}
        render={(props) => <EditPerson {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/conferences"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/conferences"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/conferences"}
        render={(props) => <Conferences {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/conferences/:conferenceId"}
        render={(props) => <ConferencesForm {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/conferences/:conferenceId/divisions"}
        render={(props) => <Divisions {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/conferences/:conferenceId/divisions/:divisionId"}
        render={(props) => <DivisionsForm {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/standings-configurations"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/standings-configurations"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/standings-configurations"}
        render={(props) => <StandingsConfiguration {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/standings-configurations/create"}
        render={(props) => <StandingsConfigurationsForm {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/standings-configuration/:standingsConfigurationId"}
        render={(props) => <StandingsConfigurationsForm {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/leaders-qualification"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/leaders-qualification"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/leaders-qualification"}
        render={(props) => <LeadersQualification {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/leaders-qualification/:leaderCriteriaId/qualifiers"}
        render={(props) => <LeaderQualifiers {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/match-profiles"}
        render={() => <Redirect to={"/admin/" + mainState.sport + "/" + orgId + "/match-profiles"} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/match-profiles"}
        render={(props) => <MatchProfiles {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/:sport/:organizationId/match-profiles/:matchProfileId"}
        render={(props) => <EditMatchProfile {...props} updateState={updateState} title={title} />}
      />
    </>
  );
};

export default AdminRoutes;

import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";

const displayModes = ["BASIC", "ALL"];
function DisplayModeSelector({ displayMode, onDisplayModeChange }) {
  function handleDisplayModeChage(event) {
    onDisplayModeChange(event.target.value);
  }
  function renderDisplayMode(mode) {
    return (
      <option key={mode} value={mode}>
        {mode}
      </option>
    );
  }
  return (
    displayMode && (
      <FormGroup>
        <Label>
          <FormattedMessage id="displayMode" defaultMessage="Display Mode" />
        </Label>
        <Input type="select" onChange={handleDisplayModeChage} value={displayMode}>
          {displayModes.map(renderDisplayMode)}
        </Input>
      </FormGroup>
    )
  );
}

export default injectIntl(DisplayModeSelector);

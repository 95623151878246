import React, { useState } from "react";
import { Container } from "reactstrap";
import { HelmetProvider } from "react-helmet-async";
import { Route, Redirect } from "react-router-dom";

import { useAuth0 } from "./Auth/react-auth0-wrapper";

import defaultSetupConfiguration from "./config/defaultSetupConfiguration";
import { EnvironmentContext } from "./config/EnvironmentContext";

import OrganizationRoutes from "./Components/Organizations/OrganizationRoutes";
import AdminRoutes from "./Components/ManageOrganization/AdminRoutes";
import SeasonRoutes from "./Components/Seasons/SeasonRoutes";

import Login from "./Components/Base/Login/Login";
import Header from "./Components/Base/Header/Header";
import Sidedrawer from "./Components/Base/Sidedrawer/Sidedrawer";
import Footer from "./Components/Base/Footer/Footer";
import UserProfile from "./Components/Base/UserProfile/UserProfile";
import LanguageSelect from "./Components/Base/LanguageSelect/LanguageSelect";

import "./App.scss";

import sideLogo from "./Components/Base/Assets/Atrium-Negative.png";
import { StaffContext } from "./config/StaffContext";
import { CheckStaffPermissions } from "./Components/Base/Extras/Utilities/CheckScope";
import { useAccess } from "./Components/Base/Hooks/access";
import { useToken } from "./Components/Base/Hooks/token";

const App = (props) => {
  const { environment, language, locale } = props;
  const { loading, user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const title = "Competition Admin";
  const setup = defaultSetupConfiguration;
  const [mainState, setMainState] = useState({
    ...JSON.parse(localStorage.getItem("mainState")),
    fluid: false
  });
  const [token, setToken] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);
  const [products, setProducts] = useState({});
  const [, updateAllState] = React.useState();
  const forceUpdate = React.useCallback(() => updateAllState({}), []);
  const { productsToken: productsTokenFromUseAccess } = useAccess();
  const { productsToken: productsTokenFromUseToken } = useToken();
  // const [fluidLayout, setFluidLayout] = useState(false);

  function updateState(key, data) {
    let thisState = mainState;
    if (mainState[key] !== data) {
      thisState[key] = data;
      setMainState(thisState);
      localStorage.setItem("mainState", JSON.stringify(thisState));
      forceUpdate();
    }
  }

  const logoutWithRedirect = () => {
    setToken(null);
    setHasAccess(false);
    if (token) {
      localStorage.removeItem("productList_" + token.customerId);
    }
    let tokenName = setup.options.subProduct
      ? setup.options.subProduct.replace(/\s/g, "_").toLowerCase() + "_" + setup.options.productCode + "_tokenDetails"
      : setup.options.productCode + "_tokenDetails";
    localStorage.removeItem(tokenName);
    logout({
      returnTo: window.location.origin
    });
  };

  const isStaffScope = () => {
    if (
      productsTokenFromUseToken?.expiry &&
      productsTokenFromUseAccess?.expiry &&
      productsTokenFromUseAccess?.expiry > productsTokenFromUseToken?.expiry
    ) {
      return CheckStaffPermissions(productsTokenFromUseAccess);
    }
    return CheckStaffPermissions(productsTokenFromUseToken);
  };

  return (
    <EnvironmentContext.Provider value={environment}>
      <StaffContext.Provider value={isStaffScope}>
        <HelmetProvider>
          <Header
            title={title}
            login={loginWithRedirect}
            logout={logoutWithRedirect}
            isAuthenticated={isAuthenticated}
            hasAccess={hasAccess}
            orgId={mainState.orgId}
            userprofile={<UserProfile {...props} user={user} />}
            languageselect={<LanguageSelect setup={setup} locale={locale} language={language} />}
          />
          <Sidedrawer
            logout={logoutWithRedirect}
            isAuthenticated={isAuthenticated}
            hasAccess={hasAccess}
            products={products}
            logo={sideLogo}
            orgId={mainState.orgId}
          />
          {!loading && (
            <Container fluid={mainState.fluid} className="mainbody">
              <Login
                login={loginWithRedirect}
                setup={setup}
                loading={loading}
                isAuthenticated={isAuthenticated}
                setToken={setToken}
                setHasAccess={setHasAccess}
                token={token}
                setProducts={setProducts}
              />
              {isAuthenticated && token && (
                <React.Fragment>
                  <Route exact path="/" render={() => <Redirect to="/select/organization" />} />
                  <OrganizationRoutes {...props} updateState={updateState} title={title} setup={setup} />
                  <AdminRoutes {...props} updateState={updateState} title={title} mainState={mainState} setup={setup} />
                  <SeasonRoutes
                    {...props}
                    updateState={updateState}
                    title={title}
                    mainState={mainState}
                    setup={setup}
                  />
                </React.Fragment>
              )}
            </Container>
          )}
          <Footer setup={setup} />
        </HelmetProvider>
      </StaffContext.Provider>
    </EnvironmentContext.Provider>
  );
};

export default App;

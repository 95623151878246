import { useContext, useState, useEffect } from "react";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
import { useAuth0 } from "../../../Auth/react-auth0-wrapper";

export const useAccess = (loading, isAuthenticated, productCode, customer, subProduct = null) => {
  const [productToken, setToken] = useState(null);
  const [productsToken, setProductsToken] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [tokenStorage, setTokenStorage] = useState(null);
  const [getCustomers, setGetCustomers] = useState(false);
  const [customerList, setCustomerList] = useState(null);
  const [productList, setProductList] = useState(null);
  const [rawProductList, setRawProductList] = useState(null);
  const [applicationId, setApplicationId] = useState(null);
  const { readBody = (body) => body.json() } = {};
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvironmentContext);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const tokenName = subProduct
    ? subProduct.replace(/\s/g, "_").toLowerCase() + "_" + productCode + "_tokenDetails"
    : productCode + "_tokenDetails";

  useEffect(() => {
    let tokenDetails = localStorage.getItem(tokenName) ? JSON.parse(localStorage.getItem(tokenName)) : null;
    if (localStorage.getItem("productsToken")) {
      setProductsToken(JSON.parse(localStorage.getItem(tokenName)));
    }
    setTokenStorage(tokenDetails);
    if (tokenDetails) {
      // let payload = JSON.parse(atob(tokenDetails.token.split(".")[1]));
      // console.log(payload);
    }
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (!loading && isAuthenticated) {
        try {
          const authToken = await getTokenSilently();
          setAuthToken(authToken);
        } catch (e) {
          console.log("EE (getToken):", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    (async () => {
      if (tokenStorage) {
        if (checkExpiry(tokenStorage.expiry)) {
          setToken(tokenStorage);
        } else {
          // localStorage.removeItem(tokenName);
          setTokenStorage(null);
          if (authToken) {
            setGetCustomers(true);
          }
        }
      } else {
        if (authToken) {
          setGetCustomers(true);
        }
      }
    })();
    // eslint-disable-next-line
  }, [authToken, tokenStorage]);

  useEffect(() => {
    (async () => {
      if (authToken) {
        let url = environments.token[env] + "/v1/list/customers";
        try {
          let values = {
            userIdentityToken: authToken,
            product: productCode
          };
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json"
            },
            signal: signal,
            body: JSON.stringify(values)
          });
          if (response.ok) {
            const body = await readBody(response);
            setCustomerList(body.data.customers);
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [getCustomers]);

  useEffect(() => {
    (async () => {
      if (rawProductList && authToken && customer > 0) {
        setCustomerList(null);

        let url = "";
        let values = null;

        if (productCode === "datacore") {
          url = environments.token[env] + "/v1/oauth2/rest/token";
          let subProducts = rawProductList.find((el) => el.code === productCode).subProducts;
          let applicationId = subProducts.find((el) => el.subProductName === subProduct).subProductId;
          values = {
            userIdentityToken: authToken,
            applicationId: applicationId,
            sport: "basketball"
            // organization: {
            //   id: ["btest"],
            // },
          };
        } else {
          url = environments.token[env] + "/v1/products/token";
          values = {
            userIdentityToken: authToken,
            product: productCode,
            customerId: String(customer)
          };
        }

        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json"
            },
            signal: signal,
            body: JSON.stringify(values)
          });
          if (response.ok) {
            const body = await readBody(response);
            let expiryDelta = body.data.expiresIn * 1000;
            let expiry = expiryDelta + Date.now();
            let token = {
              token: body.data.token,
              tokenType: body.data.tokenType,
              expiry: expiry,
              customerId: customer
            };
            if (url.includes("/products/token")) {
              localStorage.setItem("productsToken", JSON.stringify(token));
              setProductsToken(token);
            }
            if (subProduct === "datacore") {
              let payload = JSON.parse(atob(token.token.split(".")[1]));
              setApplicationId(payload.appId);
            } else {
              setToken(token);
              localStorage.setItem(tokenName, JSON.stringify(token));
              setTokenStorage(token);
            }
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [customer, authToken, rawProductList]);

  useEffect(() => {
    (async () => {
      if (applicationId) {
        let url = environments.token[env] + "/v1/oauth2/rest/token";
        let values = {
          userIdentityToken: authToken,
          applicationId: applicationId,
          sport: "basketball"
        };
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json"
            },
            signal: signal,
            body: JSON.stringify(values)
          });
          if (response.ok) {
            const body = await readBody(response);
            let expiryDelta = body.data.expiresIn * 1000;
            let expiry = expiryDelta + Date.now();
            let token = {
              token: body.data.token,
              tokenType: body.data.tokenType,
              expiry: expiry,
              customerId: customer
            };
            setToken(token);
            localStorage.setItem(tokenName, JSON.stringify(token));
            setTokenStorage(token);
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();

    // eslint-disable-next-line
  }, [applicationId]);

  useEffect(() => {
    let productStorage = null;
    if (tokenStorage !== null) {
      productStorage =
        localStorage.getItem("productList_" + tokenStorage.customerId) !== null
          ? JSON.parse(localStorage.getItem("productList_" + tokenStorage.customerId))
          : null;
    }
    if (productStorage) {
      setProductList(productStorage);
    } else {
      (async () => {
        if (authToken && customer > 0) {
          let url = environments.token[env] + "/v1/list/products";
          try {
            let values = {
              userIdentityToken: authToken,
              customerId: String(customer)
            };
            const response = await fetch(url, {
              method: "POST",
              headers: {
                Accept: "application/json"
              },
              signal: signal,
              body: JSON.stringify(values)
            });
            if (response.ok) {
              const body = await readBody(response);
              let productObject = {};
              setRawProductList(body.data.products);
              body.data.products.forEach((product) => {
                productObject[product.code] = true;
                if (product.subProducts) {
                  product.subProducts.forEach((subProduct) => {
                    productObject[product.name + "_" + subProduct.subProductName.replace(/\s/g, "_").toLowerCase()] =
                      true;
                  });
                }
              });
              localStorage.setItem("productList_" + customer, JSON.stringify(productObject));
              setProductList(productObject);
            } else {
              const body = await readBody(response);
              console.log("error", body);
            }
          } catch (e) {
            console.log("E", e);
          }
        }
      })();
    }
    // eslint-disable-next-line
  }, [customer, authToken]);

  function checkExpiry(tokenDate) {
    if (parseInt(tokenDate) > Date.now()) {
      return true;
    } else {
      return false;
    }
  }

  return { productToken, customerList, productList, productsToken };
};

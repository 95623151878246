import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { injectIntl } from "react-intl";
import { useToken } from "../../Base/Hooks/token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";

function BuildStandings(props) {
  const {
    intl,
    match: {
      params: { sport, organizationId, seasonId }
    },
    disabled
  } = props;
  const { formatMessage } = intl;
  const env = useContext(EnvironmentContext);

  const [revision, setRevision] = useState(0);
  const { token } = useToken();

  const handleBuild = () => {
    setRevision(revision + 1);
  };

  const url = `/v1/${sport}/o/${organizationId}/seasons/${seasonId}/standings/build`;

  const handleSuccess = (result) => {
    if (result.data && result.data[0] && result.data[0].success && props.pageRef) {
      props.pageRef.current.addFlash(
        formatMessage({
          id: "standings.build.success",
          defaultMessage: "Standings Build success"
        }),
        "success",
        5
      );
      props.refetch();
    }
  };

  const handleError = (e) => {
    props.pageRef.current.addFlash(String(e), "danger", 5);
  };

  useEffect(() => {
    if (revision) {
      let tokenType = token.tokenType.charAt(0).toUpperCase() + token.tokenType.substr(1).toLowerCase();
      const envURL = environments["location"][env] + url;
      fetch(envURL, {
        method: "POST",
        headers: {
          Authorization: tokenType + " " + token.token
        }
      })
        .then((r) => r.json())
        .then(handleSuccess)
        .catch(handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revision]);

  const tooltip =
    disabled &&
    formatMessage({
      id: "standings.build.disabled.tooltip",
      defaultMessage: "You need to create a Standings Configuration before building standings for this season"
    });

  return (
    <Button title={tooltip} disabled={disabled} outline={disabled} onClick={handleBuild}>
      {formatMessage({
        id: "buildStandings",
        defaultMessage: "Build Standings"
      })}
    </Button>
  );
}

export default injectIntl(BuildStandings);

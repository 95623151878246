export const officialRoles = [
  "COMMISSIONER",
  "CREW_CHIEF",
  "GROUNDSKEEPER",
  "MATCH_OFFICIAL",
  "REFEREE",
  "REFEREE_ASSISTANT",
  "REFEREE_ASSISTANT_RESERVE",
  "REFEREE_RESERVE",
  "SCOREKEEPER",
  "SCOREKEEPER_ASSISTANT",
  "STATISTICIAN",
  "TIMEKEEPER",
  "UMPIRE",
  "UMPIRE_1",
  "UMPIRE_2",
  "SHOT_CLOCK_OPERATOR"
];

export default officialRoles;

var formSetup = {
  forms: {
    NewSeasonTeam: {
      title: "Add New Team",
      titletranslation: "team.add",
      endpoint: "v1/#sport/o/#0/seasons/#1/entities",
      parameters: ["organizationId", "seasonId"],
      method: "POST",
      successmessage: "New Team added successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: []
      },
      submit: "Add New Team",
      submittranslation: "team.add",
      fields: [
        {
          name: "entityId",
          size: "col-sm-4 col",
          label: "Team:",
          labeltranslation: "team",
          type: "lookup",
          placeholder: "Team",
          className: "form-control",
          required: false,
          initial: "null",
          lookup: {
            endpoint: "v1/#sport/o/#0/entities?limit=200",
            text: "nameFullLocal",
            value: "entityId",
            parameters: ["organizationId"]
          },
          errors: {
            required: "Team Is Required"
          }
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "conferenceId",
          size: "col-sm-4 col",
          label: "Conference",
          labeltranslation: "conference",
          type: "lookup",
          className: "form-control",
          initial: null,
          lookup: {
            endpoint: "v1/#sport/o/#0/conferences",
            text: "nameLocal",
            value: "conferenceId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "divisionId",
          size: "col-sm-4 col",
          label: "Division",
          labeltranslation: "division",
          type: "custom",
          customItem: "DivisionSelector",
          className: "form-control",
          initial: null
        }
      ]
    },
    EditSeasonTeam: {
      title: "Update team",
      titletranslation: "team.update",
      endpoint: "v1/#2/o/#0/seasons/#1/entities",
      parameters: ["organizationId", "seasonId", "sport"],
      method: "PUT",
      successmessage: "Team updated successfully",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: []
      },
      submit: "Update team",
      submittranslation: "team.update",
      fields: [
        {
          name: "entityId",
          size: "col-sm-4 col",
          label: "Team:",
          labeltranslation: "team",
          type: "text",
          placeholder: "Team",
          className: "form-control",
          required: false,
          initial: "entityId",
          hidden: true
        },
        {
          name: "status",
          size: "col-sm-4 col",
          label: "Status:",
          labeltranslation: "status",
          type: "lookupData",
          placeholder: "Status",
          className: "form-control",
          required: true,
          initial: "status",
          lookupData: "status",
          errors: {
            required: "Status Is Required"
          }
        },
        {
          name: "conferenceId",
          size: "col-sm-4 col",
          label: "Conference",
          labeltranslation: "conference",
          type: "lookup",
          className: "form-control",
          initial: "conferenceId",
          lookup: {
            endpoint: "v1/#sport/o/#0/conferences",
            text: "nameLocal",
            value: "conferenceId",
            parameters: ["organizationId"]
          }
        },
        {
          name: "divisionId",
          size: "col-sm-4 col",
          label: "Division",
          labeltranslation: "division",
          type: "custom",
          customItem: "DivisionSelector",
          className: "form-control",
          initial: "divisionId"
        }
      ]
    }
  }
};
export default formSetup;
